import React  from 'react'
import {ifElse, equals, always} from "ramda";
import {nextDropdown} from "../../../../../../actions/dropdown";
import {withHandlers, compose, setDisplayName, withPropsOnChange, withStateHandlers, lifecycle} from "recompose";
import {connect} from "react-redux";
import {DropdownMenu} from "../../../../../Shared/DropdownMenu/DropdownMenu";
import {Scrollbars} from 'react-custom-scrollbars'
import {getLocaleString} from "../../../../../../locale";

import './Chapters.css'
import {trackName} from "../../utilities";

const DROPDOWN_ID = 'AUDIOBOOK_CHAPTERS'
const isActive = equals(DROPDOWN_ID)

const toggleMenu = ({activeDropdown, nextDropdown}) => ({isInner = false}) => ifElse(
  equals(true),
  () => nextDropdown(null),
  () => ifElse(
    equals(false),
    () => nextDropdown(DROPDOWN_ID),
    always(null)
  )(isInner)
)(isActive(activeDropdown))

const mapStateToProps = state => ({
  activeDropdown: state.activeDropdown
})

const mapDispatchToProps = (dispatch) => ({
  nextDropdown: (id) => dispatch(nextDropdown(id))
})

const handlers = withHandlers({
  toggleMenu
})

const state = {
  componentNode: React.createRef()
}

const stateHandlers = {
  handleClickOutside: ({componentNode}, {activeDropdown, nextDropdown}) => (event) => {
    if (componentNode.current && !componentNode.current.contains(event.target)) {
      if (activeDropdown === DROPDOWN_ID) nextDropdown(null)
    }
  }
}

export const enhance = compose(
  setDisplayName('AudioBook Chapters'),
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['activeDropdown'], ({activeDropdown}) => ({
    active: isActive(activeDropdown)
  })),
  handlers,
  withStateHandlers(state, stateHandlers),
  lifecycle({
    UNSAFE_componentWillMount() {
      document.addEventListener('mousedown', this.props.handleClickOutside);
    },
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.props.handleClickOutside);
    }
  })
)

export const ChapterList = ({chapters, trackNumber, changeTrack}) => (
  <ul className="play-list">
    {chapters.length > 0 ? chapters.map((chapter, index) =>
     <ChapterItem key={index} chapter={chapter} index={index} trackNumber={trackNumber}
                  changeTrack={changeTrack} chapterName={trackName(chapters, trackNumber)}
     />)
     : null}
  </ul>
)

const ChapterItem = ({chapter, index, trackNumber, changeTrack, chapterName}) => (
  <li className={ parseInt(index, 10) === parseInt(trackNumber, 10) ? "play-list-item active" : "play-list-item"}
      onClick={(e) => { e.preventDefault(); changeTrack(chapter.name, null, index)}}
  >
    <p className="track-item">
      <span className="track-name">{`${index + 1}. ${chapter.title}`}</span>
      <span className="track-duration">{chapter.metadata.duration}</span>
    </p>
  </li>
)

const View = ({toggleMenu, active, chapters, trackNumber, changeTrack, componentNode}) => (
  <div className="AudioBook__chapters AudioBook__dropdown" ref={componentNode}>
    <button className="player-button chapters-button" onClick={toggleMenu} data-active={active}></button>
    <DropdownMenu active={active}>
      <div className="Chapters__header Dropdown__header">
        <span className="Chapters__header-text">{getLocaleString('books.chaptersTitle')}</span>
      </div>
      <div className="Chapters__content limit-content-height">
        <Scrollbars style={{ width: 315, height: 432 }}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
        >
          <ChapterList chapters={chapters} toggleMenu={toggleMenu} trackNumber={trackNumber}
                       changeTrack={changeTrack}/>
        </Scrollbars>
      </div>
    </DropdownMenu>
  </div>
)

export const Chapters = enhance(View)