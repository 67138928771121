import React from 'react'
import {ifElse, equals, always} from "ramda";
import {nextDropdown} from "../../../../../../actions/dropdown";
import {withHandlers, compose, setDisplayName, withPropsOnChange, withStateHandlers, lifecycle} from "recompose";
import {connect} from "react-redux";
import {DropdownMenu} from "../../../../../Shared/DropdownMenu/DropdownMenu";
import {Scrollbars} from 'react-custom-scrollbars'
import {getLocaleString} from "../../../../../../locale";
import {formatTime, sortList} from "../../utilities";

import './Bookmarks.css'
import {cropText} from "../../../../../../utilities";
import {EditNote} from "./components/EditNote/EditNote";

const DROPDOWN_ID = 'AUDIOBOOK_BOOKMARKS'
const isActive = equals(DROPDOWN_ID)

const toggleMenu = ({activeDropdown, nextDropdown}) => ({isInner = false}) => ifElse(
  equals(true),
  () => nextDropdown(null),
  () => ifElse(
    equals(false),
    () => nextDropdown(DROPDOWN_ID),
    always(null)
  )(isInner)
)(isActive(activeDropdown))

const mapStateToProps = state => ({
  activeDropdown: state.activeDropdown,
  marks: state.books.audioBook.files.mark,
  chapters: state.books.audioBook.files.chapters,
  fetchingFiles: state.books.audioBook.fetchingFiles
})

const mapDispatchToProps = (dispatch) => ({
  nextDropdown: (id) => dispatch(nextDropdown(id))
})

const handlers = withHandlers({
  toggleMenu
})

const state = {
  componentNode: React.createRef()
}

const stateHandlers = {
  handleClickOutside: ({componentNode}, {activeDropdown, nextDropdown}) => (event) => {
    if (componentNode.current && !componentNode.current.contains(event.target)) {
      if (activeDropdown === DROPDOWN_ID) nextDropdown(null)
    }
  }
}

export const enhance = compose(
  setDisplayName('AudioBook Bookmarks'),
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['activeDropdown'], ({activeDropdown}) => ({
    active: isActive(activeDropdown)
  })),
  handlers,
  withStateHandlers(state, stateHandlers),
  lifecycle({
    UNSAFE_componentWillMount() {
      document.addEventListener('mousedown', this.props.handleClickOutside);
    },
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.props.handleClickOutside);
    }
  })
)

export const BookmarkList = ({marks, changeTrack, onRemoveMarkDown, onEditNote, openEditNote, editNoteNode,
                               closeEditNote, onUpdateBookmark, chapters}) => (
  <ul className="mark-list">
    {marks.length > 0 ? marks.sort((a,b)=>sortList(a,b)).map((mark, index) =>
    <BookmarkItem key={index} mark={mark} changeTrack={changeTrack} onEditNote={onEditNote}
                  onRemoveMarkDown={onRemoveMarkDown} openEditNote={openEditNote} editNoteNode={editNoteNode}
                  closeEditNote={closeEditNote} onUpdateBookmark={onUpdateBookmark} chapters={chapters}/>)
    : null}
  </ul>
)

const getDate = (mark) => {
  if (mark.date.indexOf('T') > -1) {
    return mark.date.split('T')[0]
  }
  return mark.date.split(' ')[0]
}

const getTitle = (page, chapters) => {
  if (!chapters) return page
  let title = page
  for(let i=0;i<chapters.length;i++) {
    let chapter = chapters[i]
    if (chapter.name === page) {
      title = chapter.title
      break
    }
  }
  return title
}

const BookmarkItem = ({mark, changeTrack, onRemoveMarkDown, onEditNote, openEditNote, editNoteNode,
                        closeEditNote, onUpdateBookmark, chapters}) => (
  <li className="mark-list-item">
    <p className="mark-item" onClick={() => changeTrack(mark.page, mark)}>
      <span className="mark-played">{getTitle(mark.page, chapters)}</span>
      <span className="mark-date">{formatTime(mark.offs/1000)} {mark.percent ? `(${mark.percent}%)`: ''} - {getDate(mark)}</span>
      <MarkNote mark={mark} openEditNote={openEditNote} editNoteNode={editNoteNode}
                closeEditNote={closeEditNote} onUpdateBookmark={onUpdateBookmark}/>
    </p>
    <div className="mark-buttons">
      <button className="edit-markdown" onClick={() => onEditNote(mark)}></button>
      <button className="remove-markdown" onClick={(e) => onRemoveMarkDown(e, mark)} ></button>
    </div>
  </li>
)

const MarkNote = ({mark, openEditNote, editNoteNode, closeEditNote, onUpdateBookmark}) => {
  if (mark === openEditNote) {
    return <EditNote editNoteNode={editNoteNode} closeEditNote={closeEditNote} note={mark.name} mark={mark}
                     onUpdateBookmark={onUpdateBookmark}/>
  } else if (mark.name) {
    return <span className="mark-note">{cropText(150)(mark.name)}</span>
  }
  return null
}

const View = ({toggleMenu, active, marks, componentNode, changeTrack, onRemoveMarkDown, onEditNote,
                openEditNote, editNoteNode, closeEditNote, onUpdateBookmark, chapters}) => (
  <div className="AudioBook__bookmarks AudioBook__dropdown" ref={componentNode}>
    <button className="player-button bookmarks-button" onClick={toggleMenu} data-active={active}></button>
    <DropdownMenu active={active}>
      <div className="Bookmarks__header Dropdown__header">
        <span className="Bookmarks__header-text">{getLocaleString('books.bookmarksTitle')}</span>
      </div>
      <div className="Bookmarks__content limit-content-height">
        <Scrollbars style={{ width: 315, height: 432 }}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
        >
          <BookmarkList marks={marks} toggleMenu={toggleMenu} onRemoveMarkDown={onRemoveMarkDown} changeTrack={changeTrack}
                        onEditNote={onEditNote} openEditNote={openEditNote} editNoteNode={editNoteNode}
                        closeEditNote={closeEditNote} onUpdateBookmark={onUpdateBookmark} chapters={chapters}/>
        </Scrollbars>
      </div>
    </DropdownMenu>
  </div>
)

export const Bookmarks = enhance(View)