import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {equals, ifElse, isEmpty} from 'ramda'

import { getLocaleString } from '../../../../../../../../locale'

import './SearchResults.css'
import { hiddenBlocks } from "../../../../../../../../constants";
import {checkLcp, isDevice} from "../../../../../../../../utilities";
import {Scrollbars} from "react-custom-scrollbars";

const SearchResults = ({nextDropdown, resultsLength, resetSearch, onToggleSearch, history, active, books, audioBooks, authors, collections, recentlyRead,
                         language, userId, toggleActiveLogin, setUploadErrors, addNotification, internal_email }) => {

  history.listen(() =>  {
    resetSearch()
    nextDropdown(null)
  })

  books.length = resultsLength
  audioBooks.length = resultsLength
  authors.length = resultsLength
  collections.length = resultsLength
  recentlyRead.length = resultsLength

  const clickOpenCurrent = (path) => onToggleSearch() || history.push(path)

  const WrapInLink = ({classes, render, name, url, isAudioBook, fast_hash, internal_email, language, isLcp, isDrm,
                        toggleActiveLogin, setUploadErrors, addNotification, link}) => ifElse(
      equals(false),
      () => (<span className={classes} rel={"noopener noreferrer"}
                onClick={(e) => checkLcp(e, url, link, language, isLcp, isDrm, name, toggleActiveLogin, setUploadErrors, addNotification, fast_hash, internal_email, false)}
      >
        {render()}
      </span>),
      () => (url !== null && !isDevice() ? <Link className={classes} to={`/${language}/user/${userId}${url}`}>
        {render()}
      </Link> : <span className={classes} rel={"noopener noreferrer"}
                   onClick={(e) => checkLcp(e, url, link, language, isLcp, isDrm, name, toggleActiveLogin, setUploadErrors,
                       addNotification, fast_hash, internal_email, false)}>{render()}</span>)
  )(isAudioBook)

  const renderBook = (book) => {
    const bookCover = book.cover.length > 0 ? { backgroundImage: `url("${book.cover}")`, backgroundSize: 'cover' } : {}
    return (
      <li className="results-item" key={book.id}>
        <WrapInLink  fast_hash = {book.fast_hash} internal_email={internal_email} url={book.uri} name={book.name} language={language}
                     isAudioBook={book.isAudioBook} isLcp={book.isLcp} toggleActiveLogin={toggleActiveLogin}
                     classes={`results-wrapper-link${(book.isAudioBook && book.uri !== null) || (book.uri !== null && book.link) !== null ? ' with-cursor' : ''}`}
                     link={book.link}
                     setUploadErrors={setUploadErrors} addNotification={addNotification} render={() => (
                <div className="results-book-wrapper">
                  <div className={`results-book-wrapper__book-cover${book.isAudioBook ? ' isAudioBook' : ''}`} style={bookCover}>
                    {!book.cover ? <i></i> : ''}
                  </div>
                  <h3 className="results-book-wrapper__book-title">{book.title}</h3>
                </div>
            )}/>
      </li>
  )}

  const renderListItem = (index, path, target, propertyName) => (
    <li key={target.id || index} className="results-item">
      <a onClick={() => clickOpenCurrent(`${path}${target[propertyName]}`)} href={`${path}${target[propertyName]}`}>{target[propertyName]}</a>
    </li>
  )

  const renderRecntly = (index, recently) => (
    <li key={index} className="results-item">
      {recently.isAudioBook ? recently.uri !== null && !isDevice() ?
          <Link to={`/${language}/user/${userId}${recently.uri}`}>{`${recently.title}`}</Link> :
          <a onClick={(e) => {checkLcp(e, recently.uri, recently.link, language, recently.isLcp, recently.isDrm, recently.name, toggleActiveLogin, setUploadErrors, addNotification,
              recently.fast_hash, internal_email); onToggleSearch()}} href={`/${recently.uri}`}>{`${recently.title}`}</a>
      : recently.link ? <a onClick={(e) => {checkLcp(e, recently.uri, recently.link, language, recently.isLcp, recently.isDrm, recently.name, toggleActiveLogin, setUploadErrors, addNotification,
              recently.fast_hash, internal_email); onToggleSearch()}} href={`/${recently.uri}`}>{`${recently.title}`}</a> : <span>{`${recently.title}`}</span>
      }
    </li>
  )
  const booksList = books.map((book) => renderBook(book))
  const audioBooksList = audioBooks.map((book) => renderBook(book))
  const authorsList = authors.map((author, index) => renderListItem(index, `/${language}/user/${userId}/books/author/`, author, 'author'))
  const collectionsList = collections.map((collection, index) => renderListItem(index, `/${language}/user/${userId}/books/collection/`, collection, 'name'))
  const recentlyReadList = recentlyRead.map((recently, index) => renderRecntly(index, recently))

  if (!active) return null

  const renderList = (list) => {
    if (isEmpty(list)) {
      return (
        <li className="results-item">
          <p>{getLocaleString('search.noItems')}</p>
        </li>)
    } else {
      return list
    }
  }

  return (
    <div className="SearchResults">
      <Scrollbars style={{ width: '100%', height: '500px' }}
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}>
        <div className="wrapper">
          <div className="results-container">

            <div className="recently">
              <h2 className="title">{getLocaleString('search.recentlyRead')}</h2>
              <ul className="results-list">
                {
                  renderList(recentlyReadList)
                }
              </ul>
            </div>

            <div className="collections">
              <h2 className="title">{getLocaleString('search.collections')}</h2>
              <ul className="results-list">
                {
                  renderList(collectionsList)
                }
              </ul>
            </div>

            <div className="authors">
              <h2 className="title">{getLocaleString('search.authors')}</h2>
              <ul className="results-list">
                {
                  renderList(authorsList)
                }
              </ul>
            </div>
          </div>
          <div className="results-container">
            <div className="books">
              <h2 className="title">{getLocaleString('search.books')}</h2>
              <ul className="results-list">
                {
                  renderList(booksList)
                }
                </ul>
            </div>
            {hiddenBlocks.audioBooks ? '' :
              <div className="books">
                <h2 className="title">{getLocaleString('search.audioBooks')}</h2>
                <ul className="results-list">
                  {
                    renderList(audioBooksList)
                  }
                </ul>
              </div>
            }
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default withRouter(SearchResults);
