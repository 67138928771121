import adobe1 from '../../assets/images/adobe1.jpg'
import adobe2 from '../../assets/images/adone2.jpg'
import adobe3 from '../../assets/images/adobe3.jpg'
import adobe4 from '../../assets/images/adobe4.jpg'

export const section1 = () => (`
 <h2>Как войти</h2>
 <p>Вы можете войти в  PocketBook Cloud через веб-сайт, через приложение PocketBook  для чтения с мобильных устройств (для Android и IOS) или с электронной книги PocketBook.</p>
      <p><span>Web:</span> Для входа на сайт, введите логин и пароль своей учетной записи в партнерском книжком магазине. 
Также Облако поддерживает авторизацию через Facebook или Google. Если вы зарегистрированы в книжном магазине, используя Facebook или Google, то вы можете войти в Облако таким же способом.</p>
      <h2>Вход в Pocketbook Cloud на других устройствах</h2>
      <p>Для того, чтобы войти с <span>электронной книги PocketBook</span> перейдите в раздел Настройки > Учетные записи и синхронизация > PocketBook Облако и введите адрес вашей электронной почты  и пароль.</p>
      <p>Для того, чтобы войти с вашего <span>Android устройства</span> войдите в приложение для чтения PocketBook, перейдите в боковое меню и выберите раздел PocketBook Облако. Введите  адрес вашей электронной почты  и пароль и нажмите кнопку Вход.</p>
      <p>Для того, чтобы войти с <span>устройства IOS</span> перейдите в приложение для чтения PocketBook, выберите Настройки > PocketBook Облако. Введите  адрес вашей электронной почты  и пароль и нажмите кнопку Вход.</p>
      <h2>Как зарегистрироваться</h2>
      <p>Если у вас нет учетной записи, нажмите на ссылку  "Нет учетной записи". Отобразится список партнерских книжных магазинов. Чтобы продолжить работу, пожалуйста, выберите один из предложенных книжных магазинов, либо воспользуйтесь поиском. Вы будете перенаправлены на сайт книжного магазина партнера. Перейдите к форме регистрации и создайте новую учетную запись, следуя инструкциям. После этого вернитесь на сайт</p>
      <p><span>PocketBook E-ink reader:</span> Во время первоначальной настройки устройства Вам будет предложено войти в PocketBook Облако.   Если вы пропустили этот шаг, предложение войти в учетную запись PocketBook Облако появится на панели уведомлений. Чтобы войти в систему, нажмите на это уведомление или перейдите в раздел Настройки > Учетные записи и синхронизация>PocketBook Облако. Перейдите по ссылке Нет учетной записи . В браузере откроется список партнеров книжных магазинов. Чтобы продолжить работу, пожалуйста, выберите один из предложенных книжных магазинов, щелкнув на его название. Вы будете перенаправлены на сайт книжного магазина партнера. Перейдите к форме регистрации и создайте новую учетную запись, следуя инструкциям. После этого, пожалуйста, вернитесь в раздел Настройки > Учетные записи и синхронизация> PocketBook Облако, введите личную информацию, которую вы использовали для создания учетной записи книжного магазина партнера и нажмите кнопку Зарегистрироваться.</p>
      <p><span>Android устройства:</span> зайдите в приложение для чтения PocketBook, перейдите в боковое меню и выберите раздел PocketBook Облако. Перейдите по ссылке Нет учетной записи . В браузере откроется список партнеров книжных магазинов. Чтобы продолжить работу, пожалуйста, выберите один из предложенных книжных магазинов, щелкнув на его название. Вы будете перенаправлены на сайт книжного магазина партнера. Перейдите к форме регистрации и создайте новую учетную запись, следуя инструкциям.  После этого, пожалуйста, вернитесь в приложение для чтения PocketBook, выберите PocketBook Облако в разделе Настройки на странице входа в систему введите личную информацию, которую вы использовали для создания учетной записи книжного магазина партнера и нажмите кнопку Зарегистрироваться. <span>Download PocketBook Reader app for Android <a href="https://play.google.com/store/apps/details?id=com.obreey.reader">https://play.google.com/store/apps/details?id=com.obreey.reader</a></span></p>
      <p><span>IOS:</span> Для входа в систему с устройства IOS  зайдите в приложение для чтения PocketBook, выберите Настройки &gt; PocketBook Облако. Перейдите по ссылке Нет учетной записи . В браузере откроется список партнеров книжных магазинов. Чтобы продолжить работу, пожалуйста, выберите один из предложенных книжных магазинов, щелкнув на его название. Вы будете перенаправлены на сайт книжного магазина партнера. Перейдите к форме регистрации и создайте новую учетную запись, следуя инструкциям.  После этого, пожалуйста, вернитесь в приложение для чтения PocketBook, выберите PocketBook Облако в разделе Настройки на странице входа в систему введите личную информацию, которую вы использовали для создания учетной записи книжного магазина партнера и нажмите кнопку Зарегистрироваться. <span>Download PocketBook Reader app for iOS: <a href="https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?l=ru&mt=8">https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?l=ru&mt=8</a></span></p>
      <h2>Забыл пароль</h2>
      <p>Восстановить пароль можно на сайте Вашего магазин. Вход в Облако происходит с логином и паролем магазна.</p>
      <h2>Как изменить пароль</h2>
      <p>Изменить пароль можно на сайте Вашего магазина. Вход в Облако происходит с логином и паролем магазна.</p>
      <h2>Добавить /Регистрация Adobe ID</h2>
      <p>Для того, чтобы добавить Adobe ID, пожалуйста, перейдите в Settings/AdobeID и нажмите кнопку Добавить Adobe ID. Выберите из списка свой Vendor и введите логин и пароль. 
Имея несколько идентификаторов учетных записей Adobe, вы можете выбрать один из них в качестве основного Adobe ID учетной записи ("Использовать по умолчанию"). Он будет использоваться для загрузки электронных книг в PocketBook облако.
Чтобы посмотреть пароль основной учетной записи Adobe ID (Vendor: Pocketbook), Вы можете нажать на значок глаза</p>
      <h2>Регистрация Adobe ID:</h2>
      <p>Пейдите на страницу Синхронизация аккаунта Adobe</p>
      <img src="`+adobe1+`" />
      <p>Выберите свой Adobe ID и нажмите Зарегистрироваться</p>
      <img src="`+adobe2+`" />
      <p>Выберите PocketBook Vendor из списка: обратите внимание на то, что вы можете использовать только один eBook Vendor для нескольких Vendor ID’s; Укажите второй vendor Account ID и пароль</p>
      <img src="`+adobe3+`" />
      <p>Для того, чтобы добавить несколько учетных записей Adobe, пожалуйста, сделайте следующее Нажмите кнопку Синхронизация Аккаунтов. Теперь вы можете легко передавать контент между несколькими Vendor устройствами.</p>
      <img src="`+adobe4+`" />
`)

export const section2 = () => (`
<h2>Как проходит синхронизация между моими устройствами</h2>
 <p>Вы можете читать книги на различных устройствах и быть уверенным, что все ваши заметки, закладки и прогресс чтения будет сохранен. Вы будете иметь доступ к книгам с любого устройства, с которого вы вошли в PocketBook Облако.</p>
      <p><span>On the web</span> Авто синхронизация списка книг и чтения позиции происходит в момент входа в PocketBook Облако и закрытия книги.</p>
      <p><span>On e-ink devices</span> Авто синхронизация списка книг и чтения позиции происходит в момент входа в PocketBook Облако и закрытия книги. Для принудительной синхронизации нажмите на значок Синхронизация на панели уведомлений.</p>
      <p><span>On Android devices</span> Авто синхронизация списка книг и чтения позиции происходит в момент входа в PocketBook Облако и закрытия книги. Для принудительной синхронизации нажмите на значок Синхронизация в Библиотеке.</p>
      <h2>Как приостановить и возобнить синхронизацию</h2>
      <p>Нет возможности прервать синхронизацию, но обратите внимание на то, что синхронизация будет отключена, если нет подключения к Интернету.</p>
      <h2>Cмогу ли я получить доступ к моим книгам в Облаке без подключения к Интернету</h2>
      <p>Если нет подключения к Интернету на вашем устройстве, то у вас будет доступ только к книгам из вашей последней синхронизации.</p>
      <h2>Ошибки синхронизации</h2>
      <p>Если произошла ошибка во время синхронизации, пожалуйста, проверьте подключение к Интернету. Если подключение к Интернету установлено корректно попытайтесь синхронизировать ваши книги заново, так как возможно был сбой на сервере.</p>
`)
export const section3 = () => (`
<h2>Загрузка новых книг</h2>
<p>Вы можете загружать новые книги в PocketBook Облако через веб-сайт, с электронной книги PocketBook или с Android / IOS приложения для чтения PocketBook. После синхронизации все новые книги будут автоматически сохранены в Вашем кабинете в PocketBook Облаке.</p>
      <h2>Какие форматы поддерживает Pocketbook облако</h2>
      <p>PocketBook Облако поддерживает все форматы, имеющиеся на электронной книге PocketBook и в приложении для чтения на мобильных устройствах PocketBook Reader (PDF (Adobe DRM), EPUB (Adobe DRM), DJVU, TXT, FB2, FB2.ZIP, CHM, HTML (основной), CBZ, CBR, СBT, RTF)</p>
      <h2>Будет ли книга храниться на устройстве после синхронизации</h2>
      <p>Да, если книга была загружена из PocketBook Облака на устройство.</p>
      <h2>Как удалить файлы</h2>
      <p>Для того, чтобы удалить книгу с помощью веб-сайта PocketBook Облако, выберете пункт "Удалить" из выпадающего меню книги. (Для вызова выпадающего меню - нажмите иконку в виде трех точек). 
Для удаления нескольких книг одновременно, можно выделить эти книги при помощи Ctrl+левая кнопка мышки. После чего нажать на иконку удаления в верхней строке меню. 
Чтобы удалить книгу из вашей учетной записи, используя электронную книгу  PocketBook, или приложение для чтения на мобильных устройствах Android или IOS,  выделите книгу из списка библиотеки и откройте контекстное меню. Вам будет предложено два варианта - Удалить (из памяти устройства) и Удалить из Облака</p>
      <h2>Можно ли восстановить файлы</h2>
      <p>Если файл был сохранен в PocketBook Облаке и был удален только из памяти устройства, он заново появится после следующей синхронизации  Если файл был удален из Облака, то  нет возможности его восстановить.</p>
      <h2>Как скачать книги с облака</h2>
      <p>Для того, чтобы скачать книгу из PocketBook Облако на ваш компьютер, выберете пункт "Скачать" из выпадающего меню книги. (Для вызова выпадающего меню - нажмите иконку в виде трех точек).</p>
      <p>Для скачивания нескольких книг одновременно, можно выделить эти книги при помощи Ctrl+левая кнопка мышки. После чего нажать на иконку загрузки в верхней строке меню.</p>
      <p>Чтобы скачать книгу в память вашей электронной книги, или на мобильное устройство Android или IOS, просто в Облаке нажмите на книгу из списка книг в библиотеке и процесса загрузки начнется автоматически.</p>
      <h2>Как читать книги из браузера</h2>
      <p>На данный момент можно прочитать из браузера книги в формате EPUB, FB2 и PDF.</p>
      <p>Чтобы открыть книгу в браузере, нажмите на обложку книги (в режиме "плитка"), либо на строку с книгой (в режиме "список"). В новом окне откроется книга в веб-ридере. Также можно открыть книгу из пункта "Информация о книге" выпадающего меню.</p>
      <p>Чтение из браузера является также предметом синхронизации, так что ваши позиции чтения, закладки и заметки будут сохранены. Чтобы выйти из книги -  нажмите на значок "Выход" в левом углу окна приложения.</p>            
`)

const faq = {
  account: 'Аккаунт',
  sync: 'Синхронизация',
  storage: 'Файловое хранилище',
  section1: section1,
  section2: section2,
  section3: section3
}
export default faq;