import React from 'react'
import { connect } from 'react-redux'
import { always, equals, ifElse, pick } from 'ramda'
import { compose, setDisplayName, withPropsOnChange, withHandlers } from 'recompose'

import { nextDropdown } from '../../../../../../actions/dropdown'

import './HeaderMenu.css'

import { Sidebar } from '../../../Sidebar/Sidebar'
import { DropdownMenu } from '../../../../../Shared/DropdownMenu/DropdownMenu'

const DROPDOWN_ID = 'HEADER_MENU_DROPDOWN'

const isActive = equals(DROPDOWN_ID)

const ToggleButton = ({active, toggleHeaderMenu}) => (
  <button
    className="HeaderMenu__toggle"
    data-active={active}
    onClick={toggleHeaderMenu}>
    <div className="HeaderMenu__toggle-bar" />
    <div className="HeaderMenu__toggle-bar" />
    <div className="HeaderMenu__toggle-bar" />
  </button>
)

const toggleMenu = ({active, nextDropdown}) => ({isInner = false}) => ifElse(
  equals(true),
  () => nextDropdown(null),
  () => ifElse(
    equals(false),
    () => nextDropdown(DROPDOWN_ID),
    always(null)
  )(isInner)
)(active)

const handlers = withHandlers({
  toggleMenu
})

const mapStateToProps = pick(['activeDropdown'])

const mapDispathToProps = (dispatch) => ({
  nextDropdown: (id) => dispatch(nextDropdown(id))
})


export const enhance = compose(
  setDisplayName('HeaderMenu'),
  connect(mapStateToProps, mapDispathToProps),
  withPropsOnChange(['activeDropdown'], ({activeDropdown}) => ({
    active: isActive(activeDropdown)
  })),
  handlers
)

export const View = ({active, toggleMenu, isSettings, location}) => (
  <div className="HeaderMenu">
    <ToggleButton active={active} toggleHeaderMenu={toggleMenu} />
    <DropdownMenu active={active}>
      <div className="HeaderMenu__Sidebar" data-active={active}>
        <div className="HeaderMenu__Sidebar-shadow" onClick={toggleMenu}></div>
        <Sidebar closeHeaderMenu={toggleMenu} renderUserOptions={true} isSettings={isSettings} location={location} isMobile={true}/>
      </div>
    </DropdownMenu>
  </div>
)

export const HeaderMenu = enhance(View)
