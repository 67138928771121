export const infoText = () => (`
  <div data-ng-bind-html="text" class="break-word ng-binding">
    In order to create an account in PocketBook Cloud follow the instructions below:
    <br><br>
    1. Find the bookstore near you. 
    <br>
    2. Go to the bookstore's website and create an account.
    <br>
    3. Return to the PocketBook Cloud and sign in with created bookstore's account.
  </div>
`)

const findStore = {
  infoText: infoText,
  okay: 'GOT IT',
  findModalTitle: 'Find your nearest Bookstore',
  findModalSearch: 'Name, address, zip code',
  redirect_To_Registration: 'Register now'
}
export default findStore;