import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { always, ifElse, equals, not, isNil, pathOr, pipe, prop, head, toUpper, when, split, join, map } from 'ramda'
import { lifecycle, compose, setDisplayName, withPropsOnChange, withHandlers, defaultProps, renderComponent, branch, withStateHandlers } from 'recompose'
import { Link } from 'react-router-dom'

import { logout } from '../../../../../../actions/user'
import { nextDropdown } from '../../../../../../actions/dropdown'
import { formatBytes, notChildren } from '../../../../../../utilities'
import { getLocaleString } from '../../../../../../locale'

import './User.css'

import { InnerLink } from '../../../../../Shared/InnerLink/InnerLink'
import { DropdownMenu } from '../../../../../Shared/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from '../../../../../Shared/DropdownMenu/components/DropdownMenuItem/DropdownMenuItem'
import { Collapsable } from '../../../../../Shared/Collapsable/Collapsable'

import { hiddenBlocks } from "../../../../../../constants";
import {NavigationLink} from "../../../Sidebar/components/NavigationLink/NavigationLink";

const DROPDOWN_ID = 'USER_DROPDOWN'

const isActive = equals(DROPDOWN_ID)

const getFirstUpper = pipe(head, toUpper)

const getInitials = when(
  pipe(isNil, not),
  pipe(split(' '), map(getFirstUpper), join(''))
)

const Settings = (props) => (
  <Link to={`/${props.language}/user/${props.userId}/settings/account`} style={{display: 'block'}}>{getLocaleString('settings.settings')}</Link>
)

const Logout = ({onLogout}) => (
  <div onClick={onLogout}>{getLocaleString('settings.logOut')}</div>
)

const SpaceInfo = ({ files_size, files_size_limit, language, userId }) => (
  <div className="User__space-info">
    <p className={`User__space-info-text${hiddenBlocks.account.getMoreSpace ? ' short' : ''}`}>{getLocaleString('settings.used')} {formatBytes(files_size || 0)} {getLocaleString('settings.outOf')} {formatBytes(files_size_limit)}</p>
    {hiddenBlocks.account.getMoreSpace ? '' : <InnerLink to={`/${language}/user/${userId}/settings/plan/change`}>{getLocaleString('settings.getMoreSpace')}</InnerLink>}
  </div>
)

const menuOptions = [
  {
    id: 'SpaceInfo',
    action: false,
    render: SpaceInfo
  },
  {
    id: 'settings',
    action: true,
    render: Settings
  },
  {
    id: 'logout',
    action: true,
    render: Logout
  },
]


const Avatar = ({username, avatar}) => ifElse(
  isNil,
  always(<span className="User__avatar-ph">{getInitials(username)}</span>),
  always(<div className="User__avatar" style={{backgroundImage: `url(${avatar})`}} />)
)(avatar)

const toggleMenu = ({active, nextDropdown}) => ({isInner = false}) => ifElse(
  equals(true),
  () => nextDropdown(null),
  () => ifElse(
    equals(false),
    () => nextDropdown(DROPDOWN_ID),
    always(null)
  )(isInner)
)(active)

const onClickOutSide = ({nextDropdown, activeDropdown}) => (e) => {
  let menu = []
  notChildren(menu, e.target, document.getElementsByClassName('User'))
  if (menu.indexOf(false) < 0 && activeDropdown === DROPDOWN_ID) {
    nextDropdown(null)
  }
}

const handlers = withHandlers({
  toggleMenu,
  onClickOutSide
})

const RenderOptionsInDropdown = (options) => ({storageSpaceInfo: {files_size_limit, files_size}, avatar, toggleMenu, active, onLogout, userData: { full_name }, language, userId}) => (
  <Fragment>
    <div className="User__info" onClick={toggleMenu}>
      <div className="User__avatar-wrapper">
        <Avatar username={full_name} avatar={avatar} />
      </div>
      <span className={`User__username`}>{full_name}</span>
    </div>
    <DropdownMenu onClose={toggleMenu} active={active} withoutLeave={true}>
      {map(({action, render, id}) => (
        <DropdownMenuItem key={id} action={action}>
          {render({onLogout, files_size, files_size_limit, language, userId})}
        </DropdownMenuItem>
      ))(options)}
    </DropdownMenu>
  </Fragment>
)

const SettingsMenu = ({language, userId}) => (
  <nav className="DropdownMenuItem-subitems">
    <NavigationLink to={`/${language}/user/${userId}/settings/account`} label={getLocaleString('sidebar.yourAccount')}/>
    <NavigationLink to={`/${language}/user/${userId}/settings/adobe`} label={getLocaleString('sidebar.adobeID')}/>
    {hiddenBlocks.settings.readRate ? '' : <NavigationLink to={`/${language}/user/${userId}/settings/readrate`} label={getLocaleString('sidebar.readRate')}/>}
    {hiddenBlocks.settings.plan ? '' : <NavigationLink to={`/${language}/user/${userId}/settings/plan`} label={getLocaleString('sidebar.plan')}/>}
  </nav>
)


const RenderOptionsInCollapsable = (options) => ({userId, isSettings, storageSpaceInfo: {files_size_limit, files_size}, avatar, onLogout, onCollapse, collapsed, onCloseMenu, userData: { full_name }, language }) => (
  <Fragment>
    <div className={`User__info${isSettings ? ' inSettings' : ''}`} onClick={onCollapse}>
      <div className="User__avatar-wrapper">
        <Avatar username={full_name} avatar={avatar} />
      </div>
      <span className={`User__username${isSettings ? ' hide-arrow' : ''}`}>{full_name}</span>
    </div>
    <Collapsable collapsed={isSettings ? false : collapsed}>
      {map(({action, render, id}) => (
        <DropdownMenuItem key={id} action={action} onClick={onCloseMenu}>
          {render({onLogout, files_size, files_size_limit, language, userId})}
          {id === 'settings' ?
            <SettingsMenu closeHeaderMenu={onCloseMenu} language={language} userId={userId}/>
          : ''}
        </DropdownMenuItem>
      ))(options)}
    </Collapsable>
  </Fragment>
)

const RenderOptions = branch(
  ({ type }) => equals('dropdown', type),
  renderComponent(RenderOptionsInDropdown(menuOptions)),
  renderComponent(RenderOptionsInCollapsable(menuOptions))
)()

const state = {
  collapsed: true
}

const stateHandlers = {
  onCollapse: ({collapsed}) => () => ({
    collapsed: not(collapsed)
  })
}

const props = withPropsOnChange(['activeDropdown'], ({activeDropdown}) => ({
  active: isActive(activeDropdown),
}))

const defProps = defaultProps({
  type: 'dropdown',
  onCloseMenu: () => {}
})

const mapStateToProps = state => ({
  activeDropdown: state.activeDropdown,
  userData: pathOr({ full_name: 'Anonymous Reader', }, ['userData', 'data'], state),
  storageSpaceInfo: pathOr({ files_size: 0, files_size_limit: 0 }, ['notifications', 'storageSpaceInfo'], state),
  language: state.userData.language,
  userId: state.userData.data ? state.userData.data.user_id : null,
  avatar: state.login.avatar
})

const mapDispathToProps = (dispatch) => ({
  onLogout: () => dispatch(logout()),
  nextDropdown: (id) => dispatch(nextDropdown(id))
})

export const enhance = compose(
  setDisplayName('User'),
  connect(mapStateToProps, mapDispathToProps),
  props,
  withStateHandlers(state, stateHandlers),
  handlers,
  lifecycle({
    componentDidMount() {
      document.addEventListener('mousedown', this.props.onClickOutSide)
    },
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.props.onClickOutSide)
    }
  }),
  defProps
)

const isMenuActive = ifElse(
  ({type}) => equals('collapsable', type),
  ({collapsed}) => equals(false, collapsed),
  ({active}) => equals(true, active)
)

export const View = (props) => (
  <div className="User" data-active={isMenuActive(props)} data-type={prop('type', props)}>
    <RenderOptions {...props} />
  </div>
)

export const User = enhance(View)
