const books = {
  uploadBook: 'Загрузить новую книгу',
  sortBy: 'Сортировать по',
  recent: 'Дате',
  byRecent: 'По дате',
  byRecentTitle: 'Недавно прочитанные',
  byTitle: 'По названию',
  byAuthor: 'По автору',
  byProgress: 'По прогрессу',
  title: 'Названию',
  titleInList: 'Название',
  author: 'Автору',
  authorInList: 'Автор',
  readingProgress: 'Прогрессу чтения',
  progress: 'Прогресс',
  lastRead: 'Дата активности',
  dateAdded: 'Дата добавления',
  format: 'Формат',
  size: 'Размер',
  collectionNotEmpty: 'Название коллекции не может быть пустым',
  collectionNameTaken: 'Это название уже используется',
  book: 'книга',
  books: 'книги',
  e_book: 'Электронная книга',
  e_books: 'Электронные книги',
  selected: 'выделена',
  selected_more_than_one: 'выделено',
  delete: 'Удалить',
  backTo: 'Вернуться к',
  noBooksInCollection: 'В коллекции еще нет книг. ',
  addBooksToCatalogue: 'Чтобы добавить новые книги, перейдите в каталог',
  allBooks: 'Все книги',
  sureDeleteBook: 'Вы действительно хотите удалить выбранные книги?',
  sureDelete: 'Вы уверены, что хотите удалить: ',
  listen: 'Слушать',
  read: 'Читать',
  details: 'О книге',
  addToCollection: 'Добавить в коллекцию',
  addToCollectionInDropdown: 'В коллекцию',
  addBooks: 'Добавить книги',
  newCollection: 'Новая коллекция',
  removeFavorites: 'Удалить из избранного',
  addFavorites: 'Добавить в избранное',
  addFavoritesInDropdown: 'В избранное',
  download: 'Скачать',
  removeFromCollection: 'Удалить из коллекции',
  bookFormat: 'Формат:',
  bookSize: 'Размер:',
  audiobook: 'Аудиокнига',
  audiobooks: 'Аудиокниги',
  audioBookTitle: 'Название книги: ',
  audioBookYear: 'Год: ',
  audioBookAuthor: 'Автор: ',
  audioBookSize: 'Размер: ',
  audioBookFavorite: 'В избранных: ',
  audioBookPurchased: 'В купленных: ',
  audioBookGenres: 'Жанры: ',
  searchAuthors: 'Поиск по автору',
  anonymousAuthor: 'Неизвестный автор',
  authors: 'Авторы',
  addBook: 'Добавить книгу ',
  by: ' от ',
  toCollection: ' в коллекцию',
  chooseCollection: 'Выберите коллекцию:',
  confirm: 'Подтвердить',
  collection: 'Коллекция',
  collections: 'Коллекции',
  collectionsLinkBack: 'Коллекциям',
  collectionName: 'Название коллекции',
  confirmNewCollection: 'подтвердить новую коллекцию',
  createNewCollection: 'Создать новую коллекцию' ,
  uploading: 'Загрузка: ',
  completed: 'завершено',
  seeDetails: 'Подробности',
  getBook: 'Получить книгу',
  readRateLogo: 'ReadRate logo',
  seeAllBooks: 'Просмотреть все книги',
  newThisWeek: 'Новинки',
  uploadFailed: 'Загрузка не удалась',
  uploadSuccess: 'Загрузка завершена успешно',
  uplodeToPBCloud: 'Загрузить в PBCloud',
  done: 'Готово',
  addMoreFiles: 'Добавить файлы',
  from: 'из',
  filesLeft: 'файлов осталось',
  unknownAuthor: 'Неизвестный Автор',
  unknown: 'Неизвестный формат',
  purchased: 'Купленные',
  favorites: 'Избранные',
  series: 'Серии',
  genres: 'Жанры',
  genresLinkBack: 'Жанрам',
  formats: 'Форматы',
  formatsLinkBack: 'Форматам',
  bookMoreThanFive: 'книг',
  collectionMoreThanFive: 'коллекции',
  byAuthorTitle: 'Книги автора',
  emptyAudioBook: 'Здесь будут аудиокниги, купленные вами в магазине',

  isbn: 'ISBN',
  annotation: 'Аннотация',
  publisher: 'Издатель',

  chaptersTitle: 'Содержание',
  bookInfoTitle: 'Информация о книге',
  bookmarksTitle: 'Закладки',
  addBookmark: 'Добавить закладку',
  add: 'Добавить',
  cancel: 'Отменить',
  noteOptional: 'Примечание (необязательно)'
}
export default books;