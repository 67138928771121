import React from 'react'
import { setDisplayName, setPropTypes, onlyUpdateForPropTypes, compose } from 'recompose'
import PropTypes from 'prop-types'

import './Collapsable.css'

const propTypes = setPropTypes({
  children: PropTypes.any.isRequired,
  collapsed: PropTypes.bool.isRequired
})

export const enhance = compose(
  setDisplayName('Collapsable'),
  onlyUpdateForPropTypes,
  propTypes
)

export const View = ({children, collapsed}) => (
  <div className="Collapsable" data-collapsed={collapsed}>
    {children}
  </div>
)

export const Collapsable = enhance(View)
