import { put, takeEvery, call } from 'redux-saga/effects'
import * as actions from '../actions/adobe'
import * as API from '../API/adobe'
import * as userActions from '../actions/user'

const TYPES = actions.TYPES

function* getVendorsIds () {
  try {
    const vendors = yield call(() => API.getVendorsIds())
    yield put(actions.setVendorsIds(vendors))
  } catch (error) {
    console.log('Error while getting vendors ids', error)
  }
}

function* getVendors () {
  try {
    const { vendors, defaultVendor } = yield call(() => API.getVendors())
    yield put(actions.setVendors(vendors))
    if (defaultVendor) {
      yield put(actions.setDefault(defaultVendor))
    }
  } catch (error) {
    console.log('Error while getting vendors', error)
  }
}

function* setDefault ({ payload }) {
  try {
    yield call(() => API.setDefault(payload))
    yield put(actions.setDefault(payload.email))
  } catch (error) {
    console.log('Error while setting default vendor', error)
  }
}

function* deleteVendor ({ payload }) {
  try {
    yield call(() => API.deleteVendor(payload))
    yield put(actions.removeVendor(payload.email))
  } catch (error) {
    console.log('Error while deleting vendor', error)
  }
}

function* addVendor ({ payload }) {
  try {
    yield call(() => API.addVendor(payload))
    yield put(actions.setVendorFormStatus('success'))
    yield put(actions.getVendors())
    yield put(actions.setVendorFormStatus(null))
  } catch (error) {
    yield put(actions.setVendorFormStatus('failure'))
    console.log('Error while adding vendor', error)
  }
}

function* updateVendor ({ payload }) {
  try {
    yield call(() => API.updateVendor(payload))
    yield put(actions.updateVendor(payload))
  } catch (error) {
    console.log('Error while updating vendor', error)
  }
}

function* getPassword ({payload}) {
  try {
    let status = yield call(() => API.getPassword(payload))
    if (status === 'OK') {
      yield put(actions.setPasswordFormStatus('success'))
    } else {
      yield put(actions.setPasswordFormStatus('failed'))
    }
  } catch (error) {
    console.log('Error while get password', error)
  }
}

function* showPassword ({payload}) {
  try {
    let data = yield call(() => API.showPassword(payload))
    yield put(userActions.setInternalPasswordData(data))
  } catch (error) {
    if (error.response && error.response.status === 403) {
      yield put(userActions.setInternalPasswordData(error.response.data))
    }
    console.log('Error while get data for show password', error)
  }
}

export const adobeSaga = [
  takeEvery(TYPES.GET_VENDORS, getVendors),
  takeEvery(TYPES.UPDATE_DEFAULT, setDefault),
  takeEvery(TYPES.DELETE_VENDOR, deleteVendor),
  takeEvery(TYPES.ADD_VENDOR, addVendor),
  takeEvery(TYPES.EDIT_VENDOR, updateVendor),
  takeEvery(TYPES.GET_VENDORS_IDS, getVendorsIds),
  takeEvery(TYPES.GET_PASSWORD, getPassword),
  takeEvery(TYPES.SHOW_PASSWORD, showPassword)
]
