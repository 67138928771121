import { put, takeEvery, call } from 'redux-saga/effects'
import * as actions from '../actions/plans'
import * as API from '../API/plans'

const TYPES = actions.TYPES

function* upgradePlan ({ payload }) {
  try {
    yield call(() => API.upgradePlan(payload))
    getPlans()
    getCurrentPlan()
  } catch (error) {
    console.log('Error while upgrading plan', error)
  }
}

function* getPlans () {
  try {
    const plans = yield call(() => API.getPlans())
    yield put(actions.setPlans(plans))
  } catch (error) {
    console.log('Error while getting plans', error)
  }
}

function* getCurrentPlan () {
  try {
    const plan = yield call(() => API.getCurrentPlan())
    yield put(actions.setCurrentPlan(plan))
  } catch (error) {
    console.log('Error while getting current plan', error)
  }
}

export const plansSaga = [
  takeEvery(TYPES.GET_PLANS, getPlans),
  takeEvery(TYPES.GET_CURRENT_PLAN, getCurrentPlan),
  takeEvery(TYPES.UPGRADE_PLAN, upgradePlan)
]
