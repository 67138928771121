import {
  addBooks, setBooksCount, onFetchBooks, TYPES, removeBooks, setStatus, setFavorite,
  setCollections, attachBooksToCollection, detachBooksFromCollection, removeCollection,
  renameCollection, fetchAudioBookFiles, onFetchAudioBookFiles, setMark, removeMark,
  setBooksInfo, setGenres, removeCollections, removeCollectionsByIds, setProviderUrl
} from '../actions/books'
import {removeUser, usersStorageData} from '../actions/user'
import { put, takeEvery, call } from 'redux-saga/effects'
import * as API from '../API/books'
import { sortWith, ascend, prop, has } from 'ramda'
import {removeUser as storageRemoveUser} from "../utilities";

function* getAllBooks ({payload}) {
  yield put(onFetchBooks(true))

  if (payload.type !== 'audio') payload.options = {limit: 0}
  let books = yield call(() => API.fetchBooks(payload))
  if (has('total', books)) {
    yield put(setBooksCount(books.total))
    payload.options.limit = books.total
    books = yield call(() => API.fetchBooks(payload))
    books = books.items
  }

  books = books.filter((book) => Object.keys(book).length > 0)
  yield put(addBooks(payload.type, books))

  yield put(onFetchBooks(false))
}

function* getBooks ({payload}) {
  try {
    if (payload.options && has('limit', payload.options))
      yield put(onFetchBooks(true))

    let books = yield call(() => API.fetchBooks(payload))

    if (has('total', books)) {
      yield put(setBooksCount(books.total))
      books = books.items
    }
    const partners = [
      'recommended',
      'bestsellers',
      'new'
    ]
    if (partners.indexOf(payload.type) > -1) {
      yield put(setProviderUrl(payload.type, books.providerUrl))
      books = books.books
    }
    books = books.filter((book) => Object.keys(book).length > 0)
    yield put(addBooks(payload.type, books))
    if (payload.options && has('limit', payload.options)) {
      yield put(onFetchBooks(false))
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      storageRemoveUser()
      yield put(removeUser())
    }
    console.log('Error fetching books', error)
  }
}

function* deleteBooks ({payload: {books}}) {
  try {
    yield call(() => API.deleteBooks(books))
    yield put(removeBooks(books.map(prop('id'))))
    yield put(usersStorageData())
  } catch (error) {
    console.log('Error deleting books', error)
  }
}

function* deleteCollections ({payload: {collections}}) {
  try {
    yield call(() => API.deleteCollections(collections))
    yield put(removeCollections(collections.map(prop('name'))))
    yield put(removeCollectionsByIds(collections.map(prop('id'))))
    yield put(usersStorageData())
  } catch (error) {
    console.log('Error deleting collections', error)
  }
}

function* updateStatus ({payload}) {
  try {
    yield call(() => API.updateStatus(payload))
    yield put(setStatus(payload))
  } catch (error) {
    console.log('Error updating status', error)
  }
}

function* updateFavorite ({payload}) {
  try {
    yield call(() => API.updateFavorite(payload))
    yield put(setFavorite(payload))
  } catch (error) {
    console.log('Error updating favorite', error)
  }
}

function* updateReadPosition ({payload}) {
  try {
    yield call(() => API.updateReadPosition(payload))
  } catch (error) {
    console.log('Error updating read position', error)
  }
}

function* addMarkDown ({payload}) {
  try {
    let addMark = yield call(() => API.addMarkDown(payload))
    if( addMark ) {
      yield put(onFetchAudioBookFiles(true))
      yield put(setMark(payload))
      yield put(onFetchAudioBookFiles(false))
    }
  } catch (error) {
    console.log('Error add MarkDown', error)
  }
}

function* removeMarkDown ({payload}) {
  try {
    let rMark = yield call(() => API.removeMarkDown(payload))
    if( rMark ) {
      yield put(onFetchAudioBookFiles(true))
      yield put(removeMark(payload))
      yield put(onFetchAudioBookFiles(false))
    }
  } catch (error) {
    console.log('Error remove MarkDown', error)
  }
}

function* getMarkList ({payload}) {
  try {
    let AudioFiles = yield call(() => API.getAudioBookFiles(payload))
    AudioFiles.chapters = sortTracks(AudioFiles.chapters)

    yield put(fetchAudioBookFiles(AudioFiles))
  } catch (error) {
    console.log('Error fetching mark list', error)
  }
}

function* addCollection ({payload}) {
  try {
    yield call(() => API.addCollection(payload))
    yield getCollections()
  } catch (error) {
    console.log('Error adding collection', error)
  }
}

function* getCollections () {
  try {
    let collections = yield call(API.getCollections)
    yield put(setCollections(collections))
  } catch (error) {
    console.log('Error fetching collections', error)
  }
}

function* getBooksInfo () {
  try {
    let booksInfo = yield call(() => API.getBooksInfo())
    yield put(setBooksInfo(booksInfo))
  } catch (error) {
    console.log('Error to get books info')
  }
}

function* addBooksToCollection ({payload}) {
  try {
    yield call(() => API.addToCollection(payload))
    yield put(attachBooksToCollection(payload))
  } catch (error) {
    console.log('Error adding books to collection', error)
  }
}

function* deleteBooksFromCollection ({payload}) {
  try {
    yield call(() => API.removeFromCollection(payload))
    yield put(detachBooksFromCollection(payload))
  } catch (error) {
    console.log('Error deleting books from collection', error)
  }
}

function* deleteCollection ({payload}) {
  try {
    yield call(() => API.deleteCollection(payload))
    yield put(removeCollection(payload))
  } catch (error) {
    console.log('Error deleting collection', error)
  }
}

function* editCollection ({payload}) {
  try {
    yield call(() => API.editCollection(payload))
    yield put(renameCollection(payload))
  } catch (error) {
    console.log('Error renaming collection', error)
  }
}

const sortTracks = sortWith([
  ascend(prop('path'))
])

function* getAudioBookFiles ({payload}) {
  try {
    yield put(onFetchAudioBookFiles(true))
    let AudioFiles = yield call(() => API.getAudioBookFiles(payload))
    AudioFiles.chapters = sortTracks(AudioFiles.chapters)

    yield put(fetchAudioBookFiles(AudioFiles))
    yield put(onFetchAudioBookFiles(false))
  } catch (error) {
    console.log('Error fetching books', error)
  }
}

function* getNewLastRead ({payload}) {
  try {
    let lastRead = yield call(() => API.getLastRead())
    if (lastRead[0].path === payload) {
      yield getNewLastRead({payload: payload})
    } else {
      yield put(addBooks('lastRead', lastRead))
    }
  } catch (error) {
    console.log('Error to get last read book', error)
  }
}

function* getGenres () {
  try {
    let genresArr = yield call(() => API.getGenres())
    let genres = {}
    for (let i=0; i < genresArr.length; i++) {
      let genre = genresArr[i]
      if (genre.code === null) {
        genre.code = 'Unknown'
        genre.name.de = 'Unknown'
        genre.name.ru = 'Unknown'
        genre.name.en = 'Unknown'
      }
      genres[genre.code] = genre.name
    }
    yield put(setGenres(genres))
  } catch (error) {
    console.log('Error to get genres', error)
  }
}

function* updateBookmark ({payload}) {
  try {
    yield call(() => API.updateBookmark(payload))
  } catch (error) {
    console.log('Error to update bookmark', error)
  }
}

export const booksSaga = [
  takeEvery(TYPES.FETCH_BOOKS, getBooks),
  takeEvery(TYPES.FETCH_ALL_BOOKS, getAllBooks),
  takeEvery(TYPES.DELETE_BOOKS, deleteBooks),
  takeEvery(TYPES.DELETE_COLLECTIONS, deleteCollections),
  takeEvery(TYPES.UPDATE_STATUS, updateStatus),
  takeEvery(TYPES.UPDATE_FAVORITE, updateFavorite),
  takeEvery(TYPES.UPDATE_READ_POSITION, updateReadPosition),
  takeEvery(TYPES.ADD_COLLECTION, addCollection),
  takeEvery(TYPES.ADD_MARK_DOWN, addMarkDown),
  takeEvery(TYPES.REMOVE_MARK_DOWN, removeMarkDown),
  takeEvery(TYPES.GET_COLLECTIONS, getCollections),
  takeEvery(TYPES.ADD_BOOKS_TO_COLLECTION, addBooksToCollection),
  takeEvery(TYPES.DELETE_BOOKS_FROM_COLLECTION, deleteBooksFromCollection),
  takeEvery(TYPES.DELETE_COLLECTION, deleteCollection),
  takeEvery(TYPES.EDIT_COLLECTION, editCollection),
  takeEvery(TYPES.GET_AUDIOBOOKS_FIES, getAudioBookFiles),
  takeEvery(TYPES.GET_BOOKS_INFO, getBooksInfo),
  takeEvery(TYPES.GET_MARK_LIST, getMarkList),
  takeEvery(TYPES.GET_NEW_LAST_READ, getNewLastRead),
  takeEvery(TYPES.GET_GENRES, getGenres),
  takeEvery(TYPES.UPDATE_BOOKMARK, updateBookmark)
]
