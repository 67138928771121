const books = {
  uploadBook: 'Завантажити нову книгу',
  sortBy: 'Сортувати по',
  recent: 'Даті',
  byRecent: 'По даті',
  byRecentTitle: 'Нещодавно прочитані',
  byTitle: 'По назві',
  byAuthor: 'По автору',
  byProgress: 'По прогресу',
  title: 'Назві',
  titleInList: 'Назва',
  author: 'Автору',
  authorInList: 'Автор',
  readingProgress: 'Прогресу читання',
  progress: 'Прогрес',
  lastRead: 'Дата активності',
  dateAdded: 'Дата додавання',
  format: 'Формат',
  size: 'Розмір',
  collectionNotEmpty: 'Назва колекції не може бути порожньою',
  collectionNameTaken: 'Ця назва вже використовується',
  book: 'книга',
  books: 'книги',
  e_book: 'Електронна книга',
  e_books: 'Електронні книги',
  selected: 'виділена',
  selected_more_than_one: 'виділено',
  delete: 'Видалити',
  backTo: 'Повернутися до',
  noBooksInCollection: 'У колекції ще немає книжок. ',
  addBooksToCatalogue: 'Щоб додати нові книги, перейдіть до каталогу',
  allBooks: 'Усі книги',
  sureDeleteBook: 'Ви дійсно хочете видалити вибрані книги?',
  sureDelete: 'Ви впевнені, що хочете видалити: ',
  listen: 'Слухати',
  read: 'Читати',
  details: 'Про книгу',
  addToCollection: 'Додати до колекції',
  addToCollectionInDropdown: 'У колекцію',
  addBooks: 'Додати книги',
  newCollection: 'Нова колекція',
  removeFavorites: 'Видалити з обраних',
  addFavorites: 'Додати в обрані',
  addFavoritesInDropdown: 'У обране',
  download: 'Завантажити',
  removeFromCollection: 'Видалити з колекції',
  bookFormat: 'Формат:',
  bookSize: 'Розмір:',
  audiobook: 'Аудіокнига',
  audiobooks: 'Аудіокниги',
  audioBookTitle: 'Назва книги: ',
  audioBookYear: 'Рік: ',
  audioBookAuthor: 'Автор: ',
  audioBookSize: 'Розмір: ',
  audioBookFavorite: 'В обраних: ',
  audioBookPurchased: 'В куплених: ',
  audioBookGenres: 'Жанри: ',
  searchAuthors: 'Пошук за автором',
  anonymousAuthor: 'Невідомий автор',
  authors: 'Автори',
  addBook: 'Додати книгу ',
  by: ' від ',
  toCollection: ' у колекцію',
  chooseCollection: 'Виберіть колекцію:',
  confirm: 'Підтвердити',
  collection: 'Колекція',
  collections: 'Колекції',
  collectionsLinkBack: 'До колекції',
  collectionName: 'Назва колекції',
  confirmNewCollection: 'підтвердити нову колекцію',
  createNewCollection: 'Створити нову колекцію' ,
  uploading: 'Завантаження: ',
  completed: 'завершено',
  seeDetails: 'Подробиці',
  getBook: 'Отримати книгу',
  readRateLogo: 'ReadRate logo',
  seeAllBooks: 'Переглянути всі книги',
  newThisWeek: 'Новинки',
  uploadFailed: 'Завантаження не вдалося',
  uploadSuccess: 'Завантаження завершено успішно',
  uplodeToPBCloud: 'Завантажити до PBCloud',
  done: 'Готово',
  addMoreFiles: 'Додати файли',
  from: 'з',
  filesLeft: 'файлів залишилося',
  unknownAuthor: 'Невідомий автор',
  unknown: 'Невідомий формат',
  purchased: 'Куплені',
  favorites: 'Обрані',
  series: 'Серії',
  genres: 'Жанри',
  genresLinkBack: 'До жанрів',
  formats: 'Формати',
  formatsLinkBack: 'До форматів',
  bookMoreThanFive: 'книг',
  collectionMoreThanFive: 'колекції',
  byAuthorTitle: 'Книги автора',
  emptyAudioBook: 'Тут будуть аудіокниги, які ви купили в магазині',

  isbn: 'ISBN',
  annotation: 'Анотація',
  publisher: 'Видавець',

  chaptersTitle: 'Зміст',
  bookInfoTitle: 'Інформація про книгу',
  bookmarksTitle: 'Замітки',
  addBookmark: 'Додати замітку',
  add: 'Додати',
  cancel: 'Скасувати',
  noteOptional: 'Примітка (необов\'язково)'
}
export default books;