import { removeUser, setUser, TYPES } from '../actions/user'
import { nextDropdown } from '../actions/dropdown'
import { resetBooks } from '../actions/books'
import { removeUser as storageRemoveUser, storeUser } from '../utilities'
import { put, takeEvery } from 'redux-saga/effects'
import store from "../store";

function* login ({payload}) {
  let lang = store.getState().userData.language
  let selectedLocale = lang !== null || lang !== 'null' ? lang : 'en'
  payload.language = selectedLocale
  storeUser(payload)
  yield put(setUser(payload))
}

function* logout () {
  storageRemoveUser()
  yield put(removeUser())
  yield put(nextDropdown(null))
  yield put(resetBooks())
}

export const authSaga = [
  takeEvery(TYPES.LOGIN, login),
  takeEvery(TYPES.LOGOUT, logout)
]
