import {SOCKET_URL} from "./constants";

export const getClientId = () => {
  let SOCKET_ID = 'socket_client_id'
  let clientID = window.sessionStorage.getItem(SOCKET_ID)
  if (clientID) {
    return clientID
  } else {
    clientID = new Date().valueOf().toString()
    window.sessionStorage.setItem(SOCKET_ID, clientID)
    return clientID
  }
}

export const createSocketClient = (clientID, setSocket, addMessage) => {
  let socket = new WebSocket(SOCKET_URL+'?client_id='+clientID)
  socket.onclose = (e) => {
    if (e.wasClean) {
      console.log(`[close] Connection closed cleanly, code=${e.code} reason=${e.reason}`)
    } else {
      console.log('[close] Connection died')
    }
    setTimeout(function() {
      createSocketClient(clientID, setSocket, addMessage)
    }, 1000)
  }
  socket.onerror = (error) => {
    console.log(`[error] ${error.message}`)
  }
  getMessage(socket, addMessage)
  setSocket(socket)
}

export const getMessage = (socket, addMessage) => {
  socket.addEventListener('message', (e) => {
    let msg = JSON.parse(e.data)
    if (msg.action !== 'upload') {
      addMessage(msg)
    }
  })
}

export const sendMessage = (socket, msg) => {
  let data = {
    from: "web",
    data: msg
  }
  socket.onopen = () => {
    socket.send(JSON.stringify(data))
  }
}