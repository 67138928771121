const cookie = {
  text_before: "Like many companies, PocketBook uses cookie technology to enhance your user experience, for analytics and marketing purposes that are to show you relevant offers, tailored the best to your interests while running this website and third parties websites. PocketBook respects your privacy rights, thus we kindly ask you to take a moment to enjoy",
  preferences: "Managing Cookie Preferences.",
  text_between: "Please take a note that strictly necessary cookies are always enabled. If you are happy with the use of all cookie files, just click",
  ok: "Ok",
  text_after: "in this pop-up. To learn more about cookie technology, its benefits and how Pocketbook use it, please go to our",
  cookie_notice: "Cookie notice.",
  accept: "Accept all cookies",
  customise: "Customise Cookies",
  save: "Save custom preferences",
  necessary: "Strictly Necessary Cookies: These are Cookies which are needed to provide Services and features you have specifically requested. We may use cookies and tracking technologies for system administration and/or to allow you to make use of shopping-cart functionality.",
  analytics: "Analytics and Performance-Related Cookies: We may use cookies to assess the performance of our Services, including as part of our analytics practices to improve the selection of content recommended to you through the Services.",
  functionality: "Functionality-Related Cookies: We may use cookies to tell us, for example, whether you have visited the Services before or if you are a new visitor and to help us identify the features in which you may have the greatest interest.",
  targeting: "Targeting-Related Cookies: We may use Cookies to deliver content, including ads, relevant to your interests on our Services."
}
export default cookie;