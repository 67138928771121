export const policyHeaderText = () => (`
    <h1>Политика конфиденциальности</h1>
    
    <p>Ответственный:</p>
    <p>Pocketbook International SA</p>
    <p>Адрес: Crocicchio Cortogna, 6, 6900 Lugano, Switzerland</p>
    <p><a href="tel:+41919220705">Телефон: +41 91 922 07 05</a></p>
    <p><a href="tel:+41919220337">Факс: +41 91 922 03 37</a></p>
    <p>Идентификационный номер предприятия: CHE-416.098.857</p>
    <p>Идентификационный номер налогоплательщика: CHE-416.098.857 IVA</p>
    <p>Уполномоченный представитель: Евгений Зайцев, директор по продажам и маркетингу</p>
    <p><a href="mailto:privacy@pocketbook-int.com">privacy@pocketbook-int.com</a></p>
`)

export const section1 = () => (`
<p>(1) Данная политика конфиденциальности описывает способ, объем и цели обработки персональных данных в рамках нашего онлайн-предложения и связанных с ним веб-сайтов, функций и контента.</p>
      <p>(2) Мы используем терминологию, такую как, например, «персональные данные» или их «обработка» в соответствии с определениями в статье 4 Общих правил о защите данных (ОПЗД).</p>
      <p>(3) Понятие «пользователь» включает в себя все категории лиц, затрагиваемых в ходе обработки данных. В их число входят наши деловые партнеры, клиенты, заинтересованные лица и прочие посетители нашего онлайн-предложения. Используемые термины, как, например, «пользователь» следует понимать в гендерно-нейтральной формулировке.</p>
      <p>(4) Персональные данные пользователя, обрабатываемые в рамках данного онлайн-предложения:</p>

      <ul>
        <li>персональные данные (например, ФИО и адреса клиентов);</li>
        <li>контактные данные (например, адрес электронной почты, телефонные номера);</li>
        <li>контрактные данные (например, использованные услуги или приобретенные продукты, платежная информация);</li>
        <li>данные об использовании (например, посещенные вебсайты нашего онлайн-предложения, интерес к нашим услугам и продуктам);</li>
        <li>данные контента (например, ввод информации в форме для отправки запроса); </li>
        <li>технические данные (например, IP адреса, информация об устройстве).</li>
      </ul>

      <p>(5) Обработка персональных данных пользователя осуществляется в следующих целях:</p>

      <ul>
        <li>предоставление онлайн-предложения, его контента и функций;</li>
        <li>предоставление наших договорных услуг и сервисов;</li>
        <li>служба работы с клиентами;</li>
        <li>ответ на запросы в форме для контакта и коммуникация с пользователями;</li>
        <li>маркетинг, а также </li>
        <li>безопасность сайта онлайн-предложения.</li>
      </ul>

      <p>(6) Мы обрабатываем персональные данные пользователей только при условии соблюдения соответствующих положений о защите персональных данных. Это означает, что обработка данных пользователей осуществляется только при наличии предусмотренного законом разрешения. В особенности это относится к случаю, когда обработка данных требуется для выполнения нами услуг по договору (например, для обработки заявок и заказов), а также нашего онлайн-предложения или это прямо предусмотрено законом, или имеется согласие пользователей, или это выполняется на основании наших правомерных интересов. Правомерным интересом считается анализ, оптимизация, безопасность, а также коммерческое использование нашего онлайн-предложения.</p>
      <p>(7) Мы обращаем внимание на то, что правовое основание для предоставления согласия составляет ст. 6 п. 1 подп. a) и ст. 7 ОПЗД, правовое основание для обработки данных в целях предоставления наших услуг и осуществления мер составляет ст. 6 п. 1 подп. b) ОПЗД, правовое основание для обработки в целях наших договорных обязательств соответствует ст. 6 п. 1 подп. c) ОПЗД и правовое основание для обработки в целях соблюдения наших правомерных интересов составляет ст. 6 п. 1 подп. f) ОПЗД.</p>
`)
export const section2 = () => (`
      <p>(1) В соответствии со ст. 32 ОПЗД с учетом современного уровня развития техники, затрат на реализацию и способа, объема, условий и целей обработки, а также различной вероятности возникновения и серьезности риска для прав и свобод физических лиц, мы принимаем соответствующие технические и организационные меры для обеспечения надлежащего уровня безопасности в соответствии с риском. Благодаря этому должна быть обеспечена защита обрабатываемых нами данных, в частности от случайных или преднамеренных манипуляций, утраты, уничтожения или от несанкционированного доступа третьих сторон. Меры безопасности включают в себя также шифрованную передачу данных между Вашим браузером и нашим сервером.</p>
      <p>(2) Наряду с этим мы установили способ, который обеспечивает соблюдение прав затрагиваемых лиц, реагирования на удаление файлов и возникновение угрозы для данных.</p>
`)
export const section3 = () => (`
      <p>(1) В рамках нашей обработки данных мы раскрываем данные другим лицам и предприятиям (компаниям, предоставляющим услуги по обработке данных, или третьим сторонам), передаем им данные или предоставляем доступ к ним иным способом, что осуществляется исключительно на основании предусмотренного законом разрешения. Это имеет место, например, при передаче данных третьим сторонам, таким как служба доставки, согласно ст. 6 п. 1 подп. b) ОПЗД, поскольку это требуется для осуществления договора при наличии Вашего согласия, это предусмотрено в правовом обязательстве или осуществляется на основании наших правомерных интересов (например, при использовании уполномоченных, провайдеров услуг веб-хостинга и т. д.).</p>
      <p>(2) Поскольку обработка данных осуществляется в третьей стране (т. е. за пределами Европейского Союза или Европейской экономической зоны) или в рамках использования услуг третьих сторон или имеет место раскрытие или передача данных третьим сторонам, мы выполняем это только при дополнительном наличии особых исходных условий согласно ст. 44 и далее в Общих правилах ЕС защиты данных ОПЗД. Это означает, что обработка осуществляется, например, на основании таких специальных гарантий, как официально признанный установленный уровень защиты данных, соответствующего требованиям ЕС (например, для США посредством рамочной инициативы «Privacy Shield») или при условии соблюдении официально признанных специальных обязательств по договору (так называемые «стандартные договорные оговорки»).</p>
      <p>(3) Поскольку мы поручаем обработку данных третьей стороне на основании так называемого «договора об обработке по поручению», то это осуществляется на основании ст. 28 ОПЗД.</p>
`)
export const section4 = () => (`
      <p>(1) На основании наших правомерных интересов в соответствии со ст. 6 п. 1 подп. f) ОПЗД мы собираем данные о каждом обращении к серверу, на котором находится наш сервис (так называемые файлы журналов сервера или лог-файлы). Эти данные  необходимы технически для отображения соответствующего веб-сайта и обеспечения стабильности и безопасности. Данные обращения – это, прежде всего, название запрашиваемого веб-сайта, файла и время суток запроса, количество переданных данных, сообщение об успешности запроса, тип браузера и его версия, операционная система пользователя, посещенные им ранее веб-сайты и IP-адрес.</p>
      <p>(2) Из соображений безопасности (например, для выяснения случаев использования не по назначению или мошенничества) информация о файлах журналов сервера сохраняется в течение максимум тридцати дней, затем удаляется. Данные, которые необходимо хранить в доказательных целях, следует исключить из подлежащего удалению массива до окончательного выяснения случая.</p>
`)
export const section5 = () => (`
      <p>(1) Мы обрабатываем персональные, контактные данные и данные по договору в целях исполнения наших договорных обязательств и предоставления сервиса согласно ст. 6 п. 1 подп. b) ОПЗД. Данные, отмеченные в онлайн-формулярах как обязательные, требуются для заключения договора.</p>
      <p>(2) Дополнительно, Вы можете создать учетную запись пользователя, под которой Вы, кроме всего прочего, можете просматривать свои заказы или, если они зарегистрированы в «облаке» PocketBook, управлять своим электронным контентом и использовать его (в частности, электронными книгами). При регистрации пользователю сообщается, какие обязательные данные требуются. Если пользователь решил отказаться от своей учетной записи, то все данные, имеющие отношение к его учетной записи, удаляются, поскольку дальнейшее их хранение не предписывается законодательно, в особенности согласно торговому или налоговому законодательству согласно ст. 6 п. 1 подп. c) ОПЗД. После удаления учетной записи пользователи должны обеспечить безопасность своих данных до окончания срока договора. Мы имеем право безвозвратно удалить все данные, сохраненные на протяжении срока действия договора.</p>
      <p>(3) В рамках регистрации и повторного входа в систему, а также использования нашего онлайн-предложения, мы сохраняем IP-адрес и момент времени соответствующего действия пользователя. Мы сохраняем данные в наших правомерных интересах и в интересах пользователей для защиты от злоупотреблений и прочего использования без разрешения. Эти данные не передаются третьим сторонам за исключением случаев, когда это необходимо для соблюдения наших требований или имеется правовое обязательство согласно ст. 6 п. 1 подп. c) ОПЗД.</p>
      <p>(4) Мы обрабатываем данные об использовании (например, посещенные веб-сайты нашего онлайн-предложения, проявленный к нашим продуктам интерес) и данные контента (например, ввод в формуляр контакта) из профиля пользователя в рекламных целях, чтобы показать пользователям информацию о продуктах, исходя из услуг, которыми они воспользовались ранее.</p>
      <p>(5) Данные об оказанных по договору услугах удаляются по окончанию срока действия установленных законодательством гарантийных и аналогичных обязательств. Если существуют устанавливаемые законодательством обязательства по архивированию, то они удаляются по истечении срока их действия согласно коммерческому законодательству через 6 лет и согласно налоговому законодательству через 10 лет. Данные учетной записи пользователя сохраняются до ее удаления.</p>
`)
export const section6 = () => (`
      <p>Для коммуникации с нами (через формуляр для контакта или по электронной почте) данные пользователя обрабатываются для ответа на его запрос и его исполнения согласно ст. 6 п. 1 подп. b) ОПЗД.</p>
`)
export const section7 = () => (`
      <p>В нашем онлайн-магазине Вы можете дать оценку нашим продуктам. Ваша оценка публикуется вместе с указанными Вами именами по соответствующему продукту. Мы рекомендуем использовать псевдоним вместо Вашего настоящего имени. Указание имени и адреса электронной почты обязательно, вся дальнейшая информация добровольная. Когда Вы даете оценку, мы сохраняем Ваш IP-адрес, который удаляем через тридцать дней. Сохранение необходимо в целях нашей защиты от претензий в части ответственности в случае возможного опубликования текстов противоправного содержания. Ваш электронный адрес необходим нам для коммуникации с Вами в случае предъявления третьей стороной претензии к Вашей оценке как к противоправной. Правовое основание изложено в ст.6 п. 1 подп. b) и f) ОПЗД. Мы не проверяем оценки перед их опубликованием. Мы оставляем за собой право удалять комментарии, если третья сторона посчитает их противоправными. </p>
`)
export const section8 = () => (`
      <p>(1) Далее мы приводим разъяснение о содержании наших новостных рассылок, а также о том, как можно подписаться на них и о способе их отправки, а также о Вашем праве отказаться от них. Подписываясь на наши новостные рассылки, Вы заявляете о своем согласии на их получение и с описанным способом.</p>
      <p>(2) Мы рассылаем новостные рассылки (сообщения по электронной почте) или другие электронные уведомления («новостная рассылка») с информацией рекламного характера только с согласия получателя или при наличии предусмотренного законом разрешения. Наши новостные рассылки содержат информацию о наших продуктах и сервисах, акциях и о нашей компании. </p>
      <p>(3) Подписка на нашу новостную рассылку осуществляется при использовании так называемого метода двойного подтверждения согласия на подписку (Double Opt-In). Это означает, что после регистрации Вы получите сообщение по электронной почте, в котором Вас попросят подтвердить свою подписку. Это подтверждение необходимо для того, чтобы никто посторонний не смог зарегистрироваться с чужого адреса электронной почты. Подписка на новостную рассылку протоколируется, чтобы можно было доказать, что процесс подписки выполнен в соответствии с требованиями законодательства. Этот процесс включает в себя сохранение момента времени оформления подписки и ее подтверждения, а также IP-адрес. Цель состоит в доказательстве оформления Вами подписки и выяснения, при необходимости, случая злоупотребления Вашими персональными данными. Это выполняется на основании ст. 6 п. 1 подп. f) ОПЗД.</p>
      <p>(4) Для регистрации получения новостной рассылки достаточно указать свой адрес электронной почты. Мы сохраняем адрес Вашей электронной почты для направления новостной рассылки. Правовое основание - ст. 6 п. 1 подп. a) ОПЗД.</p>
      <p>(5) Вы можете в любое время отозвать свое согласие на получение нашей новостной рассылки. Для того, чтобы воспользоваться своим правом на отказ, найдите линк в конце любой из новостных рассылок. Если пользователь зарегистрировал свою подписку и сразу же ее отменил, его персональные данные удаляются. </p>
`)
export const section9 = () => (`
      <p>(1) Для нашего онлайн-предложения мы используем технологию файлов cookie. Файлы cookie – это небольшие текстовые файлы, которые сохраняются в определенном порядке на Вашем конечном устройстве при помощи используемого Вами браузера, и благодаря которым к месту, которое занимает файл cookie, от нас поступает определенная информация. Файлы cookie не могут выполнять никаких программ или переносить вирусы на Ваш компьютер. Они служат для того, чтобы сделать онлайн-предложение более удобным и эффективным для пользователя.</p>
      <p>(2) Мы используем временные и постоянные файлы cookie. Временные файлы cookie удаляются автоматически по закрытию Вами браузера. К ним, в частности, относятся и сессионные файлы cookie. Они сохраняют так называемый идентификатор ID сессии, при помощи которого упорядочиваются различные запросы Вашего браузера на протяжении совместного сеанса. Благодаря этому возможно распознавание Вашего конечного устройства при повторном посещении нашего веб-сайта. Сессионные файлы cookie удаляются, когда Вы выходите из учетной записи или закрываете  браузер. Постоянные файлы cookie сохраняются на Вашем конечном устройстве между сеансами браузера, поэтому Ваши интересы или действия могут сохраняться на многих веб-сайтах. Постоянные файлы cookie автоматически удаляются через определенный срок (здесь 1 день). Вы можете в любое время удалить файлы cookie через настройки безопасности Вашего браузера.</p>
      <p>(3) В добавление к этому мы используем файлы cookie для измерения охвата аудитории, смотрите далее раздел 10 об использовании Google Analytics. </p>
      <p>(4) Вы можете изменить конфигурацию настроек своего браузера в соответствии со своими пожеланиями, например, отклонить прием файлов cookie от стороннего провайдера или отклонить все файлы cookie. Обращаем Ваше внимание на то, что возможно тогда Вы не сможете воспользоваться всеми функциями нашего онлайн-предложения. </p>
      <p>(5) Вы можете отказаться от использования файлов cookie, которые служат для измерения охвата и для рекламных целей, воспользовавшись страницей группы Network Advertising Initiative (<a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a>) и дополнительно на американском веб-сайте (<a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices</a>) или европейском веб-сайте (<a href="http://www.youronlinechoices.com/uk/your-ad-choices/">http://www.youronlinechoices.com/uk/your-ad-choices/</a>).</p>
`)
export const section10 = () => (`
      <p>(1) На основании наших правомерных интересов (т. е. интереса к анализу, оптимизации и коммерческому использованию нашего онлайн-предложения в соответствии со ст. 6 п. 1 подп. f) ОПЗД) мы используем Google Analytics, сервис для анализа интернет-сайтов, предоставляемый компанией Google LLC (далее «Google»). Google Analytics использует файлы cookie.  Составленная при помощи файлов сookie информация об использовании пользователями онлайн-предложения, как правило, передается на сервер Google в США и сохраняется там. Компания Google сертифицирована по Рамочному соглашению EU-U.S. Privacy Shield и, таким образом, гарантирует соблюдение европейского Законодательства о защите данных (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>).</p>
      <p>(2) Google будет использовать данную информацию по нашему поручению для оценки использования пользователями нашего онлайн-предложения, составления отчета об активности в рамках данного онлайн-предложения и предоставления нам других услуг, связанных с использованием данного онлайн-предложения и Интернета. При этом из обработанных данных составляются профили пользователей под псевдонимом.</p>
      <p>(3) Мы используем Google Analytics только при активации IP-анонимизации. Это означает, что IP-адрес пользователей сокращается Google в странах-членах Европейского Союза и/или других странах-участницах договора о Европейской экономической зоне. Только в исключительных случаях на сервер Google в США передается полный IP-адрес, где он  сокращается.</p>
      <p>(4) IP-адрес, передаваемый браузером пользователя в рамках сервиса Google Analytics, не будет объединен с другими данными Google. Пользователи могут предотвратить сохранение файлов cookie, выбрав соответствующие настройки в своем браузере. Пользователи могут предотвратить сбор компанией Google данных, сгенерированных файлом cookie и связанных с использованием онлайн-предложения, а также обработку этих данных компанией Google, загрузив и установив в своем браузере подключаемый модуль, доступный по ссылке: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
      <p>(5) Информация о стороннем провайдере: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, <a href="tel:+3531436 1001">Fax: +353 (1) 436 1001</a>. Условия использования: <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>, сводная информация о защите данных: <a href="http://www.google.com/intl/de/analytics/learn/privacy.html">http://www.google.com/intl/de/analytics/learn/privacy.html</a>, а также 
Политика конфиденциальности данных: <a href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>.</p>
`)
export const section11 = () => (`
      <p>(1) В рамках нашего онлайн-предложения на основании наших правомерных интересов к  анализу, оптимизации и коммерческому использованию нашего онлайн-предложения и в этих целях компания Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland (далее «Facebook») использует так называемый сервис аналитики Facebook Pixel социальной сети Facebook. Компания Facebook сертифицирована по Рамочному соглашению Privacy Shield и, таким образом, гарантирует соблюдение Европейского законодательства о защите данных (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=A...</a>).</p>
      <p>(2) При помощи инструмента Pixel компания Facebook может определять посетителей нашего онлайн-предложения как целевую группу для демонстрации рекламных объявлений (называемые «Facebook Ads»). Таким образом, мы используем Facebook Pixel, чтобы показывать через подключенные Facebook Ads рекламные объявления пользователям Facebook, которые проявили интерес к нашему онлайн-предложению или определенным характеристикам (например, интерес к определенным темам или продуктам, который определяется на основании посещенных веб-сайтов), передаваемыем нами на Facebook (называемые «Custom Audiences» или индивидуализированные аудитории). При помощи Facebook Pixel мы хотели бы удостовериться, что наши Facebook Ads соответствуют потенциальному интересу пользователей и выглядят не надоедливо. Далее, при помощи Pixel мы можем проследить эффективность рекламных объявлений на Facebook для сбора статистики и выполнения маркетингового исследования рынка, благодаря чему мы видим, был ли пользователь перенаправлен на наш веб-сайт после клика на рекламное объявление на Facebook (так называемая «конверсия»).</p>
      <p>(3) Обработка данных через Facebook осуществляется в рамках указаний Facebook об использовании данных. Соответственно, общие указания о демонстрации объявлений Facebook Ads содержатся в политике конфиденциальности Facebook: <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>. Специальную и подробную информацию об инструменте Facebook Pixel и его функционировании можно получить в Центре справки Facebook: <a href="https://www.facebook.com/business/help/651294705016616">https://www.facebook.com/business/help/651294705016616</a>.</p>
      <p>(4) Вы можете отказаться от сбора данных при помощи Facebook Pixel и использования Ваших данных для демонстрации рекламных объявлений Facebook Ads. Для настройки типа рекламных объявлений, которые будут Вам демонстрироваться на Facebook, Вы можете зайти на подключенную страницу Facebook и выполнить на ней указания по настройке пользовательской рекламы: <a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a>. Настройки выполняются независимо от платформы, т. е. они принимаются всеми устройствами, такими как настольный компьютер или мобильные устройства.</p>
`)
export const section12 = () => (`
      <p>(1) По предоставлению заявки пользователи имеют право бесплатно получить справку о том, какие их персональные данные мы обрабатываем.</p>
      <p>(2) Далее, пользователи имеют право корректировать неправильные данные, ограничивать обработку своих персональных данных и удалять их, право на переносимость данных, если применимо, и в случае принятия неправомерной обработки данных, право подать жалобу в компетентный орган надзора.</p>
      <p>(3) Равным образом пользователи могут прекратить действие своего согласия на будущее время.</p>
`)
export const section13 = () => (`
      <p>(1) Сохраненные у нас данные удаляются, как только в них больше не будет необходимости, а их удаление не будет противоречить установленным законом обязанностям по хранению. До тех, пока данные пользователей не удалены по причине, поскольку они требуются в других допустимых законодательством целях, их обработка будет ограничена. Это означает, что данные блокируются и не обрабатываются в других целях. Это действительно, например, для данных пользователей, которые должны храниться согласно торговому или налоговому законодательству.</p>
      <p>(2) Согласно требованиям законодательства, хранение обязательно в течение 6 лет согласно § 257 п. 1 Торгового кодекса (HGB) (например, для коммерческих и деловых писем), а также в течении 10 лет согласно § 147 п. 1 Налогового кодекса (AO) (например, для торговых книг и бухгалтерских документов)</p>
`)
export const section14 = () => (`
      <p>Пользователи могут в любое время возразить против обработки их персональных данных в будущем в соответствии с требованиями законодательства. В частности, возражение можно подать против обработки в целях прямой рекламы.</p>
`)
export const section15 = () => (`
      <p>Мы просим пользователей регулярно осведомляться о содержании нашей политики конфиденциальности.  Мы проводим согласование политики конфиденциальности, как только в этом возникает необходимость вследствие выполненных нами изменений в процессе обработки данных или вследствие изменения правовых норм. Мы проинформируем Вас, как только с Вашей стороны потребуется участие вследствие таких изменений (например, согласие) или другое индивидуальное уведомление.</p>
`)

export const policyFooterText = () => (`
    <p>25.05.2018</p>
`)

export const cookiesTermsText = (`<h1>Политика использования файлов cookie</h1>
<section>
<p>Компания POCKETBOOK INTERNATIONAL SA (здесь и далее «PocketBook», «мы», «нас» или  «наш») обеспечивает работу своего вебсайта <a href="https://cloud.pocketbook.digital" target="_blank" rel="noopener noreferrer">https://cloud.pocketbook.digital</a> («Услуги»).</p>
<p>Мы используем ряд технологий, чтобы понять, как используются услуги. В предоставленной далее информации Вы можете узнать о наших технологиях и о том, как можно ими управлять. Данная информация относительно файлов cookie и технологий отслеживания является частью нашей политики конфиденциальности.</p>

<p>Файлы cookie и другие технологии отслеживания: как мы их применяем</p>

<p>Как и многие другие компании, мы используем файлы cookie и другие технологии отслеживания для предоставления наших услуг (далее в тексте «файлы cookie», если не обозначено иным образом).</p>

<p>Сookie – это небольшие файлы, которые размещаются на Вашем компьютере или мобильном устройстве, когда Вы посещаете  веб-сайт. Далее, файлы cookie используются провайдерами интернет-услуг для того, чтобы, например, обеспечить работу Ваших веб-сайтов или сервисов или предоставить обратную информацию.</p>

<p>Мы используем файлы cookie для ряда функций и улучшения Вашего опыта взаимодействия с сайтом, например, при помощи Ваших настроек входа на сайт.</p>

<p>Мы используем как временные, так и постоянные технологии отслеживания. Технологии отслеживания (например, файлы cookie) могут быть как постоянными (т.е., они сохраняются на Вашем компьютере, пока Вы их не удалите) или временными (т. е., они сохраняется на Вашем устройстве до тех пор, пока Вы не закроете свой браузер).</p>

<p>Мы также используем основные файлы файлы cookie и сторонние файлы cookie. Мы используем основные файлы cookie и управляем ими с целью предоставления сервиса для наших Услуг. Управление сторонними файлы cookie осуществляется третьими сторонами, в основном для аналитики.</p>

<p>Наши Услуги используют следующие категории файлов cookie:</p>

<p>Строго необходимые файлы cookie: эти файлы cookie необходимы для использования веб-сайта, делая возможной работу таких основных функций, как, например, навигация по сайту и доступ к защищенным разделам веб-сайта. Без этих файлов cookie веб-сайт не будет работать в полном объеме.</p>

<p>Файлы аналитики и определения эффективности: мы можем использовать файлы cookie для оценки эффективности наших услуг в составе наших методов анализа с целью улучшения отбора контента, рекомендованного для Вас нашими сервисами.</p>

<p>Функциональные файлы cookie: мы можем использовать файлы cookie, например, для сбора информации о том, искали ли Вы ранее Услуги или Вы посетили наш сайт впервые, с целью помочь нам выяснить, какие функции могут заинтересовать Вас больше всего.</p>

<p>Целевые файлы cookie: мы можем использовать файлы cookie для предоставления контента, включая рекламу, который может быть для Вас интересен.</p>

<p>Используя данные услуги, Вы соглашаетесь с использованием файлов cookie и технологий отслеживания и с соответствующей обработкой информации. Вы можете отозвать свое согласие, удалив сохраненные файлы cookie и деактивировав файлы cookie в своем компьютере.</p>

<p>Ваш выбор файлов cookie и отказ от их использования</p>

<p>Вы можете решить, соглашаться ли с использованием файлов cookie. Далее мы объясним, как Вы можете воспользоваться своими правами.</p>

<p>Изначально большинство браузеров настроено на принятие HTTP-файлов cookie. Функция «Справка» на панели меню большинства браузеров объясняет, как можно прекратить принятие новых файлов cookie, как получать уведомление о новых файлах cookie и как деактивировать уже имеющиеся файлы cookie. Дальнейшую информацию о HTTP-файлах cookie и как их деактивировать Вы найдете по адресу <a href="http://www.allaboutcookies.org/manage-cookies" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/manage-cookies</a></p>

<p>Управление локальной памятью HTML5 в Вашем браузере зависит от того, какой браузер Вы используете. Дальнейшую информацию о Вашем конкретном браузере смотрите на веб-сайте своего браузера (часто размещается в закладке «Справка»).</p>

<p>Однако просим принять во внимание, что без HTTP-файлов cookie и локальной памяти HTML5 Вы не сможете воспользоваться функциональными возможностями наших Услуг в полном объеме, и что некоторые разделы наших Услуг не смогут работать без них должным образом.</p>

<p>Для мобильных приложений Вы можете деактивировать аналитику во вкладке «Настройки» Вашего мобильного устройства.</p>

<p>Файлы cookie и технологии отслеживания, используемые для сервисов Pocketbook</p>

<p>В следующей таблице подробно представлены некоторые используемые нами отдельные файлы cookie и технологии отслеживания и цели, для которых мы их используем.</p>

<p>Мы не несем ответственность за веб-сайты третьих сторон и их методы защиты данных в случае отказа от отслеживания. Следующие третьи стороны, которые собирают информацию от Вас касательно наших Услуг, сообщили, что Вы можете получить информацию об их политиках и методах и в некоторых случаях отказаться от их определенных действий.</p>

<p>Дальнейшую информацию об отдельных, используемых нами файлах cookie и целях их использования Вы найдете в представленной ниже таблице:</p>

<div class="CookiesTerms__table">
  <table>
      <thead>
        <tr>
          <th>Файлы cookie</th>
          <th>Сервис</th>
          <th>Цель</th>
          <th>Политика конфиденциальности</th>
          <th>Pocketbookreaders.de</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>accounts.google.com</td>
        <td>Google Analytics</td>
        <td>Аналитика и определение эффектив-ности</td>
        <td><a href="http://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">http://www.google.com/intl/en/policies/privacy/</a></td>
        <td>Да</td>
      </tr>
    </tbody>
  </table>
</div>


<p>Заключительные положения  </p>

<p>Периодически мы изменяем данную ПОЛИТИКУ ОБ ИСПОЛЬЗОВАНИИ ФАЙЛОВ COOKIE И ТЕХНОЛОГИЙ ОТСЛЕЖИВАНИЯ, потому что изменяется наш веб-сайт или правила в отношении файлов cookie. Мы сохраняем за собой право в любое время без уведомления дополнять данную политику об использовании файлов cookie  и содержащиеся в указанном перечне файлы cookie. Соответствующая новая политика вступает в силу с момента ее опубликования. Если Вы не согласны с пересмотренной редакцией политики, то Вы должны изменить свои приоритеты или учесть это по окончанию использования страниц PocketBook. Если после вступления в силу изменений Вы получаете доступ к нашим услугам или используете их далее, Вы соглашаетесь с обновленной  политикой об использовании файлов cookie. Последнюю версию политики Вы найдете на нашем веб-сайте.
Если у Вас имеются вопросы и/или замечания, свяжитесь  с нами по электронной почте <a href="mailto:privacy@pocketbook-int.com">privacy@pocketbook-int.com</a></p>
</section>
`)


export const termsText = () => (`
 <section>
        <h2>I. Scope of application, amendments to the Terms of Use</h2>
        <p>(1) The following Terms of Use apply to the use of the Cloud Services of Pocketbook International SA, Crocicchio Cortogna, 6, 6900 Lugano, Switzerland (hereinafter “Pocketbook”). Pocketbook makes its Cloud Service available to consumers of electronic content, in particular readers of e-books (hereinafter “Users”). The prerequisite for this is the prior registration of the User in an e-bookshop connected to the Cloud Service and further log in in the Cloud Service on Cloud Service home page, whereby the User and Pocketbook  enter into and abide by these Terms of Use.</p>
        <p>(2) These Terms of Use exclusively regulate the legal relationship between Pocketbook and the User.</p>
        <p>(3) Pocketbook reserves the right to amend these Terms of Use at any time being effective also within the existing of contractual relationships. Pocketbook will inform the User of such changes at least 30 calendar days prior  to such amendments entering into force and effect. If the User does not object within 30 days of receipt of the notification and the use of the services continues even after expiry of the objection period, the amendments shall be deemed to have been agreed as effective from the expiry of the period. In the event of objection, the Terms of Use will be continued under the previous conditions. The User shall be informed separately of the right to object and the legal consequences of maintaining silence in the event of amendments to the Terms of Use.</p>
        <h2>II. Subject matter of the services of Pocketbook</h2>
        <p>(1) Pocketbook provides Users of its Cloud Service with the opportunity to store, retrieve, manage and view electronic content in the cloud.</p>
        <p>(2) The use of Cloud Services is limited to those file types that Pocketbook supports within its Cloud Services. Pocketbook does not grant any further usage possibilities.</p>
        <p>(3) In all other respects, the object and scope of the usage options result from the functionalities provided within the Cloud Services.</p>
        <p>(4) The Terms of Use concluded between Pocketbook and the respective User via log in is free of charge for the User.</p>
        <p>(5) Pocketbook is entitled at any time to change provided services free of charge, to provide new services available free of charge or charged, and to discontinue the provision of services free of charge. Pocketbook will consider the legitimate interests of the Users in each case.</p>
        <h2>III. Registration. Log in.</h2>
        <p>(1) Pocketbook’s Cloud Service shall be available to User upon previous registration with an e-bookshop connected to the Cloud Service and entrance into these Terms of Use between Pocketbook and User. . If the User uses a Pocketbook reader or reading application, Pocketbook’s Cloud Service is one of its functionalities. The log in  in the Cloud Service including entering into these Terms of Use is determined in these cases according to the respective purchase or usage processes via Pocketbook services. If the Cloud Service is accessed via the Pocketbook website, log in is governed by the following paragraphs 2 and 3. Log in is only permitted for persons over 16 years old and unrestricted legal capacity. There is no entitlement to the conclusion of  these Terms of Use.</p>
        <p>(2) The prerequisite for using the Cloud Service via the Pocketbook websites is that the User has already registered and has a user account with an e-bookshop connected to the Cloud Service. If this is not yet the case, the User can select an e-bookshop from the map, available on User’s screen in the process of log in in Cloud Service and via the “You do not yet have an account?” link in the registration form, create a user account in the selected e-bookshop, connected to the Cloud Service and then log in  to use the Cloud Service via the Pocketbook websites in accordance with the following paragraph 3.</p>
        <p>(3) When log in to use the Cloud Service via the Pocketbook websites, the User must complete the registration form provided in the respective e-bookshop, connected to the Cloud Service. To do this, the e-mail address or user name must first be entered for the user account of the User in an e-bookshop connected to the Cloud Service. After clicking the “CONTINUE” button, Pocketbook checks whether the specified e-mail address is assigned by access date to an e-bookshop connected to the Cloud Service. If this is the case, the e-bookshop in question is displayed and the User is requested to log in with the corresponding password. The User can identify and correct any errors in the entry of his data within the respective entry fields. By clicking the “log in” button, the User submits a binding offer to enter into these Terms of Use between Pocketbook and User for the use of Cloud Services. User shall enter into these Terms of Use with Pocketbook in the moment of log in in the Cloud Service on Cloud Service home page. Immediately thereafter, the data provided by the User will be checked by Pocketbook. If Pocketbook considers the information to be correct and there are no other concerns, Pocketbook activates access to its Cloud Services for the User and notifies them of this by e-mail. This e-mail is regarded as Pocketbook’s declaration of acceptance and confirms the conclusion of these Terms of Use between Pocketbook and the User. .. The contract text, i.e. in particular the data provided by the User, is stored on Pocketbook’s internal systems and can be accessed at any time after conclusion of the Terms of Use via the login to Pocketbook’s Cloud Services.</p>
        <p>(4) The contract language is English.</p>
        <h2>IV. The User’s obligations</h2>
        <p>(1) The User’s access data including the password must be kept secret and not made accessible to unauthorized third parties. It is within the User’s responsibility to ensure that access to Pocketbook’s Cloud Services is exclusively provided by the User or persons authorized by them. If it is to be feared that unauthorized third parties have gained or will gain knowledge of the access data, Pocketbook must be informed immediately. In this case, Pocketbook is entitled to block access to the Cloud Services until identify the circumstances of the case.</p>
        <p>(2) The User is liable for any use and/or other activity carried out in respect of their usage data, in accordance with the statutory provisions.</p>
        <p>(3) When using the Cloud Services, the User is obliged to observe the applicable laws and the rights of third parties. In particular, it is the User’s responsibility to ensure that the User’s files and other content have been lawfully acquired. The User is also responsible for ensuring that their files and other content are free of malware, viruses, Trojans, spyware, worms or any other harmful code. Pocketbook does not check the files and contents of the User for legitimate grounds and freedom from harmful code or the like.</p>
        <h2>V. Indemnification of Pocketbook</h2>
        <p>The User is obliged to compensate Pocketbook for any damage resulting from the non-observance of the User’s obligations from the usage relationship regarding the Cloud Services of Pocketbook and in particular those from section IV of these Terms of Use, and furthermore to release Pocketbook against any claims arose from third parties which can be lodged against Pocketbook by such third parties due to the non-observance of such obligations by the User.</p>
        <h2>VI. Pocketbook’s rights to use</h2>
        <p>(1) By storing its electronic content in the Pocketbook Cloud Service, the User grants Pocketbook a free and transferable right of use of the respective content, in particular for storage, reproduction and processing, insofar as this is necessary for the proper provision of the Cloud Services by Pocketbook.</p>
        <p>(2) Pocketbook reserves the right to refuse to save the electronic content of the User and/or to edit, block or remove already saved content without prior notice if the saving of the content by the User or the saved content itself has led to a violation of these Terms of Use or if there are concrete indications that a serious violation will occur. Pocketbook will take into consideration the legitimate interests of the User and choose the most appropriate means to defend against such violation.</p>
        <h2>VII. Term, termination</h2>
        <p>(1) These Terms of Useof Pocketbook’s Cloud Services shall run perpetually. These Terms of Use can be terminated by the User without notice in writing or by e-mail.</p>
        <p>(2) Pocketbook may terminate these Terms of Use for the use of Cloud Services with 14 days’ notice. The right to block remains unaffected.</p>
        <p>(3) The right to termination for a good cause remains unaffected. The compelling reason for Pocketbook exists in particular if:</p>
        <p>
            <ol type="a">
                <li>the User violates its obligations undertaken according to these Terms of Use about the use of the Cloud Services of Pocketbook as well as in particular those from section IV of these Terms of Use and, despite, in particular, a reminder to remedy the situation within the set time limit. A reminder is not required if it does not promise success or if the violation is so serious that Pocketbook cannot reasonably be expected to adhere to these Terms of Use. In the event of a breach of the obligations from section IV (User Obligations), a serious breach must principally be assumed. Furthermore, the seriousness of the violation can also result from the fact that the User has already been warned several times on the fact of violation.</li>
                <li>Pocketbook discontinues to provide its Cloud Services or to render its business activities.</li>
            </ol>
        </p>
        <h2>VIII. Measures to be taken in the event of illegal and/or non-contractual conduct on the User’s part</h2>
        <p>(1) If there are concrete indications that a User violates or has violated these Terms of Use or applicable law, Pocketbook can, without notice, take one or more of the following measures subject to termination:</p>
        <p>
            <ol type="a">
                <li>warn the User, if the violation has occurred once and has not resulted in serious damage to PocketBook;</li>
                <li>delete contents of the User;</li>
                <li>restrict the use of the Cloud Services by the User; or</li>
                <li>temporarily or permanently exclude the User from using the Cloud Services (blocking).</li>
            </ol>
            The same applies in the case of any other justified interest of Pocketbook in one or more of these measures undertaken by Pocketbook.
        </p>
        <p>(2) When choosing a measure, Pocketbook also considers the legitimate interests of the User concerned.</p>
        <p>(3) If a User has been blocked by Pocketbook, there is no right to restore the blocked access to the Cloud Services of Pocketbook. The User may not use Pocketbook's Cloud Services via other Users and may not re-register.</p>
        <h2>IX. Liability of Pocketbook</h2>
        <p>(1) Liability of Pocketbook consists – no matter for what legal reason – only</p>
        <p>
            <ol type="a">
                <li>in the case of intent and gross negligence on the part of Pocketbook, of a legal representative, an executive employee or other vicarious agents;</li>
                <li>in case of any culpable breach of an essential contractual obligation (whereby the term “essential contractual obligation” refers abstractly to such an obligation, the fulfilment of which makes the proper execution of Terms of Use possible in the first place and the observance of which the respective other party may regularly rely on), in case of non-performance  and impossibility of performance.</li>
            </ol>
        </p>
        <p>(2) The liability according to section IX. (1) lit. b) is limited to the amount of the typically foreseeable damage in the case of financial loss and damage to property.</p>
        <p>(3) The aforementioned limitations of liability shall not apply in cases of mandatory statutory liability, in particular with regard to the Product Liability Act, the assumption of a guarantee or culpable injury to life, limb or health.</p>
        <p>(4) Pocketbook is only liable in the case of data loss caused by simple negligence, for the damage that would also have been incurred if the User had made a proper and regular backup of the data and contents appropriate to the importance of the data and contents; this limitation does not apply if the data backup was hindered or impossible for reasons for which Pocketbook is responsible.</p>
        <p>(5) To the extent that the liability of Pocketbook is excluded or limited, this also applies to the personal liability of its employees, representatives and other employees and vicarious agents.</p>
        <h2>X. Final provisions</h2>
        <p>(1) Pocketbook is entitled to transfer its rights and obligations undertaken by these Terms of Use in whole or in part to a third party. In the event of a change of the contractual partner (i.e., if a third party takes the place of Pocketbook in the rights and obligations arising from these Terms of Use), the User is entitled to terminate Terms of Use without notice in writing or by e-mail. An assignment of the rights of the User granted by these Terms of Use to third parties is excluded. In particular, a transfer of the User account is inadmissible.</p>
        <p>(2) Should individual provisions of these Terms of Use be or become invalid in whole or in part, they shall otherwise remain valid. Instead of the invalid provision, the relevant statutory provisions shall apply.</p>
        <br>
        <br>
        <p>Last reviewed : March __, 2020</p>
    </section>
`)

const privacy = {
  termsOfUse: 'Условия использования',
  cookiesConditions: 'Условия использования Cookies',
  policyHeaderText: policyHeaderText,
  policyFooterText: policyFooterText,
  privacy01: '1. Сфера применения и правовые основы',
  privacy02: '2. Меры безопасности',
  privacy03: '3. Передача данных третьим сторонам и сторонним провайдерам',
  privacy04: '4. Сбор данных обращения и файлов журнала сервера',
  privacy05: '5. Предоставление Услуг по договору',
  privacy06: '6. Коммуникация',
  privacy07: '7. Оценка продукта',
  privacy08: '8. Новостная рассылка',
  privacy09: '9. Файлы cookie',
  privacy10: '10. Google Analytics',
  privacy11: '11. Сервисы Facebook для маркетинга',
  privacy12: '12. Права пользователей',
  privacy13: '13. Удаление данных',
  privacy14: '14. Право возражения',
  privacy15: '15. Изменения политики конфиденциальности',
  section1: section1,
  section2: section2,
  section3: section3,
  section4: section4,
  section5: section5,
  section6: section6,
  section7: section7,
  section8: section8,
  section9: section9,
  section10: section10,
  section11: section11,
  section12: section12,
  section13: section13,
  section14: section14,
  section15: section15,

  termsOfUseText: termsText,
  cookiesTermsText: cookiesTermsText,
}
export default privacy;