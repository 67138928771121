import React from 'react'
import {compose, lifecycle, setDisplayName, withStateHandlers} from "recompose";
import {connect} from "react-redux";

import './EditNote.css'
import {getLocaleString} from "../../../../../../../../locale";

const mapStateToProps = state => ({

})

const mapDispatchToProps = (dispatch) => ({

})

const stateHandlers = {
    handleClickOutside: (state, {editNoteNode, closeEditNote}) => (event) => {
        if (editNoteNode.current && !editNoteNode.current.contains(event.target) && !event.target.classList.contains('edit-markdown')) {
            closeEditNote()
        }
    },
    changeValue: () => (e) => ({
        note: e.target.value
    })
}

export const enhance = compose(
    setDisplayName('AudioBook Edit Note'),
    connect(mapStateToProps, mapDispatchToProps),
    withStateHandlers(null, stateHandlers),
    lifecycle({
        UNSAFE_componentWillMount() {
            document.addEventListener('mousedown', this.props.handleClickOutside);
        },
        componentWillUnmount() {
            document.removeEventListener('mousedown', this.props.handleClickOutside);
        }
    })
)

const View = ({editNoteNode, note, changeValue, onUpdateBookmark, mark}) => (
    <span className="mark-note">
        <input
            ref={editNoteNode}
            id="edit-audiobook-note"
            className="Input"
            placeholder={getLocaleString('books.noteOptional')}
            value={note}
            onChange={changeValue}
            onKeyPress={event => {
                if (event.key === 'Enter') {
                    onUpdateBookmark(mark, note)
                }
            }}
            onClick={e => e.stopPropagation()}
        />
    </span>
)

export const EditNote = enhance(View)