import { API, getAuthHeaders } from './axiosInstance'

const PAYMENT_SERVICE = 'paypal'

const formatPlanFromRequest = (plan) => ({
  name: plan.title,
  description: plan.description || 'No Description Provided',
  storage: plan.space,
  price: plan.price,
  id: plan.id,
})

export const getPlans = () => API
  .get('/user/tariff-plans', getAuthHeaders())
  .then(({ data }) => data.map(formatPlanFromRequest))

export const getCurrentPlan = () => API
  .get('/user/current-tariff-plan', getAuthHeaders())
  .then(({ data }) => formatPlanFromRequest(data))

export const upgradePlan = (planId) => API
  .post(`/payment/${PAYMENT_SERVICE}/confirm`, {
    product_type: 'tariff',
    product_id: planId,
    payment_status: 'success', // now hardcoded to send only success messages
  }, getAuthHeaders())
  .then(({ data }) => data)
