import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import {withStateHandlers, compose, branch, setDisplayName, renderComponent} from 'recompose'
import {pipe, equals, match, forEach, gt, __, when, prop, length, filter, propEq} from 'ramda'
import { withRouter } from 'react-router'
import {
  fetchBooks,
  fetchAllBooks,
  getCollections,
  sortBooks,
  sortCollections,
  getGenres,
  changeViewBooks,
  getBooksInfo
} from '../../actions/books'
import {setLanguage, userData, setSearchParam, setIsLogin} from '../../actions/user'
import { selectors } from '../../selectors/books'
import * as readRateActions from '../../actions/readRate'
import { loadLangInUrl, loadUserIdInUrl, updateLangInUrl, updateUserIdInUrl } from "../../utilities";
import {AudioBookPlayer} from "./components/AudioBook/AudioBookPlayer";
import { purchasedIsEmpty, seriesIsEmpty, recentlyIsEmpty, genresIsEmpty, formatsIsEmpty, favoritesIsEmpty, authorsIsEmpty} from "../../actions/sidebar";

import './Dashboard.css'

import { createAsync } from '../../utilities'

import { Loading } from '../Shared/Loading/Loading'
import {addNotification, removeNotification} from "../../actions/notifications";
import { nextDropdown } from '../../actions/dropdown'
import { getPlans, getCurrentPlan } from '../../actions/plans'

const AsyncBooks = createAsync('Books', () => import('./components/Books/Books'))
const AsyncOverview = createAsync('Overview', () => import('./components/Overview/Overview'))
const AsyncAuthors = createAsync('Authors', () => import('./components/Authors/Authors'))
const AsyncCollections = createAsync('Collections', () => import('./components/Collections/Collections'))
const AsyncSettings = createAsync('Settings', () => import('./components/Settings/Settings'))

const AsyncAudioBook = createAsync('AudioBook', () => import('./components/AudioBook/AudioBook'))

const mapDispatchToProps = (dispatch) => ({
  fetchBooks: (type, options) => dispatch(fetchBooks(type, options)),
  fetchAllBooks: (type) => dispatch(fetchAllBooks(type)),
  getCollections: () => dispatch(getCollections()),
  userData: () => dispatch(userData()),
  getPartnerData: () => {
    dispatch(readRateActions.getLoginStatus())
    dispatch(readRateActions.getLoginUrl())
    dispatch(readRateActions.getNews())
    dispatch(readRateActions.getDiscussion())
    dispatch(readRateActions.getUserInfo())
  },
  addNotification: (notification) => dispatch(addNotification(notification)),
  removeNotification: (id) => dispatch(removeNotification(id)),
  sortBooks: (type) => dispatch(sortBooks(type)),
  sortCollections: (type) => dispatch(sortCollections(type)),
  getGenres: () => dispatch(getGenres()),
  changeViewBooks: (type) => dispatch(changeViewBooks(type)),
  nextDropdown: (id) => dispatch(nextDropdown(id)),
  getPlans: () => dispatch(getPlans()),
  getCurrentPlan: () => dispatch(getCurrentPlan()),
  setLanguage: (lang) => dispatch(setLanguage(lang)),
  setSearchParam: (param) => dispatch(setSearchParam(param)),
  setIsLogin: (status) => dispatch(setIsLogin(status)),
  booksInfo: () => dispatch(getBooksInfo()),
  purchasedIsEmpty: (flag) => dispatch(purchasedIsEmpty(flag)),
  recentlyIsEmpty: (flag) => dispatch(recentlyIsEmpty(flag)),
  favoritesIsEmpty: (flag) => dispatch(favoritesIsEmpty(flag)),
  authorsIsEmpty: (flag) => dispatch(authorsIsEmpty(flag)),
  seriesIsEmpty: (flag) => dispatch(seriesIsEmpty(flag)),
  genresIsEmpty: (flag) => dispatch(genresIsEmpty(flag)),
  formatsIsEmpty: (flag) => dispatch(formatsIsEmpty(flag)),
})

const state = {
  fetchUserBooksStatus: 'not yet started'
}

const stateHandlers = {
  updateFetchBooksStatus: () => (status) => ({
    fetchUserBooksStatus: status
  }),
  checkBooks: (state, {totalBooks}) => (wait, maybeFetchBooks, updateFetchBooksStatus) => {
    if (totalBooks >= 0) {
      updateFetchBooksStatus('fetched')
      maybeFetchBooks()
      clearInterval(wait)
    }
  }
}

const mapStateToProps = (state) => ({
  fetchBooksRequest: state.books.fetchBooksRequest,
  totalBooks: state.books.totalBooks,
  books: state.books.byId,
  onFetchUserData: state.userData.onFetchUserData,
  booksInState: selectors.countBooksInState(state),
  activeUpload: state.books.uploadingBooks.active,
  files: state.books.uploadingBooks.files,
  language: state.userData.language,
  notifications: state.notifications.notificationsList,
  userId: state.userData.data ? state.userData.data.user_id : null,
  user: state.user,
  login: state.userData.data.login
})

export const enhance = compose(
  setDisplayName('Dashboard'),
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(state, stateHandlers)
)

const RenderBooksRoutes = () => (
  <Switch>
    <Route path={`/:language?/user/:userId?/settings`} component={AsyncSettings} />
    <Route path={`/:language?/user/:userId?/books/:displayType?/:displayQuery?`} component={AsyncBooks} />
    <Route path={`/:language?/user/:userId?/authors`} component={AsyncAuthors} />
    <Route path={`/:language?/user/:userId?/audio/:bookName?`} component={AsyncAudioBook} />
    <Route path={`/:language?/user/:userId?/collections/:displayType?`} component={AsyncCollections} />
    <Route path={`/:language?/user/:userId?/`} exact component={AsyncOverview} />
  </Switch>
)

export const isSettings = (language, userId) => pipe(match(`/${language}/user/${userId}/settings`), length, gt(__, 0))(window.location.pathname)

export const isIE = () => /Trident\/|MSIE/.test(window.navigator.userAgent)

const RenderRoutes = branch(
  ({fetchUserBooksStatus}) => equals('fetched', fetchUserBooksStatus),
  renderComponent(RenderBooksRoutes),
  renderComponent(() => <Loading active={true} />)
)()

export class View extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.setIsLogin(false)
  }

  componentDidMount () {
    const { props: {user, login, fetchAllBooks, fetchBooks, getCollections, userData, getPlans, getCurrentPlan,
      getPartnerData, sortBooks, sortCollections, getGenres, changeViewBooks, updateFetchBooksStatus, language,
      setLanguage, setSearchParam } } = this

    setSearchParam('')
    if (user && !login) {
      userData()
      getPlans()
      getCurrentPlan()
    }

    const booksToFetch = [
      'recommended',
      'bestsellers',
      'new',
      'lastRead'
    ]

    fetchAllBooks('user')
    fetchAllBooks('audio')
    updateFetchBooksStatus('fetched')

    forEach(fetchBooks, booksToFetch)
    getCollections()
    getPartnerData()
    getGenres()

    let sortType = window.localStorage.getItem('sortType')
    if (sortType) {
      sortBooks(sortType)
    }
    let collectionSortType = window.localStorage.getItem('collectionsSortType')
    if (collectionSortType) {
      sortCollections(collectionSortType)
    }
    let viewType = window.localStorage.getItem('viewType')
    if (viewType) {
      changeViewBooks(viewType)
    }

    loadLangInUrl(this.props, language, setLanguage)
    loadUserIdInUrl(this.props)
  }

  UNSAFE_componentWillUpdate(nextProps) {
    updateLangInUrl(this.props, nextProps)
    updateUserIdInUrl(this.props, nextProps)
    if (this.props.totalBooks !== nextProps.totalBooks) {
      setTimeout(() => {
        this.props.booksInfo()
      }, 1000)
    }

    if (this.props.books !== nextProps.books) {
      let filterPurchased = 0
      let filterFavorite = 0
      let filterSeries = 0

      if (!nextProps.books.title) {
        filterPurchased = Object.keys(filter(book => propEq('isPurchased', true, book))(nextProps.books)).length
        filterFavorite =  Object.keys(filter(book => propEq('isFavorite', true, book))(nextProps.books)).length
        filterSeries = Object.keys(filter(book => book.series && book.series.length > 0)(nextProps.books)).length
      }

      this.props.purchasedIsEmpty(filterPurchased ? false : true)
      this.props.favoritesIsEmpty(filterFavorite ? false : true)
      this.props.authorsIsEmpty(nextProps.totalBooks ? false : true)
      this.props.genresIsEmpty(nextProps.totalBooks ? false : true)
      this.props.formatsIsEmpty(nextProps.totalBooks ? false : true)
      this.props.seriesIsEmpty(filterSeries ? false : true)
    }
  }

  maybeFetchBooks = () => {
    const { props: { totalBooks, fetchBooks } } = this
    when(
      () => gt(totalBooks, 0),
      () => {
        fetchBooks('user', {limit: totalBooks})
        fetchBooks('audio', {limit: totalBooks})
      }
    )(totalBooks)
  }

  render () {
    const { fetchUserBooksStatus } = prop('props', this)

    return (
      <div>
        <RenderRoutes fetchUserBooksStatus={fetchUserBooksStatus}/>
        <AudioBookPlayer/>
      </div>
    )
  }
}

export const Dashboard = withRouter(enhance(View))


// for async
export default withRouter(enhance(View))
