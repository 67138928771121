const settings = {
  delete: 'Удалить',
  eMail: 'E-mail',
  vendor: 'Vendor',
  UUID: 'UUID',
  password: 'Пароль',
  makeDefault: 'Использовать по умолчанию',
  defaultID: 'По умолчанию',
  addAdobeID: 'Добавить Adobe ID',
  youAreSigned: 'Вы вошли в свою учетную запись ReadRate',
  logOut: 'Выйти',
  linkAccount: 'Просоеденить свой аккаунт',
  used: 'Использовано',
  outOf: 'из',
  changeAccount: 'Изменить тарифный план',
  spaceInAccount: 'Места в вашем аккаунте',
  settings: 'Настройки',
  getMoreSpace: 'Получить больше места',
  loginLength: 'Логин должен быть длиннее 6 символов',
  loginNotEmpty: 'Логин не может быть пустым',
  passLength: 'Пароль должен быть длиннее 8 символов',
  selectVendor: 'Пожалуйста, выберите Vendor из списка',
  passNotEmpty: 'Пароль не может быть пустым',
  chooseVendorAdobe: 'Выберите свой vendor для Adobe ID',
  login: 'Авторизоваться',
  provideLogin: 'Введите логин',
  providePassword: 'Введите пароль',
  continue: 'Продолжить',
  currency: 'USD',
  month: 'месяц',
  moreSpace: 'Больше места, больше книг, больше радости',
  perfectPlan: 'Найдите идеальный план для вас - гарантированное удовлетворение 100%',
  cancel: 'Отменить',
  confirm: 'Подтвердить',
  showPassword: 'Показать пароль',
  secured: 'Secured',
  addVendorFormFailureMessage: 'Adobe authorization failed. Login or Password is invalid',
  add: 'Добавить',
  freshTheme: 'Современная тема',
  classicTheme: 'Классическая тема',

  emailLength: '',
  getPassword: 'Узнать пароль',
  emailNotEmpty: 'E-mail не может быть пустым',
  resetPasswordFormText: 'Укажите e-mail, на который Вам будет отправлена ссылка для просмотра пароля.',
  passwordFormSuccessMessage: 'Email был отправлен успешно',
  passwordFormFailedMessage: 'Ошибка при отправке письма',
  showPasswordTitle: 'Пароль от Pocketbook Adobe DRM:',
  'Invalid hash': 'Неправильная ссылка',
  'Link expired': 'Время действия ссылки истекло'
}
export default settings;