export const TYPES = {
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER',
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN',
  USER_DATA: 'USER_DATA',
  LOAD_USER_DATA: 'LOAD_USER_DATA',
  ON_FETCH_USER_DATA: 'ON_FETCH_USER_DATA',
  USERS_STORAGE_DATA: 'USERS_STORAGE_DATA',
  LOAD_USERS_STORAGE_DATA: 'LOAD_USERS_STORAGE_DATA',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  ON_UPDATE_USER_DATA: 'ON_UPDATE_USER_DATA',
  SET_LANGUAGE: 'SET_LANGUAGE',
  CHANGE_LOCATION_IN_SETTINGS: 'CHANGE_LOCATION_IN_SETTINGS',
  SET_STEP: 'SET_STEP',
  SET_PROVIDERS: 'SET_PROVIDERS',
  SET_SELECTED_PROVIDER: 'SET_SELECTED_PROVIDER',
  SET_USERNAME: 'SET_USERNAME',
  SET_LOGGED_WITH_SN: 'SET_LOGGED_WITH_SN',
  SET_SEARCH_PARAM: 'SET_SEARCH_PARAM',
  SET_IS_LOGIN: 'SET_IS_LOGIN',
  TOGGLE_LOADING_STATUS: 'TOGGLE_LOADING_STATUS',
  SET_PROVIDERS_FROM_SN_LOGIN: 'SET_PROVIDERS_FROM_SN_LOGIN',
  SET_INTERNAL_PASSWORD: 'SET_INTERNAL_PASSWORD',
  SET_INTERNAL_PASSWORD_DATA: 'SET_INTERNAL_PASSWORD_DATA'
}

export const setUserName = (username) => ({
  type: TYPES.SET_USERNAME,
  payload: username
})

export const setStep = (step) => ({
  type: TYPES.SET_STEP,
  payload: step
})

export const setProviders = (providers) => ({
  type: TYPES.SET_PROVIDERS,
  payload: providers
})

export const setSelectedProvider = (provider) => ({
  type: TYPES.SET_SELECTED_PROVIDER,
  payload: provider
})

export const onUpdateUserData = (data) => ({
  type: TYPES.ON_UPDATE_USER_DATA,
  payload: data
})

export const updateUserData = (data) => ({
  type: TYPES.UPDATE_USER_DATA,
  payload: data
})

export const setUser = (user) => ({
  type: TYPES.SET_USER,
  payload: user
})

export const removeUser = () => ({
  type: TYPES.REMOVE_USER
})

export const login = (user) => ({
  type: TYPES.LOGIN,
  payload: user
})

export const logout = () => ({
  type: TYPES.LOGOUT
})

export const userData = () => ({
  type: TYPES.LOAD_USER_DATA,
})

export const loadUserData = (userData) => ({
  type: TYPES.USER_DATA,
  payload: userData
})

export const onFetchUserData = (status) => ({
  type: TYPES.ON_FETCH_USER_DATA,
  payload: status
})

export const usersStorageData = () => ({
  type: TYPES.USERS_STORAGE_DATA
})

export const loadUsersStorageData = (data) => ({
  type: TYPES.LOAD_USERS_STORAGE_DATA,
  payload: {
    files_size_limit: data.files_size_limit,
    files_size: data.files_size
  }
})

export const setLanguage = (data) => ({
  type: TYPES.SET_LANGUAGE,
  payload: data
})

export const changeLocationInSettings = (status) => ({
  type: TYPES.CHANGE_LOCATION_IN_SETTINGS,
  payload: status
})

export const setLoggedWithSN = (snType) => ({
  type: TYPES.SET_LOGGED_WITH_SN,
  payload: snType
})

export const setProvidersFromSNLogin = (providers) => ({
  type: TYPES.SET_PROVIDERS_FROM_SN_LOGIN,
  payload: providers
})

export const setSearchParam = (search) => ({
  type: TYPES.SET_SEARCH_PARAM,
  payload: search
})

export const setIsLogin = (status) => ({
  type: TYPES.SET_IS_LOGIN,
  payload: status
})

export const toggleLoadingStatus = (status) => ({
  type: TYPES.TOGGLE_LOADING_STATUS,
  payload: status
})

export const setInternalPassword = (password) => ({
  type: TYPES.SET_INTERNAL_PASSWORD,
  payload: password
})

export const setInternalPasswordData = (data) => ({
  type: TYPES.SET_INTERNAL_PASSWORD_DATA,
  payload: data
})