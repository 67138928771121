import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import reducer from './reducers/index'
import rootSaga from './sagas/root'

export const initialState = {
  user: null,
  userData: {
    data: {language: null},
    language: null,
    internalPassword: null,
    internalPasswordData: null
  },
  books: {
    byId: {},
    allIds: []
  },
  readRate: {
    isLogged: false,
    loginUrl: '#',
    news: [],
    discussion: [],
    user: null
  },
  activeDropdown: null,
  adobe: {
    vendors: [],
    defaultVendor: null,
    vendorsIds: [],
    addVendorFormStatus: null,
    passwordFormStatus: null
  },
  plans: {
    available: [],
    current: null,
  }
}

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = composeWithDevTools({})

const compose = () => {
  return process.env.NODE_ENV === 'development' ?
      composeEnhancers(
          applyMiddleware(sagaMiddleware)
      ) :
      applyMiddleware(sagaMiddleware)
}

const store = createStore(
  reducer,
  initialState,
  compose()
)

sagaMiddleware.run(rootSaga)

export default store
