import React  from 'react'
import {compose, setDisplayName, withStateHandlers} from "recompose";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {connect} from "react-redux";
import {Scrollbars} from 'react-custom-scrollbars'
import {BookInfoList} from "../BookInfo/BookInfo";
import {ChapterList} from "../Chapters/Chapters";
import {BookmarkList} from "../Bookmakrs/Bookmarks";

const mapStateToProps = state => ({
  language: state.userData.language || navigator.language || navigator.userLanguage,
  marks: state.books.audioBook.files.mark,
  fetchingFiles: state.books.audioBook.fetchingFiles
})

const mapDispatchToProps = (dispatch) => ({})

const state = {
  selectedTab: 1,
}

const stateHandlers = {
  selectTab: () => (index) => ({
    selectedTab: index
  }),
}

export const enhance = compose(
  setDisplayName('AudioBook Sidebar'),
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(state, stateHandlers),
)

const View = ({selectedTab, selectTab, audioBook, chapters, trackNumber, changeTrack, marks,
                onRemoveMarkDown, onEditNote, openEditNote, editNoteNode, closeEditNote,
                onUpdateBookmark}) => (
  <div className="play-list-wrapper">
    <Tabs
      selectedIndex={selectedTab}
      onSelect={selectTab}
    >
      <TabList>
        <Tab></Tab>
        <Tab></Tab>
        <Tab></Tab>
      </TabList>

      <TabPanel>
        <Scrollbars style={{ width: '100%', height: '100%' }}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
          <BookInfoList audioBook={audioBook}/>
        </Scrollbars>
      </TabPanel>

      <TabPanel>
        <Scrollbars style={{ width: '100%', height: '100%' }}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
          <ChapterList chapters={chapters} trackNumber={trackNumber}
                       changeTrack={changeTrack}/>
        </Scrollbars>
      </TabPanel>

      <TabPanel>
        <Scrollbars style={{ width: '100%', height: '100%' }}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
          <BookmarkList marks={marks} onRemoveMarkDown={onRemoveMarkDown} changeTrack={changeTrack}
                        onEditNote={onEditNote} openEditNote={openEditNote} editNoteNode={editNoteNode}
                        closeEditNote={closeEditNote} onUpdateBookmark={onUpdateBookmark}
                        chapters={chapters}
          />
        </Scrollbars>
      </TabPanel>
    </Tabs>
  </div>
)

export const Sidebar = enhance(View)