import { TYPES } from '../actions/notifications'
import { TYPES as STORAGE_TYPES } from '../actions/user'
import { propEq, find } from 'ramda'

const initialState = {
  notificationsList: [],
  storageSpaceInfo: {},
  closeToLimit: false,
  popUpNotification: {},
  showNotification: false
}

export const notificationsReducer = (state = initialState, action) => {

  const closeToLimit = (limit, size) => limit - size <= 104857600

  const uniqSpaceMessage = (notifications, prop, message) => {
    const searchbyID = propEq(prop, message);
    const result = find(searchbyID, notifications);
    if(result) {
      const filteredNotifications = notifications.filter(notification => notification.message !== message)
      return [ ...filteredNotifications, result]
    }
    return notifications
  }

  switch(action.type) {

  case TYPES.ADD_NOTIFICATION:
    state.notificationsList = [ ...state.notificationsList, action.payload ]
    state.notificationsList = [ ...uniqSpaceMessage(state.notificationsList, 'message', 'Free space is almost over') ]
    state.notificationsList = [ ...uniqSpaceMessage(state.notificationsList, 'message', 'An error Happened while uploading book') ]
    return state

  case TYPES.REMOVE_NOTIFICATION:
    if (state.popUpNotification.id === action.payload) {
      state.popUpNotification = {}
    }
    return { ...state, notificationsList: state.notificationsList.filter(notification => notification.id !== action.payload) }

  case STORAGE_TYPES.LOAD_USERS_STORAGE_DATA:
    state.closeToLimit = closeToLimit(action.payload.files_size_limit, action.payload.files_size)
    return { ...state, storageSpaceInfo: action.payload }

  case STORAGE_TYPES.USER_DATA:
    state.closeToLimit = closeToLimit(action.payload.files_size_limit, action.payload.files_size) 
    return { ...state, storageSpaceInfo: { files_size_limit: action.payload.files_size_limit, files_size: action.payload.files_size } }

  case TYPES.SHOW_POPUP_NOTIFICATION:
    return { ...state, popUpNotification: action.payload, showNotification: true }

  case TYPES.HIDE_POPUP_NOTIFICATION:
    return { ...state, showNotification: false }

   default:
    return state 
  }
}