import ecoSystem from '../../assets/images/eco_system.png'
import {SERVER} from "../../constants";

export const sectionArticle1 = () => (`
  <div class="step">
      <h2>1. The Role of Pocketbook Cloud in the Pocketbook Ecosystem</h2>
      <img class="eco-system" src="`+ecoSystem+`"/>
  </div>
  <div class="step">
                    <h2>2. Instructions for connecting  eBookshop to PB Cloud</h2>
                    <p class="sub-title">Methods for connecting to Cloud:</p>
                    <ol>
                        <li>Authorization Api (get_auth_options) <a target="_blank" href="https://drive.google.com/open?id=1He1vFdyjB69FGvWZhWVnpSo0tymV6f9e">https://drive.google.com/open?id=1He1vFdyjB69FGvWZhWVnpSo0tymV6f9e</a></li>
                        <li>Upload files to cloud: <a href="`+SERVER+`/api.php#files__path__put">`+SERVER+`/api.php#files__path__put</a></li>
                        <li>Other methods: recommendations, news, list of bookstores <a href="`+SERVER+`/api.php#">`+SERVER+`/api.php#</a></li>
                    </ol>
                    <p class="sub-title">Steps to connect your e-bookstore to PocketBook Cloud:</p>
                    <div class="sub-step">
                        <p class="title">1. Give to Pocketbook info (get_auth_options_url and secret_key).</p>
                        <p>For example:</p>
                        <p><strong>get_auth_options_url:</strong> http://test-partner.com/pbcloud/get_auth_option</p>
                        <p><strong>secret_key:</strong> 7Y5JhK67G567Yik8</p>
                        <div class="sub-step">
                            <p class="title">1.1 How the get_auth_options_url works?</p>
                            <p>When user tries to login in Pocketbook, Pocketbook will send the request:</p>
                            <pre>
POST http://test-partner.com/pbcloud/get_auth_options
{
    "UserID": "test_user@gmail.com"
}</pre>
                            <p>The response answer - this is the list of partner's shops where this user has an account. <strong>Example:</strong></p>
                            <pre>
{
    "auth-providers": [
        "name": "Test partner shop 1",
        "clientID": 1,
        "loggedBy": "password|facebook|gmail",
        "icon": "http://test-partner-shop.com/img/logo.png",
        "icon-eink": "http://test-partner-shop.com/img/icon-eink.png",
        "shop_id": 1,
        "login": {
            "native": {
                "url": "http://test-partner-shop.com/pbcloud/user_authenticate"
            }
        },
    ],
    ...
}</pre>
                            <p>Where "auth-providers" - this is the list of shops</p>
                            <p class="title">1.2. User selects the one of given shops, posts the password and than Pocketbook will send the next request:</p>
                            <pre>
POST http://test-partner-shop.com/pbcloud/user_authenticate
{
    "login": "test_user@gmail.com",
    "password": "test",
    "serial": "",
    "subpartner": "1",
    "timestamp": "1572867549",
    "signature": "bbae95abe8836dfff47737f56d54357f24125b4f",
}</pre>
                            <p>The expected response - this is the info about user. <strong>Example:</strong></p>
                            <pre>
{
    "user": 123,
    "email": "test_user@gmail.com",
    "firstName": "Test",
    "lastName": "User"
}</pre>
                            <p class="title">1.3. Creating a signature:</p>
                            <div class="sub-step">
                                <p class="title">1.3.1. Create a string like this:</p>
                                <p>login=test_user@gmail.com&password=test&serial=&subpartner=1&timestamp=1572867549&7Y5JhK67G567Yik8</p>
                                <p>where the last element ("7Y5JhK67G567Yik8") - this is the provider's secret_key</p>
                                <p class="title">1.3.2. Make the "sha1" hash from this string</p>
                            </div>
                        </div>
                    </div>
                </div>
`)

const forDevelopers = {
  'howToConnectStoreToPbCloud': 'Как подключить Книжный Магазин к PB Cloud',
  'section-how-to-connect-store-to-pb-cloud': sectionArticle1
}
export default forDevelopers;