export const TYPES = {
    PURCHASED_IS_EMPTY: 'PURCHASED_IS_EMPTY',
    RECENTLY_IS_EMPTY: 'RECENTLY_IS_EMPTY',
    FAVORITES_IS_EMPTY: 'FAVORITES_IS_EMPTY',
    AUTHORS_IS_EMPTY: 'AUTHORS_IS_EMPTY',
    SERIES_IS_EMPTY: 'SERIES_IS_EMPTY',
    GENRES_IS_EMPTY: 'GENRES_IS_EMPTY',
    FORMATS_IS_EMPTY: 'FORMATS_IS_EMPTY',
}

export const purchasedIsEmpty = (flag) => ({
    type: TYPES.PURCHASED_IS_EMPTY,
    payload: flag
})

export const recentlyIsEmpty = (flag) => ({
    type: TYPES.RECENTLY_IS_EMPTY,
    payload: flag
})

export const favoritesIsEmpty = (flag) => ({
    type: TYPES.FAVORITES_IS_EMPTY,
    payload: flag
})

export const authorsIsEmpty = (flag) => ({
    type: TYPES.AUTHORS_IS_EMPTY,
    payload: flag
})

export const seriesIsEmpty = (flag) => ({
    type: TYPES.SERIES_IS_EMPTY,
    payload: flag
})

export const genresIsEmpty = (flag) => ({
    type: TYPES.GENRES_IS_EMPTY,
    payload: flag
})

export const formatsIsEmpty = (flag) => ({
    type: TYPES.FORMATS_IS_EMPTY,
    payload: flag
})