import React from 'react'
import PropTypes from 'prop-types'
import { compose, setDisplayName, pure, setPropTypes, onlyUpdateForPropTypes, mapProps, withStateHandlers } from 'recompose'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import { ifElse, equals, prop, propOr, not, isNil, pipe, concat, path } from 'ramda'
import { changeLocationInSettings } from "../../../../../../actions/user";

import './NavigationLink.css'
import {connect} from "react-redux";

const propTypes = setPropTypes({
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  acceptableRoutes: PropTypes.arrayOf(PropTypes.string)
})

const hasNestedLinks = pipe(prop('children'), isNil, not)

const getUrlFromEl = path(['props', 'to'])

const mergeLinkPath = (baseUrl) => ifElse(
  pipe(path(['props', 'root']), equals(false)),
  pipe(getUrlFromEl, concat(baseUrl)),
  path(['props', 'to'])
)

const isNestedActive = (base, {pathname}, acceptableRoutes = []) => {
  let state = false
  for (let i=0; i < acceptableRoutes.length; i++) {
    let route = acceptableRoutes[i]
    if (pathname.indexOf(route) > -1) {
      state = true
      break
    }
  }
  return state
}


const isActive = (base, {pathname}, language, userId) => {
  let state = false
  let isSettings = pathname.indexOf('/settings/') > -1
  let isDashboard = base === `/${language}/user/${userId}` && (pathname === `/${language}/user/${userId}` || pathname === `/${language}/user/${userId}/`)
  let isOtherPage = pathname === base || pathname === `${base}/`
  if (isSettings || isDashboard || isOtherPage) state = true
  return state
}

const props = mapProps((props) => ({
  onClick: () => {},
  ...props,
  collapsable: hasNestedLinks(props),
  exact: not(hasNestedLinks(props)),
  children: propOr(null, 'children', props)
}))

const collapse = ({collapsed}) => () => ({
  collapsed: not(collapsed)
})

const state = (props) => ({
  collapsed: props.isOpen || isNestedActive(props.to, props.location, props.acceptableRoutes) ? false : true
})

const stateHandlers = {
  collapse
}

const mapStateToProps = (state) => ({
  isChanged: state.userData.isChanged,
  language: state.userData.language,
  userId: state.userData.data ? state.userData.data.user_id : null
})

const mapDispatchToProps = (dispatch) => ({
  changeLocation: (status) => dispatch(changeLocationInSettings(status))
})

export const enhance = compose(
  withRouter,
  setDisplayName('NavigationLink'),
  connect(mapStateToProps, mapDispatchToProps),
  pure,
  onlyUpdateForPropTypes,
  propTypes,
  props,
  withStateHandlers(state, stateHandlers)
)

export const View = ({to, exact, children, collapsable, label, collapse, collapsed, location, onClick, acceptableRoutes,
                       changeLocation, isChanged, language, userId, someClasses }) => (
  <div
    className={`NavigationLink${someClasses ? ` ${someClasses} ${language}` : ''}`}
    data-collapsable={collapsable}
    data-collapsed={collapsed}
    data-nested-active={isNestedActive(to, location, acceptableRoutes)}>
    {collapsable ?
      <div className="NavigationLink__label"
           onClick={e => {e.preventDefault();collapse()}}>
        {label}
        <div className="arrow-wrap"><i className="arrow"></i></div>
      </div>
      : <NavLink
        to={to}
        exact
        onClick={() => changeLocation(!isChanged)}
        onMouseUp={onClick}
        className={`NavigationLink__label${isActive(to, location, language, userId) ? ' NavigationLink__label--active' : ''}`}>
        {label}
      </NavLink>
    }
    {React.Children.map(children, nestedLink =>
      React.cloneElement(nestedLink, {
        to: mergeLinkPath(to)(nestedLink)
      }))}
  </div>
)

export const NavigationLink = enhance(View)
