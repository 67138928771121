import { TYPES } from '../actions/socket'
import {always, cond, equals, prop, T} from "ramda";
import {combineReducers} from "redux";

const addMessage = (state, {payload}) => {
  state.push(payload)
  return state
}

const removeMessage = (state, {payload}) => {
  let i = state.indexOf(payload)
  state.slice(i)
  return state
}

export const setSocket = (state = null, {type, payload}) => cond([
  [equals(TYPES.SET_SOCKET), always(payload)],
  [T, always(state)]
])(type)

export const getMessages = (state = [], action) => cond([
  [equals(TYPES.ADD_MESSAGE), () => addMessage(state, action)],
  [equals(TYPES.REMOVE_MESSAGE), () => removeMessage(state, action)],
  [T, always(state)]
])(prop('type', action))

export const socketReducer = combineReducers({
  socket: setSocket,
  messages: getMessages
})