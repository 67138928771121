import { combineReducers } from 'redux'
import { TYPES } from '../actions/plans'
import { equals, cond, T, always } from 'ramda'

const availablePlans = (state = [], action) => cond([
  [equals(TYPES.SET_PLANS), always(action.payload)],
  [T, always(state)]
])(action.type)

const currentPlan = (state = null, action) => cond([
  [equals(TYPES.SET_CURRENT_PLAN), always(action.payload)],
  [T, always(state)]
])(action.type)

export const plansReducer = combineReducers({
  available: availablePlans,
  current: currentPlan
})
