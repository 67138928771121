const search = {
  noItems: 'Keine Elemente gefunden',
  recentlyRead: 'vor kurzem gelesen',
  collections: 'Sammlungen',
  authors: 'Autoren',
  books: 'Bücher',
  audioBooks: 'Hörbücher',
  searchIcon: 'Suchsymbol',
  canselSearch: 'Symbol zum Abbruch der Suche',
  searchByTitleAuthor: 'Nach Buchtitel oder Autor suchen',

  notFound: 'Nicht gefunden'
}
export default search;