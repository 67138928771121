export const TYPES = {
  SET_SOCKET: 'SET_SOCKET',
  ADD_MESSAGE: 'ADD_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE'
}

export const setSocket = (socket) => ({
  type: TYPES.SET_SOCKET,
  payload: socket
})

export const addMessage = (message) => ({
  type: TYPES.ADD_MESSAGE,
  payload: message
})

export const removeMessage = (message) => ({
  type: TYPES.REMOVE_MESSAGE,
  payload: message
})