import React from 'react'
import { connect } from 'react-redux'
import { lifecycle, compose, setDisplayName } from 'recompose'

import './NotificationPopUp.css'

import { DropdownMenu } from '../../../../../../Shared/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from '../../../../../../Shared/DropdownMenu/components/DropdownMenuItem/DropdownMenuItem'
import Notification from '../components/Notification/Notification'
import { removeNotification, hidePopUpNotification } from '../../../../../../../actions/notifications'

const mapStateToProps = (state) =>({
  notification: state.notifications.popUpNotification,
  showNotification: state.notifications.showNotification,
})

const mapDispatchToProps = (dispatch) => ({
  removeNotification: (id) => dispatch(removeNotification(id)),
  hidePopUpNotification: () => dispatch(hidePopUpNotification()),
})

export const enhance = compose(
  setDisplayName('NotificationPopUp'),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.timer = setTimeout(() => this.props.hidePopUpNotification(), 3000)
    },
    componentWillUnmount() {
      clearTimeout(this.timer)
    }
  })
)

export const View = ({notification, removeNotification, showNotification}) => (
  <div className="Notifications-pop-up">
    <DropdownMenu active={showNotification}>
      <DropdownMenuItem>
        {showNotification}
        <ul className="Notifications__list">
          <Notification notification={notification} action={removeNotification} />
        </ul>
      </DropdownMenuItem>
    </DropdownMenu>
  </div>
)

export const NotificationPopUp = enhance(View)