const cookie = {
  text_before: "Будь ласка, зверніть увагу, що даний сайт PocketBook використовує у своїй роботі технологію cookie для покращення Вашого досвіду користувача, а також в аналітичних та маркетингових цілях, для демонстрації пропозицій, адаптованих відповідно до Ваших інтересів. PocketBook поважає Ваше право на приватність, тому ми просимо приділити хвилину часу для того, щоб Ви самі змогли",
  preferences: "Керування параметрами файлів cookie.",
  text_between: "Будь ласка, зверніть увагу, що украй необхідні файли cookie завжди включені. Якщо Ви погоджуєтеся з використанням всіх видів файлів cookie, натисніть кнопку",
  ok: "Ok",
  text_after: "у цьому вікні. Щоб дізнатися більше про технологію cookie, її переваги та про те, як її використовує PocketBook, ознайомтеся з нашим",
  cookie_notice: "Повідомленям про файли cookie.",
  accept: "Прийняти всі файли cookie",
  customise: "Налаштувати файли cookie",
  save: "Зберегти налаштування користувача",
  necessary: "Надзвичайно необхідні файли cookie: це файли cookie, необхідні для належного надання послуг та забезпечення функціоналу, який Ви спеціально запитували. Ми можемо використовувати файли cookie та технології відстеження для адміністрування системи та/або щоб Ви могли використовувати функціонал кошика покупок. Ми не повинні отримувати Вашу згоду на використання вкрай необхідних файлів cookie.",
  analytics: "Аналітичні файли та файли cookie, пов'язані з ефективністю. Ми можемо використовувати файли cookie для оцінки ефективності наших сервісів, у тому числі в рамках нашої аналітичної роботи, необхідної для покращення вибору контенту, рекомендованого Вам за допомогою сервісів.",
  functionality: "Функціональні файли cookie: ми можемо використовувати файли cookie, наприклад, для того, щоб повідомити нас, чи користувалися Ви сервісами раніше, чи є новим клієнтом, а також щоб допомогти нам визначити функціонал, який може Вас зацікавити.",
  targeting: "Рекламні файли cookie: ми можемо використовувати файли cookie, щоб запропонувати Вам контент, у тому числі рекламний, який відповідає Вашим інтересам щодо використання нашими сервісами."
}
export default cookie;