import adobe1 from '../../assets/images/adobe1.jpg'
import adobe2 from '../../assets/images/adone2.jpg'
import adobe3 from '../../assets/images/adobe3.jpg'
import adobe4 from '../../assets/images/adobe4.jpg'
import {CLOUD_URL} from "../../constants";

export const section1 = () => (`
<h2>How to sign in</h2>
<p>You can sing in to PocketBook Cloud service through a website, PocketBook Reader mobile application (for Android and IOS) or with the help of PocketBook e-ink reader.</p>
        <p><span>Web:</span> To enter the site, use the login and password of your account in the affiliate bookstore.
The Cloud also supports authorization via Facebook or Google. If you are registered in a bookstore using Facebook or Google, you can access Cloud in the same way.
If you do not have an account, click on the link "No account". A list of affiliate bookstores will appear. To continue, please select one of the proposed bookstores, or use the search. You will be redirected to the affiliate bookstore website. Go to the registration form and create a new account, following the instructions. After that, return to the site.
</p>
        <p><span>PocketBook E-ink reader:</span> During initial setup of your Device you will be suggested to sign in to PocketBook Cloud account. </p>
        <p>If you have skipped this step, the suggestion to sign in to PocketBook Cloud account will appear at the notification panel. To sign in, tap on this notification or go to the Settings > Accounts and Synchronization > PocketBook Cloud. You will be redirected to the login form, where you have to tap on Don't have an account link. A list of partners bookstores will open at a browser. To continue, please, choose one of the suggested bookstores by clicking on its name. You will be redirected to the partner's bookstore website. Go to the Sign in form and create a new account according to the instructions. After that, please, return to Settings > Accounts and Synchronization > PocketBook Cloud, enter personal info you used to create a partner's bookstore account and press Login button.</p>
        <p><span>Andriod device:</span> To sign in on your Android device go to your PocketBook Reader application, slide to the right side menu and choose PocketBook Cloud section. On the login page tap on Don't have an account link. A list of partners bookstores will open at browser. To continue, please, choose one of the suggested bookstores by clicking on its name. You will be redirected to the partner's bookstore website. Go to the Sign in form and create a new account according to the instructions. After that, please, return to PocketBook Reader application, choose PocketBook Cloud section on side menu, on the Login page enter personal info you used to create a partner's bookstore account and press Login button.</p>
        <p>Download PocketBook Reader app for Android: <a href="https://play.google.com/store/apps/details?id=com.obreey.reader">https://play.google.com/store/apps/details?id=com.obreey.reader</a></p>
        <p><span>IOS device:</span> To sign in on your IOS device go to your PocketBook Reader application, choose Settings > PocketBook Cloud. On the login page tap on Don't have an account link. A list of partners bookstores will open at browser. To continue, please, choose one of the suggested bookstores by clicking on its name. You will be redirected to the partner's bookstore website. Go to the Sign in form and create a new account according to the instructions. After that, please, return to PocketBook Reader application, choose PocketBook Cloud at Settings section, on the Login page enter personal info you used to create a partner's bookstore account and press Login button.</p>
        <p>Download PocketBook Reader app for iOS: <a href="https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?l=ru&mt=8">https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?l=ru&mt=8</a></p>
        <h2>How to log in</h2>
        <p>To log in to your PocketBook Cloud account through web, please, go to the web-site <a href="`+CLOUD_URL+`">`+CLOUD_URL+`</a>, put your e-mail and press button Next. Choose a bookstore you want to continue with and then enter your password and press Login.</p>
        <p>To log in on your e-ink PocketBook reader go to Settings > Accounts and Synchronization > PocketBook Cloud and enter your e-mail and password.</p>
        <p>To log in on your Android device go to your PocketBook Reader application, slide to right side menu and choose PocketBook Cloud section. Put your e-mail and password and press Login button.</p>
        <p>To log in on your IOS device go to your PocketBook Reader application, choose Settings > PocketBook Cloud. Put your e-mail and password and press Login button.</p>
        <h2>Recover/change your password</h2>
        <p>You can recover your password on the website of your store. Log in to the Cloud occurs with login and password of the store.</p>
        <p>You can change your password on the website of your store. Log in to the Cloud occurs with login and password of the store.</p>
        <h2>Add/Join Adobe ID</h2>
        <p><span>Add Adobe ID:</span></p>
        <p>In order to add an Adobe ID, please go to Settings/AdobeID and click the Add Adobe ID button. Select your Vendor from the list and enter your login and password.
With multiple Adobe account IDs, you can choose one of them as your main Adobe account ID ("Use by Default"). It will be used to download e-books to the PocketBook cloud.
To view the password for your primary Adobe ID account (Vendor: PocketBook), you can click on the "eye" icon.</p>
        <p><span>Join Adobe ID:</span></p>
        <p>To join several Adobe accounts, please, do the following:</p>
        <p><a href="http://adeactivate.adobe.com/adept/en/JoinAccountLoginForm.jsp" target="_blank">Go to the Adobe Account Synchronization page</a></p>
        <img src="`+adobe1+`" />
        <p>Choose your Adobe ID and press Login</p>
        <img src="`+adobe2+`" />
        <p>Choose PocketBook Vendor from the list: keep in mind that you can use only one eBook Vendor for several Vendor ID’s</p>
        <p>Type in second vendor Account ID and the password</p>
        <img src="`+adobe3+`" />
        <p>Press Synchronize Accounts button. Now you can easily transfer your content between the multiple vendor devices.</p>
        <img src="`+adobe4+`" />
`)
export const section2 = () => (`
<h2>How the synchronization proceeds between my devices</h2>
 <p>You can read your books on different devices and be sure that all your notes, bookmarks and reading progress will be saved. You can have access to your books from any device on which you are logged in to the PocketBook Cloud account.</p>
        <p><span>On the web:</span> the auto synchronization of books list and reading position takes place at the time of login to the PocketBook Cloud account and book closure.</p>
        <p><span>On e-ink devices:</span> the auto synchronization of books list and reading position takes place at the time of login to the PocketBook Cloud account, book opening, book closure or book fold. To enforce synchronization click on the sync icon on the notification panel.</p>
        <p><span>On Android devices:</span> the auto synchronization of books list and reading position takes place at the time of login to the PocketBook Cloud account and book closure. Also you can enforce synchronization by clicking on the sync icon at the Library.</p>
        <p><span>On IOS devices:</span> the auto synchronization of books list and reading position takes place at the time of login to the PocketBook Cloud account and book closure.</p>
        <h2>How do I pause and renew synchronization</h2>
        <p>There is no possibility to pause synchronization expressly, but note, that synchronization will be disabled if there is no Internet connection.</p>
        <h2>Can I have access to my Cloud books without internet connection</h2>
        <p>If there is no Internet connection on your Device you will have access only to the books from your last synchronization.</p>
        <h2>Synchronization errors</h2>
        <p>If an error occurred during synchronization, please, check your Internet connection. If an Internet connection is established correctly try to sync your books lately as possible failure occurred on the server.</p>
`)
export const section3 = () => (`
 <h2>Uploading new book</h2>
 <p>You can upload new books to your PocketBook Cloud via website, PocketBook e-ink reader or Android/IOS PocketBook Reader application. After synchronization all new books will automatically stored on your PocketBook Cloud account.</p>
        <h2>What formats pocketbook cloud supports</h2>
        <p>PocketBook Cloud supports all available on PocketBook e-ink reader and PocketBook Reader mobile application books formats (PDF (Adobe DRM), EPUB (Adobe DRM), DjVu, TXT, FB2, FB2.zip, CHM, html (basic),CBZ, CBR, СBT, RTF).</p>
        <h2>Whether the book is stored the device after synchronization</h2>
        <p>Yes, if the book was loaded from PB Cloud on the Device.</p>
        <h2>How to delete files</h2>
        <p>To delete a book using the PocketBook Cloud website, select "Delete" from the drop-down menu of the book. (To call the drop-down menu - click on the icon in the form of three points).</p>
        <p>To delete several books at once, you can select these books using Ctrl + left mouse button. Then click on the delete icon in the top menu bar.</p>
        <p>To remove a book from your account using PocketBook e-reader, or an application for reading on Android or IOS devices, select the book from the library list and open the context menu. You will be offered two options - "Delete" (from device memory) and "Delete from Cloud".</p>
        <h2>Is it possible to recover files</h2>
        <p>If the file was stored on PocketBook Cloud and was deleted only from the device memory, it will appear again after next synchronization.</p>
        <p>If the file was deleted from the Cloud there is no possibility to restore it.</p>
        <h2>How to download books from cloud</h2>
        <p>To download book to the memory of your e-ink, Android or IOS device just tap on the Cloud book from the books list in the Library and the downloading process will begin automatically.</p>
        <p>In order to download a book from PocketBook Cloud to your computer, select "Download" from the drop-down menu of the book. (To call the drop-down menu - click on the icon in the form of three points).</p>
        <p>To download several books at the same time, you can select these books using Ctrl + left mouse button. Then click on the download icon in the top line of menu bar.</p>
        <h2>How to read book from browser</h2>
        <p>At the moment, you can read from the browser books in EPUB and PDF formats.</p>
        <p>To open a book in the browser, click on the book cover (in the "Tiles" mode), or on the line with the book title (in the "List" mode). In a new window, the book will be opened in a web-reader. You can also open the book from the "Information about the book" drop-down menu.</p>
        <p>Reading from the browser is also subject for synchronization, so your reading positions, bookmarks, and notes will be saved. To exit the book - click on the "Exit" icon in the left corner of the application window.</p>    
`)

const faq = {
  account: 'Account',
  sync: 'Synchronization',
  storage: 'Files Storage',
  section1: section1,
  section2: section2,
  section3: section3
}
export default faq;