export const TYPES = {
  SEND_FORM: 'SEND_FORM',
  SEND_FORM_STATUS: 'SEND_FORM_STATUS',
  SEND_LOGIN_STATUS: 'SEND_LOGIN_STATUS'
}

export const sendForm = (fields) => ({
  type: TYPES.SEND_FORM,
  payload: fields
})

export const sendFormStatus = (status) => ({
  type: TYPES.SEND_FORM_STATUS,
  payload: status
})

export const sendLoginStatus = (status) => ({
  type: TYPES.SEND_LOGIN_STATUS,
  payload: status
})