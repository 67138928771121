import {call, takeEvery, put} from "redux-saga/effects";
import {setStores} from "../actions/findStore";
import * as API from "../API/finsStore";
import {TYPES} from "../actions/findStore";

function* getStores () {
  let stores = yield call(() => API.getStores())
  //stores = stores.concat(localStores)
  yield put(setStores(stores))
}

export const findStoreSaga = [
  takeEvery(TYPES.GET_STORES, getStores)
]