import React from 'react'
import { compose, setDisplayName, pure } from 'recompose'

import { AppLink } from './components/AppLink/AppLink'
import { getLocaleString } from '../../../../../../locale'

import './AppLinks.css'

import androidAppLogo from '../../../../../../assets/icons/android.svg'
import iosAppLogo from '../../../../../../assets/icons/ios.svg'
import {connect} from "react-redux";
import { appLinks } from '../../../../../../constants'

const mapStateToProps = (state) => ({
  language: state.userData.language
})

const mapDispatchToProps = (dispatch) => ({

})

export const enhance = compose(
  setDisplayName('AppLinks'),
  connect(mapStateToProps, mapDispatchToProps),
  pure
)

export const View = (props) => (
  <div className="AppLinks">
    <h6 className="AppLinks__title">{getLocaleString('sidebar.getApp')}</h6>
    <AppLink icon={androidAppLogo} label="Android" href={appLinks.android} />
    <AppLink icon={iosAppLogo} label="iOS" href={appLinks.ios} iconStyle={{
      width: '21px',
      height: '21px',
      marginTop: '-1px'
    }} />
  </div>
)

export const AppLinks = enhance(View)
