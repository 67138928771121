export const about = () => (`
  <h1>Про сервіс PocketBook Cloud</h1>
   <section>
   <p>Уявіть собі величезну книжкову полицю, де зберігаються сотні улюблених книг із вашими нотатками на полях. Завдяки PocketBook Cloud, така полиця завжди під рукою, незалежно від того, де ви знаходитесь і який девайс використовуєте.</p>
   <p><strong>PocketBook Cloud – кросплатформний хмарний сервіс</strong>, що поєднує ваш рідер, смартфон на Android або iOS, планшети та ПК, в єдину екосистему. Це дозволяє синхронізувати файли електронних книг, позиції читання та навіть персональні налаштування. Ви починаєте читати на рідері, продовжуєте на смартфоні, а потім знову повертаєтеся до звичної читалки, не відшукуючи кожного разу сторінку, на якій зупинилися, і не змінюючи щоразу шрифт та інші параметри відображення.</p>
   <p>PocketBook – це не тільки сучасні девайси, це велика родина, що поєднує пристрасних книголюбів з усього світу. Саме тому було створено <strong>хмарний сервіс для читання</strong> будь-де і на будь-якому пристрої.</p>
   <p>На відміну від гаджетів з TFT дисплеями, рідери використовують технологію електронного чорнила, яка робить екран максимально близьким до звичайного паперу, гарантуючи безпеку вашим очам. Виникає логічне питання: навіщо читати на звичайному дисплеї TFT? Справа в тому, що E Ink рідер, при всій його компактності та легкості, далеко не завжди поряд. Наприклад, ви їдете в громадському транспорті або чекаєте на друга в кафе, а рідер залишили вдома. Ситуацій, коли можна викроїти 10 хвилин і поринути у захоплююче читання, дійсно багато, і відсутність рідера не повинна завадити вам.</p>
   <p>Завдяки <strong>PocketBook Cloud, iOS та Android пристрої</strong>, електронний рідер та ПК утворюють ваш особистий віртуальний простір для читання та роботи з текстами. Зверніть увагу, що синхронізовано також нотатки та закладки, які ви зробили під час читання. Таким чином ви можете працювати з текстом на будь-якому девайсі, пов'язаному з <strong>PocketBook Cloud: Android</strong> смартфоні, iOS планшеті або комп'ютері з будь-якою операційною системою.</p>
   <p>Крім того, сервіс надає читачам безкоштовні 2 ГБ, для зберігання у хмарі книг та налаштувань.</p>
   <p>Почніть користуватися вашою інтерактивною бібліотекою, яка завжди буде з вами!</p>
  </section>
`)
export const advantages = () => (`
  <h1>Переваги сервісу PocketBook Cloud</h1>
   <section>
   <p>Одним із головних принципів PocketBook є постійний рух уперед та розвиток. Відмінний приклад такої інноваційності – відкритий доступ до вашої бібліотеки за допомогою будь-якого гаджета. Завдяки фірмовому хмарному сервісу <span>PocketBook, синхронізація</span> файлів між різними пристроями здійснюється просто та швидко.</p>
   <p>PocketBook Cloud дає читачам безліч переваг: ваші книги завжди поруч, незалежно від того, чи тримаєте ви в руках рідер або смартфон, сидите за комп'ютером або з планшетом. Наприклад, за допомогою хмари <span>PocketBook, синхронізація з телефоном</span> займе лише кілька секунд. Все, що потрібно зробити – підключити його до сервісу</p>
   <p>Основне завдання PocketBook Cloud – <span>синхронізація читалки з телефоном</span> або ПК. Ви отримуєте 2 гігабайти безкоштовного простору для зберігання документів, які стають доступними одночасно на всіх підключених девайсах. Але це далеко ще не все. Разом із бібліотекою зберігаються також налаштування та позиції читання. Тільки уявіть, наскільки це приємно: завжди відкривати книгу на потрібній сторінці, незалежно від того, яким пристроєм ви користуєтеся.</p>
   <p>Справді, <span>читалка із синхронізацією</span> – це зручно. Незважаючи на те, що E Ink екран набагато безпечніший за TFT дисплеї звичайних гаджетів, існує багато ситуацій, коли рідера немає під рукою. Тут і настає черга хмарного сервісу, який дозволяє продовжити читання незакінченого роману на смартфоні.</p>
   <p>Як багато брендів можуть похвалитися абсолютною відкритістю своїх продуктів задля зручності користувачів? Дбаючи про читачів, PocketBook створив цілу екосистему, об'єднавши девайси, що працюють на Android, iOS, Windows та Linux. Однією з переваг наших рідерів є той факт, що це <span>кросплатформна читалка із синхронізацією</span>. Вам не доведеться вибирати між різними операційними системами та книжковими форматами: PocketBook Cloud зібрав усі популярні гаджети воєдино.</p>
   <p>Компанія PocketBook упевнена, що майбутнє – за відкритістю. Тому <span>читалка із синхронізацією між пристроями</span> – це предмет нашої особливої гордості. Наш продукт – це не просто рідер, це справжня бібліотека, яка завжди з вами.</p>
   <h2>PocketBook Cloud підтримується такими пристроями:</h2>
   <p>- електронні рідери серії PocketBook Touch Lux, починаючи з моделі Touch Lux 2,</p>
   <p>- електронні рідери серії PocketBook Touch HD, починаючи з моделі Touch HD,</p>
   <p>- електронні рідери серії PocketBook Aqua, починаючи з моделі Aqua 2,</p>
   <p>- електронні рідери серії PocketBook InkPad, починаючи з моделі InkPad.</p>
  </section>
`)
export const howToUse = () => (`
  <h1>Як користуватися сервісом PocketBook Cloud</h1>
   <section>
   <p>Хмарний сервіс PocketBook – це ваша віртуальна бібліотека, до якої можна зайти з будь-якого електронного пристрою. При цьому не лише електронні книги, а й позиції читання та персональні налаштування будуть синхронізовані. Це дозволить завжди продовжувати з потрібної сторінки, незалежно від гаджета: рідер, Android та iOS смартфони, планшети та ПК. Сервіс створений максимально простим і зрозумілим для кожного читача, пропонуємо познайомитися з ним ближче і дізнатися, як користуватися PocketBook Cloud.</strong></p>
   <p><strong>PocketBook Cloud інструкція</strong> для початківців</p>
   <p>1. Перший крок для роботи з <strong>PocketBook Cloud – реєстрація</strong> у книжковому он-лайн магазині. Створивши персональний обліковий запис, не забудьте записати логін та пароль від нього – вони знадобляться для підключення ваших пристроїв до хмари.</p>
   <p>2. Далі все просто: потрібно ввести дані облікового запису он-лайн книгарні на вашому рідері, щоб підключити його до <strong>PocketBook Cloud, вхід</strong> можливий тільки при підключеному Wi-Fi і займе не більше однієї хвилини.</p>
   <p>3. Для синхронізації смартфонів і планшетів, знадобиться програма PocketBook Reader, яка доступна для безкоштовного скачування в офіційних магазинах Android та iOS. Вказавши логін та пароль від онлайн-магазину, ви прив'язуєте гаджет до хмари.</p>
   <p>4. Якщо ви хочете почитати з ПК, потрібно зайти з будь-якого браузера на офіційний сайт PocketBook Cloud і ввести вищезгадані дані облікового запису магазину.</p>
   <p>Ви можете додати до PocketBook Cloud також електронні книги, придбані в інших книгарнях. Щоб завантажити такий контент, необхідно зайти на cloud.pocketbook.digital і авторизуватися. На стартовій сторінці натисніть «Upload a new book» і виберіть файл для завантаження в документах комп'ютера. Книга з'явиться не лише у веб-версії хмари, але й на всіх пристроях, зареєстрованих у хмарі.</p>
   <p>Крім того, кожному користувачеві надається 2 ГБ безкоштовного простору для зберігання своєї електронної бібліотеки.</p>
   <p>Ваша бібліотека ще ніколи не була такою мобільною, як з <strong>PocketBook Cloud, обліковий запис</strong> якого об'єднує ваш рідер з іншими пристроями.</p>
   </section>
`)

const seo = {
  about: about,
  advantages: advantages,
  howToUse: howToUse
}
export default seo;