import React from 'react'
import PropTypes from 'prop-types'
import { compose, setPropTypes, pure, onlyUpdateForPropTypes, setDisplayName } from 'recompose'
import { ifElse, equals, always, not, length, pipe } from 'ramda'

import './Loading.css'

const propTypes = setPropTypes({
  active: PropTypes.bool.isRequired,
  description: PropTypes.string
})

const maybeRenderDescription = ifElse(
  pipe(length, equals(0), not),
  (description) => <p className="Loading__text">{description}</p>,
  always(null)
)

const enhance = compose(
  setDisplayName('Loading'),
  pure,
  onlyUpdateForPropTypes,
  propTypes
)

const View = ({active, description}) => (
  <div className="Loading" data-active={active}>
    {maybeRenderDescription(description)}
    <div className="Loading__spinner"></div>
  </div>
)

export const Loading = enhance(View)
