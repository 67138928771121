import React from 'react'
import { connect } from 'react-redux'
import { isEmpty, always, equals, ifElse } from 'ramda'
import { lifecycle, compose, setDisplayName, withPropsOnChange, withHandlers } from 'recompose'

import { nextDropdown } from '../../../../../../actions/dropdown'
import { removeNotification, showPopUpNotification } from '../../../../../../actions/notifications'

import './Notifications.css'

import { DropdownMenu } from '../../../../../Shared/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from '../../../../../Shared/DropdownMenu/components/DropdownMenuItem/DropdownMenuItem'
import Notification from './components/Notification/Notification'
import { NotificationPopUp } from './NotificationPopUp/NotificationPopUp'
import { getLocaleString } from '../../../../../../locale'
import {notChildren} from "../../../../../../utilities";

const DROPDOWN_ID = 'NOTIFICATIONS_DROPDOWN'

const isActive = equals(DROPDOWN_ID)

const ToggleButton = ({hasNotifications, toggleNotifications}) => (
  <button
    className="Notifications__toggle"
    data-unread={hasNotifications}
    onClick={toggleNotifications}>
    <i className="Notifications__toggle-icon" alt="notifications icon"></i>
  </button>
)

const toggleMenu = ({active, nextDropdown}) => ({isInner = false}) => ifElse(
  equals(true),
  () => nextDropdown(null),
  () => ifElse(
    equals(false),
    () => nextDropdown(DROPDOWN_ID),
    always(null)
  )(isInner)
)(active)

const handlers = withHandlers({
  toggleMenu
})

const mapStateToProps = state => ({
  activeDropdown: state.activeDropdown,
  notifications: state.notifications.notificationsList,
  popUpNotification: state.notifications.popUpNotification,
  showNotification: state.notifications.showNotification,
})

const mapDispatchToProps = (dispatch) => ({
  nextDropdown: (id) => dispatch(nextDropdown(id)),
  showPopUpNotification: (notification) => dispatch(showPopUpNotification(notification)),
  removeNotification: (id) => dispatch(removeNotification(id)),
})

const RenderNotofications = ({ notifications, action }) => {
  if(isEmpty(notifications)) {
    return (<span className="empty-message">{getLocaleString('notifications.noNotifications')}</span>)
  }
  return (
  <ul className="Notifications__list"> 
    {
      notifications.map(notification => (
        <Notification
          key={notification.id}
          action={action}
          notification={notification}
        />))
    }
  </ul>)
}

export const enhance = compose(
  setDisplayName('Notifications'),
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['activeDropdown'], ({activeDropdown}) => ({
    active: isActive(activeDropdown)
  })),
  handlers,
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.notifications.length > prevProps.notifications.length) {
        const lastElementIndex = this.props.notifications.length -1
        const newNotification = this.props.notifications[lastElementIndex]
        this.props.showPopUpNotification(newNotification)
      }
    },
    componentDidMount() {
      const onClick = (e) => {
        let popup = []
        let btn = []
        notChildren(popup, e.target, document.getElementsByClassName('DropdownMenu'))
        notChildren(btn, e.target, document.getElementsByClassName('Notifications__toggle'))
        if (!(popup.indexOf(false) > -1) && !(btn.indexOf(false) > -1)) {
          if (this.props.activeDropdown === DROPDOWN_ID) {
            this.props.nextDropdown(null)
          }
        }
      }
      document.removeEventListener('mousedown', (e) => onClick(e))
      document.addEventListener('mousedown', (e) => onClick(e))
    }
  })
)

export const View = ({notifications, active, toggleMenu, removeNotification, showNotification, popUpNotification}) => (
  <div className="Notifications">
    <ToggleButton hasNotifications={notifications.length > 0} toggleNotifications={toggleMenu} />
    <DropdownMenu onClose={toggleMenu} active={active}>
      <DropdownMenuItem>
          <RenderNotofications notifications={notifications} action={removeNotification}/>
      </DropdownMenuItem>
    </DropdownMenu>
    {
      showNotification && !isEmpty(popUpNotification) ?  <NotificationPopUp /> : null
    }
  </div>
)

export const Notifications = enhance(View)
