export const TYPES = {
  GET_QR_CODE: 'GET_QR_CODE',
  SET_QR_CODE: 'SET_QR_CODE',
  SET_AVATAR: 'SET_AVATAR'
}

export const getQRCode = (session_id) => ({
  type: TYPES.GET_QR_CODE,
  payload: session_id
})

export const setQRCode = (blob) => ({
  type: TYPES.SET_QR_CODE,
  payload: blob
})

export const setAvatar = (avatar) => ({
  type: TYPES.SET_AVATAR,
  payload: avatar
})