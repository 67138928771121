import { combineReducers } from 'redux'
import { any, cond, always, reduce, filter, assocPath, propEq, uniqBy, over, lensProp, contains, subtract, length, equals, path, both, prop, and, T, when, identity, uniq, pipe, assoc, isNil, complement, map, converge, without, concat, isEmpty, not, has, __, ifElse } from 'ramda'
import { TYPES } from '../actions/books'
import { TYPES as USER_TYPES } from '../actions/user'

const isInState = (state) => pipe(prop('id'), has(__, state))

const idsMapper = (state) => ifElse(
  isInState(state),
  always(null),
  identity
)

const addToState = (state) => converge(
  assoc(__, __, state), [
    prop('id'),
    identity
  ]
)

const byIdReducer = (acc, val) => when(
  complement(isInState(acc)),
  addToState(acc)
)(val)

const reduceToObject = (state) => reduce(byIdReducer, state)

const mapIds = (state) => pipe(map(idsMapper(state)), without([null]), reduceToObject(state))

const isNotEmpty = complement(isEmpty)
const hasItems = (items) => () => isNotEmpty(items)
const hasUrl = (url) => () => isNotEmpty(url)

const maybeAddBooksById = (state, {payload: {items, type}}) => when(
  hasItems(items),
  () => mapIds(state)(items)
)(state)

const mapToIds = map((item) => prop('id', item))
const concatIdsWithState = (items) => converge(
  concat, [identity, () => mapToIds(items)]
)
const updateIds = (items) => pipe(concatIdsWithState(items), uniq)

const maybeAddBooksIdsToAll = (state, {payload: {items, type}}) => when(
  hasItems(items),
  updateIds(items)
)(state)

const addBooksByType = (typeFn) => (state, {payload: {items, type}}) => when(
  and(() => typeFn(type), hasItems(items)),
  updateIds(items)
)(state)

const setProviderUrlByType = (typeFn) => (state, {payload: {url, type}}) => when(
    and(() => typeFn(type), hasUrl(url)),
    () => url
)(state)

const booksCount = (state = false, { type, payload }) => cond([
  [equals(TYPES.SET_BOOKS_COUNT), always(payload)],
  [equals(TYPES.REMOVE_BOOKS), () => subtract(state, length(payload))],
  [equals(TYPES.RESET_BOOKS), always(0)],
  [T, always(state)]
])(type)

const removeBooksById = (state = {}, {payload}) =>
  filter((item) => pipe(contains(__, payload), not)(item.id), state)

const updateField = (field, state, {payload: {id, status}}) =>
  assocPath([id, field], status, state)

const bookInSelected = (books) => pipe(prop('id'), contains(__, books))

const attachBooksToCollection = (state, {payload: {name, bookIds}}) => map(
  ifElse(
    bookInSelected(bookIds),
    over(lensProp('collections'), concat([name])),
    identity
  ),
  state
)

const detachBooksFromCollection = (state, {payload: {name, bookIds}}) => map(
  ifElse(
    bookInSelected(bookIds),
    over(lensProp('collections'), without([name])),
    identity
  ),
  state
)

const renameBookCollection = (state, {payload: {oldName, newName}}) => map(
  when(
    has('collections'),
    over(lensProp('collections'), when(
      contains(oldName),
      map(when(equals(oldName), always(newName)))
    ))
  ),
  state
)

const removeBookCollection = (state, {payload}) => map(
    when(
        has('collections'),
        over(
            lensProp('collections'),
            filter(
                complement(
                    propEq('name', payload)
                )
            )
        )
    )
)(state)

const removeBookCollections = (state, {payload}) => {
  let books = []
  for (let i=0;i<payload.length;i++) {
    let collection = payload[i]
    books = map(
        when(
            has('collections'),
            over(
                lensProp('collections'),
                filter(
                    complement(
                        propEq('name', collection)
                    )
                )
            )
        )
    )(state)
  }
  return books
}

const booksById = (state = {}, action) => cond([
  [equals(TYPES.ADD_BOOKS), () => maybeAddBooksById(state, action)],
  [equals(TYPES.RESET_BOOKS), always({})],
  [equals(TYPES.REMOVE_BOOKS), () => removeBooksById(state, action)],
  [equals(TYPES.SET_STATUS), () => updateField('status', state, action)],
  [equals(TYPES.SET_FAVORITE), () => updateField('isFavorite', state, action)],
  [equals(TYPES.ATTACH_BOOKS_TO_COLLECTION), () => attachBooksToCollection(state, action)],
  [equals(TYPES.DETACH_BOOKS_FROM_COLLECTION), () => detachBooksFromCollection(state, action)],
  [equals(TYPES.RENAME_COLLECTION), () => renameBookCollection(state, action)],
  [equals(TYPES.REMOVE_COLLECTION), () => removeBookCollection(state, action)],
  [equals(TYPES.REMOVE_COLLECTIONS), () => removeBookCollections(state, action)],
  [T, always(state)]
])(prop('type', action))

const removeBooksIds = (state = [], {payload}) =>
  without(payload, state)

const allBooks = (state = [], action) => cond([
  [equals(TYPES.ADD_BOOKS), () => maybeAddBooksIdsToAll(state, action)],
  [equals(TYPES.RESET_BOOKS), always([])],
  [equals(TYPES.REMOVE_BOOKS), () => removeBooksIds(state, action)],
  [T, always(state)]
])(prop('type', action))

const getBooksType = path(['payload', 'type'])

const byType = (typeFn) => (state = [], action) => cond([
  [both(() => typeFn(getBooksType(action)), equals(TYPES.ADD_BOOKS)), () => addBooksByType(typeFn)(state, action)],
  [equals(TYPES.REMOVE_BOOKS), () => removeBooksIds(state, action)],
  [equals(TYPES.RESET_BOOKS), always([])],
  [T, always(state)]
])(prop('type', action))

const setProviderUrl = (typeFn) => (state = false, action) => cond([
  [both(() => typeFn(getBooksType(action)), equals(TYPES.SET_PROVIDER_URL)), () => setProviderUrlByType(typeFn)(state, action)],
  [T, always(state)]
])(prop('type', action))

const bestsellers = (type) => equals('bestsellers', type)
const recommended = (type) => equals('recommended', type)
const weekNew = (type) => equals('new', type)
const lastRead = (type) => equals('lastRead', type)
const bookOfTheWeek = (type) => equals('bookOfTheWeek', type)
const user = (type) => any(equals(type))(['audio', 'user'])
// const user = (type) => any(equals('user', type))

const addBestsellers = byType(bestsellers)
const addBestsellersUrl = setProviderUrl(bestsellers)
const addRecommended = byType(recommended)
const addRecommendedUrl = setProviderUrl(recommended)
const addNew = byType(weekNew)
const addNewUrl = setProviderUrl(weekNew)
const addLastRead = byType(lastRead)
const addBookOfTheWeek = byType(bookOfTheWeek)
const addUserBooks = byType(user)

const onFetchBooks = (state = false, {type, payload}) => cond([
  [equals(TYPES.ON_FETCH_BOOKS), always(payload)],
  [T, always(state)]
])(type)

const sortBooks = (state = 'none', {type, payload}) => cond([
  [equals(TYPES.SORT_BOOKS), always(payload)],
  [equals(TYPES.RESET_BOOKS), always('none')],
  [T, always(state)]
])(type)

const changeViewBooks = (state = 'gallery', {type, payload}) => cond([
  [equals(TYPES.VIEW_BOOKS), always(payload)],
  [T, always(state)]
])(type)

const sortCollections = (state = 'none', {type, payload}) => cond([
  [equals(TYPES.SORT_COLLECTIONS), always(payload)],
  [equals(TYPES.RESET_COLLECTIONS), always('none')],
  [T, always(state)]
])(type)

const setCollections = (state, collections) => uniqBy(prop('name'))(concat(collections, state))

const renameCollection = (state, {oldName, newName}) => map(
  over(lensProp('name'), when(
    equals(oldName),
    always(newName)
  ))
)(state)

const removeCollection = (state, name) => filter(
  complement(propEq('name', name)),
  state
)

const removeCollectionsByIds = (state = [], payload) =>
    filter((item) => pipe(contains(__, payload), not)(item.id), state)

const collections = (state = [], {type, payload}) => cond([
  [equals(TYPES.SET_COLLECTIONS), () => setCollections(state, payload)],
  [equals(TYPES.REMOVE_COLLECTIONS_BY_IDS), () => removeCollectionsByIds(state, payload)],
  [equals(TYPES.RENAME_COLLECTION), () => renameCollection(state, payload)],
  [equals(TYPES.REMOVE_COLLECTION), () => removeCollection(state, payload)],
  [T, always(state)]
])(type)

const uploadingBooksInitialState = {
  files: [],
  totalSize: 0,
  uploadedSize: 0,
  active: false
}

const uploadingBooks = (state = uploadingBooksInitialState, action) => {
  switch(action.type) {
    case TYPES.UPLOAD_BOOK:
      const files = [...state.files, ...action.payload]
      const totalSize = files.reduce((sum, obj) => sum + obj.size, 0)
      return { ...state, files, totalSize }

    case TYPES.UPLOAD_BOOK_SUCCESSFUL:
      return { ...state, uploadedSize: state.uploadedSize + action.payload[1], files: [...state.files.map(book => { if (book.id === action.payload[0]) { book.status = 'success' } return book })] }

    case TYPES.UPLOAD_BOOK_FAIL:
      return { ...state, uploadedSize: state.uploadedSize + action.payload[1], files: [...state.files.map(book => { if (book.id === action.payload[0]) { book.status = 'fail'; book.errorMsg = action.payload[2] } return book })] }

    case TYPES.UPLOAD_BOOK_FINISHED:
      state = { ...state, files: [], totalSize: 0, uploadedSize: 0 }
      return state

    case TYPES.UPLOAD_BOOK_PROGRESS:
      let uploaded = 0
      state.files.forEach(book => {
        if (book.id === action.payload[0]) {
          uploaded = action.payload[1] - book.progress
        }
      });
      return { ...state, uploadedSize: state.uploadedSize + uploaded, files: [...state.files.map(book => { if (book.id === action.payload[0]) { book.progress = action.payload[1]; } return book })] }

    case TYPES.UPLOAD_BOOK_TOGGLE_ACTIVE:
      const active = ifElse(
        isNil,
        () => not(state.active),
        always(action.payload)
      )(action.payload)
      return { ...state, active }

    default:
      return state
  }
}

const audioBookFilesInitialState = {
  files: false,
  fetchingFiles: true
}

const updatePosition = (files, position) => {
  files.read_position.page = position.read_position.page
  files.read_position.offs = position.read_position.offs
  files.position.page = position.read_position.page
  files.position.offs = position.read_position.offs

  for (let i=0; i < files.chapters.length; i++) {
    let chapter = files.chapters[i]
    if (chapter.name === position.read_position.page) {
      chapter.read_position.page = position.read_position.page
      chapter.read_position.offs = position.read_position.offs
      chapter.position.page = position.read_position.page
      chapter.position.offs = position.read_position.offs
    }
  }
  return files
}

const addMark = (files, mark) => {
  files.mark.unshift(mark.read_position)
  return files
}

const removeMark = (files, rMark) => {
  rMark = rMark.read_position
  files.mark = files.mark.filter(m => m !== rMark)
  return files
}

const updateMark = (files, payload) => {
  let uMark = payload.mark
  files.mark = files.mark.map(mark => {
    if (mark.page === uMark.page && mark.offs === uMark.offs && mark.date === uMark.date) {
      mark.name = uMark.name
    }
    return mark
  })
  return files
}

const audioBookFiles = (state = audioBookFilesInitialState, action) => {
  switch(action.type) {

    case TYPES.FETCH_AUDIOBOOKS_FIES:
      return { ...state, files: action.payload}

    case TYPES.UPDATE_READ_POSITION:
      return { ...state, files: updatePosition(state.files, action.payload)}

    case TYPES.ON_FETCH_AUDIOBOOKS_FIES:
      return { ...state, fetchingFiles: action.payload }

    case TYPES.SET_MARK:
      return { ...state, files: addMark(state.files, action.payload)}

    case TYPES.REMOVE_MARK:
      return { ...state, files: removeMark(state.files, action.payload)}

    case TYPES.UPDATE_BOOKMARK:
      return { ...state, files: updateMark(state.files, action.payload)}

    case USER_TYPES.REMOVE_USER:
      return { ...state, files: false}

    case TYPES.REMOVE_AUDIOBOOKS_FILES:
      return { ...state, files: false}

    default:
      return state
  }
}

const setBooksInfo = ( state = {}, action ) => {
  switch(action.type) {

    case TYPES.SET_BOOKS_INFO:
      return action.payload

    default:
      return state
  }
}

const setMarkList = ( state = {}, action ) => {
  switch(action.type) {

    case TYPES.SET_MARK_LIST:
      return action.payload

    default:
      return state
  }
}

const setUploadErrors = (state = {}, {type, payload}) => cond([
  [equals(TYPES.SET_UPLOAD_ERRORS), always(payload)],
  [T, always(state)]
])(type)

const setGenres = (state = [], {type, payload}) => cond([
  [equals(TYPES.SET_GENRES), always(payload)],
  [T, always(state)]
])(type)

export const booksReducer = combineReducers({
  byId: booksById,
  allIds: allBooks,
  userIds: addUserBooks,
  bestsellersIds: addBestsellers,
  bestsellersUrl: addBestsellersUrl,
  recommendedIds: addRecommended,
  recommendedUrl: addRecommendedUrl,
  newIds: addNew,
  newUrl: addNewUrl,
  lastReadId: addLastRead,
  bookOfTheWeekId: addBookOfTheWeek,
  totalBooks: booksCount,
  genres: setGenres,
  fetchBooksRequest: onFetchBooks,
  sortType: sortBooks,
  viewType: changeViewBooks,
  collectionsSortType: sortCollections,
  collections: collections,
  uploadingBooks: uploadingBooks,
  audioBook: audioBookFiles,
  booksInfo: setBooksInfo,
  markList: setMarkList,
  uploadErrors: setUploadErrors
})
