import React from 'react'
import { mapProps, setDisplayName, compose, withStateHandlers, toClass } from 'recompose'
import { ifElse, length, prop, omit, pick, both, always, concat, pipe, equals, not } from 'ramda'

import './Input.css'

import { omitProps } from '../../../utilities'
import { mergeAndSetClassNames, isInvalid } from '../constants'
import { getLocaleString } from '../../../locale'

const setPlaceholder = ifElse(
  both(isInvalid, pipe(prop('errorMessage'), length, equals(0), not)),
  prop('errorMessage'),
  prop('placeholder')
)

const maybeRenderShowPasswordButton = ({type, onShowPassword, showPassword}) => ifElse(
  equals('password'),
  always(<button type="button" className="Input__show-password" onClick={(e) => e.preventDefault() || onShowPassword(e)}>
    <i alt={getLocaleString('settings.showPassword')}></i>
  </button>),
  always(null)
)(type)

const state = ({
  showPassword = false
}) => ({
  showPassword
})

const stateHandlers = {
  onShowPassword: ({showPassword}) => () => ({
    showPassword: not(showPassword)
  })
}

const setType = ({showPassword, type}) => ifElse(
  () => both(
    always(equals(true)(showPassword)),
    always(equals('password')(type))
  )(),
  always('text'),
  always(type)
)()

const dynamicClasses = (props, acc) => [
  [both(equals('invalid'), always(isInvalid(props))), always(concat(acc, 'Input--invalid'))]
]

const enhance = compose(
  setDisplayName('Input'),
  mapProps((props) => ({
    ...props,
    className: mergeAndSetClassNames({defaultClass: 'Input', dynamicClasses: dynamicClasses})(props),
    placeholder: setPlaceholder(props)
  })),
  omitProps(['errorMessage', 'invalid']),
  withStateHandlers(state, stateHandlers),
  toClass
)

const View = ({registerRef = () => {}, ...props}) => (
  <span className="Input__wrapper">
    {props.autoComplete ?
      <input
        {...omit(['onShowPassword', 'showPassword', 'type', 'autoComplete'], props)}
        type={setType(props)}
        ref={registerRef}
        autoComplete={props.autoComplete}
      />
      :
      <input
        {...omit(['onShowPassword', 'showPassword', 'type', 'autoComplete'], props)}
        type={setType(props)}
        ref={registerRef}
      />
    }
    {maybeRenderShowPasswordButton(pick(['type', 'onShowPassword'], props))}
  </span>
)

export const Input = enhance(View)
