import { API, getAuthHeaders } from './axiosInstance'

const partnerPrefix = (alias) => `/partner/${alias}`

const mapNewsResponse = (data) => ({
  id: data.title+''+data.author,
  text: data.text,
  author: {
    name: data.author,
    avatar: data.image
  },
  title: data.title,
  comments: data.comments || 0,
  link: data.link || '#'
})

export const isLoggedToPartner = (alias) => API
  .get(`${partnerPrefix(alias)}/is-user-logged`, getAuthHeaders())
  .then(res => !!res.data.is_logged)

export const getLoginUrl = (alias) => API
  .get(`${partnerPrefix(alias)}/login-url`, getAuthHeaders())
  .then(res => res.data.login_url)

export const getPartnerUserInfo = (alias) => API
  .get(`${partnerPrefix(alias)}/user-info`, getAuthHeaders())
  .then(({ data }) => ({
    username: `${data.first_name || ''} ${data.last_name || ''}`,
    login_type: `${data.logged_by}`,
    avatar: `${data.avatar}`
  }))

export const getPartnerNews = (alias) => API
  .get(`${partnerPrefix(alias)}/news`, getAuthHeaders())
  .then(({ data }) => data.map(mapNewsResponse))

export const getPartnerDiscussion = (alias) => API
  .get(`${partnerPrefix(alias)}/discussions`, getAuthHeaders())
  .then(({ data }) => data.map(mapNewsResponse))

export const logoutFromPartner = (alias) => API
  .post(`${partnerPrefix(alias)}/logout`, null, getAuthHeaders())
  .then(res => res.data)
