const dashboard = {
  dashboardTitle: 'Главная',
  lastRead: 'Последняя',
  continueReading: 'Продолжить чтение',
  continueListening: 'Продолжить слушать',
  newBookMonth: 'Новых книг в этом месяце',
  booksInCloud: 'Книг в Вашем облаке',
  readingNow: 'Сейчас читаю',
  markedRead: 'Прочитано',
  yourCloudStat: "Ваша статистика",
  myBooks: 'Мои книги',
  mostDiscussed: 'Самые обсуждаемые',
  news: 'Новости',
  shareYourReading: 'Поделитесь своим прогрессом чтения с друзьями и обсудите любимые книги',
  loginToReadRate: 'Войти в ReadRate',
  login: 'Войти',
  more: 'Больше',
  justAboutToStart: 'Пока не приступили к чтению',
  haveNotMarked: 'Еще нет прочитанных книг'
}
export default dashboard;