import React from 'react'
import { compose, setDisplayName } from 'recompose'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { isLoading, checkBooksRoute } from '../../../../utilities'

import './Header.css'

import logo from '../../../../assets/icons/logo.svg'

import { Search } from './components/Search/Search'
import { Notifications } from './components/Notifications/Notifications'
import { User } from './components/User/User'
import { HeaderMenu } from './components/HeaderMenu/HeaderMenu'
import { UploadBooksStatusBar } from './components/UploadBooksStatusBar/UploadBooksStatusBar'

const mapStateToProps = (state) => ({
  onFetchUserData: state.userData.onFetchUserData,
  userData: state.userData.data,
  activeUpload: state.books.uploadingBooks.active,
  files: state.books.uploadingBooks.files,
  language: state.userData.language,
  notifications: state.notifications.notificationsList,
  user: state.user,
  userId: state.userData.data.user_id
})

const Logo = ({language, userId}) => (
  <figure className={`Header__logo`}>
    <Link to={userId ? `/${language}/user/${userId}` : `/${language}`} label="Dashboard">
      <img src={logo} alt="PocketBook" />
    </Link>
  </figure>
)

export const enhance = compose(
  setDisplayName('Header'),
  connect(mapStateToProps, null),
)

export const View = ({ user, isSettings, language, userId, onFetchUserData, location, ...props }) => (
  <header className={`Header ${isSettings ? 'Header--settings-active' : ''}${location.pathname.indexOf('/find-store') > -1 ? ' without_shadow' : ''}`}>
    <Logo language={language} userId={userId}/>
    <div className="Header__content">
      {user ? <Search /> : ''}
      {user ? <Notifications /> : ''}
      { onFetchUserData || !user ? null : <User isSettings={isSettings}/> }
      <HeaderMenu isSettings={isSettings} location={location}/>
    </div>
    {
      checkBooksRoute([`/${language}/user/${userId}/books`,
        `/${language}/user/${userId}`,
        `/${language}/user/${userId}/`,
        `/${language}/user/${userId}/books/audio`], location.pathname) && isLoading(props.files) && !props.activeUpload ?
        <UploadBooksStatusBar /> : ''
    }
  </header>
)

export const Header = withRouter(enhance(View))
