const search = {
  noItems: 'No items found',
  recentlyRead: 'recently read',
  collections: 'collections',
  authors: 'authors',
  books: 'books',
  audioBooks: 'Audio books',
  searchIcon: 'Search Icon',
  canselSearch: 'cancel search icon',
  searchByTitleAuthor: 'Search by book title or author',

  notFound: 'Not found'
}
export default search;