import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { compose, setDisplayName, pure, setPropTypes } from 'recompose'
import { hiddenBlocks } from "../../../../../../../../constants";

import './NestedLink.css'
import {connect} from "react-redux";
import {getLocaleString} from "../../../../../../../../locale";

const propTypes = setPropTypes({
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  root: PropTypes.bool.isRequired,
  onClick: PropTypes.func
})

const mapStateToProps = (state) => ({
  language: state.userData.language
})

const mapDispatchToProps = (dispatch) => ({

})

const isActive = (base, {pathname}, language, userId, acceptableRoutes) => {
  if (acceptableRoutes) {
    let state = false
    for (let i=0; i < acceptableRoutes.length; i++) {
      let route = acceptableRoutes[i]
      if (route === 'collections') {
        if ((pathname.indexOf('collections/formats') === -1 && pathname.indexOf('collections/genres') === -1
          && pathname.indexOf('collections/series') === -1) && pathname.indexOf(route) > -1) {
          state = true
          break
        }
      } else if (pathname.indexOf(route) > -1) {
        state = true
        break
      }
    }
    return state
  } else {
    return '/'+language+'/user/'+userId+base === pathname || '/'+language+base === pathname
  }
}

export const enhance = compose(
  setDisplayName('NestedLink'),
  connect(mapStateToProps, mapDispatchToProps),
  pure,
  propTypes
)

export const View = ({label, to, path, isEmpty, language, userId, onClick = () => {}, location, acceptableRoutes}) => {
  if (hiddenBlocks[label] || isEmpty) return ''

  return <div className="NestedLink">
    <Link to={to} className={`NestedLink__label${isActive(path, location, language, userId, acceptableRoutes) ? ' NestedLink__label--active' : ''}`} onClick={onClick}>{getLocaleString(label)}</Link>
  </div>
}

export const NestedLink = enhance(View)
