import React  from 'react'
import {equals, ifElse} from "ramda";
import {nextDropdown} from "../../../../../../actions/dropdown";
import {compose, lifecycle, setDisplayName, withHandlers, withPropsOnChange, withStateHandlers} from "recompose";

import './BookmarkAdding.css'
import {connect} from "react-redux";
import {DropdownMenu} from "../../../../../Shared/DropdownMenu/DropdownMenu";
import {getLocaleString} from "../../../../../../locale";
import {formatPercent, formatTime} from "../../utilities";

const DROPDOWN_ID = 'AUDIOBOOK_BOOKMARK_ADDING'
const isActive = equals(DROPDOWN_ID)

const toggleMenu = ({activeDropdown, nextDropdown}) => (preAddBookmark) => ifElse(
    equals(true),
    () => {
        nextDropdown(null)
        preAddBookmark(true)
    },
    () => {
        nextDropdown(DROPDOWN_ID)
        preAddBookmark()
    }
)(isActive(activeDropdown))

const mapStateToProps = state => ({
    activeDropdown: state.activeDropdown
})

const mapDispatchToProps = (dispatch) => ({
    nextDropdown: (id) => dispatch(nextDropdown(id))
})

const handlers = withHandlers({
    toggleMenu
})

const state = {
    componentNode: React.createRef(),
    noteNode: React.createRef(),
    bookmarkDuration: false,
    percent: false
}

const stateHandlers = {
    handleClickOutside: ({componentNode, noteNode}, {activeDropdown, nextDropdown}) => (event) => {
        if (componentNode.current && !componentNode.current.contains(event.target)) {
            if (activeDropdown === DROPDOWN_ID) {
                nextDropdown(null)
                noteNode.current.value = ''
                return {bookmarkDuration: false, percent: false}
            }
        }
    },
    preAddBookmark: ({noteNode}, {played, duration}) => (remove = false) => {
        if (remove) {
            noteNode.current.value = ''
            return {bookmarkDuration: false, percent: false}
        }
        return {
            bookmarkDuration: formatTime(played),
            percent: formatPercent(played, duration)
        }
    },
    onFocusInput: ({noteNode}) => () => {
        setTimeout(() => {noteNode.current.focus()}, 200)
    }
}

export const enhance = compose(
    setDisplayName('AudioBook Bookmark adding'),
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(['activeDropdown'], ({activeDropdown}) => ({
        active: isActive(activeDropdown)
    })),
    handlers,
    withStateHandlers(state, stateHandlers),
    lifecycle({
        UNSAFE_componentWillMount() {
            document.addEventListener('mousedown', this.props.handleClickOutside);
        },
        componentWillUnmount() {
            document.removeEventListener('mousedown', this.props.handleClickOutside);
        }
    })
)

const View = ({bookmarkAdding, played, toggleMenu, active, componentNode, preAddBookmark, bookmarkDuration, percent,
                  noteNode, onFocusInput}) => (
    <div className="Bookmark__adding AudioBook__dropdown" ref={componentNode}>
        {played > 0 ? <button className="player-button add-to-bookmark" onClick={() => {toggleMenu(preAddBookmark);onFocusInput()}} data-active={active}></button> : null}
        <DropdownMenu active={active}>
            <div className="Bookmark__adding__header Dropdown__header">
                <span className="Bookmark__adding__header-text">{getLocaleString('books.addBookmark')}{bookmarkDuration ? ` (${bookmarkDuration} - ${percent})` : ''}</span>
            </div>
            <div className="Bookmark__adding__content">
                <input ref={noteNode} className="Input" id="bookmark_note" placeholder={getLocaleString('books.noteOptional')}/>
                <div className="Bookmark__adding__buttons">
                    <button className="Button" onClick={() => toggleMenu(preAddBookmark)}>{getLocaleString('books.cancel')}</button>
                    <button className="Button" onClick={() => {bookmarkAdding(noteNode.current.value);toggleMenu(preAddBookmark)}}>{getLocaleString('books.add')}</button>
                </div>
            </div>
        </DropdownMenu>
    </div>
)

export const BookmarkAdding = enhance(View)