export const TYPES = {
  ADD_VENDOR: 'ADD_VENDOR',
  DELETE_VENDOR: 'DELETE_VENDOR',
  REMOVE_VENDOR: 'REMOVE_VENDOR',
  SET_VENDORS: 'SET_VENDORS',
  SET_DEFAULT: 'SET_DEFAULT',
  EDIT_VENDOR: 'EDIT_VENDOR',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
  UPDATE_DEFAULT: 'UPDATE_DEFAULT',
  GET_VENDORS: 'GET_VENDORS',
  GET_VENDORS_IDS: 'GET_VENDORS_IDS',
  SET_VENDORS_IDS: 'SET_VENDORS_IDS',
  SET_VENDOR_FORM_STATUS: 'SET_VENDOR_FORM_STATUS',
  GET_PASSWORD: 'GET_PASSWORD',
  SET_PASSWORD_FORM_STATUS: 'SET_PASSWORD_FORM_STATUS',
  SHOW_PASSWORD: 'SHOW_PASSWORD'
}

export const setVendorFormStatus = (status) => ({
  type: TYPES.SET_VENDOR_FORM_STATUS,
  payload: status
})

export const setPasswordFormStatus = (status) => ({
  type: TYPES.SET_PASSWORD_FORM_STATUS,
  payload: status
})

export const setVendorsIds = (vendorsIds) => ({
  type: TYPES.SET_VENDORS_IDS,
  payload: vendorsIds
})

export const getVendorsIds = () => ({
  type: TYPES.GET_VENDORS_IDS
})

export const getVendors = () => ({
  type: TYPES.GET_VENDORS,
})

export const addVendor = (vendor) => ({
  type: TYPES.ADD_VENDOR,
  payload: vendor
})

export const setVendors = (vendors) => ({
  type: TYPES.SET_VENDORS,
  payload: vendors
})

export const deleteVendor = (vendor) => ({
  type: TYPES.DELETE_VENDOR,
  payload: vendor
})

export const removeVendor = (vendor) => ({
  type: TYPES.REMOVE_VENDOR,
  payload: vendor
})

export const setDefault = (vendorId) => ({
  type: TYPES.SET_DEFAULT,
  payload: vendorId
})

export const updateDefault = (vendorId) => ({
  type: TYPES.UPDATE_DEFAULT,
  payload: vendorId
})

export const editVendor = (vendor) => ({
  type: TYPES.EDIT_VENDOR,
  payload: vendor
})

export const updateVendor = (vendor) => ({
  type: TYPES.UPDATE_VENDOR,
  payload: vendor
})

export const getPassword = (email, language) => ({
  type: TYPES.GET_PASSWORD,
  payload: {
    email,
    language
  }
})

export const showPassword = (hash) => ({
  type: TYPES.SHOW_PASSWORD,
  payload: hash
})