export const TYPES = {
  PRIVACY: 'PRIVACY',
  IMPRESSIUM: 'IMPRESSIUM',
  COOKIES_TERMS: 'COOKIES_TERMS',
  CONTACT_US: 'CONTACT_US',
  FAQ: 'FAQ',
  FIND_INFO: 'FIND_INFO',
  TERMS: 'TERMS',
  LOG_IN_ACTION: 'LOG_IN_ACTION'
}

export const termsAction = () => ({
  type: TYPES.TERMS
})

export const privacyAction = () => ({
  type: TYPES.PRIVACY,
})

export const impressiumAction = () => ({
  type: TYPES.IMPRESSIUM,
})

export const cookiesAction = () => ({
  type: TYPES.COOKIES_TERMS,
})

export const faqAction = () => ({
  type: TYPES.FAQ
})

export const contactUsAction = () => ({
  type: TYPES.CONTACT_US
})

export const findInfoAction = () => ({
  type: TYPES.FIND_INFO
})

export const logInAction = () => ({
  type: TYPES.LOG_IN_ACTION
})