import React  from 'react'
import {compose, setDisplayName, defaultProps, branch, renderComponent, renderNothing} from 'recompose'
import {map, equals, always, ifElse, cond, T, contains} from 'ramda'
import { getLocaleString } from '../../../../locale'

import './Sidebar.css'

import { Button } from '../../../Shared/Button/Button'
import { NavigationLink } from './components/NavigationLink/NavigationLink'
import { AppLinks } from './components/AppLinks/AppLinks'
import { NestedLink } from './components/NavigationLink/components/NestedLink/NestedLink'
import { User } from '../Header/components/User/User'
import {connect} from "react-redux";
import {defaultShopLink, hiddenBlocks, SUPPORT_MAIL} from '../../../../constants'
import {Link} from 'react-router-dom'
import {Scrollbars} from "react-custom-scrollbars";

const booksNestedLinks = [
  {to: '', label: 'sidebar.eBooks', path: '/books'},
  {to: '/books/audio', label: 'sidebar.audioBooks', root: true, path: '/books/audio', withLang: true},
  {to: '/purchased', label: 'sidebar.purchased', path: '/books/purchased', name: 'purchasedIsEmpty'},
  {to: '/recent', label: 'sidebar.recentlyRead', path: '/books/recent', name: 'recentlyIsEmpty'},
  {to: '/favorites', label: 'sidebar.favorites', path: '/books/favorites', name: 'favoritesIsEmpty'},
  {to: '/authors', label: 'sidebar.authors', root: true, path: '/authors', name: 'authorsIsEmpty', withLang: true, acceptableRoutes: ['authors', 'books/author']},
  {to: '/collections/series', label: 'sidebar.series', root: true, path: '/collections/series', name: 'seriesIsEmpty', withLang: true, acceptableRoutes: ['collections/series', 'books/series']},
  {to: '/collections/genres', label: 'sidebar.genres', root: true, path: '/collections/genres', name: 'genresIsEmpty', withLang: true, acceptableRoutes: ['collections/genres', 'books/genre']},
  {to: '/collections/formats', label: 'sidebar.formats', root: true, path: '/collections/formats', name: 'formatsIsEmpty', withLang: true, acceptableRoutes: ['collections/formats', 'books/format']},
  {to: '/collections', label: 'sidebar.collections', root: true, path: '/collections', withLang: true, acceptableRoutes: ['collections', 'books/collection']},
]

const renderNestedLinks = (closeHeaderMenu, location, language, userId, sidebarLinks) => map(({to, label, path, root = false, withLang = false, name, acceptableRoutes}) => (
    <NestedLink to={withLang ? '/' + language + '/user/' + userId + to : to} label={label} key={to} isEmpty={name && sidebarLinks[name]}
                       onClick={closeHeaderMenu} root={root} path={path} location={location} language={language} userId={userId} acceptableRoutes={acceptableRoutes}/>
))

const SidebarNav = ({closeHeaderMenu, location, language, userId, isMobile, user, sidebarLinks}) => cond([
  [contains(`/${language}/user/${userId}/settings`), always(
    <Settings closeHeaderMenu={closeHeaderMenu} language={language} userId={userId} location={location} sidebarLinks={sidebarLinks}/>
  )],
  [T, always(
    <div className="Sidebar__scroll_wrap">
      {isMobile ?
        <SidebarMenu language={language} userId={userId} closeHeaderMenu={closeHeaderMenu} location={location} user={user}
                     sidebarLinks={sidebarLinks}/>
        :
        <Scrollbars style={{ width: '100%', height: 'calc(100vh - 66px - 230px)' }}
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
        >
          <SidebarMenu language={language} userId={userId} closeHeaderMenu={closeHeaderMenu} location={location} user={user}
                       sidebarLinks={sidebarLinks}/>
        </Scrollbars>
      }
    </div>
  )]
])(location.pathname)

const SidebarMenu = ({language, userId, closeHeaderMenu, location, user, sidebarLinks}) => (
  <div>
    { user ? <nav className="Sidebar__content">
      <NavigationLink to={`/${language}/user/${userId}`} label={getLocaleString('sidebar.dashboard')} onClick={closeHeaderMenu} />
      <NavigationLink to={`/${language}/user/${userId}/books`} label={getLocaleString('sidebar.books')} isOpen={true}
                      acceptableRoutes={['books', 'authors', 'books/audio', 'books/recent', 'books/favorites', 'collections/genres', 'collections/formats', 'collections', 'books/author', 'books/genre', 'books/format', 'books/collection']}>
        {renderNestedLinks(closeHeaderMenu, location, language, userId, sidebarLinks)(booksNestedLinks)}
      </NavigationLink>
    </nav>
    : ''}
    <nav className="Sidebar__content">
      { !user ? <div className="NavigationLink">
        <Link to={`/${language}`} className="NavigationLink__label back-arrow">{getLocaleString('books.backTo')}{getLocaleString('sidebar.backToLogin')}</Link>
      </div> : ''}
      <NavigationLink to={`/${language}/about_pocketbook_cloud`} label={getLocaleString('sidebar.aboutUs')} isParent={true}
                      acceptableRoutes={['about_pocketbook_cloud', 'advantages_pocketbook_cloud', 'how_to_use_pocketbook_cloud']}>
        {renderAboutNestedLinks(closeHeaderMenu, location, language, userId)(aboutNestedLinks)}
      </NavigationLink>
      <NavigationLink to={`/${language}/faq/account`} label={getLocaleString('login.FAQ_btn')} isParent={true}
                      acceptableRoutes={['faq/account', 'faq/synchronization', 'faq/file_storage']}>
        {renderAboutNestedLinks(closeHeaderMenu, location, language, userId)(faqNestedLinks)}
      </NavigationLink>
      <NavigationLink to={`/${language}/privacy-policy`} label={getLocaleString('login.legal')} isParent={true} acceptableRoutes={['privacy-policy', 'terms-of-use', 'cookies']} someClasses="legal">
        {renderAboutNestedLinks(closeHeaderMenu, location, language, userId)(legalNestedLinks)}
      </NavigationLink>
      <NavigationLink to={`/${language}/impressum`} label={getLocaleString('login.impressum_btn')} onClick={closeHeaderMenu} />
      <div className="NavigationLink">
        <a href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${SUPPORT_MAIL}&tf=1`} target="_blank" rel="noopener noreferrer" onClick={e => closeHeaderMenu(e)} className={`NavigationLink__label`}
        >{getLocaleString('login.contact_us_btn')}</a>
      </div>
      <NavigationLink to={`/${language}/for-developers/how-to-connect-store-to-pb-cloud`} label={getLocaleString('login.for_developers_btn')} isParent={true} acceptableRoutes={['for-developers/how-to-connect-store-to-pb-cloud']}>
        {renderAboutNestedLinks(closeHeaderMenu, location, language, userId)(forDevNestedLinks)}
      </NavigationLink>
    </nav>
  </div>
)

const aboutNestedLinks = [
  {to: '/about_pocketbook_cloud', label: 'sidebar.about_btn', root: true, path: '/about_pocketbook_cloud'},
  {to: '/advantages_pocketbook_cloud', label: 'sidebar.advantages_btn', root: true, path: '/advantages_pocketbook_cloud'},
  {to: '/how_to_use_pocketbook_cloud', label: 'sidebar.how_to_use_btn', root: true, path: '/how_to_use_pocketbook_cloud'},
]

const legalNestedLinks = [
  {to: '/privacy-policy', label: 'login.privacyPolicy_btn', root: true, path: '/privacy-policy'},
  {to: '/terms-of-use', label: 'privacy.termsOfUse', root: true, path: '/terms-of-use'},
  {to: '/cookies', label: 'privacy.cookiesConditions', root: true, path: '/cookies'},
]

const faqNestedLinks = [
  {to: '/faq/account', label: 'faq.account', root: true, path: '/faq/account'},
  {to: '/faq/synchronization', label: 'faq.sync', root: true, path: '/faq/synchronization'},
  {to: '/faq/file_storage', label: 'faq.storage', root: true, path: '/faq/file_storage'},
]

const forDevNestedLinks = [
  {to: '/for-developers/how-to-connect-store-to-pb-cloud', label: 'forDevelopers.howToConnectStoreToPbCloud', root: true, path: '/for-developers/how-to-connect-store-to-pb-cloud'}
]

const renderAboutNestedLinks = (closeHeaderMenu, location, language, userId) => map(({to, label, path, root = false}) => (
  <NestedLink to={'/' + language + to} label={label} key={to}
              onClick={closeHeaderMenu} root={root} path={path} location={location} language={language} userId={userId}/>
))

const settingsNestedLinks = [
  {name: 'account',to: '/settings/account', label: 'sidebar.yourAccount', root: true, path: '/settings/account', withLang: true},
  {name: 'adobe',to: '/settings/adobe', label: 'sidebar.adobeID', root: true, path: '/settings/adobe', withLang: true},
  {name: 'readRate', to: '/settings/readrate', label: 'sidebar.readRate', root: true, path: '/settings/readrate', withLang: true},
  {name: 'plan',to: '/settings/plan', label: 'sidebar.plan', root: true, path: '/settings/plan', withLang: true},
]

const removeHidden = (links) => links.filter(link => !hiddenBlocks.settings[link.name])
const Settings = ({closeHeaderMenu, language, userId, location, sidebarLinks}) => (
  <nav className="Sidebar__content hidden">
    <div className="NavigationLink">
      <Link to={`/${language}/user/${userId}`} className="NavigationLink__label back-arrow">{getLocaleString('sidebar.backToDashboard')}</Link>
    </div>
    <NavigationLink to={`/${language}/user/${userId}/settings/account`} label={getLocaleString('sidebar.settings')} isOpen={true}
                    acceptableRoutes={['settings/account', 'settings/adobe', 'settings/readrate', 'settings/plan']}>
      {renderNestedLinks(closeHeaderMenu, location, language, userId, sidebarLinks)(removeHidden(settingsNestedLinks))}
    </NavigationLink>
  </nav>
)

const SidebarCallToAction = ({shop, provider, language}) => (
  <div className="Sidebar__call-to-action">
    <img className="Sidebar__call-to-action-logo" alt={shop ? shop.name : provider ? provider.name : ''} src={shop ? shop.icon : provider ? provider.icon : ''}/>
    <Button tag="a" target={"_blank"} href={shop && shop.home_url.indexOf('pocketbook') === -1 ? shop.home_url : defaultShopLink[language]}>
      {getLocaleString('sidebar.getMore')}
    </Button>
  </div>
)

const SidebarFooter = () => (
  <footer className="Sidebar__footer">
    <AppLinks />
  </footer>
)

const MaybeRenderUser = branch(
  ({renderUserOptions}) => equals(true, renderUserOptions),
  renderComponent(User),
  renderNothing
)()

const mapStateToProps = (state) => ({
    language: state.userData.language,
    isChanged: state.userData.isChanged,
    shop: state.userData.data ? state.userData.data.shop : null,
    provider: state.user ? state.user.provider : {},
    userId: state.userData.data ? state.userData.data.user_id : null,
    user: state.user,
    sidebarLinks: state.sidebar,
})

export const enhance = compose(
  setDisplayName('Sidebar'),
  connect(mapStateToProps, null),
  defaultProps({
    closeHeaderMenu: () => {},
    renderUserOptions: false
  })
)

const getSidebarType = ifElse(equals(true), always('settings'), always('default'))

export const View = ({closeHeaderMenu, renderUserOptions, isSettings, shop, provider, location, language, userId, isMobile, user,
                         sidebarLinks}) => (
  <aside className="Sidebar" data-sidebar-type={getSidebarType(isSettings)}>
    {isMobile ?
      <Scrollbars style={{ width: '100%', height: 'calc(90vh - 68px - 230px)' }}
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
      >
        <InsideSidebar renderUserOptions={renderUserOptions} closeHeaderMenu={closeHeaderMenu} isSettings={isSettings}
                       isMobile={isMobile} location={location} language={language} userId={userId} shop={shop} provider={provider} user={user}
                       sidebarLinks={sidebarLinks}/>
      </Scrollbars>
      :
      <InsideSidebar renderUserOptions={renderUserOptions} closeHeaderMenu={closeHeaderMenu} isSettings={isSettings}
                     isMobile={isMobile} location={location} language={language} userId={userId} shop={shop} provider={provider} user={user}
                     sidebarLinks={sidebarLinks}/>
    }
    {!isSettings && (
      <div className="Sidebar__fixed_footer">
        {userId ? <SidebarCallToAction shop={shop} provider={provider} language={language}/> : ''}
        <SidebarFooter />
      </div>
    )}
  </aside>
)

const InsideSidebar = ({renderUserOptions, closeHeaderMenu, isSettings, isMobile, location, language, userId, user, sidebarLinks}) => (
 <div>
   { user ? <MaybeRenderUser renderUserOptions={renderUserOptions} type={'collapsable'} onCloseMenu={closeHeaderMenu} isSettings={isSettings}/> : ''}
   <SidebarNav closeHeaderMenu={closeHeaderMenu} isSettings={isSettings} location={location} language={language}
               userId={userId} isMobile={isMobile} user={user} sidebarLinks={sidebarLinks}/>
 </div>
)

export const Sidebar = enhance(View)
