import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { compose, setDisplayName, defaultProps } from 'recompose'
import { ifElse, always, equals } from 'ramda'

export const enhance = compose(
  setDisplayName('Protected'),
  defaultProps({
    redirectTo: '/',
    condition: true
  }),
)

export const View = ({location, path, condition, redirectTo, component: Component, ...rest}) =>
  ifElse(equals(redirectTo),
    always(<Component />),
    always(<Route path={path} {...rest} render={({match, location}) =>
      ifElse(equals(true),
        always(<Component match={match} />),
        always(<Redirect to={{pathname: redirectTo}} />)
      )(condition)
  } />))('pathname', location)

export const Protected = enhance(View)
