export const infoText = () => (`
    <div data-ng-bind-html="text" class="break-word ng-binding">
      Для того, чтобы создать учетную запись PocketBook Cloud, пожалуйста, воспользуйтесь инструкцией ниже:
      <br>
      <br>
      1. Найдите ближайший к Вам магазин.
      <br>
      2. Перейдите на веб-сайт магазина и создайте учетную запись.
      <br>
      3. Вернитесь в PocketBook Cloud и авторизируйтесь в облаке с помощью созданной учетной записи магазина.
    </div>
`)

const findStore = {
  infoText: infoText,
  okay: 'ПОНЯТНО',
  findModalTitle: 'Найти ближайший к Вам магазин',
  findModalSearch: 'Имя, адрес, почтовый индекс',
  redirect_To_Registration: 'Зарегистрироваться сейчас'
}
export default findStore;