export const TYPES = {
  SET_LOGIN_URL: 'SET_LOGIN_URL',
  GET_LOGIN_URL: 'GET_LOGIN_URL',
  GET_NEWS: 'GET_NEWS',
  SET_NEWS: 'SET_NEWS',
  GET_DISCUSSION: 'GET_DISCUSSION',
  SET_DISCUSSION: 'SET_DISCUSSION',
  GET_USER_INFO: 'GET_USER_INFO',
  SET_USER_INFO: 'SET_USER_INFO',
  GET_LOGIN_STATUS: 'GET_LOGIN_STATUS',
  SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
  PARTNER_LOGOUT: 'PARTNER_LOGOUT'
}

export const getLoginUrl = () => ({
  type: TYPES.GET_LOGIN_URL
})

export const setLoginUrl = (loginUrl) => ({
  type: TYPES.SET_LOGIN_URL,
  payload: loginUrl
})

export const getNews = () => ({
  type: TYPES.GET_NEWS
})

export const getDiscussion = () => ({
  type: TYPES.GET_DISCUSSION
})

export const setNews = (data) => ({
  type: TYPES.SET_NEWS,
  payload: data
})

export const setDiscussion = (data) => ({
  type: TYPES.SET_DISCUSSION,
  payload: data
})

export const getUserInfo = () => ({
  type: TYPES.GET_USER_INFO
})

export const setUserInfo = (data) => ({
  type: TYPES.SET_USER_INFO,
  payload: data
})

export const getLoginStatus = () => ({
  type: TYPES.GET_LOGIN_STATUS
})

export const setLoginStatus = (status) => ({
  type: TYPES.SET_LOGIN_STATUS,
  payload: status
})

export const partnerLogout = () => ({
  type: TYPES.PARTNER_LOGOUT
})
