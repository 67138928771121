import {always, cond, equals, T} from "ramda";
import {TYPES} from "../actions/login";
import {combineReducers} from "redux";

export const setQRCode = (state = false, {type, payload}) => cond([
  [equals(TYPES.SET_QR_CODE), always(payload)],
  [T, always(state)]
])(type)

export const setAvatar = (state = null, {type, payload}) => cond([
  [equals(TYPES.SET_AVATAR), always(payload)],
  [T, always(state)]
])(type)

export const loginReducer = combineReducers({
  qrCode: setQRCode,
  avatar: setAvatar
})