import { API, APIUrlencoded, getAuthHeaders } from './axiosInstance'

import { find, propEq } from 'ramda'

const url = '/adobe/vendor'

const formatVendorFromResponse = (vendor) => ({
  id: vendor.vendor_id || 'Unknown Adobe ID',
  email: vendor.vendor_user,
  uuid: vendor.uuid,
  serial: vendor.serial,
  isDefault: vendor.is_main,
  password: null
})

const handleResponse = res => res.data

export const getVendorsIds = () => API
  .get(`/adobe/vendors-list`, getAuthHeaders())
  .then(res => res.data)

export const getVendors = () => API
  .get(url, getAuthHeaders())
  .then(({ data }) => data.map(formatVendorFromResponse))
  .then(formattedVendors => ({
    vendors: formattedVendors,
    defaultVendor: (find(propEq('isDefault', true), formattedVendors) || {}).email || null
  }))

export const deleteVendor = (vendor) => APIUrlencoded
  .delete(`${url}/${vendor.id}?vendor_login=${vendor.email}`, getAuthHeaders())
  .then(handleResponse)

export const addVendor = (vendor) => APIUrlencoded
  .post(url, {
    vendor_id: vendor.selectedVendor,
    vendor_login: vendor.login,
    vendor_password: vendor.password,
    main_account: vendor.isDefault,
  }, getAuthHeaders())
  .then(handleResponse)

export const updateVendor = (vendor) => APIUrlencoded
  .put(`${url}/${vendor.id}`, {
    vendor_id: vendor.id,
    is_main: vendor.isDefault,
    vendor_login: vendor.email,
  }, getAuthHeaders())
  .then(handleResponse)

export const setDefault = (vendor) => APIUrlencoded
  .post(`${url}/${vendor.id}/set-default`, {
    vendor_login: vendor.email,
    vendor_password: vendor.password || undefined,
  }, getAuthHeaders())
  .then(handleResponse)

export const getPassword = ({email, language}) => APIUrlencoded
  .post(`/adobe/mail/internal-password`, {email: email, language: language}, getAuthHeaders())
  .then(handleResponse)

export const showPassword = (hash) => APIUrlencoded
  .get(`/adobe/internal-password/${hash}`, getAuthHeaders())
  .then(handleResponse)