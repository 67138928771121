const search = {
  noItems: 'Не найдено',
  recentlyRead: 'Недавно прочитанные',
  collections: 'Коллекции',
  authors: 'Авторы',
  books: 'Книги',
  audioBooks: 'Аудиокниги',
  searchIcon: 'Search Icon',
  canselSearch: 'cancel search icon',
  searchByTitleAuthor: 'Поиск по названию книги или автору',

  notFound: 'Не найдено'
}
export default search;