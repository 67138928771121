import {TYPES, setSeoMeta} from "../actions/seo";
import { put, takeEvery, call } from 'redux-saga/effects'
import * as API from '../API/seo'

function* getSeoMeta () {
  try {
    let meta = yield call(() => API.getSeoMeta())
    let seoMeta = {}
    for (let i=0; i < meta.length; i++) {
      let page = meta[i]
      seoMeta[page.route] = page.params
    }
    yield put(setSeoMeta(seoMeta))
  } catch (e) {
    console.log('Error to get meta', e)
  }
}

export const seoSaga = [
  takeEvery(TYPES.GET_SEO_META, getSeoMeta)
]