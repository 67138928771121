const sidebar = {
  eBooks: 'E-Books',
  audioBooks: 'Hörbücher',
  purchased: 'Gekauft',
  recentlyRead: 'Zuletzt gelesen',
  favorites: 'Favoriten',
  authors: 'Autoren',
  series: 'Buchreihen',
  genres: 'Genre',
  formats: 'Formate',
  collections: 'Sammlungen',
  settings: 'Einstellungen',
  yourAccount: 'Ihr Konto',
  adobeID: 'AdobeID',
  readRate: 'ReadRate',
  plan: 'Abo',
  dashboard: 'Startseite',
  books: 'Bücher',
  getMore: 'Mehr im meinem Shop',
  getApp: 'App holen',

  interface: 'Layout',

  about_btn: 'Was ist die PocketBook Cloud',
  advantages_btn: 'Die Vorteile der',
  how_to_use_btn: 'So verwenden Sie die',
  backToDashboard: 'Zurück zum Dashboard',
  backToLogin: ' anmelden',
  aboutUs: 'Über uns'
}
export default sidebar;