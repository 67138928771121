const login = {
  login: 'Увійти',
  loginWith: 'Увійдіть за допомогою',
  useQRCode: 'QR-код для входу через смартфон',
  useEmail: 'Або введіть e-mail',
  emailPlaseHolder: 'введіть ваш e-mail або ім\'я користувача',
  FAQ_btn: 'Допомога',
  privacyPolicy_btn: 'Політика конфіденційності',
  privacyPolicy: 'Політикою конфіденційності',
  impressum_btn: 'Контакти',
  contact_us_btn: 'Зв\'язатися з нами',
  login_btn: 'Увійти',
  next_btn: 'Далі',
  passwordIncorect: 'Неверный пароль',
  emailIncorect: 'Невірний e-mail',
  searchProviders: 'Пошук провайдерів',
  loggingIn: 'Вхід',
  syncAllDevices: 'Синхронізація між усіма вашими пристроями та платформами',
  readAnyFormats: 'Зручний web рідер для ePub, PDF, fb2',
  allYourLibrary: 'Вся Ваша бібліотека – у надійному місці',
  chooseBookstore: 'Виберіть свій книжковий магазин',
  iAgreeTo: 'Я згоден з ',
  termsOfUse: 'Умовами використання',
  andWith: ' и з ',
  goBack_btn: 'Повернутись',
  enterPassword: 'Введіть Ваш пароль',
  savePasswordNextTime: ' Зберегти пароль для наступного разу',
  googleBtn: 'Увійти за допомогою Google',
  facebookBtn: 'Увійти за допомогою Facebook',

  legal: 'Юридична інформація',
  about: 'Про сервіс',
  for_developers_btn: 'Для розробників'
}
export default login;