import store from '../store'

import enUS from './en-US'
import ruRU from './ru-RU'
import deDE from './de-DE'
import ukUA from './uk-UA'

export const getDataByPath = (obj, path) => {
  const pathArr = path ? path.split(/\./) : []
  for (obj || (obj = {}); pathArr.length > 1;) {
    obj = obj[pathArr.shift()]
  }
  return obj[pathArr[0]]
}

export const locales = {
  'en': enUS,
  'ru': ruRU,
  'de': deDE,
  'uk': ukUA,
}

export const getLocaleString = (path, ...args) => {
  let lang = store.getState().userData.language
  let selectedLocale = lang !== null || lang !== 'null' ? lang : 'en'
  const locale = locales[selectedLocale]
  let value = getDataByPath(locale.messages, path)

  if (typeof value === 'function') {
    value = value.apply(null, args)
  }

  return value
}