import axios from 'axios'
import stringify from 'qs/lib/stringify'
import { getAccessToken } from '../utilities'
import { getClientId } from '../socket'

//todo: webpack resolve:alias for js?
import { API_URL, API_NEW_URL, URLENCODED } from '../constants'

export const API = axios.create({
  baseURL: API_URL
})

export const APINew = axios.create({
  baseURL: API_NEW_URL
})

export const APIUrlencoded = axios.create({
  baseURL: API_URL,
  headers: URLENCODED,
  transformRequest: [data => stringify(data)]
})

export const getAuthHeaders = (otherHeaders, otherConfig) => ({
  headers: {
    'Authorization': `Bearer ${getAccessToken()}`,
    'X-WEB-CLIENT': getClientId(),
    'Cache-Control': 'no-cache',
    ...otherHeaders
  },
  ...otherConfig
})
