import adobe1 from '../../assets/images/adobe1.jpg'
import adobe2 from '../../assets/images/adone2.jpg'
import adobe3 from '../../assets/images/adobe3.jpg'
import adobe4 from '../../assets/images/adobe4.jpg'
import {CLOUD_URL} from "../../constants";

export const section1 = () => (`
<h2>Wie melde ich mich an?</h2>
      <p>Sie können sich bei PocketBook Cloud-Service auf der Webseite, mit der PocketBook Reader (mobile) App (für Android und IOS) oder direkt auf einem PocketBook E-Ink Reader anmelden.</p>
      <p><span>Web:</span> Verwenden Sie für den Zugriff auf die Website Ihren Benutzernamen und das Kennwort Ihres Kontos in der Affiliate-Buchhandlung.
Die Cloud unterstützt ebenfalls die Autorisierung über Facebook und Google. Wenn Sie über Facebook oder Google in einer Buchhandlung registriert sind, können Sie gleichermaßen auf die Cloud zugreifen.
Wenn Sie kein Konto besitzen, klicken Sie auf den Link „Kein Konto“. Eine Liste der Affiliate-Buchhandlungen wird angezeigt. Wählen Sie zum Fortsetzen eine der vorgeschlagenen Buchhandlungen aus oder verwenden Sie die Suche. Sie werden auf die Website der Affiliate-Buchhandlung weitergeleitet. Öffnen Sie das Registrierungsformular und erstellen Sie ein neues Konto, indem Sie den Anweisungen folgen. Kehren Sie anschließend zur Website zurück.</p>
      <p><span>PocketBook E-inc reader:</span>  Beim Einrichten des Gerätes wird Ihnen empfohlen, sich bei PocketBook Cloud anzumelden. Wenn Sie diesen Schritt übersprungen haben, wird Ihnen die Anmeldung erneut im Benachrichtigungsbereich vorgeschlagen. Um sich anzumelden, tippen Sie auf diese Nachricht  im Benachrichtigungsbereich oder gehen Sie auf Einstellungen> Konten  und Synchronisierung> PocketBook Cloud . Sie werden auf das Registrierungsformular weitergeleitet, wo Sie die Option Sie besitzen noch kein Konto  antippen müssen. Es wird eine Liste der Partnerbuchhandlungen im Browser geöffnet. Wählen Sie bitte eine der vorgeschlagenen Buchhandlungen aus und tippen Sie darauf, um fortzufahren. Sie werden zur Webseite der Partnerbuchhandlung weitergeleitet. Gehen im Registrierungsformular auf Anmelden  und erstellen Sie laut den Anweisungen ein neues Konto. Danach gehen Sie zu Einstellungen> Konten  und Synchronisierung> PocketBook Cloud  zurück. Geben Sie Ihre persönlichen Daten, die Sie beim Anmelden auf der Seite der Partnerbuchhandlung benutzt haben, ein und klicken Sie auf Einloggen.</p>
      <p><span>Android-Gerät:</span>  Um sich auf Ihrem Android-Gerät   anzumelden, starten Sie die PocketBook Reader App. Gehen Sie zum rechten Menü und wählen Sie PocketBook Cloud aus. Auf der Registrierungsseite tippen Sie auf den Link Sie besitzen noch kein Konto . Es wird eine Liste der Partnerbuchhandlungen im Browser geöffnet. Wählen Sie bitte eine der vorgeschlagenen Buchhandlungen aus und tippen Sie darauf. Sie werden zur Webseite der Partnerbuchhandlung weitergeleitet. Gehen Sie im Registrierungsformular auf Anmelden  und erstellen Sie laut den Anweisungen ein neues Konto. Danach gehen Sie zur PocketBook Reader App zurück und wählen die PocketBook Cloud  im Seitenmenü aus. Geben Sie auf der Registrierungsseite Ihre persönlichen Daten, die Sie beim Anmelden auf der Seite der Partnerbuchhandlung benutzt haben, ein und klicken Sie auf Einloggen. Laden Sie Pocketbook Reader App für Android herunter: <a href="https://play.google.com/store/apps/details?id=com.obreey.reader" target="_blank">https://play.google.com/store/apps/details?id=com.obreey.reader</a></p>
      <p><span>iOS Gerät:</span> Um sich auf Ihrem iOS Gerät   anzumelden, starten Sie die PocketBook Reader App auf Ihrem Gerät und wählen Sie Einstellungen > PocketBook Cloud . Auf der Login-Seite tippen Sie auf den Link Sie besitzen noch kein Konto  Es wird eine Liste der Partnerbuchhandlungen im Browser geöffnet. Um fortzufahren, wählen Sie bitte eine der vorgeschlagenen Buchhandlungen aus, indem Sie den entsprechenden Namen anklicken. Sie werden auf die Webseite der Buchhandlung weitergeleitet. Gehen Sie auf Anmelden  und erstellen Sie laut den Anweisungen ein neues Konto . Danach gehen Sie zur PocketBook Reader App zurück und wählen die PocketBook Cloud in den Einstellungen aus. Auf der Login-Seite geben Sie Ihre persönlichen Daten ein, die Sie beim Anmelden auf der Seite der Partnerbuchhandlung benutzt haben, und klicken auf Einloggen. Laden Sie die PocketBook Reader App für IOS herunter: <a href="https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?l=ru&mt=8" target="_blank">https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?l=ru&mt=8</a></p>
      <h2>Wie logge ich mich ein?</h2>
      <p>Um sich bei PocketBook Cloud über <span>Webbrowser</span> einzuloggen, gehen Sie auf <a href="`+CLOUD_URL+`">`+CLOUD_URL+`</a> , geben Sie Ihre E-Mail-Adresse ein und drücken Sie auf Weiter  . Wählen Sie den Buchshop aus, den Sie weiter benutzen möchten. Geben Sie Ihr Passwort ein und drücken Sie auf Login.</p>
      <p>Um sich bei PocketBook Cloud mit Ihrem <span>Pocketbook Lesegerät</span> einzuloggen, gehen Sie auf Einstellungen  > Konten  und Synchronisierung > PocketBook Cloud, geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein und drücken Sie auf Login.</p>
      <p>Um sich bei PocketBook Cloud mit Ihrem <span>Adroid Gerät</span> einzuloggen, gehen Sie auf das rechte Menü und wählen Sie den PocketBook Cloud Bereich, geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein und drücken Sie auf Login.</p>
      <p>Um sich bei PocketBook Cloud auf Ihrem <span>IOS Gerät</span> einzuloggen, wählen Sie Einstellungen > PocketBook Cloud . Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein und drücken Sie auf Login.</p>
      <h2>Was, wenn ich mein Passwort vergessen habe?</h2>
      <p>Sie können Ihr Kennwort auf der Website Ihrer Buchhandlung zurücksetzen. Die Anmeldung an der Cloud erfolgt mit Benutzername und Kennwort der Buchhandlung.</p>
      <h2>Wie ändere ich mein Passwort?</h2>
      <p>Sie können Ihr Kennwort auf der Website Ihrer Buchhandlung ändern. Die Anmeldung an der Cloud erfolgt mit Benutzername und Kennwort der Buchhandlung.</p>
      <h2>Adobe DRM Kopierschutz in Pocketbook Cloud Adobe DRM*</h2>
      <p>Öffnen Sie zum Hinzufügen einer Adobe ID das Menü „Einstellungen > Adobe ID“, und klicken Sie auf die Schaltfläche „Adobe ID hinzufügen“. Wählen Sie Ihren Anbieter aus der Liste aus, und geben Sie Ihren Benutzernamen und das Kennwort ein.</p>
      <p>Wenn Sie mehrere Adobe Konten-IDs besitzen, können Sie eine davon als Ihre ID für das primäre Adobe-Konto auswählen („Als Standard verwenden“). Sie wird zum Herunterladen von E-Books in die PocketBook Cloud verwendet.</p>
      <p>Zur Anzeige des Kennwortes für Ihr primäres Adobe-Konto (Anbieter: PocketBook) können Sie auf das Augensymbol klicken.</p>
      <h2>Adobe-ID verknüpfen / synchronisieren</h2>
      <p>Um mehrere Adobe-Konten zu synchronisieren, verfahren Sie bitte wie folgt:</p>
      <p><a href="http://adeactivate.adobe.com/adept/de/JoinAccountLoginForm.jsp" target="_blank">Gehen Sie auf die Adobe Konten-Synchronisierungs Seite</a></p>
      <p>Adobe Konten-Synchronisierungs</p>
      <img src="`+adobe1+`" />
      <p>Wählen Sie im ersten Feld zunächst „PocketBook“ als eBook-Händler aus.</p>
      <img src="`+adobe2+`" />
      <p>Geben Sie die dazugehörige E-Mail Adresse Ihrer PocketBook-ID ein (Diese finden Sie wie weiter oben beschrieben in der Cloud auf der linken Seite am Bildschirmrand) Geben Sie das dazugehörige Kennwort ein;</p>
      <p>Bestätigen Sie im Anschluss Ihre Eingaben;</p>
      <img src="`+adobe3+`" />
      <p>Verfahren Sie nach erfolgreicher Anmeldung, gleichermaßen mit Ihrer Adobe-ID. Wählen Sie im ersten Feld daher „Adobe-ID“ als eBook-Händler aus. Geben Sie die dazugehörige E-Mail Adresse Ihrer Adobe-ID ein Geben Sie das zugehörige Kennwort ein; Danach können Sie völlig unabhängig von der jeweils hinterlegten ID in Ihrem E-Book lesen.</p>
      <img src="`+adobe4+`" />
      <h2>Adobe DRM*:</h2>
      <p>Autoren, Verlage und Buchhändler   leben vom Buchverkauf. Um Missbrauch   zu verhindern (z. B. Schwarzkopien),   hat die Softwareschmiede   Adobe das System DRM (Digital   Rights Management, also digitale   Rechteverwaltung) entwickelt. DRM   stellt sicher, dass eine einmal erworbene   E-Book-Kopie dem Kunden   eindeutig zugeordnet werden   bzw. nur auf einem Gerät gelesen   werden kann, das dem Kunden   eindeutig zugeordnet ist.</p>
      <h2>ADOBE DIGITAL EDITIONS® Installieren:</h2>
      <h3>Wozu brauche ich </h3>
      <h3>Adobe Digital Editions®?</h3>
      <p>Digital Editions® dient einerseits   zum Schutz vor Copyright-Missbrauch.   Andererseits aber ist es ein   intelligent gestaltetes Verwaltungstool   für Ihre E-Books auf dem PC   bzw. dem Laptop – quasi Ihre persönliche   elektronische Bibliothek.   Hier können Sie Ihre E-Books sortieren   und in „Regale“ einordnen,   die Sie nach Belieben beschriften   können. Von hier aus können Sie   die E-Books, die Sie erworben   haben, auf Ihren E-Book-Reader,   Ihr Tablet oder Ihr Smartphone   verschieben. Sie benötigen aber in   jedem Fall Adobe Digital Editions®,   um E-Books mit Hard oder Social   DRM auf den PC herunterladen und   lesen zu können.</p>
      <p>Nachdem Sie Ihre Adobe-ID eingegeben haben, können Sie Ihren Reader sowohl über Ihren PC (via Adobe Digital Editions®) als auch direkt über WLAN / WiFi registrieren.</p>
      <h3>Installation von</h3>
      <h3>Adobe Digital Editions®</h3>
      <p>Adobe Digital Editions® ist – ebenso wie die Adobe-ID – kostenlos.</p>
      <p>Um dieses Programm zu beziehen, gehen Sie wieder auf https://www.adobe.com/at/  und klicken dort auf  Herunterladen  oder Downloads</p>
      <p>Dann wählen Sie den richtigen Link für Ihr Betriebssystem aus.</p>
      <p>für Windows: Adobe Digital Editions® 4.0.3</p>
      <p>für Apple (iOS):   Adobe Digital Editions® 4.0.3   Sobald die Installationsdatei   vollständig auf Ihren Computer   geladen ist, erscheint der Installationsdialog   „Adobe Digital Editions   ® installieren“. Bitte folgen   Sie nun den dort abgefragten   Schritten. Wenn Ihr PC zu einem   (Firmen-)Netzwerk gehört, kann   es sein, dass Sie für die Installation   ein Administrator-Passwort   benötigen.</p>
      <p>Computer mit der ADOBE-ID Autorisieren:</p>
      <p>Sobald Adobe Digital Editions®   auf Ihrem PC installiert ist, können   Sie (unter Hilfe | Computer   autorisieren) den Aktivierungs und   Autorisierungsprozess   starten. Im Zuge dieses Vorgangs   werden Sie nach Ihrer Adobe-ID   gefragt (E-Mail-Adresse und   Passwort). Danach ist Ihr Computer   befähigt, E-Books, die Sie   einkaufen, herunterzuladen, mit   Ihrer Adobe-Kennung zu versehen   und zu öffnen.</p>
      <h2>Sollten Sie eine Adobe-ID benötigen, hier der Registrierungsvorgang.</h2>
      <p><span>Bei Adobe Registrieren</span> Als Erstes benötigen Sie eine   Adobe-ID. Diese erhalten Sie   kostenlos im Internet.</p>
      <p>Gehen Sie auf die Adobe-Homepage unter <a href="https://www.adobe.com/at/">https://www.adobe.com/at/</a> . Dort klicken Sie   rechts oben Anmelden.</p>
      <p>Auf der nächsten Seite klicken Sie auf <span>Adobe-ID erstellen</span></p>
      <p>Füllen Sie den kurzen Fragebogen aus. Wählen Sie als ID Ihre E-Mail-Adresse und legen Sie ein Passwort fest.</p>
`)
export const section2 = () => (`
      <h2>Wie findet die Synchronisierung zwischen meinen Geräten statt?</h2>
      <p>Sie können Ihre Bücher auf verschiedenen Geräten lesen. Sie können sicher sein, dass alle Ihre Notizen, Lesezeichen und Ihr Lesefortschritt gespeichert werden.   Sie haben über jedes beliebige Gerät, mit dem Sie sich bei PocketBook Cloud anmelden, Zugang zu Ihren Büchern.</p>
      <p><span>Im Webbrowser:</span> Die automatische Synchronisierung der Bücherlisten und Lesepositionen findet beim Einloggen bei PocketBook Cloud sowie beim Schließen des Buches statt.</p>
      <p><span>Auf E-Ink Geräten:</span> Die automatische Synchronisierung der Bücherlisten und Lesepositionen  findet beim Einloggen bei PocketBook Cloud sowie beim Öffnen, Schließen oder  Falten  des Buches statt. Um die Synchronisierung durchzuführen, tippen Sie auf das Sync-Symbol im Benachrichtigungsbereich.</p>
      <p><span>Auf Android Geräten:</span> Die automatische Synchronisierung der Bücherlisten und Lesepositionen  findet beim Einloggen bei PocketBook Cloud und beim Schließen des Buches statt. Sie können auch eine Synchronisierung durchführen, indem Sie das Sync-Symbol in der Bibliothek anklicken.</p>
      <p><span>Auf IOS Geräten:</span> Die automatische Synchronisierung der Bücherlisten  und Lesepositionen  findet beim Einloggen bei PocketBook Cloud sowie beim Schließen des Buches statt.</p>
      <h2>Wie kann ich die Synchronisierung unterbrechen und dann fortsetzen?</h2>
      <p>Die Synchronisierung kann nicht unterbrochen werden. Achten Sie jedoch bitte darauf, dass die Synchronisierung unterbrochen wird, wenn Sie keinen Zugang zum Internet haben.</p>
      <h2>Habe ich ohne aktive Internetverbindung Zugang zu den Büchern in meiner Cloud?</h2>
      <p>Falls Sie keine Internetverbindung auf Ihrem Gerät haben, werden Sie nur Zugang zu den Büchern von der letzten Synchronisierung haben.</p>
      <h2>Wie verhalte ich mich bei einem Synchronisierungsfehler?</h2>
      <p>Falls ein Synchronisierungsfehler aufgetreten ist, überprüfen Sie bitte die Internetverbindung. Falls die Internetverbindung stabil ist und richtig hergestellt wird, versuchen Sie es bitte später noch einmal, die Bücher zu synchronisieren; der Fehler ist vermutlich auf dem Server aufgetreten.</p>
`)
export const section3 = () => (`
      <h2>Wie kann ich Bücher hinzufügen?</h2>
      <p>Die neuen Bücher können einfach von der Webseite, dem PocketBook E-Ink Lesegerät oder der Android/iOS PocketBook Reader App auf  Ihr PocketBook Cloud-Konto hochgeladen werden. Nach der Synchronisierung werden alle neuen Bücher auf Ihrem PocketBook Cloud-Konto automatisch gespeichert.</p>
      <h2>Welche Formate werden von meiner Cloud unterstützt?</h2>
      <p>PocketBook Cloud unterstützt alle auf den PocketBook E-Ink Lesegeräten und der Android / IOS PocketBook Reader App für Mobilgeräte verfügbaren Formate (PDF (Adobe DRM), EPUB (Adobe DRM), DjVu, TXT, FB2, FB2.zip, CHM, HTML (basic), CBZ, CBR, СBT, RTF).</p>
      <h2>Wird das Buch nach der Synchronisierung auf dem Gerät gespeichert?</h2>
      <p>Ja, wenn das Buch von PocketBook Cloud auf das Gerät heruntergeladen wurde.</p>
      <h2>Wie kann ich eine Datei komplett löschen?</h2>
      <p>Wählen Sie zum Löschen eines Buches über die Website der PocketBook Cloud die Option „Löschen“ aus dem Dropdown-Menü des Buches aus. (Klicken Sie zum Aufrufen des Dropdown-Menüs auf das Symbol in der Form von drei Punkten.)
      <p>Zum gleichzeitigen Löschen mehrerer Bücher können Sie diese Bücher mithilfe von Strg + linker Maustaste auswählen. Klicken Sie anschließend auf das Löschsymbol in der oberen Menüleiste.</p>
      <p>Um ein Buch mithilfe von PocketBook E-Reader oder einer Anwendung zum Lesen auf Android- oder iOS-Geräten zu entfernen, wählen Sie das Buch aus der Bibliotheksliste aus und öffnen Sie das Kontextmenü. Dort finden Sie zwei Optionen: „Löschen“ (aus dem Gerätespeicher) und „Aus Cloud löschen“.</p>
      <h2>Ist es möglich gelöschte Dateien wiederherzustellen?</h2>
      <p>Wenn eine Datei bei PocketBook Cloud gespeichert ist und nur aus dem Gerätespeicher gelöscht wurde, dann wird diese Datei bei der nächsten Synchronisierung erneut im Gerätespeicher erscheinen. Wenn die Datei aus der Cloud gelöscht wurde, dann besteht keine Möglichkeit, die Datei wiederherzustellen.</p>
      <h2>Wie kann ich Bücher aus der Cloud herunterladen?</h2>
      <p>Um ein Buch auf den internen Speicher Ihres E-Ink Gerätes oder Android/IOS Gerätes herunterzuladen, tippen Sie einfach das gewünschte Buch aus der Bücherliste in der Bibliothek an und das Herunterladen wird automatisch gestartet.</p>
      <p>Wählen Sie zum Herunterladen eines Buches aus der PocketBook Cloud auf Ihren Computer die Option „Herunterladen“ aus dem Dropdown-Menü des Buches aus. (Klicken Sie zum Aufrufen des Dropdown-Menüs auf das Symbol in der Form von drei Punkten.)
Zum gleichzeitigen Herunterladen mehrerer Bücher können Sie diese Bücher mithilfe von Strg + linker Maustaste auswählen. Klicken Sie anschließend auf das Download-Symbol in der oberen Menüleiste.</p>
      <h2>Wie kann ich meine E-Books im Browser lesen?</h2>
      <p>Momentan können Sie Browser-Bücher in den Formaten EPUB und PDF lesen.</p>
      <p>Klicken Sie zum Öffnen eines Buches im Browser auf das Buchcover (im Modus „Kacheln“) oder auf die Zeile mit dem Buchtitel (im Modus „Liste“). Das Buch wird daraufhin in einem neuen Fenster im Web-Reader geöffnet. Sie können das Buch auch über das Dropdown-Menü „Informationen über das Buch“ öffnen.</p>
      <p>Während des Lesens im Browser erfolgt eine Synchronisierung, so dass Ihre Lesepositionen, Lesezeichen und Notizen gespeichert werden. Klicken Sie zum Schließen des Buches auf das Symbol „Schließen“ in der linken Ecke des Anwendungsfensters.</p>            
`)

const faq = {
  account: 'Konto',
  sync: 'Synchronisierung',
  storage: 'Dateispeicherung',
  section1: section1,
  section2: section2,
  section3: section3
}
export default faq;