export const infoText = () => (`
    <div data-ng-bind-html="text" class="break-word ng-binding">
      Для створення облікового запису PocketBook Cloud, будь ласка, скористайтесь інструкцією нижче:
      <br>
      <br>
      1. Знайдіть найближчий до Вас магазин.
      <br>
      2. Перейдіть на веб-сайт магазину та створіть обліковий запис.
      <br>
      3. Поверніться до PocketBook Cloud та авторизуйтесь у хмарі за допомогою створеного облікового запису магазину.
    </div>
`)

const findStore = {
  infoText: infoText,
  okay: 'Зрозуміло',
  findModalTitle: 'Знайти найближчий до Вас магазин',
  findModalSearch: 'Ім\'я, адреса, поштовий індекс',
  redirect_To_Registration: 'Зареєструватись зараз'
}
export default findStore;