const account = {
  name: 'Ім\'я',
  surname: 'Прізвище',
  loginEmail: 'E-mail',
  bookOfWeek: 'Книга тижня',
  personRecom: 'Рекомендації',
  bestsellers: 'Бестселери',
  newThisWeek: 'Новинки',
  readReateNews: 'ReadRate новини',
  readRateDisc: 'ReadRate обговорення',
  nameNotEmpty: 'Ім\'я не може бути порожнім',
  nameIncorect: 'Ім\'я невірне',
  emailNotEmpty:'Електронна пошта не може бути порожня',
  emailIncorect: 'Неправильна адреса електронної пошти',
  surnameNotEmpty: 'Прізвище не може бути порожнім',
  surnameIncorect: 'Прізвище неправильне',
  resetGoToStore: 'Щоб скинути пароль, перейдіть до свого магазину:',
  resetPassword: 'Скидання пароля',
  dashboardWidgets: 'Віджети на головній сторінці:',
  saveChanges: 'Зберегти зміни',
  changeLanguage: 'Змінити мову',
  en: 'Англійська',
  ru: 'Російська',
  de: 'Німецька',
  zh: 'Китайська',
  uk: 'Українська',
  findStore: 'Немає облікового запису?'
}
export default account;