const notifications = {
  noNotifications: 'Keine ungelesenen Benachrichtigungen',
  spaceAlmostOver: 'Speicherplatz fast erschöpft',
  bookAdded: 'Neues E-Book hinzugefügt',
  cannotBeLoaded: 'nicht genug Speicherplatz zum Upload',
  alreadyInCloud: 'datei bereits in der Cloud',
  errorHappened: 'Fehler während des Uploads',
  formatNotSupported: 'Format nicht unterstützt',
  getMoreSpace: 'Mehr Speicherplatz erhalten',
  view: 'Ansehen',

  licenseCancelled: 'Lizenz gekündigt',
  licenseExpired: 'Lizenz abgelaufen',
  licenseRevoked: 'Lizenz widerrufen',
  withoutLcpToken: 'Kein LCP-Token vorhanden',
  incorrectLcpToken: 'LCP-Schlüssel inkorrekt',
  plsLogin: 'Bitte melden Sie sich mit Lcp Konto',
  tryAgain: 'Versuchen Sie es nochmal',
  errorLoginText: 'Kein LCP(DRM)-Konto vorhanden',
  doneLoginText: 'Anmeldung erfolgreich',

  oldCloud: 'Wenn Sie die alte Cloud-Ansicht verwenden möchten, gehen Sie auf Einstellungen -> Layout',

  bookmarkAdded: 'Lesezeichen hinzugefügt',
  bookmarkError: 'Dieser Artikel hat bereits ein Lesezeichen',

  'error303': 'datei bereits in der Cloud',
  'error325': 'format nicht unterstützt',
  'error311': 'datei nicht gefunden',

  'error312': 'File is empty',
  'error320': 'Invalid file data',
  'error321': 'Can not create directory or file',
  'error322': 'Can not save file changes',
  'error324': 'File path is empty or invalid',
  'error326': 'File mime type is not found or is not supported',
  'error334': 'User has no enough free space left to upload the file',
  'error335': 'File size doesn\'t much with header param "Content-Length" or file md5 hash doesn\'t much with header param "file_md5"',
  'error339': 'Can not parse metadata',
  'error441': 'Invalid downloading url',
  'error442': 'File downloading failed',
  'error443': 'File uploading to azure failed',
  'error450': 'Unknown file service error',
  'error1701': 'Task not found',
  'error1702': 'Task not created',
  'error1703': 'Task not completed',
  'error1704': 'Task failed',
  'error151': 'This book is fulfilled for another account',
  'error152': 'This book is fulfilled only for one (some other) device'
}
export default notifications;