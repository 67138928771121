import React from 'react'
import { propEq, any, omit, ifElse, always, equals, when, complement, prop, isNil, findLastIndex, concat, slice, length, gt, __, pipe } from 'ramda'
import { mapProps } from 'recompose'
import LoadableVisibility from 'react-loadable-visibility'
import Loadable from 'react-loadable'
import { Loading } from './components/Shared/Loading/Loading'
import {checkLcpToken} from "./API/login";
import {getLocaleString, locales} from "./locale";
import {appLinks} from './constants'
import * as CryptoJS from 'crypto-js'
import crypto from 'crypto'
import {isMobile, isIOS, isAndroid} from 'mobile-device-detect'

const USER_STORAGE_ID = '__pbcloud_user__'

export const log = (...args) => (data) => {
  console.log.apply(null, args.concat([data]));
  return data;
};

export const handleError = (err) => {
  console.log('API error:', err)
}

export const cropText = (max) => when(
  pipe(length, gt(__, max)),
  (str) => pipe(
    slice(0, max),
    str => slice(0, findLastIndex(equals(' '), str))(str),
    concat(__, ' ...')
  )(str)
)

export const omitProps = keys => mapProps(props => omit(keys, props))

export const createAsync = (name, loader) => Loadable({
  loader: loader,
  render (module, props) {
    const Component = module[name]
    return <Component {...props} />
  },
  loading: () => <Loading active={true} />
})

export const createVisibilityAsync = (name, loader) => LoadableVisibility({
  loader: loader,
  render (module, props) {
    const Component = module[name]
    return <Component {...props} />
  },
  loading: () => <Loading active={true} />
})

export const storeQrCode = (qrCode, session_id, minutes) => {
  if (!qrCode) return false
  let object = {value: qrCode, expired: new Date().getTime() + minutes*60000}

  window.localStorage.setItem(session_id + '_qr_code', JSON.stringify(object))
  return true
}

export const getQrCode = (session_id) => {
  let object = window.localStorage.getItem(session_id + '_qr_code')
  let qrCode = null
  if (object) {
    object = JSON.parse(object)
    qrCode = object
    let expired = object.expired
    if (expired <= new Date().getTime()) {
      qrCode = null
    }
  }
  return qrCode
}


export const getUser = () => {
  const user = window.localStorage.getItem(USER_STORAGE_ID)
  return user ? JSON.parse(user) : null
}

export const storeUser = (user) => {
  if (!user) return false

  window.localStorage.setItem(USER_STORAGE_ID, JSON.stringify(user))
  return true
}

export const updateUser = (user) => {
  if (!user) return false

  const oldUserInfo = getUser()
  !oldUserInfo ? storeUser(user) : storeUser({...oldUserInfo, ...user})
}

export const removeUser = () => {
  window.localStorage.removeItem(USER_STORAGE_ID)
}

export const getAccessToken = () => when(
  complement(isNil),
  prop('access_token')
)(getUser())

export const env = ({dev = () => {}, prod = () => {}}) => ifElse(
  equals('development'),
  dev,
  prod
)(process.env.NODE_ENV)

export const formatBytes = (a, b = 2) => ifElse(
  equals(0),
  always('0 KB'),
  () => {
    const c = 1024
    const d = b
    const e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const f = Math.floor(Math.log(a)/Math.log(c))
    return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]
  }
)(a)

export const pluralize = (word, plural, pred) => ifElse(
  equals(1),
  always(word),
  always(plural)
)(pred)

export const isLoading = (files) => ifElse(
  any(propEq('status', 'loading')),
  () => true,
  () => false
)(files)

export const checkBooksRoute = (routes, route) => {
  return routes.indexOf(route) > -1
}

export const getJsonFromUrl = (url) => {
  let query
  if (url.indexOf('#?') > -1) {
    query = url.substr(2);
  } else {
    query = url.substr(1);
  }

  let result = {};
  if (query.indexOf('&amp;') > -1) {
    query.split("&amp;").forEach(function(part) {
      let item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
  } else {
    query.split("&").forEach(function(part) {
      let item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
  }

  return Object.keys(result).length >= 1 ? result : null
}

export const isDevice = () => {
  if (isMobile && isAndroid) {
    return 'android'
  } else if (isMobile && isIOS) {
    return 'ios'
  } else {
    return false
  }
}

export const checkLcp = (e, url, link, language, isLcp, isDrm, name, toggleActiveLogin, setUploadErrors, addNotification, fast_hash, internal_email, onSelectBook, isLink) => {
  e.preventDefault()

  if (isLink) {
    window.open(link,'_blank')
  } else if (isDevice()) {
    let appLink = 'pocketbook://read/'+name+'?fast_hash='+fast_hash+'&internal_email='+internal_email
    let wait = setTimeout(() => {
      window.location = isDevice() === 'ios' ? appLinks.ios : appLinks.android
    }, 3000);
    window.location = appLink
    setInterval(() => {
      if (document.hidden) {
        clearTimeout(wait)
      }
    }, 50)
  } else if(isLcp) {
    let req = checkLcpToken()
    let link = url

    req.then(res => {
      if (e.ctrlKey && onSelectBook) {
        onSelectBook(e)
      }
      let inBooks = e.target.closest('.Books')
      if (inBooks) {
        if (inBooks.dataset.hasSelected === 'true') return
      }
      if (!url || e.ctrlKey || e.metaKey) {
        return
      }
      window.location = link
    })
    req.catch(error => {
      const lcpErrorCodes = [1707, 1708, 1709, 1700, 1702]
      const bookNotification = (bookName, message, sortbooks, isSpaceNotification, showButton) => ({
        id: Math.random(),
        message: {
          bookName,
          message
        },
        sortbooks,
        isSpaceNotification,
        showButton
      })

      let errorCode = error.response.data.error_code

      if (lcpErrorCodes.indexOf(errorCode) > -1) {
        let errorMsg = ''
        switch (errorCode) {
          case 1707:
            errorMsg = 'notifications.licenseCancelled'
            break
          case 1708:
            errorMsg = 'notifications.licenseExpired'
            break
          case 1709:
            errorMsg = 'notifications.licenseRevoked'
            break
          case 1700:
            toggleActiveLogin()
            setUploadErrors({errorCode: 1700, filePath: name})
            errorMsg = 'notifications.withoutLcpToken'
            break
          case 1702:
            toggleActiveLogin()
            setUploadErrors({errorCode: 1702, filePath: name})
            errorMsg = 'notifications.incorrectLcpToken'
            break
          default:
            console.log(errorCode)
        }
        addNotification(bookNotification(name, getLocaleString(errorMsg), false, false, false))
      }
    })
  } else if (isDrm) {
    if (e.ctrlKey && onSelectBook) {
      onSelectBook(e)
    }
    let inBooks = e.target.closest('.Books')
    if (inBooks) {
      if (inBooks.dataset.hasSelected === 'true') return
    }
    if (!url || e.ctrlKey || e.metaKey) {
      return
    }
    let l = url
    window.location = l
  } else {
    if (e.ctrlKey && onSelectBook) {
      onSelectBook(e)
    }
    let inBooks = e.target.closest('.Books')
    if (inBooks) {
      if (inBooks.dataset.hasSelected === 'true') return
    }
    if (!url || !link || e.ctrlKey || e.metaKey) {
      return
    }
    let l = url
    window.location = l
  }
}

export const notChildren = (flags, target, childs) => {
  if (childs) {
    for(let i=0;i<childs.length;i++) {
      flags.push(target !== childs[i])
      notChildren(flags, target, childs[i].children)
    }
  } else {
    return true
  }
}

export const decryptInternalPassword = (login, password, access_token) => {
  let key = CryptoJS.SHA256(login);
  let iv = CryptoJS.enc.Utf8.parse(access_token.substr(0, 16));
  let decrypted = CryptoJS.AES.decrypt(password, key, { iv: iv }).toString(CryptoJS.enc.Utf8);

  return decrypted
}

const toBytesInt32 = (num) => {
  const buf = Buffer.alloc(4);
  buf.writeUInt32LE(num, 0);
  return buf
}

const toBuffer = (ab) => {
  var buf = Buffer.alloc(ab.byteLength);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buf.length; ++i) {
    buf[i] = view[i];
  }
  return buf;
}

export const getMd5File = (file) => {
  let blockSize = 4096
  let fileSize = file.size
  let packV = toBytesInt32(fileSize)

  let reader = new FileReader()
  let hc = crypto.createHash('md5')

  if (fileSize > 2 * blockSize) {
    let start = new Promise((resolve, reject) => {
      reader.onloadend = e => {
        let result = e.target.result
        let buf = toBuffer(result)
        hc.update(buf)
        hc.update(packV)
      }
      reader.readAsArrayBuffer(file.slice(0, blockSize))
      resolve(hc)
    })
    return start.then(res => {
      let end = new Promise((resolve, reject) => {
        reader = new FileReader()
        reader.onloadend = e => {
          let result = e.target.result
          let buf = toBuffer(result)
          res.update(buf)
          resolve(res.digest('hex'))
        }
        reader.readAsArrayBuffer(file.slice(fileSize - blockSize))
      })
      return end.then(res => ({hash: res}))
    })
  } else {
    let start = new Promise((resolve, reject) => {
      reader.onloadend = e => {
        let result = e.target.result
        let buf = toBuffer(result)
        hc.update(buf)
        resolve(hc.digest('hex'))
      }
      reader.readAsArrayBuffer(file)
    })
    return start.then(res => ({hash: res}))
  }
}

const pad = (string) => {
  return ('0' + string).slice(-2)
}

export const dateForSaveNote = () => {
  let date = new Date()

  const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
  const year = date.getUTCFullYear()
  const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()

  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())

  return `${year}-${month}-${day}T${hh}:${mm}:${ss}Z`
}

export const loadLangInUrl = (props, language, setLanguage) => {
  let langInUrl = props.match.params.language
  let pathname = props.history.location.pathname
  if (!langInUrl || !locales[langInUrl]) {
    let path = props.history.location.pathname
    let params = path.split('/')
    params[1] = language
    pathname = params.join('/')
    props.history.push({
      pathname: pathname,
    })
  } else if (locales[langInUrl]) {
    window.localStorage.setItem('language', langInUrl)
    let userData = getUser()
    if (userData) {
      userData.language = langInUrl
      updateUser(userData)
    }
    setLanguage(langInUrl)
  }
}

export const loadUserIdInUrl = (props) => {
  let userIdInUrl = props.match.params.userId
  let pathname = props.history.location.pathname

  if (!userIdInUrl && props.userId) {
    let params = pathname.split('/')
    params[2] = 'user/'+props.userId
    pathname = params.join('/')
    props.history.push({
      pathname: pathname,
    })
  } else if (props.userId && userIdInUrl !== props.userId) {
    let params = pathname.split('/')
    params[3] = props.userId
    pathname = params.join('/')
    props.history.push({
      pathname: pathname,
    })
  }
}

export const updateLangInUrl = (props, nextProps) => {
  if (props.language === nextProps.language) return

  let path = props.history.location.pathname
  let params = path.split('/')
  params[1] = nextProps.language
  let pathname = params.join('/')
  props.history.push({
    pathname: pathname,
  })
}

export const updateUserIdInUrl = (props, nextProps) => {
  if (props.userId === nextProps.userId) return

  let userIdInUrl = nextProps.match.params.userId
  let path = props.history.location.pathname
  let params = path.split('/')

  if (!userIdInUrl) {
    params[2] = 'user/'+nextProps.userId
  } else if (nextProps.userId && userIdInUrl !== nextProps.userId) {
    params[3] = nextProps.userId
  }
  let pathname = params.join('/')
  props.history.push({
    pathname: pathname,
  })
}

export const getRequest = (url, dataType, callback) => {
  let xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = dataType;
  xhr.onload = function() {
    let status = xhr.status;
    if (status === 200) {
      callback(null, xhr.response);
    } else {
      callback(status);
    }
  };
  xhr.send();
};

export const getInternalPassword = (location) => {
  const pathname = location.pathname
  const key = 'internal-password'
  if (pathname.indexOf(key) > -1) {
    return pathname.split(key+'/')[1].replace('/', '')
  }
  return null
}

export const getCssFromPage = () => {
  var css = [];
  for (var i=0; i<document.styleSheets.length; i++)
  {
    var sheet = document.styleSheets[i];
    if (sheet.href && sheet.href.indexOf('googleapis') > -1) continue;
    var rules = ('cssRules' in sheet)? sheet.cssRules : sheet.rules;
    if (rules)
    {
      for (var j=0; j<rules.length; j++)
      {
        var rule = rules[j];
        if ('cssText' in rule)
          css.push(rule.cssText);
        else
          css.push(rule.selectorText+' {\n'+rule.style.cssText+'\n}\n');
      }
    }
  }
  return css.join('\n')+'\n';
}

export const checkGetParams = () => window.location.search.indexOf('getStorage') > -1 ? false : true