import {TYPES} from "../actions/sidebar";
import {combineReducers} from "redux";
import {always, cond, equals, T} from "ramda";

const purchasedIsEmpty = (state = false, {type, payload}) => cond([
    [equals(TYPES.PURCHASED_IS_EMPTY), always(payload)],
    [T, always(state)]
])(type)

const recentlyIsEmpty = (state = false, {type, payload}) => cond([
    [equals(TYPES.RECENTLY_IS_EMPTY), always(payload)],
    [T, always(state)]
])(type)

const favoritesIsEmpty = (state = false, {type, payload}) => cond([
    [equals(TYPES.FAVORITES_IS_EMPTY), always(payload)],
    [T, always(state)]
])(type)

const authorsIsEmpty = (state = false, {type, payload}) => cond([
    [equals(TYPES.AUTHORS_IS_EMPTY), always(payload)],
    [T, always(state)]
])(type)

const seriesIsEmpty = (state = false, {type, payload}) => cond([
    [equals(TYPES.SERIES_IS_EMPTY), always(payload)],
    [T, always(state)]
])(type)

const genresIsEmpty = (state = false, {type, payload}) => cond([
    [equals(TYPES.GENRES_IS_EMPTY), always(payload)],
    [T, always(state)]
])(type)

const formatsIsEmpty = (state = false, {type, payload}) => cond([
    [equals(TYPES.FORMATS_IS_EMPTY), always(payload)],
    [T, always(state)]
])(type)

export const sidebarReducer = combineReducers({
    purchasedIsEmpty: purchasedIsEmpty,
    recentlyIsEmpty: recentlyIsEmpty,
    favoritesIsEmpty: favoritesIsEmpty,
    authorsIsEmpty: authorsIsEmpty,
    seriesIsEmpty: seriesIsEmpty,
    genresIsEmpty: genresIsEmpty,
    formatsIsEmpty: formatsIsEmpty
})