const login = {
  login: 'Login',
  loginWith: 'Login with',
  useQRCode: 'QR code for logging in via smartphone',
  useEmail: 'Or use e-mail to login',
  emailPlaseHolder: 'enter your e-mail or username',
  FAQ_btn: 'FAQ',
  privacyPolicy_btn: 'Privacy policy',
  privacyPolicy: 'Privacy policy',
  impressum_btn: 'Contacts',
  contact_us_btn: 'Contact us',
  login_btn: 'Login',
  next_btn: 'Next',
  passwordIncorect: 'password is incorrect',
  emailIncorect: 'email is incorrect',
  searchProviders: 'Searching for providers',
  loggingIn: 'Logging in',
  syncAllDevices: 'Synchronization between all your devices and platforms',
  readAnyFormats: 'A handy web reader for ePub, PDF, fb2',
  allYourLibrary: 'All your library in a safe place',
  chooseBookstore: 'Choose your bookstore',
  iAgreeTo: 'I agree to ',
  termsOfUse: 'Terms of Use',
  andWith: ' and with  ',
  goBack_btn: 'Go back',
  enterPassword: 'Enter your password',
  savePasswordNextTime: ' Save my password for next time',
  googleBtn: 'Sign in with Google',
  facebookBtn: 'Sign in with facebook',

  legal: 'Legal Notices',
  about: 'About Cloud',
  for_developers_btn: 'For developers'
}
export default login;