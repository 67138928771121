import React  from 'react'
import {ifElse, equals, always} from "ramda";
import {nextDropdown} from "../../../../../../actions/dropdown";
import {withHandlers, compose, setDisplayName, withPropsOnChange, lifecycle, withStateHandlers} from "recompose";
import {connect} from "react-redux";
import {DropdownMenu} from "../../../../../Shared/DropdownMenu/DropdownMenu";
import {Scrollbars} from 'react-custom-scrollbars'
import {getLocaleString} from "../../../../../../locale";

import './BookInfo.css'
import {formatBytes} from "../../../../../../utilities";

const DROPDOWN_ID = 'AUDIOBOOK_BOOK_INFO'
const isActive = equals(DROPDOWN_ID)

const toggleMenu = ({activeDropdown, nextDropdown}) => ({isInner = false}) => ifElse(
  equals(true),
  () => nextDropdown(null),
  () => ifElse(
    equals(false),
    () => nextDropdown(DROPDOWN_ID),
    always(null)
  )(isInner)
)(isActive(activeDropdown))

const mapStateToProps = state => ({
  activeDropdown: state.activeDropdown
})

const mapDispatchToProps = (dispatch) => ({
  nextDropdown: (id) => dispatch(nextDropdown(id))
})

const handlers = withHandlers({
  toggleMenu
})

const state = {
  componentNode: React.createRef()
}

const stateHandlers = {
  handleClickOutside: ({componentNode}, {activeDropdown, nextDropdown}) => (event) => {
    if (componentNode.current && !componentNode.current.contains(event.target)) {
      if (activeDropdown === DROPDOWN_ID) nextDropdown(null)
    }
  }
}

export const enhance = compose(
  setDisplayName('AudioBook BookInfo'),
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['activeDropdown'], ({activeDropdown}) => ({
    active: isActive(activeDropdown)
  })),
  handlers,
  withStateHandlers(state, stateHandlers),
  lifecycle({
    UNSAFE_componentWillMount() {
      document.addEventListener('mousedown', this.props.handleClickOutside);
    },
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.props.handleClickOutside);
    }
  })
)

export const BookInfoList = ({audioBook}) => (
  <div className="BookInfo__list">
    <div className="line">
      <span className="line-title">{getLocaleString('books.audioBookTitle')}</span>
      <span className="line-text">{audioBook.title}</span>
    </div>
    {audioBook.year ?
      <div className="line">
        <span className="line-title">{getLocaleString('books.audioBookYear')}</span>
        <span className="line-text">{audioBook.year}</span>
      </div>
      : ''}
    <div className="line">
      <span className="line-title">{getLocaleString('books.audioBookAuthor')}</span>
      <span className="line-text">{audioBook.author}</span>
    </div>
    <div className="line">
      <span className="line-title">{getLocaleString('books.audioBookSize')}</span>
      <span className="line-text">{formatBytes(audioBook.size)}</span>
    </div>
    <div className="line">
      <span className="line-title">{getLocaleString('books.audioBookFavorite')}</span>
      <span className="line-text">{ audioBook.isFavorite ? 'Yes' : 'No' }</span>
    </div>
    <div className="line">
      <span className="line-title">{getLocaleString('books.audioBookPurchased')}</span>
      <span className="line-text">{ audioBook.isPurchased ? 'Yes' : 'No' }</span>
    </div>
    <div className="line">
      <span className="line-title">{getLocaleString('books.audioBookGenres')}</span>
      <span className="line-text">{ audioBook.genres.join(', ') }</span>
    </div>
  </div>
)

const View = ({toggleMenu, active, audioBook, componentNode}) => (
  <div className="AudioBook__book_info AudioBook__dropdown" ref={componentNode}>
    <button className="player-button book-info-button" onClick={toggleMenu} data-active={active}></button>
    <DropdownMenu active={active}>
      <div className="BookInfo__header Dropdown__header">
        <span className="BookInfo__header-text">{getLocaleString('books.bookInfoTitle')}</span>
      </div>
      <div className="BookInfo__content limit-content-height">
        <Scrollbars style={{ width: 315, height: 432 }}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
          <BookInfoList audioBook={audioBook}/>
        </Scrollbars>
      </div>
    </DropdownMenu>
  </div>
)

export const BookInfo = enhance(View)