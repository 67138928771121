import React, { Fragment } from 'react'
import { mapProps, setDisplayName, compose, withProps } from 'recompose'
import { ifElse, propOr, prop, always, concat, pipe, has, split, contains, cond, equals, isNil, not } from 'ramda'

import './Button.css'
import facebookIcon from '../../../assets/icons/facebook.svg'
import googlePlusIcon from '../../../assets/icons/g_icon.svg'

import { mergeClassNames } from '../constants'

const maybeSetIconClassName = ifElse(
  has('icon'),
  always(' Button--with-icon'),
  always('')
)

const maybeSetSocialType = ifElse(
  pipe(prop('className'), split(' '), contains('Button--social')),
  prop('icon'),
  always(null)
)

const setClassNames = (defaultClassNames, props) => concat(mergeClassNames(defaultClassNames)(props), maybeSetIconClassName(props))

const getIcon = cond([
  [equals('facebook'), always(facebookIcon)],
  [equals('google-plus'), always(googlePlusIcon)]
])

const maybeRenderIcon = (props) =>
  ifElse(
    pipe(prop('icon'), isNil, not),
    ({icon}) => (
      <Fragment>
        <img src={getIcon(icon)} alt={icon} />
        {prop('children', props)}
      </Fragment>
    ),
    prop('children')
  )(props)

const enhance = compose(
  setDisplayName('Button'),
  mapProps((props) => ({
    ...props,
    component: 'button',
    tag: propOr('button', 'tag', props),
    className: setClassNames('Button', props),
  })),
  withProps((props) => ({
    'social-type': maybeSetSocialType(props)
  }))
)

const View = ({tag, socialType, ...rest}) => {
    return (
      React.createElement(tag, rest, maybeRenderIcon(rest))
    )
}

export const Button = enhance(View)
