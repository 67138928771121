import { all } from 'redux-saga/effects'

import { authSaga } from './auth'
import { booksSaga } from './books'
import { userSaga } from './user'
import { readRateSaga } from './readRate'
import { adobeSaga } from './adobe'
import { plansSaga } from './plans'
import { contactSaga } from './contactUs'
import { findStoreSaga } from "./findStore";
import { seoSaga } from "./seo";
import { loginSaga } from "./login";

export default function* rootSaga () {
  yield all([
    ...booksSaga,
    ...authSaga,
    ...userSaga,
    ...readRateSaga,
    ...adobeSaga,
    ...plansSaga,
    ...contactSaga,
    ...findStoreSaga,
    ...seoSaga,
    ...loginSaga
  ])
}
