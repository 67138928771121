export const TYPES = {
  ADD_BOOK: 'ADD_BOOK',
  UPLOAD_BOOK: 'UPLOAD_BOOK',
  UPLOAD_BOOK_SUCCESSFUL: 'UPLOAD_BOOK_SUCCESSFUL',
  UPLOAD_BOOK_FAIL: 'UPLOAD_BOOK_FAIL',
  UPLOAD_BOOK_PROGRESS: 'UPLOAD_BOOK_PROGRESS',
  UPLOAD_BOOK_FINISHED: 'UPLOAD_BOOK_FINISHED',
  UPLOAD_BOOK_TOGGLE_ACTIVE: 'UPLOAD_BOOK_TOGGLE_ACTIVE',
  DELETE_BOOKS: 'DELETE_BOOKS',
  DELETE_COLLECTIONS: 'DELETE_COLLECTIONS',
  REMOVE_BOOKS: 'REMOVE_BOOKS',
  REMOVE_COLLECTIONS: 'REMOVE_COLLECTIONS',
  REMOVE_COLLECTIONS_BY_IDS: 'REMOVE_COLLECTIONS_BY_IDS',
  FETCH_BOOKS: 'FETCH_BOOKS',
  SET_PROVIDER_URL: 'SET_PROVIDER_URL',
  ADD_BOOKS: 'ADD_BOOKS',
  SET_BOOKS_COUNT: 'SET_BOOKS_COUNT',
  ON_FETCH_BOOKS: 'ON_FETCH_BOOKS',
  SORT_BOOKS: 'SORT_BOOKS',
  SORT_COLLECTIONS: 'SORT_COLLECTIONS',
  RESET_BOOKS: 'RESET_BOOKS',
  RESET_COLLECTIONS: 'RESET_COLLECTIONS',
  UPDATE_STATUS: 'UPDATE_STATUS',
  SET_STATUS: 'SET_STATUS',
  UPDATE_FAVORITE: 'UPDATE_FAVORITE',
  UPDATE_READ_POSITION: 'UPDATE_READ_POSITION',
  SET_FAVORITE: 'SET_FAVORITE',
  SET_READ_POSITION: 'SET_READ_POSITION',
  ADD_COLLECTION: 'ADD_COLLECTION',
  EDIT_COLLECTION: 'EDIT_COLLECTION',
  RENAME_COLLECTION: 'RENAME_COLLECTION',
  ADD_BOOKS_TO_COLLECTION: 'ADD_BOOKS_TO_COLLECTION',
  ATTACH_BOOKS_TO_COLLECTION: 'ATTACH_BOOKS_TO_COLLECTION',
  DELETE_BOOKS_FROM_COLLECTION: 'DELETE_BOOKS_FROM_COLLECTION',
  DETACH_BOOKS_FROM_COLLECTION: 'DETACH_BOOKS_FROM_COLLECTION',
  DELETE_COLLECTION: 'DELETE_COLLECTION',
  REMOVE_COLLECTION: 'REMOVE_COLLECTION',
  GET_COLLECTIONS: 'GET_COLLECTIONS',
  SET_COLLECTIONS: 'SET_COLLECTIONS',
  FETCH_AUDIOBOOKS_FIES: 'FETCH_AUDIOBOOKS_FIES',
  ON_FETCH_AUDIOBOOKS_FIES: 'ON_FETCH_AUDIOBOOKS_FIES',
  GET_AUDIOBOOKS_FIES: 'GET_AUDIOBOOKS_FIES',
  ADD_MARK_DOWN: 'ADD_MARK_DOWN',
  REMOVE_MARK_DOWN: 'REMOVE_MARK_DOWN',
  GET_MARK_LIST: 'GET_MARK_LIST',
  SET_MARK_LIST: 'SET_MARK_LIST',
  SET_BOOKS_INFO: 'SET_BOOKS_INFO',
  GET_BOOKS_INFO: 'GET_BOOKS_INFO',
  SET_UPLOAD_ERRORS: 'SET_UPLOAD_ERRORS',
  GET_NEW_LAST_READ: 'GET_NEW_LAST_READ',
  GET_GENRES: 'GET_GENRES',
  SET_GENRES: 'SET_GENRES',
  VIEW_BOOKS: 'VIEW_BOOKS',
  SET_MARK: 'SET_MARK',
  REMOVE_MARK: 'REMOVE_MARK',
  ADD_SCROLL_BOOKS_REF: 'ADD_SCROLL_BOOKS_REF',
  FETCH_ALL_BOOKS: 'FETCH_ALL_BOOKS',
  REMOVE_AUDIOBOOKS_FILES: 'REMOVE_AUDIOBOOKS_FILES',
  UPDATE_BOOKMARK: 'UPDATE_BOOKMARK'
}


export const deleteBooksFromCollection = (collectionName, bookPaths, isAudioBook, bookIds) => ({
  type: TYPES.DELETE_BOOKS_FROM_COLLECTION,
  payload: {
    name: collectionName,
    bookPaths,
    isAudioBook,
    bookIds
  }
})

export const addBooksToCollection = (collectionName, bookPaths, isAudioBook, bookIds) => ({
  type: TYPES.ADD_BOOKS_TO_COLLECTION,
  payload: {
    name: collectionName,
    bookPaths,
    isAudioBook,
    bookIds
  }
})

export const attachBooksToCollection = ({name, bookIds}) => ({
  type: TYPES.ATTACH_BOOKS_TO_COLLECTION,
  payload: {
    name, bookIds
  }
})

export const detachBooksFromCollection = ({name, bookIds}) => ({
  type: TYPES.DETACH_BOOKS_FROM_COLLECTION,
  payload: {
    name, bookIds
  }
})

export const getCollections = () => ({
  type: TYPES.GET_COLLECTIONS
})

export const setCollections = (collections) => ({
  type: TYPES.SET_COLLECTIONS,
  payload: collections
})

export const addCollection = (name) => ({
  type: TYPES.ADD_COLLECTION,
  payload: name
})

export const deleteCollection = (name) => ({
  type: TYPES.DELETE_COLLECTION,
  payload: name
})

export const removeCollection = (name) => ({
  type: TYPES.REMOVE_COLLECTION,
  payload: name
})

export const editCollection = (oldName, newName) => ({
  type: TYPES.EDIT_COLLECTION,
  payload: {
    oldName,
    newName
  }
})

export const renameCollection = ({oldName, newName}) => ({
  type: TYPES.RENAME_COLLECTION,
  payload: {
    oldName,
    newName
  }
})

export const updateFavorite = (status, path, isAudioBook, id) => ({
  type: TYPES.UPDATE_FAVORITE,
  payload: {
    status,
    path,
    isAudioBook,
    id
  }
})

export const updateReadPosition = (read_position, id) => ({
  type: TYPES.UPDATE_READ_POSITION,
  payload: {
    read_position,
    id
  }
})

export const addMarkDown = (read_position, bookName, id) => ({
  type: TYPES.ADD_MARK_DOWN,
  payload: {
    read_position,
    bookName,
    id
  }
})

export const removeMarkDown = (read_position, bookName, id) => ({
  type: TYPES.REMOVE_MARK_DOWN,
  payload: {
    read_position,
    bookName,
    id
  }
})

export const getMarkList = (payload) => ({
  type: TYPES.GET_MARK_LIST,
  payload
})

export const setMarkList = (markList) => ({
  type: TYPES.SET_MARK_LIST,
  payload: markList
})

export const setFavorite = ({status, id}) => ({
  type: TYPES.SET_FAVORITE,
  payload: {
    status: status,
    id
  }
})

export const setReadPosition = ({read_position, id}) => ({
  type: TYPES.SET_READ_POSITION,
  payload: {
    read_position,
    id
  }
})

export const updateStatus = (status, bookId) => ({
  type: TYPES.UPDATE_STATUS,
  payload: {
    status: status,
    id: bookId
  }
})

export const setStatus = ({status, id}) => ({
  type: TYPES.SET_STATUS,
  payload: {
    status: status,
    id
  }
})

export const addBook = (type, book) => ({
  type: TYPES.ADD_BOOK,
  payload: book
})

export const uploadBookAction = (files) => ({
  type: TYPES.UPLOAD_BOOK,
  payload: files
})

export const uploadBookSuccessful = (id, size) => ({
  type: TYPES.UPLOAD_BOOK_SUCCESSFUL,
  payload: [ id, size ]
})

export const uploadBookFail = (id, size, errorMsg) => ({
  type: TYPES.UPLOAD_BOOK_FAIL,
  payload: [ id, size, errorMsg ]
})

export const uploadBookProgress = (id, progress) => ({
  type: TYPES.UPLOAD_BOOK_PROGRESS,
  payload: [ id, progress ]
})

export const uploadBookFinished = () => ({
  type: TYPES.UPLOAD_BOOK_FINISHED,
  payload: null
})

export const toggleActive = (status) => ({
  type: TYPES.UPLOAD_BOOK_TOGGLE_ACTIVE,
  payload: status
})

export const deleteBooks = (books) => ({
  type: TYPES.DELETE_BOOKS,
  payload: {
    books
  }
})

export const deleteCollections = (collections) => ({
  type: TYPES.DELETE_COLLECTIONS,
  payload: {
    collections
  }
})

export const removeBooks = (books) => ({
  type: TYPES.REMOVE_BOOKS,
  payload: books
})

export const removeCollections = (collections) => ({
  type: TYPES.REMOVE_COLLECTIONS,
  payload: collections
})

export const removeCollectionsByIds = (collections) => ({
  type: TYPES.REMOVE_COLLECTIONS_BY_IDS,
  payload: collections
})

export const fetchBooks = (type, options) => ({
  type: TYPES.FETCH_BOOKS,
  payload: {
    type,
    options
  }
})

export const setProviderUrl = (type, url) => ({
  type: TYPES.SET_PROVIDER_URL,
  payload: {
    type,
    url
  }
})

export const fetchAllBooks = (type) => ({
  type: TYPES.FETCH_ALL_BOOKS,
  payload: {
    type
  }
})

export const addBooks = (type, items) => ({
  type: TYPES.ADD_BOOKS,
  payload: {
    type,
    items
  }
})

export const setBooksCount = (count) => ({
  type: TYPES.SET_BOOKS_COUNT,
  payload: count
})

export const onFetchBooks = (status) => ({
  type: TYPES.ON_FETCH_BOOKS,
  payload: status
})

export const sortBooks = (sortType) => ({
  type: TYPES.SORT_BOOKS,
  payload: sortType
})

export const changeViewBooks = (viewType) => ({
  type: TYPES.VIEW_BOOKS,
  payload: viewType
})

export const sortCollections = (sortType) => ({
  type: TYPES.SORT_COLLECTIONS,
  payload: sortType
})

export const resetCollections = () => ({
  type: TYPES.RESET_COLLECTIONS
})

export const resetBooks = () => ({
  type: TYPES.RESET_BOOKS
})

export const getBooksInfo = () => ({
  type: TYPES.GET_BOOKS_INFO
})

export const setBooksInfo = (payload) => ({
  type: TYPES.SET_BOOKS_INFO,
  payload: payload
})

export const getAudioBookFiles = (payload) => ({
  type: TYPES.GET_AUDIOBOOKS_FIES,
  payload
})

export const removeAudioBookFiles = () => ({
  type: TYPES.REMOVE_AUDIOBOOKS_FILES
})

export const fetchAudioBookFiles = (payload) => ({
  type: TYPES.FETCH_AUDIOBOOKS_FIES,
  payload
})

export const onFetchAudioBookFiles = (status) => ({
  type: TYPES.ON_FETCH_AUDIOBOOKS_FIES,
  payload: status
})

export const setUploadErrors = (params) => ({
  type: TYPES.SET_UPLOAD_ERRORS,
  payload: params
})

export const getNewLastRead = (lastReadId) => ({
  type: TYPES.GET_NEW_LAST_READ,
  payload: lastReadId
})

export const getGenres = () => ({
  type: TYPES.GET_GENRES
})

export const setGenres = (payload) => ({
  type: TYPES.SET_GENRES,
  payload: payload
})

export const setMark = (payload) => ({
  type: TYPES.SET_MARK,
  payload: payload
})

export const removeMark = (payload) => ({
  type: TYPES.REMOVE_MARK,
  payload: payload
})

export const updateBookmark = (id, mark) => ({
  type: TYPES.UPDATE_BOOKMARK,
  payload: {
    mark,
    id
  }
})