export const TYPES = {
  SET_PLANS: 'SET_PLANS',
  SET_CURRENT_PLAN: 'SET_CURRENT_PLAN',
  GET_PLANS: 'GET_PLANS',
  GET_CURRENT_PLAN: 'GET_CURRENT_PLAN',
  UPGRADE_PLAN: 'UPGRADE_PLAN',
}

export const setPlans = (plans) => ({
  type: TYPES.SET_PLANS,
  payload: plans
})

export const setCurrentPlan = (plan) => ({
  type: TYPES.SET_CURRENT_PLAN,
  payload: plan
})

export const getPlans = () => ({
  type: TYPES.GET_PLANS,
})

export const getCurrentPlan = () => ({
  type: TYPES.GET_CURRENT_PLAN
})

export const upgradePlan = (planId) => ({
  type: TYPES.UPGRADE_PLAN,
  payload: planId
})
