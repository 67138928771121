import { propOr, prop, always, T, concat, reduce, pipe, cond, equals, keys } from 'ramda'

export const isInvalid = pipe(prop('invalid'), equals(true))

export const mergeClassNames = (defaultClassNames) => pipe(
  propOr('', 'className'),
  concat(`${defaultClassNames} `)
)

export const dynamicClassesReducer = (dynamicClasses) => (props) => (acc, key) =>
  cond([
    ...dynamicClasses(props, acc),
    [T, always(acc)]
  ])(key)

export const getStateClassNames = (dynamicClasses) => (props) =>
  pipe(keys, reduce(dynamicClassesReducer(dynamicClasses)(props), ''))(props)

export const setClassNames = (defaultClass) => (dynamicClasses) => (props) =>
  mergeClassNames(defaultClass)({ className: getStateClassNames(dynamicClasses)(props) })

export const mergeAndSetClassNames = ({defaultClass = '', dynamicClasses = () => []}) => (props) =>
  concat(setClassNames(defaultClass)(dynamicClasses)(props), ` ${propOr('', 'className', props)}`)

// example of usage
// const dynamicClasses = (props, acc) => [
//   [both(equals('invalid'), always(isInvalid(props))), always(concat(acc, 'Input--invalid'))]
// ]
// mapProps(props => ({
//   className: mergeAndSetClassNames({defaultClass: 'Input', dynamicClasses: dynamicClasses})(props),
// }))
