const sidebar = {
  eBooks: 'e-Books',
  audioBooks: 'Audiobooks',
  purchased: 'Purchased',
  recentlyRead: 'Recently read',
  favorites: 'Favourites',
  authors: 'Authors',
  series: 'Series',
  genres: 'Genres',
  formats: 'Formats',
  collections: 'Collections',
  settings: 'Settings',
  yourAccount: 'Your account',
  adobeID: 'AdobeID',
  readRate: 'ReadRate',
  plan: 'Plan',
  dashboard: 'Dashboard',
  books: 'Books',
  getMore: 'Get more at my Store',
  getApp: 'Get the app',

  interface: 'Interface',

  about_btn: 'Service description',
  advantages_btn: 'Advantages',
  how_to_use_btn: 'How to use',
  backToDashboard: 'Back to dashboard',
  backToLogin: ' login',
  aboutUs: 'About us'
}
export default sidebar;