import React, {Fragment} from 'react'
import {compose, lifecycle, setDisplayName, withProps, withStateHandlers} from "recompose";
import {connect} from "react-redux";
import { withRouter } from 'react-router'
import {always, equals, ifElse, not} from "ramda";
import {getLocaleString} from "../../../../locale";
import InputRange from "react-input-range";
import { Link } from 'react-router-dom'
import {Sidebar} from "./components/Sidebar/Sidebar";

import './AudioBook.css'
import ReactPlayer from "react-player";
import {
  getPlayerType,
  onUpdateReadPosition,
  formatTime,
  trackPath,
  trackName,
  formatPercent, getTrackNumber, decodeBookName, getDuration
} from "./utilities"
import {dateForSaveNote} from "../../../../utilities";
import {
  addMarkDown, fetchBooks,
  onFetchAudioBookFiles,
  removeAudioBookFiles,
  removeMarkDown,
  updateReadPosition,
  updateBookmark
} from "../../../../actions/books";
import {BookInfo} from "./components/BookInfo/BookInfo";
import {Chapters} from "./components/Chapters/Chapters";
import {Bookmarks} from "./components/Bookmakrs/Bookmarks";
import {BookmarkAdding} from "./components/BookmarkAdding/BookmarkAdding";

const mapStateToProps = state => ({
  allBooks: state.books.byId,
  files: state.books.audioBook.files,
  userId: state.userData.data.user_id,
  language: state.userData.language || navigator.language || navigator.userLanguage,
  fetchingFiles: state.books.audioBook.fetchingFiles,
  marks: state.books.audioBook.files.mark,
  lastReadId: state.books.lastReadId
})

const mapDispathToProps = (dispatch) => ({
  fetchBooks: (type, options) => dispatch(fetchBooks(type, options)),
  updateReadPosition: (readPosition, bookId) => dispatch(updateReadPosition(readPosition, bookId)),
  addMarkDown: (readPosition, bookName, bookId) => dispatch(addMarkDown(readPosition, bookName, bookId)),
  removeMarkDown: (readPosition, bookName, bookId) => dispatch(removeMarkDown(readPosition, bookName, bookId)),
  removeFiles: () => dispatch(removeAudioBookFiles()),
  onFetchAudioBookFiles: (status) => dispatch(onFetchAudioBookFiles(status)),
  updateBookmark: (id, mark) => dispatch(updateBookmark(id, mark))
})

const state = {
  waitPosition: true,
  playing: false,
  trackNumber: 0,
  volume: 0.8,
  prevVolume: 0.8,
  seeking: false,
  played: 0,
  playerNode: React.createRef(),
  rangeNode: React.createRef(),
  editNoteNode: React.createRef(),
  duration: 1,
  started: false,
  isReady: false,
  notifications: [],
  bAdding: false,
  openEditNote: false
}

const stateHandlers = {
  resetStates: () => () => ({
    playing: false,
    trackNumber: 0,
    seeking: true,
    played: 0,
    duration: 1
  }),
  playPause: ({ playing }) => () => ({
    playing: not(playing)
  }),
  closePlayer: ({trackNumber, played}, {fetchBooks, removeFiles, audioBook: {fast_hash}, files: {chapters}, updateReadPosition}) => () => {
    onUpdateReadPosition(fast_hash, chapters[trackNumber].name, updateReadPosition, played )
    setTimeout(() => {
      fetchBooks('lastRead')
    }, 1000)

    removeFiles()
    return {playing: false}
  },
  toggleVolume: ({ volume, prevVolume }) => () => {
    if (volume !== 0) {
      return {
        volume: 0,
        prevVolume: volume
      }
    } else {
      return {volume: prevVolume}
    }
  },
  startFromTrack: () => (trackNumber) => {
    return { trackNumber: parseInt(trackNumber, 10) }
  },
  changeTrack: ({played, playerNode, trackNumber}, {audioBook: {fast_hash}, updateReadPosition, files: {chapters}}) => (chapterName, mark, nextTrackNumber) => {
    chapterName = chapters[trackNumber].name
    onUpdateReadPosition(fast_hash, chapterName, updateReadPosition, played )

    if (!nextTrackNumber && nextTrackNumber !== 0) {
      nextTrackNumber = getTrackNumber(chapters, mark.page)
    }
    played = mark ? mark.offs/1000 : chapters[nextTrackNumber].read_position ? chapters[nextTrackNumber].read_position.offs/1000 : 0
    let duration = getDuration(chapters[nextTrackNumber].metadata.duration)
    playerNode.current.seekTo(played, 'seconds')
    return {
      trackNumber: parseInt(nextTrackNumber, 10),
      played: played,
      started: false,
      isReady: false,
      duration: duration
    }
  },
  nextTrack: ({ trackNumber, played }, {audioBook: {fast_hash}, files: {chapters}, updateReadPosition}) => () => {
    let chapterName = chapters[trackNumber].name
    onUpdateReadPosition(fast_hash, chapterName, updateReadPosition, played )

    trackNumber = trackNumber+1
    if (trackNumber > chapters.length-1) {
      trackNumber = 0
    }
    let duration = getDuration(chapters[trackNumber].metadata.duration)

    return {
      trackNumber: trackNumber,
      played: chapters[trackNumber].read_position ? chapters[trackNumber].read_position.offs/1000 : 0,
      started: false,
      isReady: false,
      duration: duration
    }
  },
  prevTrack: ({ trackNumber, played }, {audioBook: {fast_hash}, files: {chapters}, updateReadPosition}) => () => {
    let chapterName = chapters[trackNumber].name
    onUpdateReadPosition(fast_hash, chapterName, updateReadPosition, played )
    trackNumber = trackNumber-1
    if (trackNumber < 0) {
      trackNumber = chapters.length-1
    }
    let duration = getDuration(chapters[trackNumber].metadata.duration)

    return {
      trackNumber: trackNumber,
      played: chapters[trackNumber].read_position ? chapters[trackNumber].read_position.offs/1000 : 0,
      started: false,
      isReady: false,
      duration: duration
    }
  },
  setVolume: () => (val) => ({
    volume: parseFloat(val),
    prevVolume: parseFloat(val)
  }),
  onSeekMouseDown: () => () => ({seeking: true}),
  onSeekChange: (state, {updateReadPosition, audioBook: {fast_hash}}) => (value, audioBookId, chapterName) => {
    onUpdateReadPosition(fast_hash, chapterName, updateReadPosition, value )
    return {
      played: parseFloat(value)
    }
  },
  onSeekMouseUp: ({ playerNode }) => (time) => {
    playerNode.current.seekTo(parseFloat(time))
    return { seeking: false }
  },
  onProgress: ({started, playing, seeking, played, trackNumber }, {audioBook: {path, fast_hash}, updateReadPosition, files: {chapters}}) => (progress) => {
    let chapterName = chapters[trackNumber].name
    if( started && !playing ) {
      onUpdateReadPosition(fast_hash, chapterName, updateReadPosition, played )
    }
    if(!seeking && playing) {
      return { played: progress.playedSeconds }
    }
  },
  onStart: () => () => {
    return {started: true}
  },
  onEnded: ({ trackNumber }) => (numberOfTracks) => {
    if(trackNumber < numberOfTracks - 1) {
      return {
        played: 0,
        trackNumber: trackNumber + 1
      }
    } else {
      return {
        playing: false,
      }
    }
  },
  selectTab: () => (index) => ({
    selectedTab: index
  }),
  onDuration: () => (duration) => ({
    duration: duration
  }),
  onReady: ({ playerNode, played, isReady }) => () => {
    if ( !isReady ) {
      if (played !== 0 && !isNaN(played)) playerNode.current.seekTo(parseFloat(played))
      return {
        isReady: true
      }
    }
  },
  checkPosition: (state, {files}) => (name) => {
    if (files.name === name || name.indexOf(files.name) > -1) {
      return {waitPosition: false}
    }
  },
  setPlayed: ({played, trackNumber, playerNode}, {files}) => () => {
    if (!files) return
    let offs = files.position.offs
    if (offs !== null) {
      played = offs/1000
      for (let i=0; i < files.chapters.length; i++) {
        let chapter = files.chapters[i]
        if (files.position.page === chapter.name) {
          trackNumber = i
        }
      }
    }
    let duration = getDuration(files.chapters[trackNumber].metadata.duration)
    playerNode.current.seekTo(played, 'seconds')
    return {
      played: played,
      trackNumber: trackNumber,
      duration: duration,
      seeking: false
    }
  },
  loadCurrent: ({played, trackNumber}) => () => ({
    played: played,
    trackNumber: trackNumber
  }),
  addNotification: ({notifications}) => (type) => {
    notifications.push(type)
    return {notifications: notifications}
  },
  removeNotification: ({notifications}) => (index=0) => {
    notifications.splice(index,1)
    return {notifications: notifications}
  },
  setAddNotification: () => (addNotification) => ({
    addNotification: addNotification
  }),
  setRemoveNotification: () => (removeNotification) => ({
    removeNotification: removeNotification
  }),
  changeBookmarkStatus: () => () => ({
    bAdding: false
  }),
  setChangeBookmarkStatus: () => (changeBookmarkStatus) => ({
    changeBookmarkStatus: changeBookmarkStatus
  }),
  bookmarkAdding: ({bAdding, trackNumber, played, duration, addNotification, removeNotification,
                     changeBookmarkStatus}, {files:{chapters}, addMarkDown, marks, onFetchAudioBookFiles, audioBook}) => (note) => {
    let isNew = true

    let percent = Math.ceil(Math.floor(played)*100/duration)
    const readPosition = {
      page: chapters[trackNumber].name,
      offs: Math.floor(played) * 1000,
      date: dateForSaveNote(),
      percent: percent,
      name: note
    }

    for (let i=0; i < marks.length; i++) {
      let mark = marks[i]
      if (mark.page === readPosition.page && mark.offs === readPosition.offs) {
        isNew = false
      }
    }

    if (isNew) {
      addMarkDown(readPosition, audioBook.name, audioBook.fast_hash )
    }
    onFetchAudioBookFiles(true)
    addNotification(isNew ? 'new' : 'error')
    onFetchAudioBookFiles(false)
    setTimeout(() => {
      onFetchAudioBookFiles(true)
      removeNotification()
      onFetchAudioBookFiles(false)
    }, 3000)

    setTimeout(() => {
      changeBookmarkStatus()
    }, 1500)
    return {bAdding: true}
  },
  onRemoveMarkDown: (state, {removeMarkDown, audioBook}) => (e, mark) => {
    e.stopPropagation()
    const readPosition = mark
    removeMarkDown(readPosition, decodeBookName(audioBook.name), audioBook.fast_hash )
  },
  onEditNote: ({editNoteNode}) => (mark) => {
    let wait = setInterval(() => {
      if(editNoteNode.current) {
        editNoteNode.current.focus()
        clearInterval(wait)
      }
    }, 100)
    return {openEditNote: mark}
  },
  closeEditNote: () => () => ({
    openEditNote: false
  }),
  onUpdateBookmark: (state, {updateBookmark, audioBook: {fast_hash}}) => (mark, newNote) => {
    mark.name = newNote
    updateBookmark(fast_hash, mark)
    return {openEditNote: false}
  }
}

export const enhance = compose(
  setDisplayName('AudioBook Player'),
  connect(mapStateToProps, mapDispathToProps),
  withProps(({allBooks, files: {id}}) => {
    let audioBook = id ? allBooks[id] : {}
    return {audioBook: audioBook}
  }),
  withStateHandlers(state, stateHandlers),
  lifecycle({
    componentDidMount() {
      const {props: {setRemoveNotification, setAddNotification, setChangeBookmarkStatus,
        removeNotification, addNotification, changeBookmarkStatus}} = this
      setRemoveNotification(removeNotification)
      setAddNotification(addNotification)
      setChangeBookmarkStatus(changeBookmarkStatus)
    },
    UNSAFE_componentWillUpdate(nextProps) {
      const {props: {files, setPlayed, resetStates}} = this
      if (files.id !== nextProps.files.id) {
        resetStates()
        setPlayed()
      }
    }
  })
)

const Notifications = ({notifications}) => ifElse(
  equals([]),
  always(null),
  always(
    <div className="bookmark_notifications">
      {notifications.map((n, i) => <Notification key={i} n={n}/>)}
    </div>
  )
)(notifications)

const Notification = ({n}) => (
  <div className="bookmark_notification">
    {n !== 'error' ? getLocaleString('notifications.bookmarkAdded') : getLocaleString('notifications.bookmarkError')}
  </div>
)

const Cover = ({ cover, bookName }) => (
  <div className="cover" style={{backgroundImage: `url(${cover})`}}>
    { cover
      ? <img className="cover-img" src={`${cover}`} alt={`${bookName}`}/>
      : <div className="Audiobook__no-cover Books__header Dashboard__title" data-cover-ph="true">
        <i className="EmptyAudioBookCover"></i>
      </div> }
  </div>
)

const Duration = ({played, duration, rangeNode, onSeekMouseDown, onSeekChange, onSeekMouseUp, path,
                    chapters, trackNumber}) => (
  <div className="played-duration">
    <div className="played-duration-times">
      <div className="capitalize">
        {`${formatTime(played)} (${formatPercent(played, duration)})`}
      </div>
      <div className="capitalize">{formatTime(duration)}</div>
    </div>
    <InputRange
      ref={rangeNode}
      className="controls-seeker"
      minValue={0}
      maxValue={duration}
      value={played}
      onChangeStart={onSeekMouseDown}
      onChange={(e) => onSeekChange(e, path, chapters[trackNumber].name)}
      onChangeComplete={onSeekMouseUp}
    />
  </div>
)

const Buttons = ({prevTrack, location, playing, playPause, nextTrack, bookmarkAdding, closePlayer,
                   volume, toggleVolume, setVolume, played, duration}) => (
  <div className="buttons-wrapper">
    <button className="player-button prev-button"
            onClick={prevTrack}>
    </button>
    <button className={playing ? "player-button pouse-button" : "player-button play-button" } onClick={playPause}></button>
    <button className="player-button next-button"
            onClick={nextTrack}></button>
    {getPlayerType(location) === 'inFooter' ? <button className="player-button stop-button" onClick={closePlayer}></button> : ''}
    {getPlayerType(location) === 'inFooter' ? <Volume volume={volume} toggleVolume={toggleVolume} setVolume={setVolume}/> : ''}
    <BookmarkAdding bookmarkAdding={bookmarkAdding} played={played} duration={duration}/>
  </div>
)

const Volume = ({toggleVolume, volume, setVolume}) => (
    <div className="volume-wrapper">
      <button className={volume === 0 ? "player-button muted-button" : "player-button volume-button"} onClick={toggleVolume}></button>
      <InputRange
          className="controls-volume"
          minValue={0}
          maxValue={1}
          step={.1}
          value={volume}
          onChange={setVolume}
      />
    </div>
)

const OtherButtons = ({uri, userId, language, audioBook, changeTrack, chapters, onEditNote,
                        played, trackNumber, onRemoveMarkDown, openEditNote, editNoteNode,
                        closeEditNote, onUpdateBookmark}) => (
    <div className="other-buttons-wrapper">
      <BookInfo audioBook={audioBook}/>
      <Chapters chapters={chapters} trackNumber={trackNumber} changeTrack={changeTrack}
                audioBook={audioBook} played={played}/>
      <Bookmarks changeTrack={changeTrack} audioBook={audioBook}
                 played={played} onRemoveMarkDown={onRemoveMarkDown} editNoteNode={editNoteNode}
                 openEditNote={openEditNote} onEditNote={onEditNote} closeEditNote={closeEditNote}
                 onUpdateBookmark={onUpdateBookmark}/>
      <Link to={`/${language}/user/${userId}${uri}`} className="player-button expand-button"></Link>
    </div>
)

const Player = ({props: {files: {chapters}, notifications, location, audioBook, playerNode,
  trackNumber, playing, volume, onEnded, onProgress, onDuration, onEditNote, openEditNote,
  onStart, onReady, audioBook: {cover, title, path, uri}, played, duration, rangeNode, nextTrack,
  onSeekMouseDown, onSeekChange, onSeekMouseUp, prevTrack, playPause, userId, language, editNoteNode,
  bookmarkAdding, toggleVolume, setVolume, closePlayer, marks, changeTrack, onRemoveMarkDown,
  closeEditNote, onUpdateBookmark}}) => (
  <div className={`audioBook ${getPlayerType(location)}`}>
    <Notifications notifications={notifications}/>
    <div className="cover-wrapper">
      <Cover cover={cover} bookName={title}/>
      <ReactPlayer
        ref={playerNode}
        className='react-player'
        url={chapters ? trackPath(chapters, trackNumber) : ''}
        playing={playing}
        volume={volume}
        onEnded={() => onEnded(chapters.length)}
        onProgress={(progress) => onProgress(progress)}
        onDuration={onDuration}
        onStart={onStart}
        onReady={onReady}
      />
      <div className="controls-name">
        <h1 className="Dashboard__title">{trackName(chapters, trackNumber)}</h1>
        <Duration played={played} duration={duration} rangeNode={rangeNode} onSeekMouseDown={onSeekMouseDown}
                  onSeekChange={onSeekChange} onSeekMouseUp={onSeekMouseUp} path={path} chapters={chapters}
                  trackNumber={trackNumber}/>
      </div>
      <Buttons prevTrack={prevTrack} playing={playing} playPause={playPause} nextTrack={nextTrack}
               bookmarkAdding={bookmarkAdding} closePlayer={closePlayer} location={location}
               volume={volume} toggleVolume={toggleVolume} setVolume={setVolume} duration={duration}
               played={played}/>
      {getPlayerType(location) === 'onPage' ? <Volume volume={volume} toggleVolume={toggleVolume} setVolume={setVolume}/> : ''}
      {getPlayerType(location) === 'inFooter' ?
        <OtherButtons uri={uri} userId={userId} language={language} chapters={chapters} audioBook={audioBook} marks={marks}
                      changeTrack={changeTrack} played={played} trackNumber={trackNumber} onRemoveMarkDown={onRemoveMarkDown}
                      openEditNote={openEditNote} onEditNote={onEditNote} editNoteNode={editNoteNode}
                      closeEditNote={closeEditNote} onUpdateBookmark={onUpdateBookmark}/> : ''}
    </div>
    {getPlayerType(location) === 'onPage' ? <Sidebar audioBook={audioBook} chapters={chapters} changeTrack={changeTrack}
                                                     trackNumber={trackNumber} onRemoveMarkDown={onRemoveMarkDown}
                                                     onEditNote={onEditNote} openEditNote={openEditNote}
                                                     editNoteNode={editNoteNode} closeEditNote={closeEditNote}
                                                     onUpdateBookmark={onUpdateBookmark}/> : ''}
  </div>
)

const View = (props) => (
  <Fragment>
    {typeof props.files === 'object' && props.audioBook ? <Player props={props}/> : null}
  </Fragment>
)

export const AudioBookPlayer = withRouter(enhance(View))