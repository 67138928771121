import { combineReducers } from 'redux'
import {
  cond,
  always,
  equals,
  T,
} from 'ramda'
import {TYPES} from '../actions/contactUs'

const sendFormStatus = (state = false, {type, payload}) => cond([
  [equals(TYPES.SEND_FORM_STATUS), always(payload)],
  [T, always(state)]
])(type)

const sendLoginStatus = (state = false, {type, payload}) => cond([
  [equals(TYPES.SEND_LOGIN_STATUS), always(payload)],
  [T, always(state)]
])(type)

export const contactUsReducer = combineReducers({
  status: sendFormStatus,
  loginStatus: sendLoginStatus
})