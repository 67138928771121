export const TYPES = {
  GET_STORES: 'GET_STORES',
  SET_STORES: 'SET_STORES'
}


export const getStores = () => ({
  type: TYPES.GET_STORES
})

export const setStores = (stores) => ({
  type: TYPES.SET_STORES,
  payload: stores
})