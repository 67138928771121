const search = {
  noItems: 'Не знайдено',
  recentlyRead: 'Нещодавно прочитані',
  collections: 'Колекції',
  authors: 'Автори',
  books: 'Книги',
  audioBooks: 'Аудіокниги',
  searchIcon: 'Search Icon',
  canselSearch: 'cancel search icon',
  searchByTitleAuthor: 'Пошук за назвою книги або автором',

  notFound: 'Не знайдено'
}
export default search;