import React, {Fragment} from 'react'
import { Switch } from 'react-router-dom'
import { compose, setDisplayName, withStateHandlers, withHandlers, branch, renderComponent } from 'recompose'
import { both, not, when, pipe, propEq, ifElse, equals, isNil, gte } from 'ramda'
import { connect } from 'react-redux'
import moment from 'moment'
import Loadable from 'react-loadable'
import {checkBooksRoute, getJsonFromUrl, isLoading, updateUser, getInternalPassword} from '../../../../utilities'
import {createSocketClient, getClientId} from '../../../../socket'
import {getSeoMeta} from '../../../../actions/seo'
import {addMessage, setSocket} from '../../../../actions/socket'
import {getQRCode} from '../../../../actions/login'
import {getPlayerType} from '../../../Dashboard/components/AudioBook/utilities'
import { hiddenBlocks, ENABLE_GTM, GTM_ID, COOKIES_ID } from '../../../../constants'

import { refreshToken } from '../../../../API/login'

import { Loading } from '../../../Shared/Loading/Loading'
import { Protected } from '../../../Shared/Protected/Protected'

import { getUser, removeUser } from '../../../../utilities'

import {setUser, setLanguage, setSearchParam, setIsLogin, setInternalPassword} from '../../../../actions/user'
import {px, WithWindowHeightStyle} from "../../../../HOCs/WithWindowHeightStyle";
import {isIE, isSettings} from "../../../Dashboard/Dashboard";
import {SeoTags} from "../../../Shared/SeoTags/SeoTags";
import {Header} from "../../../Dashboard/components/Header/Header";
import {Sidebar} from "../../../Dashboard/components/Sidebar/Sidebar";
import {Scrollbars} from "react-custom-scrollbars";

import TagManager from 'react-gtm-module'

const AsyncLogin = Loadable({
  loader: () => import('../../../Login/Login'),
  loading: () => <Loading active={true} />
})

const AsyncDashboard = Loadable({
  loader: () => import('../../../Dashboard/Dashboard'),
  loading: () => <Loading active={true} />
})

const AsyncFindStore = Loadable({
  loader: () => import('../../../FindStore/FindStore'),
  loading: () => <Loading active={true} />
})

const AsyncImpressum = Loadable({
  loader: () => import('../../../Impressum/Impressum'),
  loading: () => <Loading active={true} />
})

const AsyncFaq = Loadable({
  loader: () => import('../../../Faq/Faq'),
  loading: () => <Loading active={true} />
})

const AsyncPrivacyPolicy = Loadable({
  loader: () => import('../../../PrivacyPolicy/PrivacyPolicy'),
  loading: () => <Loading active={true} />
})

const AsyncTermsOfUse = Loadable({
  loader: () => import('../../../TermsOfUse/TermsOfUse'),
  loading: () => <Loading active={true} />
})

const AsyncCookies = Loadable({
  loader: () => import('../../../Cookies/Cookies'),
  loading: () => <Loading active={true} />
})

const AsyncAbout = Loadable({
  loader: () => import('../../../About/About'),
  loading: () => <Loading active={true} />
})

const AsyncAdvantages = Loadable({
  loader: () => import('../../../Advantages/Advantages'),
  loading: () => <Loading active={true} />
})

const AsyncHowToUse = Loadable({
  loader: () => import('../../../HowToUse/HowToUse'),
  loading: () => <Loading active={true} />
})

const AsyncForDevelopers = Loadable({
  loader: () => import('../../../ForDevelopers/ForDevelopers'),
  loading: () => <Loading active={true} />
})

export const mapStateToProps = state => ({
  user: state.user,
  language: state.userData.language || navigator.language || navigator.userLanguage,
  userId: state.userData && state.userData.data ? state.userData.data.user_id : null,
  isLogin: state.userData.isLogin,
  files: state.books.audioBook.files,
  uploadingFiles: state.books.uploadingBooks.files,
  activeUpload: state.books.uploadingBooks.active
})

export const mapDispathToProps = (dispatch) => ({
  setUser: (user) => pipe(setUser, dispatch)(user),
  setLanguage: (lang) => dispatch(setLanguage(lang)),
  getSeoMeta: () => dispatch(getSeoMeta()),
  setSearchParam: (param) => dispatch(setSearchParam(param)),
  setIsLogin: (status) => dispatch(setIsLogin(status)),
  addMessage: (msg) => dispatch(addMessage(msg)),
  setSocket: (socket) => dispatch(setSocket(socket)),
  getQRCode: (session_id) => dispatch(getQRCode(session_id)),
  setInternalPassword: (password) => dispatch(setInternalPassword(password))
})

const checkUrlParams = (storedUser, str) => {
  let params = getJsonFromUrl(str)
  if (params && params.access_token && params.refresh_token) {
    storedUser = params
    updateUser(storedUser)
  }
  return storedUser
}

export const renewToken = (storedUser, user, setUser, markUserAsChecked, timeout = 0) => {
  setTimeout(() => {
    when(
        pipe(equals(null), not),
        ifElse(
            isTokenExpired,
            ifElse(
                propEq('rememberPassword', false),
                () => removeUser(),
                () => refreshToken().then(storedUser => {
                  maybeStoreUser(user, storedUser, setUser)
                  markUserAsChecked()
                }).catch(err => {
                  console.log('Error while refreshing token', err)
                  removeUser()
                })
            ),
            () => {
              maybeStoreUser(user, storedUser, setUser)
              renewToken(storedUser, user, setUser, markUserAsChecked, storedUser.expires_in*1000)
            }
        )
    )(storedUser)
  }, timeout)
}

export const checkUser = (user, setUser, markUserAsChecked) => {
  let storedUser = getUser()
  let isCleared = window.localStorage.getItem('clearedLocalStorage')

  if (!storedUser && !isCleared) {
    window.localStorage.clear()
    window.localStorage.setItem('clearedLocalStorage', true)
  }

  let hash = window.location.hash
  let href = window.location.search
  if (window.location.search.indexOf('type=social') > -1) {
    markUserAsChecked()
  } else if (hash) {
    storedUser = checkUrlParams(storedUser, hash)
  } else if (href) {
    storedUser = checkUrlParams(storedUser, href)
  } else {
    markUserAsChecked()
  }
  renewToken(storedUser, user, setUser, markUserAsChecked)
}

export const isTokenExpired = ({expiresAt}) =>
  gte(moment().diff(moment(expiresAt), 'minutes'), 0)

export const maybeStoreUser = (user, storedUser, setUser) =>
  when(
    both(
      () => isNil(user),
      () => pipe(isNil, not)(storedUser)
    ),
    () => setUser(storedUser)
  )(null)

const state = {
  isUserChecked: false,
}

const stateHandlers = {
  markUserAsChecked: () => () => ({
    isUserChecked: true
  }),
}

const handlers = {
  onCheckUser: ({markUserAsChecked, isUserChecked, user, setUser}) => () => {
    when(equals(false), () => {
      checkUser(user, setUser, markUserAsChecked)
    })(isUserChecked)
  },
  initGTM: () => () => {
    if (!ENABLE_GTM) return
    const tagManagerArgs = {
      gtmId: GTM_ID
    }
    TagManager.initialize(tagManagerArgs)

    return true;
  },
  initCookies: () => () => {
    const script = document.createElement("script");
    script.src = `//cdn.cookie-script.com/s/${COOKIES_ID}.js`;
    script.charset = 'UTF-8'

    document.head.appendChild(script);
  }
}

const getContentClass = (isLogin, location) => {
  if (isLogin) {
    return 'Login__content'
  } else if (location.pathname.indexOf('/find-store') > -1) {
    return 'Map__content'
  } else {
    return 'Dashboard__content'
  }
}
const getPageClass = (isLogin, location, id, files, activeUpload, language, userId) => {
  if (isLogin) {
    return 'Login__page'
  } else if (location.pathname.indexOf('/find-store') > -1) {
    return 'Map__page'
  } else {
    let classes = 'Dashboard__page'
    if (getPlayerType(location) === 'inFooter' && id) {
      classes += ' inFooter'
    }
    if (checkBooksRoute([`/${language}/user/${userId}/books`,
      `/${language}/user/${userId}`,
      `/${language}/user/${userId}/`,
      `/${language}/user/${userId}/books/audio`], location.pathname) && isLoading(files) && !activeUpload) {
      classes += ' withUploading'
    }
    return classes
  }
}

const Authenticated = ({user, language, history, isLogin, userId, location, files: {id}, uploadingFiles, activeUpload}) => (
  <div className="App__main">
    <WithWindowHeightStyle render={({height}) => (
      <section className={`${isLogin ? 'Login' : 'Dashboard'}${isIE() ? ' ie-browser' : ''}`} style={{minHeight: px(height), height: px(height), width: '100%'}}>
        <SeoTags pathname={history.location.pathname}/>
        {!isLogin ? <Header isSettings={isSettings(language, userId)} location={location}/> : ''}
        <main className={getContentClass(isLogin, location)}>
          {!isLogin && location.pathname.indexOf('/find-store') === -1 ? <Sidebar isSettings={isSettings(language, userId)} location={location}/> : ''}
          <article className={getPageClass(isLogin, location, id, uploadingFiles, activeUpload, language, userId)}>
            <Scrollbars style={{ width: '100%', height: isLogin ? '100vh' : getPlayerType(location) === 'inFooter' && id ? 'calc(100vh - 132px)' : 'calc(100vh - 66px)' }}
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
            >
              <Pages user={user} language={language}/>
            </Scrollbars>
          </article>
        </main>
      </section>
    )} />
  </div>
)

const Pages = ({user, language}) => (
  <Switch>
    <Protected path={`/:language?/find-store`} component={AsyncFindStore} condition={isNil(user)} />
    <Protected path={`/:language?/impressum`} component={AsyncImpressum} />
    <Protected path={`/:language?/faq/account`} component={AsyncFaq} />
    <Protected path={`/:language?/faq/synchronization`} component={AsyncFaq} />
    <Protected path={`/:language?/faq/file_storage`} component={AsyncFaq} />
    <Protected path={`/:language?/for-developers/:article?`} component={AsyncForDevelopers} />
    <Protected path={`/:language?/privacy-policy`} component={AsyncPrivacyPolicy} />
    <Protected path={`/:language?/terms-of-use`} component={AsyncTermsOfUse} />
    <Protected path={`/:language?/cookies`} component={AsyncCookies} />
    <Protected path={`/:language?/about_pocketbook_cloud`} component={AsyncAbout} />
    <Protected path={`/:language?/advantages_pocketbook_cloud`} component={AsyncAdvantages} />
    <Protected path={`/:language?/how_to_use_pocketbook_cloud`} component={AsyncHowToUse} />
    <Protected path={`/:language?/user/:userId?`} component={AsyncDashboard} condition={pipe(isNil, not)(user)} redirectTo={`/${language}`} />
    <Protected path={`/:language?`} component={AsyncLogin} condition={isNil(user)} redirectTo={`/${language}/user/`} />
  </Switch>
)

const CheckUser = branch(
  ({isUserChecked}) => equals(true, isUserChecked),
  renderComponent(Authenticated),
  renderComponent(() => <Loading active={true} />)
)()

export const enhance = compose(
  setDisplayName('CheckRoute'),
  connect(mapStateToProps, mapDispathToProps),
  withStateHandlers(state, stateHandlers),
  withHandlers(handlers)
)

export class View extends React.Component {
  constructor(props) {
    super(props);
    this.analytics = false
  }
  UNSAFE_componentWillMount() {
    const {props: { setLanguage, setSearchParam }} = this

    setSearchParam(this.props.history.location.search)

    if (window.localStorage.getItem('language')) {
      setLanguage(window.localStorage.getItem('language'))
    } else {
      let userLang = navigator.language || navigator.userLanguage;
      let lang = 'en'
      if (userLang.toLowerCase().indexOf('ru') > -1) lang = 'ru'
      if (userLang.toLowerCase().indexOf('de') > -1) lang = 'de'
      window.localStorage.setItem('language', lang)
      let userData = getUser()
      if (userData) {
        userData.language = lang
        updateUser(userData)
      }
      setLanguage(lang)
    }
  }

  componentDidMount () {
    const { props: { initGTM, initCookies, getSeoMeta, onCheckUser, addMessage, setSocket, getQRCode, location, setInternalPassword } } = this
    onCheckUser()
    getSeoMeta()
    this.analytics = initGTM()
    initCookies()

    let clientID = getClientId()
    let userData = getUser()
    if (!userData && !hiddenBlocks.qrCode) {
      getQRCode(clientID)
    }
    createSocketClient(clientID, setSocket, addMessage)

    const internalPassword = getInternalPassword(location)
    if (internalPassword) {
      setInternalPassword(internalPassword)
    }
  }

  render () {
    const { props } = this
    return <CheckUser {...props} />
  }
}

export const CheckRoute = enhance(View)
