const books = {
  uploadBook: 'Upload new book',
  sortBy: 'Sort by',
  recent: 'Date added',
  byRecent: 'By date added',
  byRecentTitle: 'Recently read',
  byTitle: 'By title',
  byAuthor: 'By author',
  byProgress: 'By progress',
  title: 'Title',
  titleInList: 'Title',
  author: 'Author',
  authorInList: 'Author',
  readingProgress: 'Reading progress',
  progress: 'Progress',
  lastRead: 'Last read',
  dateAdded: 'Date added',
  format: 'Format',
  size: 'Size',
  collectionNotEmpty: 'Collection name cannot be empty',
  collectionNameTaken: 'This name is already taken',
  book: 'Book',
  books: 'Books',
  e_book: 'e-Book',
  e_books: 'e-Books',
  selected: 'selected',
  selected_more_than_one: 'selected',
  delete: 'Delete',
  backTo: 'Back to',
  noBooksInCollection: 'No books in collection yet. ',
  addBooksToCatalogue: 'To add new books go to catalogue',
  allBooks: 'All books',
  sureDeleteBook: 'Are you sure you want to delete selected books?',
  sureDelete: 'Are you sure you want to delete: ',
  listen: 'Listen',
  read: 'Read',
  details: 'Details',
  addToCollection: 'Add to collection',
  addToCollectionInDropdown: 'Add to collection',
  addBooks: 'Add books',
  newCollection: 'New collection',
  removeFavorites: 'Remove from favorites',
  addFavorites: 'Add to favorites',
  addFavoritesInDropdown: 'Add to favorites',
  download: 'Download',
  removeFromCollection: 'Remove from collection',
  bookFormat: 'Format:',
  bookSize: 'Size:',
  audiobook: 'audiobook',
  audiobooks: 'audiobooks',
  audioBookTitle: 'Book title: ',
  audioBookYear: 'Year: ',
  audioBookAuthor: 'Author: ',
  audioBookSize: 'Size: ',
  audioBookFavorite: 'In favorites: ',
  audioBookPurchased: 'Purchased: ',
  audioBookGenres: 'Genres: ',
  searchAuthors: 'Search by author',
  anonymousAuthor: 'Anonymous Author',
  authors: 'Authors',
  addBook: 'Add book ',
  by: ' by ',
  toCollection: ' to collection',
  chooseCollection: 'Choose collection:',
  confirm: 'Confirm',
  collection: 'Collection',
  collections: 'Collections',
  collectionsLinkBack: 'Collections',
  collectionName: 'Collection name',
  confirmNewCollection: 'confirm new collection',
  createNewCollection: 'Create new collection' ,
  uploading: 'Uploading: ',
  completed: 'completed',
  seeDetails: 'see details',
  getBook: 'Get the book',
  readRateLogo: 'ReadRate logo',
  seeAllBooks: 'See all books',
  newThisWeek: 'New on this week',
  uploadFailed: 'upload failed',
  uploadSuccess: 'upload successful',
  uplodeToPBCloud: 'Upload to PBCloud',
  done: 'Done',
  addMoreFiles: 'Add more files',
  from: 'from',
  filesLeft: 'files left',
  unknownAuthor: 'Unknown Author',
  unknown: 'unknown',
  purchased: 'Purchased',
  favorites: 'Favorites',
  series: 'Series',
  genres: 'Genres',
  genresLinkBack: 'Genres',
  formats: 'Formats',
  formatsLinkBack: 'Formats',
  bookMoreThanFive: 'Books',
  collectionMoreThanFive: 'Collections',
  byAuthorTitle: 'Author books',
  emptyAudioBook: 'Here will be your audio books purchased in the store',

  isbn: 'ISBN',
  annotation: 'Annotation',
  publisher: 'Publisher',


  chaptersTitle: 'Content',
  bookInfoTitle: 'Information about the book',
  bookmarksTitle: 'Bookmark',
  addBookmark: 'Add bookmark',
  add: 'add',
  cancel: 'cancel',
  noteOptional: 'Note (optional)'
}
export default books;