export const policyHeaderText = () => (`
  <h1>Privacy Notice</h1>

  <p>Controller:</p>
  <p>Pocketbook International SA</p>
  <p>Address: Crocicchio Cortogna, 6, 6900 Lugano, Switzerland</p>
  <p><a href="tel:+41919220705">Phone: +41 91 922 07 05</a></p>
  <p><a href="tel:+41919220337">Fax: +41 91 922 03 37</a></p>
  <p>Company ID: CHE-416.098.857</p>
  <p>VAT ID number: CHE-416.098.857 IVA</p>
  <p>Authorized representative: Evgenii Zaitsev, Sales and und Marketing Manager</p>
  <p><a href="mailto:privacy@pocketbook-int.com">privacy@pocketbook-int.com</a></p>
`)
export const policyFooterText = () => (`
<p>25.05.2018</p>
`)
export const section1 = () => (`
<p>(1) This privacy notice aims to give you information on how PocketBook International SA (“PocketBook”) collects and processes your personal data on our online services and all related websites, functions and contents.</p>
  <p>(2) Please read the definitions in Art. 4 of the General Data Protection Regulation (GDPR) regarding the terms used, e.g. „personal data“, or their „processing“.</p>
  <p>(3) The term “user” means all categories of the persons in respective to data processing. These are business partners, customers, interested parties and other visitors of our online services. The terms of use, e.g. “user”, are formulated gender neutrally.</p>
  <p>(4) User’s personal data processed under these online services are as follows:</p>

  <ul>
    <li>identity data include names and addresses of the customer;</li>
    <li>contact data include email address, phone number;</li>
    <li>contractual data include services used or products purchased, payment data;</li>
    <li>usage data include websites of our online services visited, interests to our services and products;</li>
    <li>content data include entries made in the question form; </li>
    <li>technical data include IP addresses, information about devices.</li>
  </ul>

  <p>(5) We process personal data about users for the following purposes:</p>

  <ul>
    <li>provide the online services, their content, and functions;</li>
    <li>deliver services under the contract with you;</li>
    <li>provide Customer Service;</li>
    <li>answer questions from the question forms and communication with the customers;</li>
    <li>marketing; </li>
    <li>make the online services secure.</li>
  </ul>

  <p>(6) We process your personal data by following strictly to the data protection rules. It means data about the user are processed only when permitted by the applicable law. This is particularly the case where data are processed that we may be able to deliver our services under the contract with you (for example, to process the requests and orders) and where it is required for our online services as well or if it is required by law, the consent from the user is available or based on our legitimate interests. Legitimate interest means the analytics, optimization, security, and commercial operation of our online services, as well.</p>
  <p>(7) Please note that the legal basis of the consent is Art. 6 (1) lit. a) and Art. 7 GDPR, the legal basis for data processing to deliver our services and perform our activities under the contract is Art. 6 (1) lit. b) GDPR, legal basis for data processing to perform our obligations under the contract is Art. 6 (1) lit. c) GDPR and legal basis for data processing to protect our legitimate interests is Art. 6 (1) lit. f) GDPR.</p>
`)
export const section2 = () => (`
  <p>(1) According to Art. 32 GDPR and taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, we implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk. Herewith, the data we process shall be protected, in particular from accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to personal data. Security measures include the encrypted transfer of personal data between your browser and our Server.</p>
  <p>(2) Additionally, we have put in place the procedure to deal with any suspected personal data deletion and threat thereto.</p>
`)
export const section3 = () => (`
  <p>(1) As we disclose the data to other persons and entities (authorized processors or third parties), transmit to them or grant them an access to the data within the framework of our processing, we perform it only based on a lawful permission to do it. This will apply e.g. when the data are transferred to the third party, such as shipping service provider according to Art. 6 (1) lit. b) GDPR, as it is necessary for the performance of a contract provided you have given your consent, a legal obligation stipulates it or it is necessary for our legitimate interests (e.g. if using the authorized persons, web hosters, etc.).</p>
  <p>(2) As we share and process the data in a third country (it means outside the European Union or European Economic Area) or it takes place within the framework where we use the services of the third parties or disclose or transmit the data to the third parties, we perform it only when specific terms of Art. 44 ff. GDPR are available. This means that the processing is carried out, for example, in compliance with officially recognized special contractual obligations (so-called ‘standard contractual clauses’).</p>
  <p>(3) We trust the third parties with data processing based on the so called „contract with an authorized processor“ based on Art. 28 GDPR.</p>
`)
export const section4 = () => (`
  <p>(1) Based on our legitimate interests in accordance with Art. 6 (1) lit. f) GDPR we collect the data about each access to the Server where this Service is placed (so called Server log files). These data are technically required to display the relevant website to you and to ensure stability and security. The access data include in particular the name of the website, file, time of the server request, amount of the data transferred, report as to whether the access attempt was successful, browser type/version, operating system used, the previously visited websites and IP address.</p>
  <p>(2) Information about log files is retained for reasons of security (e.g. to investigate the fraud or abuse) for a period of maximum thirty days and then erased. Data to be retained for investigation purposes shall be precluded from erasing until the final investigation.</p>
`)
export const section5 = () => (`
  <p>(1) We process the identity, contact and contractual data to perform our contractual obligations and to deliver the services in accordance with Art. 6 (1) lit b) GDPR. The entry fields marked as mandatory in the contact forms are necessary to make a contract.</p>
  <p>(2) The users have an option to create a user account where they can see their orders or, provided they are subscribed to Pocketbook Cloud, control and use their e-content (in particular, eBooks). While registering the user is being informed what mandatory data are required. When you cancel your user account, your data related to the user account shall be erased provided the further retention is not prescribed by law, in particular to comply with the commercial and taxation legislation in accordance with Art. 6 (1) lit. c) GDPR. It is incumbent on the users to ensure the security of their data until the contract expires when the data are successfully erased. We are entitled to permanently erase all user data retained during the contract period.</p>
  <p>(3) For registering and signing on a new and using our online services we store IP address and the time of the relevant user activity. We store based on our legitimate interests and in order to protect the user from the fraud and other not authorized usage. In general, these data are not transferred to the third parties except this transfer is necessary to pursue our claims or there is a legal obligation in accordance with Art. 6 (1) lit. c) GDPR.</p>
  <p>(4) We process the user data (e.g. the visited websites of our online services, interests to our products) and content data (e.g. entries to the contact forms) for the advertising purposes in a user profile to display for the users, for example the product information based on the services used before.</p>
  <p>(5) Data necessary to deliver the services under the contract are erased when the guarantee and similar obligations prescribed by law expire. If the law prescribes to archive the data, they shall be erased after the archiving obligation expires (legal obligation to retain data expires in 6 years in compliance with commercial law and in 10 years in compliance with tax law). Data under the user account are retained until they are erased.</p>
`)
export const section6 = () => (`
  <p>If you contact us using the contact form or by email, we process the data you provide for the purpose to process your enquiry and to follow-up in accordance with Art. 6 (1) lit. b) GDPR.</p>
`)
export const section7 = () => (`
  <p>You may rate the products in our online shop. Your assessment will be published with your name along with the relevant product. We recommend you to use a pseudonym instead of your real name. The name and email address are mandatory to enter, the rest of information is voluntarily. When you are posting your product assessment, we store your IP address which we delete in thirty days. The storage is necessarily to protect us against liability claim for the cases where illegal contents are published. We need your email address to contact you if some third party claims your assessment as illegal. The legal basis is Art.6 (1) lit. b) and f) GDPR. The assessments are not moderated before publishing. We reserve the right to delete the comments if the third parties claim them as illegal.</p>
`)
export const section8 = () => (`
  <p>(1) Below we explain you about content of our newsletters and the subscribing and sending procedures and your rights to refuse, as well. By subscribing to our newsletter you declare that you agree to receive the newsletter and understand the procedure.</p>
  <p>(2) We will send the newsletter by email or any other e-notice with advertising information only when the receivers give their consent or a law permits to. Our newsletters contain information about our products and services, sales promotions and our company. </p>
  <p>(3) You subscribe to our newsletter by so called Double Opt-In procedure. As soon as you have subscribed to the newsletter, you will receive a confirmation email to confirm your subscription. This confirmation is required to preclude the subscription with somebody else’s address. The subscription to the newsletter shall be recorded to prove the subscription is done according to legal requirements. Hereto, the time of the subscription and confirmation and IP address are stored. The purpose of this process is to prove the subscription and to eventually investigate the abuse of your personal data. This is based on Art. 6 (1) lit. f) GDPR.</p>
  <p>(4) To subscribe to our newsletter you can only indicate your email address. We store your email address to send the newsletters. The legal basis is Art. 6 (1) lit. a) GDPR.</p>
  <p>(5) You have the right to revoke your consent at any time. You find the unsubscribe link at the end of each newsletter. If you has just subscribed to the newsletter and unsubscribed, your personal data will be erased. </p>
`)
export const section9 = () => (`
  <p>(1) For our online services we use the cookie technology. Cookies are small text files which are stored on your device using your browser and which allow certain information to come (here by us). Cookies cannot run any program or bring viruses on your computer. We use cookies to make our websites user-friendly and efficient.</p>
  <p>(2) We use temporary and persistent cookies. Temporary cookies are deleted automatically when you close your browser. They include in particular the session cookies. The latter are so called the session ID which attributes different requests of your browser to the common session. They help to remember your device for your repeat visits to our website. The session cookies are deleted when you log out or close your browser. Persistent cookies are stored on your device between the browser sessions so we remember your preferences and activities within our websites. Persistent cookies are deleted after a specified period (here one day). You can delete cookies in the security options of your browser at any time.</p>
  <p>(3) Furthermore, we use cookies to measure the reach as explained in clause 10 below about usage of Google Analytics.</p>
  <p>(4) You can set your browser configuration according to your preferences and to decline for example the cookies of the third providers or all cookies. However, please note that if you do this, you may not be able to use the full functionality of our online services.</p>
  <p>(5) You may refuse the use of cookies for measuring the coverage and displaying the advertising by selecting the deactivation on the Network Advertising Initiative (<a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a>) and additionally on the US website (<a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices</a>) or European website (<a href="http://www.youronlinechoices.com/uk/your-ad-choices/">http://www.youronlinechoices.com/uk/your-ad-choices/</a>).</p>
`)
export const section10 = () => (`
  <p>(1) Based on our legitimate interests (that is the interests to the analytics, optimization, and commercial operation of our online services in compliance with Art. 6 (1) lit. f) GDPR) we use Google Analytics, a web analytics service from Google LLC („Google“). Google uses cookies. The information generated by the cookies about your use of the online services will be transmitted to and stored by Google on servers in the United States.</p>
  <p>(2) Google uses this information on our behalf to analyze your use of our online services in order to compile reports on website activities and provide to us additional services as to our online services and internet usage.</p>
  <p>(3) We use Google Analytics only with activated IP anonymization. It means that Google will shorten IP addresses of the users within the Member States of the European Union or in other Member States of the Agreement on the European Economic Area. Only in exceptional cases the full IP address is transmitted to a Google server in the USA and shortened there.</p>
  <p>(4) The IP address transmitted by your browser is not merged with other Google data. The users can prevent the storage of cookies by making the proper setting using their browser software. In addition, the users can prevent Google from recording the data related to usage of the data related to our online services generated by the cookies and from processing this data by downloading and installing the browser plugin available at: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
  <p>(5) Third party information: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, <a href="tel:+3531436 1001">Fax: +353 (1) 436 1001</a>. Terms of Service: <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>, General overview on privacy principles: <a href="http://www.google.com/intl/de/analytics/learn/privacy.html">http://www.google.com/intl/de/analytics/learn/privacy.html</a>, as well as Google’s Privacy Policy: <a href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>.</p>
`)
export const section11 = () => (`
  <p>(1) Based on our legitimate interests to analytics, optimization and commercial operation of our online services and for these purposes we use "Facebook Pixel" tool of social network Facebook which is operated by Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 ("Facebook").</p>
  <p>(2) By using Facebook Pixel Facebook allows defining the users of our online services as a target group for displaying the advertising (so called "Facebook Ads"). Accordingly, we use Facebook Pixel to display the Facebook Ads switched on by us only to those Facebook users who are interested in our online services or show some features (e.g. interests in certain topics or products determined based on the visited websites) which we transmit to Facebook (so called „Custom Audiences“). By using Facebook Pixel we want to be sure that our Facebook Ads meet the potential user’s interest and are not annoying. Moreover, with Facebook Pixel we can track the efficiency of Facebook advertising messages for the purposes of statistics and market research by viewing the users go on to click the Facebook advertising (so called „Conversion“).</p>
  <p>(3) Facebook processes the data within the framework of Facebook’s Data Policy. You can find general information about displaying the Facebook’s Ads in Facebook’s Data Policy: <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>. Specific information and details about Facebook Pixel and functions in Help Center on Facebook: <a href="https://www.facebook.com/business/help/651294705016616">https://www.facebook.com/business/help/651294705016616</a>.</p>
  <p>(4) You may refuse from collection of your data with Facebook Pixel and usage thereof to display Facebook Ads. To set up the type of advertising to be displayed by Facebook you can open the page established by Facebook and follow the instructions how to set up the customized advertising: <a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a>. The settings are platform independent, they are picked up by all devices such as desktop computers or mobile devices.</p>
`)
export const section12 = () => (`
  <p>(1) The users are entitled to request and obtain for free information how we process your personal data.</p>
  <p>(2) Furthermore, the users have the right to rectify the wrong data, restrict processing and delete their personal data and where applicable have the right to data portability and in the case where the illegal data have been accepted the right to complain to the relevant supervisory authority.</p>
  <p>(3) The user may also withdraw their consent with effect for the future.</p>
`)
export const section13 = () => (`
  <p>(1) Data we stored may be deleted whenever they are not required any more for the intended purpose and the deletion thereof will not contradict the retention obligations. Unless the user data are not deleted as they are necessary for other lawful purposes, the processing will be limited. That is, the data are blocked and not processed for other purposes. This shall apply for the data of the user which have to be retained to comply with the commercial and taxation legislation.</p>
  <p>(2) In compliance with legislative requirements the retention period shall be 6 years in accordance with § 257 (1) Commercial Code HGB (e.g. for commercial and business papers) and 10 years in accordance with § 147 (1) Taxation Code AO (e.g. for trading books and accounting records).</p>
`)
export const section14 = () => (`
  <p>The users may withdraw their consent to process their personal data in compliance with legal requirements at any time. This withdrawal may be placed in particular because of the processing to the purposes of direct advertising.</p>
`)
export const section15 = () => (`
  <p>The users are asked to see regularly into our Privacy Notice. We amend our Privacy Notice to reflect the changes in our data processing or legislation. We inform you as soon as these changes require cooperation from your side (e.g. your consent) or any other individual notification is required.</p>
`)

export const cookiesTermsText = (`<h1>Cookie and Tracking Technology Notice</h1>
<section>
<p>COOKIE AND TRACKING TECHNOLGIES NOTICE OF POCKETBOOK READERS GMBH
POCKETBOOK INTERNATIONAL SA (collectively, “PocketBook”, “we”, “us” or “our”) maintains
website <a href="https://cloud.pocketbook.digital" target="_blank" rel="noopener noreferrer">https://cloud.pocketbook.digital</a>.
We use a variety of technologies to learn more about how people
use the Services. You can find more about these technologies and how to control them in the
information below. This Cookie and Tracking Technologies Notice is a part of our Privacy Notice.</p>

<p>Cookies & Other Tracking Technologies: How We Use Them</p>

<p>Like most companies, we use cookies and other tracking technologies on our Services
(referred to together from this point forward as “cookies,” unless otherwise stated).</p>

<p>Cookies are small data files that are placed on your computer or mobile device when you visit a website.
Cookies are widely used by online service providers in order to, for example, make their websites or services work,
or to work more efficiently, as well as to provide reporting information.</p>

<p>We may use cookies for a variety of purposes and to enhance your online experience,
for example, by remembering your log-in status.</p>

<p>We use both session and persistent tracking technologies.
Tracking technologies, for example cookies, can either be persistent
(i.e., they remain on your computer until you delete them) or temporary
(i.e., they last only until you close your browser).</p>

<p>We also use first and third-party cookies.
First party cookies are used and controlled by us to provide services for the Services.
Third-party cookies are controlled by third-parties, mostly for analytics purposes.</p>

<p>Our Services use the following categories of cookies:</p>

<p>Strictly Necessary Cookies: These are cookies which are needed to provide
Services and features you have specifically requested, for example
site navigation and access to secure areas of website.
The functionality of this website may be limited whithout cookies.</p>

<p>Analytics and Performance-Related Cookies: We may use cookies to assess the performance of our Services,
including as a part of our analytics practices to improve the
selection of content recommended to you through the Services.</p>

<p>Functionality-Related Cookies: We may use cookies to tell us, for example,
whether you have visited the Services before or if you are a new
visitor and to help us identify the features in which you may have the greatest interest.</p>

<p>Targeting-Related Cookies: We may use cookies to deliver content,
including ads, relevant to your interests on our Services.</p>

<p>By using our Services, you consent to use of cookies and tracking technologies
and the corresponding processing of information. You can withdraw your consent
at any time by deleting placed cookies and disabling cookies in your browser.</p>

<p>Your Cookie Choices & How to Opt-Out</p>

<p>You have the choice whether to accept using cookies and
we explain how you can exercise your rights below.</p>

<p>Most browsers are initially set up to accept HTTP cookies.
The “help” feature of the menu bar on the most browsers will tell you how to
stop accepting new cookies, how to receive notification of
new cookies and how to disable existing cookies. For more
information about HTTP cookies and how to disable them,
you can consult the information at <a href="http://www.allaboutcookies.org/manage-cookies" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/manage-cookies</a></p>

<p>Controlling the HTML5 local storage on your browser depends on
which browser you use. For more information regarding your
specific browser please consult the browser’s website (often in the Help section).</p>

<p>Please note, however, that without HTTP cookies and HTML5 local storage,
you may not be able to take full advantage of all the features of
our Services and some parts of the Services may not function properly.</p>

<p>You can also disable analytics for mobile apps from the settings screen on your mobile device.</p>

<p>Cookies & Tracking Technologies Used on Pocketbook’s Services</p>

<p>The following table sets out details of the some of the individual
cookies/ tracking solutions we use and the purposes for which we use them.</p>

<p>We are not responsible for third-party sites and their privacy practices as
it relates to opt-outs from tracking activities. The following third-parties
that collect information from you on our Services have provided notices that
you may obtain information on their policies and practices, and in some instances,
opt-out of certain of their activities, as follows:</p>

<p>You can find more information about the individual cookies we use and the
purposes for which we use them in the table below:</p>

<div class="CookiesTerms__table">
  <table>
      <thead>
        <tr>
          <th>Cookie</th>
          <th>Service</th>
          <th>Purpose</th>
          <th>Privacy Choices</th>
          <th>Pocketbookreaders.de</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>accounts.google.com</td>
        <td>Google Analytics</td>
        <td>Analytics & Performance Related</td>
        <td><a href="http://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">http://www.google.com/intl/en/policies/privacy/</a></td>
        <td>yes</td>
      </tr>
    </tbody>
  </table>
</div>


<p>Further information </p>

<p>We may amend this COOKIE AND TRACKING TECHNOLOGIES NOTICE from time to time,
for example, because our website or the rules relating to cookies change.
We reserve the right to amend the content of the Cookie Notice and the cookies
included in the lists at any time and without notice. A new Cookie Notice will be
effective upon posting. If you do not agree to the revised Notice, you should
alter your preferences, or consider stopping using the PocketBook pages.
By continuing to access or make use of our services after the changes become
effective, you agree to be bound by the revised Cookie Notice. You can consult
this web page for the latest version.
If you have any further questions and/or comments, please contact us <a href="mailto:privacy@pocketbook-int.com">privacy@pocketbook-int.com</a></p>
</section>
`)


export const termsText = () => (`
 <section>
        <h2>I. Scope of application, amendments to the Terms of Use</h2>
        <p>(1) The following Terms of Use apply to the use of the Cloud Services of Pocketbook International SA, Crocicchio Cortogna, 6, 6900 Lugano, Switzerland (hereinafter “Pocketbook”). Pocketbook makes its Cloud Service available to consumers of electronic content, in particular readers of e-books (hereinafter “Users”). The prerequisite for this is the prior registration of the User in an e-bookshop connected to the Cloud Service and further log in in the Cloud Service on Cloud Service home page, whereby the User and Pocketbook  enter into and abide by these Terms of Use.</p>
        <p>(2) These Terms of Use exclusively regulate the legal relationship between Pocketbook and the User.</p>
        <p>(3) Pocketbook reserves the right to amend these Terms of Use at any time being effective also within the existing of contractual relationships. Pocketbook will inform the User of such changes at least 30 calendar days prior  to such amendments entering into force and effect. If the User does not object within 30 days of receipt of the notification and the use of the services continues even after expiry of the objection period, the amendments shall be deemed to have been agreed as effective from the expiry of the period. In the event of objection, the Terms of Use will be continued under the previous conditions. The User shall be informed separately of the right to object and the legal consequences of maintaining silence in the event of amendments to the Terms of Use.</p>
        <h2>II. Subject matter of the services of Pocketbook</h2>
        <p>(1) Pocketbook provides Users of its Cloud Service with the opportunity to store, retrieve, manage and view electronic content in the cloud.</p>
        <p>(2) The use of Cloud Services is limited to those file types that Pocketbook supports within its Cloud Services. Pocketbook does not grant any further usage possibilities.</p>
        <p>(3) In all other respects, the object and scope of the usage options result from the functionalities provided within the Cloud Services.</p>
        <p>(4) The Terms of Use concluded between Pocketbook and the respective User via log in is free of charge for the User.</p>
        <p>(5) Pocketbook is entitled at any time to change provided services free of charge, to provide new services available free of charge or charged, and to discontinue the provision of services free of charge. Pocketbook will consider the legitimate interests of the Users in each case.</p>
        <h2>III. Registration. Log in.</h2>
        <p>(1) Pocketbook’s Cloud Service shall be available to User upon previous registration with an e-bookshop connected to the Cloud Service and entrance into these Terms of Use between Pocketbook and User. . If the User uses a Pocketbook reader or reading application, Pocketbook’s Cloud Service is one of its functionalities. The log in  in the Cloud Service including entering into these Terms of Use is determined in these cases according to the respective purchase or usage processes via Pocketbook services. If the Cloud Service is accessed via the Pocketbook website, log in is governed by the following paragraphs 2 and 3. Log in is only permitted for persons over 16 years old and unrestricted legal capacity. There is no entitlement to the conclusion of  these Terms of Use.</p>
        <p>(2) The prerequisite for using the Cloud Service via the Pocketbook websites is that the User has already registered and has a user account with an e-bookshop connected to the Cloud Service. If this is not yet the case, the User can select an e-bookshop from the map, available on User’s screen in the process of log in in Cloud Service and via the “You do not yet have an account?” link in the registration form, create a user account in the selected e-bookshop, connected to the Cloud Service and then log in  to use the Cloud Service via the Pocketbook websites in accordance with the following paragraph 3.</p>
        <p>(3) When log in to use the Cloud Service via the Pocketbook websites, the User must complete the registration form provided in the respective e-bookshop, connected to the Cloud Service. To do this, the e-mail address or user name must first be entered for the user account of the User in an e-bookshop connected to the Cloud Service. After clicking the “CONTINUE” button, Pocketbook checks whether the specified e-mail address is assigned by access date to an e-bookshop connected to the Cloud Service. If this is the case, the e-bookshop in question is displayed and the User is requested to log in with the corresponding password. The User can identify and correct any errors in the entry of his data within the respective entry fields. By clicking the “log in” button, the User submits a binding offer to enter into these Terms of Use between Pocketbook and User for the use of Cloud Services. User shall enter into these Terms of Use with Pocketbook in the moment of log in in the Cloud Service on Cloud Service home page. Immediately thereafter, the data provided by the User will be checked by Pocketbook. If Pocketbook considers the information to be correct and there are no other concerns, Pocketbook activates access to its Cloud Services for the User and notifies them of this by e-mail. This e-mail is regarded as Pocketbook’s declaration of acceptance and confirms the conclusion of these Terms of Use between Pocketbook and the User. .. The contract text, i.e. in particular the data provided by the User, is stored on Pocketbook’s internal systems and can be accessed at any time after conclusion of the Terms of Use via the login to Pocketbook’s Cloud Services.</p>
        <p>(4) The contract language is English.</p>
        <h2>IV. The User’s obligations</h2>
        <p>(1) The User’s access data including the password must be kept secret and not made accessible to unauthorized third parties. It is within the User’s responsibility to ensure that access to Pocketbook’s Cloud Services is exclusively provided by the User or persons authorized by them. If it is to be feared that unauthorized third parties have gained or will gain knowledge of the access data, Pocketbook must be informed immediately. In this case, Pocketbook is entitled to block access to the Cloud Services until identify the circumstances of the case.</p>
        <p>(2) The User is liable for any use and/or other activity carried out in respect of their usage data, in accordance with the statutory provisions.</p>
        <p>(3) When using the Cloud Services, the User is obliged to observe the applicable laws and the rights of third parties. In particular, it is the User’s responsibility to ensure that the User’s files and other content have been lawfully acquired. The User is also responsible for ensuring that their files and other content are free of malware, viruses, Trojans, spyware, worms or any other harmful code. Pocketbook does not check the files and contents of the User for legitimate grounds and freedom from harmful code or the like.</p>
        <h2>V. Indemnification of Pocketbook</h2>
        <p>The User is obliged to compensate Pocketbook for any damage resulting from the non-observance of the User’s obligations from the usage relationship regarding the Cloud Services of Pocketbook and in particular those from section IV of these Terms of Use, and furthermore to release Pocketbook against any claims arose from third parties which can be lodged against Pocketbook by such third parties due to the non-observance of such obligations by the User.</p>
        <h2>VI. Pocketbook’s rights to use</h2>
        <p>(1) By storing its electronic content in the Pocketbook Cloud Service, the User grants Pocketbook a free and transferable right of use of the respective content, in particular for storage, reproduction and processing, insofar as this is necessary for the proper provision of the Cloud Services by Pocketbook.</p>
        <p>(2) Pocketbook reserves the right to refuse to save the electronic content of the User and/or to edit, block or remove already saved content without prior notice if the saving of the content by the User or the saved content itself has led to a violation of these Terms of Use or if there are concrete indications that a serious violation will occur. Pocketbook will take into consideration the legitimate interests of the User and choose the most appropriate means to defend against such violation.</p>
        <h2>VII. Term, termination</h2>
        <p>(1) These Terms of Useof Pocketbook’s Cloud Services shall run perpetually. These Terms of Use can be terminated by the User without notice in writing or by e-mail.</p>
        <p>(2) Pocketbook may terminate these Terms of Use for the use of Cloud Services with 14 days’ notice. The right to block remains unaffected.</p>
        <p>(3) The right to termination for a good cause remains unaffected. The compelling reason for Pocketbook exists in particular if:</p>
        <p>
            <ol type="a">
                <li>the User violates its obligations undertaken according to these Terms of Use about the use of the Cloud Services of Pocketbook as well as in particular those from section IV of these Terms of Use and, despite, in particular, a reminder to remedy the situation within the set time limit. A reminder is not required if it does not promise success or if the violation is so serious that Pocketbook cannot reasonably be expected to adhere to these Terms of Use. In the event of a breach of the obligations from section IV (User Obligations), a serious breach must principally be assumed. Furthermore, the seriousness of the violation can also result from the fact that the User has already been warned several times on the fact of violation.</li>
                <li>Pocketbook discontinues to provide its Cloud Services or to render its business activities.</li>
            </ol>
        </p>
        <h2>VIII. Measures to be taken in the event of illegal and/or non-contractual conduct on the User’s part</h2>
        <p>(1) If there are concrete indications that a User violates or has violated these Terms of Use or applicable law, Pocketbook can, without notice, take one or more of the following measures subject to termination:</p>
        <p>
            <ol type="a">
                <li>warn the User, if the violation has occurred once and has not resulted in serious damage to PocketBook;</li>
                <li>delete contents of the User;</li>
                <li>restrict the use of the Cloud Services by the User; or</li>
                <li>temporarily or permanently exclude the User from using the Cloud Services (blocking).</li>
            </ol>
            The same applies in the case of any other justified interest of Pocketbook in one or more of these measures undertaken by Pocketbook.
        </p>
        <p>(2) When choosing a measure, Pocketbook also considers the legitimate interests of the User concerned.</p>
        <p>(3) If a User has been blocked by Pocketbook, there is no right to restore the blocked access to the Cloud Services of Pocketbook. The User may not use Pocketbook's Cloud Services via other Users and may not re-register.</p>
        <h2>IX. Liability of Pocketbook</h2>
        <p>(1) Liability of Pocketbook consists – no matter for what legal reason – only</p>
        <p>
            <ol type="a">
                <li>in the case of intent and gross negligence on the part of Pocketbook, of a legal representative, an executive employee or other vicarious agents;</li>
                <li>in case of any culpable breach of an essential contractual obligation (whereby the term “essential contractual obligation” refers abstractly to such an obligation, the fulfilment of which makes the proper execution of Terms of Use possible in the first place and the observance of which the respective other party may regularly rely on), in case of non-performance  and impossibility of performance.</li>
            </ol>
        </p>
        <p>(2) The liability according to section IX. (1) lit. b) is limited to the amount of the typically foreseeable damage in the case of financial loss and damage to property.</p>
        <p>(3) The aforementioned limitations of liability shall not apply in cases of mandatory statutory liability, in particular with regard to the Product Liability Act, the assumption of a guarantee or culpable injury to life, limb or health.</p>
        <p>(4) Pocketbook is only liable in the case of data loss caused by simple negligence, for the damage that would also have been incurred if the User had made a proper and regular backup of the data and contents appropriate to the importance of the data and contents; this limitation does not apply if the data backup was hindered or impossible for reasons for which Pocketbook is responsible.</p>
        <p>(5) To the extent that the liability of Pocketbook is excluded or limited, this also applies to the personal liability of its employees, representatives and other employees and vicarious agents.</p>
        <h2>X. Final provisions</h2>
        <p>(1) Pocketbook is entitled to transfer its rights and obligations undertaken by these Terms of Use in whole or in part to a third party. In the event of a change of the contractual partner (i.e., if a third party takes the place of Pocketbook in the rights and obligations arising from these Terms of Use), the User is entitled to terminate Terms of Use without notice in writing or by e-mail. An assignment of the rights of the User granted by these Terms of Use to third parties is excluded. In particular, a transfer of the User account is inadmissible.</p>
        <p>(2) Should individual provisions of these Terms of Use be or become invalid in whole or in part, they shall otherwise remain valid. Instead of the invalid provision, the relevant statutory provisions shall apply.</p>
        <br>
        <br>
        <p>Last reviewed : March __, 2020</p>
    </section>
`)

const privacy = {
  termsOfUse: 'Terms of Use',
  cookiesConditions: 'Cookies terms and conditions',
  policyHeaderText: policyHeaderText,
  policyFooterText: policyFooterText,
  privacy01: '1. Purpose and Legal Basis',
  privacy02: '2. Security Measures',
  privacy03: '3. Data Transfer to Third Parties and Providers',
  privacy04: '4. Collection of Access Data and Log Files',
  privacy05: '5. Performance of Services under the Contract',
  privacy06: '6. Contacting',
  privacy07: '7. Product Assessment',
  privacy08: '8. Newsletter',
  privacy09: '9. Cookies',
  privacy10: '10. Google Analytics',
  privacy11: '11. Facebook Marketing Services',
  privacy12: '12. Rights of the Users',
  privacy13: '13. Deletion of Data',
  privacy14: '14. Right to Withdraw',
  privacy15: '15. Changes to our Privacy Notice',
  section1: section1,
  section2: section2,
  section3: section3,
  section4: section4,
  section5: section5,
  section6: section6,
  section7: section7,
  section8: section8,
  section9: section9,
  section10: section10,
  section11: section11,
  section12: section12,
  section13: section13,
  section14: section14,
  section15: section15,

  termsOfUseText: termsText,
  cookiesTermsText: cookiesTermsText,
}
export default privacy;