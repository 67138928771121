import { cond, always, equals, prop, T } from 'ramda'
import { TYPES } from '../actions/user'

export const userReducer = (state = null, action) => cond([
  [equals(TYPES.SET_USER), always(prop('payload', action))],
  [equals(TYPES.REMOVE_USER), always(null)],
  [T, always(state)]
])(prop('type', action))

export const logout = (state = null, {type}) => cond([
  [equals(TYPES.LOGOUT), always(null)],
  [T, always(state)]
])(type)