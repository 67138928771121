import { put, takeEvery, call } from 'redux-saga/effects'
import * as actions from '../actions/readRate'
import * as API from '../API/partner'

const alias = 'readrate'
const TYPES = actions.TYPES

function* getLoginUrl () {
  try {
    const data = yield call(() => API.getLoginUrl(alias))
    yield put(actions.setLoginUrl(data))
  } catch (error) {
    console.log('Error while getting loginUrl', error)
  }
}

function* getLoginStatus () {
  try {
    const data = yield call(() => API.isLoggedToPartner(alias))
    yield put(actions.setLoginStatus(data))
  } catch (error) {
    console.log('Error while getting loginStatus', error)
  }
}

function* getUserInfo () {
  try {
    const data = yield call(() => API.getPartnerUserInfo(alias))
    yield put(actions.setUserInfo(data))
  } catch (error) {
    console.log('Error while getting partnerUserInfo', error)
  }
}

function* getNews () {
  try {
    const data = yield call(() => API.getPartnerNews(alias))
    yield put(actions.setNews(data))
  } catch (error) {
    console.log('Error while getting partnerNews', error)
  }
}

function* getDiscussion () {
  try {
    const data = yield call(() => API.getPartnerDiscussion(alias))
    yield put(actions.setDiscussion(data))
  } catch (error) {
    console.log('Error while getting partnerDiscussion', error)
  }
}

function* logoutFromPartner () {
  try {
    yield call(() => API.logoutFromPartner(alias))
    yield put(actions.setLoginStatus(false))
  } catch (error) {
    console.log('Error while logging out from partner', error)
  }
}

export const readRateSaga = [
  takeEvery(TYPES.GET_LOGIN_URL, getLoginUrl),
  takeEvery(TYPES.GET_NEWS, getNews),
  takeEvery(TYPES.GET_DISCUSSION, getDiscussion),
  takeEvery(TYPES.GET_LOGIN_STATUS, getLoginStatus),
  takeEvery(TYPES.GET_USER_INFO, getUserInfo),
  takeEvery(TYPES.PARTNER_LOGOUT, logoutFromPartner)
]
