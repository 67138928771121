import { createSelector } from 'reselect'
import { path, head, map, uniq, flatten, sort, contains, propOr, uniqBy, prop, __, pipe, length, filter, propEq, reverse, cond, T, always, equals, sortBy, toLower } from 'ramda'
import moment from 'moment'

const getBooks = path(['books', 'byId'])

const getBookById = (books) => prop(__, books)
const mapIdsToBooks = (ids, books) => map(getBookById(books), ids)

const createBooksSelector = (selector) => createSelector(
  selector, getBooks, mapIdsToBooks
)

const allIds = path(['books', 'allIds'])
const getAllBooks = createBooksSelector(allIds)

const bestsellersIds = path(['books', 'bestsellersIds'])
const getBestsellers = createBooksSelector(bestsellersIds)

const recommendedIds = path(['books', 'recommendedIds'])
const getRecommended = createBooksSelector(recommendedIds)

const newBooks = path(['books', 'newIds'])
const getNew = createBooksSelector(newBooks)

const userBooks = path(['books', 'userIds'])
const getUserBooks = createBooksSelector(userBooks)

const lastRead = path(['books', 'lastReadId'])
const getLastRead = createBooksSelector(lastRead)

const bookOfTheWeek = path(['books', 'bookOfTheWeekId'])
const getBookOfTheWeek = createBooksSelector(bookOfTheWeek)

const sortType = path(['books', 'sortType'])
const collectionsSortType = path(['books', 'collectionsSortType'])

const genres = path(['books', 'genres'])
const language = path(['userData', 'language'])

const sortBooksByDate = (books) => reverse(sortBy(prop('updatedAt'), books))
const sortBooksByCreateDate = (books) => reverse(sortBy(prop('createdAt'), books))
const sortBooksByTitle = sortBy(book => pipe(prop('title'), toLower)(book))
const sortBooksByAuthor = sort((a, b) => a.author.localeCompare(b.author))
const sortBooksByProgress = (books) => reverse(sortBy(prop('progress'), books))
const sortBooksByFormat = (books) => sortBy(prop('format'), books)
const sortBooksBySize = (books) => reverse(sortBy(prop('size'), books))

const getSortedUserBooks = createSelector(
  getUserBooks, sortType,
  (userBooks, sortType) => cond([
    [equals('date'), () => sortBooksByCreateDate(userBooks)],
    [equals('update_date'), () => sortBooksByDate(userBooks)],
    [equals('title'), () => sortBooksByTitle(userBooks)],
    [equals('author'), () => sortBooksByAuthor(userBooks)],
    [equals('progress'), () => sortBooksByProgress(userBooks)],
    [equals('format'), () => sortBooksByFormat(userBooks)],
    [equals('size'), () => sortBooksBySize(userBooks)],
    [T, () => sortBooksByCreateDate(userBooks)]
  ])(sortType)
)

const getMyBooks = createSelector(
  getUserBooks, sortType,
  (userBooks, sortType) => cond([
    [T, () => sortBooksByCreateDate(userBooks)]
  ])(sortType)
)

const countBooksInState = createSelector(
  userBooks,
  length
)

const getAuthors = createSelector(
  getUserBooks,
  (books) => pipe(
    sortBy(pipe(prop('author'), head, toLower)),
    map(prop('author')),
    map(author => ({
      name: author,
      books: pipe(filter(propEq('author', author)), length)(books)
    })),
    uniqBy(prop('name'))
  )(books)
)

const getGenresCollections = (propName, books, genres, language) => pipe(
  map(propOr([], propName)),
  flatten,
  uniq,
  (collections) => {
    let uniq = []
    for (let i=0;i<collections.length;i++) {
      let genre = collections[i]
      let name = genres?.[genre]?.[language] || genre

      if (uniq[name]) {
        uniq[name].codes.push(genre)
      } else {
        uniq[name] = {name, codes: [genre]}
      }
    }
    let result = []
    for (let key in uniq) {
      result.push(uniq[key]);
    }
    return result
  },
  sortByProp(
    map(collection => {
        let filteredBooks = []
        for (let i=0;i<books.length;i++) {
            let book = books[i]
            let g = book.genres
            for (let i=0;i<g.length;i++) {
                let genre = g[i]
                if (collection.codes.indexOf(genre) > -1) {
                    filteredBooks.push(book)
                }
            }
        }
        return {
            name: collection.name,
            books: filteredBooks
        }
    }),
    'title'
  )
)(books)

const getCollectionsByProp = (propName, books, sortType) => pipe(
  map(propOr([], propName)),
  flatten,
  uniq,
  sortByProp(
    map(collection => ({
      name: collection,
      books: filter(
        pipe(propOr([], propName), contains(collection))
      )(books)
    })),
    sortType
  )
)(books)

const getFormatCollections = (propName, books) => pipe(
  map(propOr([], propName)),
  flatten,
  uniq,
  sortByProp(
    map(collection => ({
      name: collection,
      books: filter(
        pipe(propOr([], propName), equals(collection))
      )(books)
    })),
    'title'
  )
)(books)

const collections = path(['books', 'collections'])

const getCollections = createSelector(
  getUserBooks,
  collections,
  (books, collections) => pipe(
    map(({id, name}) => ({
      name: name,
      id: id,
      books: filter(
        pipe(propOr([], 'collections'), contains(name))
      )(books)
    }))
  )(collections)
)

const sortCollectionsByDate = (collections) => collections.sort((collectionA, collectionB) => {
  const ADate = +moment(collectionA.update_at)
  const BDate = +moment(collectionB.update_at)
  return BDate === ADate ? 0 : BDate > ADate ? 1 : 0
})
const sortCollectionsByTitle = sortBy(collection => pipe(prop('name'), toLower)(collection))

const getSortedCollections = createSelector(
  getCollections, collectionsSortType,
  (collections, collectionsSortType) => cond([
    [equals('date'), () => sortCollectionsByDate(collections)],
    [equals('title'), () => sortCollectionsByTitle(collections)],
    [T, always(collections)]
  ])(collectionsSortType)
)

const sortByPropByTitle = (formats) => pipe(
  formats,
  sortBy(format => pipe(prop('name'), toLower)(format))
)

const getLatestDate = (xs) => {
  if (xs.length) {
    return xs.reduce((m, v, i) => (v.updatedAt > m.updatedAt) && i ? v : m).updatedAt;
  }
}

const sortByPropByDate = (formats) => pipe(
  formats,
  sort((formatA, formatB) => {
    let ADate = +moment(getLatestDate(formatA.books))
    let BDate = +moment(getLatestDate(formatB.books))
    ADate = ADate ? ADate : 0
    BDate = BDate ? BDate : 0
    return BDate === ADate ? 0 : BDate > ADate ? 1 : 0
  }),
  reverse
)

const sortByProp = (formats, collectionsSortType) => cond([
  [equals('date'), () => sortByPropByDate(formats)],
  [equals('title'), () => sortByPropByTitle(formats)],
  [T, always(formats)]
])(collectionsSortType)

const getFormats = createSelector(
  getUserBooks,
  (books) => getFormatCollections('format', books)
)

const getSeries = createSelector(
  getUserBooks, collectionsSortType,
  (books, collectionsSortType) => getCollectionsByProp('series', books, collectionsSortType)
)

const getGenres = createSelector(
  getUserBooks, genres, language,
  (books, genres, language) => getGenresCollections('genres', books, genres, language )
)

export const selectors = {
  getAllBooks,
  getBestsellers,
  getRecommended,
  getNew,
  getUserBooks,
  getLastRead,
  getBookOfTheWeek,
  countBooksInState,
  getSortedUserBooks,
  getAuthors,
  getCollections,
  getSeries,
  getFormats,
  getGenres,
  getSortedCollections,
  getMyBooks
}