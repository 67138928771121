import adobe1 from '../../assets/images/adobe1.jpg'
import adobe2 from '../../assets/images/adone2.jpg'
import adobe3 from '../../assets/images/adobe3.jpg'
import adobe4 from '../../assets/images/adobe4.jpg'

export const section1 = () => (`
 <h2>Як увійти</h2>
            <p>Ви можете увійти до PocketBook Cloud через веб-сайт, через додаток PocketBook для читання з мобільних пристроїв (для Android та IOS) або з електронної книги PocketBook.</p>
            <p><span>Web:</span> Для входу на сайт, введіть логін та пароль свого облікового запису у партнерському книжному магазині.
Також Хмара підтримує авторизацію через Facebook або Google. Якщо ви зареєстровані в книжному магазині, використовуючи Facebook або Google, ви можете увійти в Хмару таким же способом.</p>
            <h2>Вхід до Pocketbook Cloud на інших пристроях</h2>
            <p>Для того, щоб увійти з <span>електронної книги PocketBook</span> перейдіть до Налаштувань > Облікові записи та синхронізація > PocketBook Хмара та введіть адресу електронної пошти та пароль.</p>
            <p>Для того, щоб увійти з вашого <span>Android пристрою</span> увійдіть в додаток для читання PocketBook, перейдіть в бокове меню і виберіть розділ PocketBook Хмара. Введіть адресу електронної пошти та пароль і натисніть кнопку Вхід.</p>
            <p>Для того, щоб увійти з <span>пристрою IOS</span> перейдіть в додаток для читання PocketBook, виберіть Налаштування > PocketBook Хмара. Введіть адресу електронної пошти та пароль і натисніть кнопку Вхід.</p>
            <h2>Як зареєструватися</h2>
            <p>Якщо у вас немає облікового запису, натисніть посилання "Немає облікового запису". Відобразиться список партнерських книжкових магазинів. Щоб продовжити роботу, будь ласка, виберіть один із запропонованих варіантів, або скористайтеся пошуком. Ви будете перенаправлені на сайт книжкового магазину партнера. Перейдіть до форми реєстрації та створіть новий обліковий запис, дотримуючись інструкцій. Після цього поверніться на сайт</p>
            <p><span>PocketBook E-ink reader:</span> Під час початкового налаштування пристрою Вам буде запропоновано увійти до PocketBook Хмари. Якщо ви пропустили цей крок, пропозиція увійти до облікового запису PocketBook Хмари з'явиться на панелі сповіщень. Щоб увійти до системи, натисніть на це повідомлення або перейдіть до Налаштування > Облікові записи та синхронізація > PocketBook Хмара. Перейдіть за посиланням Немає облікового запису. У браузері відкриється список партнерів книжкових магазинів. Щоб продовжити роботу, будь ласка, виберіть один із запропонованих варіантів, клацнувши на його назву. Ви будете перенаправлені на сайт книжкового магазину партнера. Перейдіть до форми реєстрації та створіть новий обліковий запис, дотримуючись інструкцій. Після цього, будь ласка, поверніться в розділ Налаштування > Облікові записи та синхронізація > PocketBook Хмара, введіть особисту інформацію, яку ви використовували для створення облікового запису книжкового магазину партнера та натисніть кнопку Зареєструватися.</p>
            <p><span>Android пристрій:</span> зайдіть в додаток для читання PocketBook, перейдіть в бокове меню і виберіть розділ PocketBook Хмара. Перейдіть за посиланням Немає облікового запису. У браузері відкриється список книжкових магазинів партнерів. Щоб продовжити роботу, будь ласка, виберіть один із запропонованих варіантів, клацнувши на його назву. Ви будете перенаправлені на сайт книжкового магазину партнера. Перейдіть до форми реєстрації та створіть новий обліковий запис, дотримуючись інструкцій. Після цього, будь ласка, поверніться до програми для читання PocketBook, виберіть PocketBook Хмара в розділі Налаштування на сторінці входу в систему введіть особисту інформацію, яку ви використовували для створення облікового запису книжкового магазину партнера та натисніть кнопку Зареєструватися. <span>Завантажте програму PocketBook Reader для Android: <a href="https://play.google.com/store/apps/details?id=com.obreey.reader">https://play.google.com/store/apps/details?id=com.obreey.reader</a></span></p>
            <p><span>IOS:</span> Для входу в систему з пристрою IOS зайдіть в додаток для читання PocketBook, виберіть Налаштування > PocketBook Хмара. Перейдіть за посиланням Немає облікового запису. У браузері відкриється список книжкових магазинів партнерів. Щоб продовжити роботу, будь ласка, виберіть один із запропонованих варіантів, клацнувши на його назву. Ви будете перенаправлені на сайт книжкового магазину партнера. Перейдіть до форми реєстрації та створіть новий обліковий запис, дотримуючись інструкцій. Після цього, будь ласка, поверніться до програми для читання PocketBook, виберіть PocketBook Хмара в розділі Налаштування на сторінці входу в систему введіть особисту інформацію, яку ви використовували для створення облікового запису книжкового магазину партнера та натисніть кнопку Зареєструватися. <span>Завантажте програму PocketBook Reader для iOS: <a href="https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?l=ru&mt=8">https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?l=ru&mt=8</a></span></p>
            <h2>Забув пароль</h2>
            <p>Відновити пароль можна на сайті магазину. Вхід в Хмару відбувається з логіном та паролем магазна.</p>
            <h2>Як змінити пароль</h2>
            <p>Змінити пароль можна на сайті магазину. Вхід в Хмару відбувається з логіном та паролем магазна.</p>
            <h2>Додати / Реєстрація Adobe ID</h2>
            <p>Для того, щоб додати Adobe ID, будь ласка, перейдіть до Налаштування / AdobeID та натисніть кнопку Додати Adobe ID. Виберіть зі списку свій Vendor та введіть логін та пароль.
Маючи кілька ідентифікаторів облікових записів Adobe, ви можете вибрати один з них як основний ID облікового запису Adobe ("Використовувати за замовчуванням"). Він буде використовуватися для завантаження електронних книг у PocketBook хмара.
Щоб переглянути пароль основного облікового запису Adobe ID (Vendor: Pocketbook), Ви можете натиснути на значок ока</p>
            <h2>Реєстрація Adobe ID:</h2>
            <p>Перейдіть на сторінку Синхронізація облікового запису Adobe</p>
            <img src="`+adobe1+`" />
            <p>Виберіть Adobe ID і натисніть Зареєструватися</p>
            <img src="`+adobe2+`" />
            <p>Виберіть PocketBook Vendor зі списку: зверніть увагу, що ви можете використовувати тільки один eBook Vendor для декількох Vendor ID's; Вкажіть другий vendor Account ID та пароль</p>
            <img src="`+adobe3+`" />
            <p>Щоб додати кілька облікових записів Adobe, будь ласка, зробіть наступне Натисніть кнопку Синхронізація облікових записів. Тепер ви можете легко передавати контент між декількома пристроями Vendor.</p>
            <img src="`+adobe4+`" />
`)

export const section2 = () => (`
 <h2>Як відбувається синхронізація між моїми пристроями</h2>
            <p>Ви можете читати книги на різних пристроях і бути впевненим, що всі ваші нотатки, замітки та прогрес читання буде збережено. Ви матимете доступ до книг з будь-якого пристрою, з якого ви увійшли до PocketBook Хмари.</p>
            <p><span>На веб-сайті:</span> Авто синхронізація списку книг та читання позиції відбувається в момент входу в PocketBook Хмара та закриття книги.</p>
            <p><span>На електроній книзі:</span> Авто синхронізація списку книг та читання позиції відбувається в момент входу в PocketBook Хмара та закриття книги. Для примусової синхронізації натисніть піктограму Синхронізація на панелі сповіщень.</p>
            <p><span>На Android пристроях:</span> Авто синхронізація списку книг та читання позиції відбувається в момент входу в PocketBook Хмара та закриття книги. Для примусової синхронізації натисніть значок Синхронізація у Бібліотеці.</p>
            <h2>Як призупинити та відновити синхронізацію</h2>
            <p>Немає можливості перервати синхронізацію, але зверніть увагу на те, що синхронізацію буде вимкнено, якщо немає підключення до Інтернету.</p>
            <h2>Чи можу я отримати доступ до моїх книг у Хмарі без підключення до Інтернету</h2>
            <p>Якщо немає підключення до Інтернету на вашому пристрої, у вас буде доступ тільки до книг з вашої останньої синхронізації.</p>
            <h2>Помилки синхронізації</h2>
            <p>Якщо під час синхронізації виникла помилка, будь ласка, перевірте підключення до Інтернету. Якщо підключення до Інтернету встановлено коректно, спробуйте синхронізувати ваші книги заново, оскільки можливо був збій на сервері.</p>
`)
export const section3 = () => (`
 <h2>Завантаження нових книг</h2>
            <p>Ви можете завантажувати нові книги в PocketBook Хмару через веб-сайт, з електронної книги PocketBook або з Android / IOS додатки для читання PocketBook. Після синхронізації всі нові книги будуть автоматично збережені у Вашому кабінеті в PocketBook Хмарі.</p>
            <h2>Які формати підтримує Pocketbook хмара</h2>
            <p>PocketBook Хмара підтримує всі формати, наявні на електронній книзі PocketBook та у додатку для читання на мобільних пристроях PocketBook Reader (PDF (Adobe DRM), EPUB (Adobe DRM), DJVU, TXT, FB2, FB2.ZIP, CHM, HTML (основний) , CBZ, CBR, СBT, RTF)</p>
            <h2>Чи буде книга зберігатися на пристрої після синхронізації</h2>
            <p>Так, якщо книга була завантажена з PocketBook Хмари на пристрій.</p>
            <h2>Як видалити файли</h2>
            <p>Для того, щоб видалити книгу за допомогою веб-сайту PocketBook Хмара, виберіть пункт "Видалити" з меню книги. (Для виклику меню, що випадає - натисніть іконку у вигляді трьох точок).
Для видалення кількох книг одночасно можна виділити ці книги за допомогою Ctrl+ліва кнопка мишки. Після цього натиснути на іконку видалення у верхньому рядку меню.
Щоб видалити книгу з вашого облікового запису, використовуючи електронну книгу PocketBook, або програму для читання на мобільних пристроях Android або IOS, виділіть книгу зі списку бібліотеки та відкрийте контекстне меню. Вам буде запропоновано два варіанти - Видалити (з пам'яті пристрою) та Видалити з Хмари</p>
            <h2>Чи можна відновити файли</h2>
            <p>Якщо файл був збережений у PocketBook Хмарі і був видалений лише з пам'яті пристрою, він знову з'явиться після наступної синхронізації Якщо файл був видалений з Хмари, то немає можливості його відновити.</p>
            <h2>Як завантажити книги з хмари</h2>
            <p>Для того, щоб завантажити книгу з PocketBook Хмари на ваш комп'ютер, виберіть пункт "Завантажити" з меню книги. (Для виклику меню, що випадає, натисніть іконку у вигляді трьох точок).</p>
            <p>Для завантаження кількох книг одночасно, можна виділити ці книги за допомогою Ctrl+ліва кнопка мишки. Після цього натисніть на іконку завантаження у верхньому рядку меню.</p>
            <p>Щоб завантажити книгу в пам'ять вашої електронної книги, або на мобільний пристрій Android або IOS, просто в Хмарі натисніть на книгу зі списку книг у бібліотеці і процес завантаження почнеться автоматично.</p>
            <h2>Як читати книги з браузера</h2>
            <p>На даний момент можна прочитати з браузера книги у форматі EPUB, FB2 та PDF.</p>
            <p>Щоб відкрити книгу в браузері, натисніть на обкладинку книги (у режимі "плитка") або на рядок з книгою (в режимі "список"). У новому вікні відкриється книга у веб-рідері. Також можна відкрити книгу з пункту "Інформація про книгу" меню, що випадає.</p>
            <p>Читання із браузера також є предметом синхронізації, так що ваші позиції читання, закладки та нотатки будуть збережені. Щоб вийти з книги, натисніть значок "Вихід" у лівому куті вікна програми.</p>                
`)

const faq = {
  account: 'Обліковий запис',
  sync: 'Синхронізація',
  storage: 'Файлове сховище',
  section1: section1,
  section2: section2,
  section3: section3
}
export default faq;