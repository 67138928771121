const books = {
  uploadBook: 'Neues Buch hochladen',
  sortBy: 'Sortieren nach',
  recent: 'Datum',
  byRecent: 'Nach Datum',
  byRecentTitle: 'Zuletzt gelesen',
  byTitle: 'Nach Titel',
  byAuthor: 'Nach Autor',
  byProgress: 'Nach Lesefortschritt',
  title: 'Titel',
  titleInList: 'Titel',
  author: 'Autor',
  authorInList: 'Autor',
  readingProgress: 'Lesefortschritt',
  progress: 'Lesefortschritt',
  lastRead: 'Zuletzt gelesen',
  dateAdded: 'Hinzugefügt',
  format: 'Format',
  size: 'Größe',
  collectionNotEmpty: 'Sammlungsname darf nicht leer sein.',
  collectionNameTaken: 'Dieser Name wird bereits verwendet.',
  book: 'buch',
  books: 'bücher',
  e_book: 'E-book',
  e_books: 'E-Books',
  selected: 'ausgewählt',
  selected_more_than_one: 'ausgewählt',
  delete: 'Löschen',
  backTo: 'Zurück zu',
  noBooksInCollection: 'Noch keine Bücher in der Sammlung. ',
  addBooksToCatalogue: 'Öffnen Sie den Katalog, um neue Bücher hinzuzufügen.',
  allBooks: 'Alle Bücher',
  sureDeleteBook: 'Möchten Sie die ausgewählten Bücher wirklich löschen?',
  sureDelete: 'Möchten Sie Folgendes wirklich löschen: ',
  listen: 'Zuhören',
  read: 'Lesen',
  details: 'Details',
  addToCollection: 'Zur Sammlung hinzufügen',
  addToCollectionInDropdown: 'Zur Sammlung hinzufügen',
  addBooks: 'Bücher hinzufügen',
  newCollection: 'Neue Sammlung',
  removeFavorites: 'Aus Favoriten entfernen',
  addFavorites: 'Zu Favoriten hinzufügen',
  addFavoritesInDropdown: 'Zu Favoriten hinzufügen',
  download: 'Herunterladen',
  removeFromCollection: 'Aus Sammlung entfernen',
  bookFormat: 'Format:',
  bookSize: 'Größe:',
  audiobook: 'Hörbuch',
  audiobooks: 'Hörbücher',
  audioBookTitle: 'Buchtitel: ',
  audioBookYear: 'Jahr: ',
  audioBookAuthor: 'Autor: ',
  audioBookSize: 'Größe: ',
  audioBookFavorite: 'In Favoriten: ',
  audioBookPurchased: 'Gekauft: ',
  audioBookGenres: 'Genre: ',
  searchAuthors: 'Nach Autor suchen',
  anonymousAuthor: 'Anonymer Autor',
  authors: 'Autoren',
  addBook: 'Buch hinzufügen ',
  by: ' nach ',
  toCollection: ' zur Sammlung',
  chooseCollection: 'Sammlung auswählen:',
  confirm: 'Bestätigen',
  collection: 'Sammlung',
  collections: 'Sammlungen',
  collectionsLinkBack: 'Sammlungen',
  collectionName: 'Sammlungsname',
  confirmNewCollection: 'Neue Sammlung bestätigen',
  createNewCollection: 'Neue Sammlung erstellen' ,
  uploading: 'Laufender Upload: ',
  completed: 'abgeschlossen',
  seeDetails: 'siehe Details',
  getBook: 'Buch holen',
  readRateLogo: 'ReadRate-Logo',
  seeAllBooks: 'Alle Bücher anzeigen',
  newThisWeek: 'Neu in dieser Woche',
  uploadFailed: 'Upload fehlgeschlagen',
  uploadSuccess: 'Upload erfolgreich',
  uplodeToPBCloud: 'Upload in PBCloud',
  done: 'Fertig',
  addMoreFiles: 'Mehr Dateien hinzufügen',
  from: 'von',
  filesLeft: 'dateien übrig',
  unknownAuthor: 'Unbekannter Autor',
  unknown: 'Unbekanntes Format',
  purchased: 'Gekauft',
  favorites: 'Favoriten',
  series: 'Buchreihen',
  genres: 'Genre',
  genresLinkBack: 'Genre',
  formats: 'Formate',
  formatsLinkBack: 'Formate',
  bookMoreThanFive: 'bücher',
  collectionMoreThanFive: 'sammlungen',
  byAuthorTitle: 'Autor bücher',
  emptyAudioBook: 'Hier finden Sie Ihre im Onlineshop erworbenen Hörbücher.',
  isbn: 'ISBN',
  annotation: 'Anmerkung',
  publisher: 'Herausgeber',

  chaptersTitle: 'Inhalt',
  bookInfoTitle: 'Informationen zum Buch',
  bookmarksTitle: 'Lesezeichen',
  addBookmark: 'Lesezeichen hinzufügen',
  add: 'hinzufügen',
  cancel: 'stornieren',
  noteOptional: 'Note (optional)'
}
export default books;