import { TYPES } from '../actions/seo'
import {always, cond, equals, T} from "ramda";
import {combineReducers} from "redux";

const setSeoMeta = (state = {}, {type, payload}) => cond([
  [equals(TYPES.SET_SEO_META), always(payload)],
  [T, always(state)]
])(type)

export const seoReducer = combineReducers({
  seoMeta: setSeoMeta
})