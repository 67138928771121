import login from './login'
import dashboard from './dashboard'
import books from './books'
import search from './search'
import settings from './settings'
import privacy from './privacy'
import account from './account'
import sidebar from './sidebar'
import notifications from './notifications'
import contactUs from './contactUs'
import faq from './faq'
import findStore from './findStore'
import seo from './seo'
import forDevelopers from "./forDevelopers";
import cookie from "./cookie";

const index = {
  messages: {
    cookie,
    login,
    dashboard,
    books,
    search,
    settings,
    privacy,
    account,
    sidebar,
    notifications,
    contactUs,
    faq,
    findStore,
    seo,
    forDevelopers
  }
}
export default index;