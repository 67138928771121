const dashboard = {
  dashboardTitle: 'Dashboard',
  lastRead: 'Last read',
  continueReading: 'Continue reading',
  continueListening: 'Continue listening',
  newBookMonth: 'New book this month',
  booksInCloud: 'Books in your cloud',
  readingNow: 'Reading now',
  markedRead: 'Marked as read',
  yourCloudStat: "Your cloud's statistics",
  myBooks: 'My books',
  mostDiscussed: 'The most discussed',
  news: 'News',
  shareYourReading: 'Share your reading progress with friends and discuss favourite books',
  loginToReadRate: 'Login to your ReadRate account',
  login: 'Login',
  more: 'More',
  justAboutToStart: 'Just about to start reading now',
  haveNotMarked: 'Haven\'t marked books as read'
}
export default dashboard;
