const account = {
  name: 'Name',
  surname: 'Surname',
  loginEmail: 'Login e-mail',
  bookOfWeek: 'Book of the week',
  personRecom: 'Recommendations',
  bestsellers: 'Bestsellers',
  newThisWeek: 'New',
  readReateNews: 'ReadRate news',
  readRateDisc: 'ReadRate discussion',
  nameNotEmpty: 'Name cannot be empty',
  nameIncorect: 'Name is incorrect',
  emailNotEmpty:'Email cannot be empty',
  emailIncorect: 'Email is incorrect',
  surnameNotEmpty: 'Surname cannot be empty',
  surnameIncorect: 'Surname is incorrect',
  resetGoToStore: 'To reset password go to your store:',
  resetPassword: 'Reset Password',
  dashboardWidgets: 'Dashboard widgets:',
  saveChanges: 'Save changes',
  changeLanguage: 'Change language',
  en: 'English',
  ru: 'Russian',
  de: 'German',
  zh: 'Chinese',
  uk: 'Ukrainian',
  findStore: 'Don\'t have an account?'
}
export default account;