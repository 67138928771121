import { combineReducers } from 'redux'
import { cond, always, equals, prop, T, merge } from 'ramda'
import {TYPES} from '../actions/user'

const userData = (state = null, action) => cond([
  [equals(TYPES.USER_DATA), always(prop('payload', action))],
  [equals(TYPES.UPDATE_USER_DATA), () => merge(state, prop('payload', action))],
  [equals(TYPES.LOGOUT), always({language: null})],
  [T, always(state)]
])(prop('type', action))

const onFetchUserData = (state = false, {type, payload}) => cond([
  [equals(TYPES.ON_FETCH_USER_DATA), always(payload)],
  [T, always(state)]
])(type)

const setLanguage = (state = 'en', {type, payload}) => cond([
  [equals(TYPES.SET_LANGUAGE), always(payload)],
  [T, always(state)]
])(type)

const changeLocationInSettings = (state = false, {type, payload}) => cond([
  [equals(TYPES.CHANGE_LOCATION_IN_SETTINGS), always(payload)],
  [T, always(state)]
])(type)

export const setStep = (state = 'username', {type, payload}) => cond([
  [equals(TYPES.SET_STEP), always(payload)],
  [equals(TYPES.REMOVE_USER), always('username')],
  [T, always(state)]
])(type)

export const setProviders = (state = [], {type, payload}) => cond([
  [equals(TYPES.SET_PROVIDERS), always(payload)],
  [equals(TYPES.REMOVE_USER), always([])],
  [T, always(state)]
])(type)

export const setSelectedProvider = (state = null, {type, payload}) => cond([
  [equals(TYPES.SET_SELECTED_PROVIDER), always(payload)],
  [equals(TYPES.REMOVE_USER), always(null)],
  [T, always(state)]
])(type)

export const setUserName = (state = '', {type, payload}) => cond([
  [equals(TYPES.SET_USERNAME), always(payload)],
  [equals(TYPES.REMOVE_USER), always('')],
  [T, always(state)]
])(type)

export const setLoggedWithSN = (state = '', {type, payload}) => cond([
  [equals(TYPES.SET_LOGGED_WITH_SN), always(payload)],
  [equals(TYPES.REMOVE_USER), always('')],
  [T, always(state)]
])(type)

export const setSearchParam = (state = '', {type, payload}) => cond([
  [equals(TYPES.SET_SEARCH_PARAM), always(payload)],
  [equals(TYPES.REMOVE_USER), always('')],
  [T, always(state)]
])(type)

export const setIsLogin = (state = false, {type, payload}) => cond([
  [equals(TYPES.SET_IS_LOGIN), always(payload)],
  [T, always(state)]
])(type)

export const toggleLoadingStatus = (state = false, {type, payload}) => cond([
  [equals(TYPES.TOGGLE_LOADING_STATUS), always(payload)],
  [equals(TYPES.REMOVE_USER), always(false)],
  [T, always(state)]
])(type)

export const setInternalPassword = (state = null, {type, payload}) => cond([
  [equals(TYPES.SET_INTERNAL_PASSWORD), always(payload)],
  [T, always(state)]
])(type)

export const setInternalPasswordData = (state = null, {type, payload}) => cond([
  [equals(TYPES.SET_INTERNAL_PASSWORD_DATA), always(payload)],
  [T, always(state)]
])(type)

export const userDataReducer = combineReducers({
  data: userData,
  language: setLanguage,
  onFetchUserData,
  isChanged: changeLocationInSettings,
  step: setStep,
  providers: setProviders,
  selectedProvider: setSelectedProvider,
  username: setUserName,
  loggedWithSN: setLoggedWithSN,
  searchParam: setSearchParam,
  isLogin: setIsLogin,
  loading: toggleLoadingStatus,
  internalPassword: setInternalPassword,
  internalPasswordData: setInternalPasswordData
})