import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { always } from 'ramda'
import {checkGetParams, env} from '../../utilities'

import './App.css'

import { CheckRoute } from './components/CheckRoute/CheckRoute'
import * as createHost from "cross-domain-storage/host";
import {READER_URL} from "../../constants";

const routerBasename = env({
  dev: always('/'),
  prod: always(process.env.PUBLIC_URL)
})

export class View extends React.Component {
  componentDidMount () {
    createHost([
      {
        origin: READER_URL,
        allowedMethods: ['get']
      }
    ])
  }

  render () {
    return (
      <div className="App">
        {checkGetParams() ?
          <Router basename={routerBasename}>
            <Route path="**" component={CheckRoute} />
          </Router> :
          ''
        }
      </div>
    )
  }
}

export const App = View
