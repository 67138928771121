import {compose, lifecycle, setDisplayName, withStateHandlers} from "recompose";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import React from "react";

const mapStateToProps = state => ({
  seoMeta: state.seo.seoMeta,
  language: state.userData.language
})

const state = ({
  seoParams: {}
})

const stateHandlers = {
  updateSeoParams: () => (params) => ({
    seoParams: params
  })
}

export const enhance = compose(
  setDisplayName('seoTags'),
  connect(mapStateToProps, null),
  withStateHandlers(state, stateHandlers),
  lifecycle({
    componentDidMount() {
      const {props: {updateSeoParams, seoMeta, pathname}} = this
      let path = pathname.indexOf('/') === 0 ? pathname.slice(1) : pathname
      if (seoMeta[path]) {
        updateSeoParams(seoMeta[path])
      }
    },
    componentDidUpdate() {
      const {props: {pathname, updateSeoParams, seoMeta, seoParams}} = this
      let path = pathname.indexOf('/') === 0 ? pathname.slice(1) : pathname
      if (seoMeta[path] && seoParams !== seoMeta[path]) {
        updateSeoParams(seoMeta[path])
      } else if (!seoMeta[path] && Object.keys(seoParams).length !== 0) {
        updateSeoParams({})
      }
    }
  })
)

export const View = ({seoParams}) => (
  <Helmet>
    <title>{seoParams.title ? seoParams.title : 'PocketBook Cloud'}</title>
    {seoParams.description ? <meta name="description" content={seoParams.description} /> : ''}
    {seoParams.keywords ?  <meta name="keywords" content={seoParams.keywords} /> : ''}
  </Helmet>
)

export const SeoTags = enhance(View)

