const settings = {
  delete: 'Löschen',
  eMail: 'E-Mail',
  vendor: 'Verkäufer',
  UUID: 'UUID',
  password: 'Kennwort',
  makeDefault: 'Als Standard festlegen',
  defaultID: 'Standard-ID',
  addAdobeID: 'Adobe ID hinzufügen',
  youAreSigned: 'Sie werden an Ihrem ReadRate-Konto angemeldet.',
  logOut: 'Abmelden',
  linkAccount: 'Ihr Konto verknüpfen',
  used: 'Verwendet',
  outOf: 'von',
  changeAccount: 'Ihr Konto wechseln',
  spaceInAccount: 'Speicherplatz in Ihrem Konto',
  settings: 'Einstellungen',
  getMoreSpace: 'Mehr Speicherplatz erhalten',
  loginLength: 'Der Anmeldename muss länger als 6 Zeichen sein.',
  loginNotEmpty: 'Der Anmeldename darf nicht leer sein.',
  passLength: 'Das Kennwort muss länger als 8 Zeichen sein.',
  selectVendor: 'Wählen Sie einen Verkäufer aus der Liste aus.',
  passNotEmpty: 'Das Kennwort darf nicht leer sein.',
  chooseVendorAdobe: 'Verkäufer der Adobe ID auswählen',
  login: 'Anmelden',
  provideLogin: 'Anmeldename angeben',
  providePassword: 'Kennwert angeben',
  continue: 'Weiter',
  currency: 'USD',
  month: 'Monat',
  moreSpace: 'Mehr Speicherplatz, mehr Bücher, mehr Spaß',
  perfectPlan: 'Finden Sie das perfekte Abo für sich – 100 % Zufriedenheit garantiert',
  cancel: 'Abbrechen',
  confirm: 'Bestätigen',
  showPassword: 'Kennwort anzeigen',
  secured: 'Gesichert',
  addVendorFormFailureMessage: 'Adobe Autorisierung fehlgeschlagen. Benutzername oder Kennwort ist ungültig.',
  freshTheme: 'Neues Layout',
  classicTheme: 'Klassisches Layout',
  add: 'Hinzufügen',
  uuidMsg: 'Mit dem UUID lässt sich Ihr Adobe-Account eindeutig identifizieren. Sie benötigen Ihren UUID zum Beispiel, wenn Sie den Zähler der für Ihren Adobe-Account freigeschalteten Geräte über die Adobe-Hotline zurücksetzen wollen.',

  emailLength: '',
  getPassword: 'Passwort anzeigen',
  emailNotEmpty: 'E-Mail darf nicht leer sein',
  resetPasswordFormText: 'Bitte gib deine E-Mail-Adresse ein. Wir senden dir einen Link, über den dir dein Passwort angezeigt wird.',
  passwordFormSuccessMessage: 'E-Mail erfolgreich versendet',
  passwordFormFailedMessage: 'Fehler beim Versenden der E-Mail',
  showPasswordTitle: 'Passwort des internen DRM-Accounts:',
  'Invalid hash': 'Ungültiger Link',
  'Link expired': 'Link abgelaufen'
}
export default settings;