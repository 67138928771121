import { cond, always, equals, map, forEach, split, prop, head, both, __, complement, gte, length, find, propEq, and, ifElse, isNil, pipe, has } from 'ramda'
import { API, APINew, getAuthHeaders } from './axiosInstance'
import { API_URL, SERVER, hiddenBlocks, READER_URL, readerFormats} from '../constants'
import { getAccessToken } from '../utilities'

// 'dTozM2I6MjRhMmE1MmQzYWFmMWEwNjIzNjFjZDQ2MjMxYWMzYmU4NDI2OGRlNg==' - eternal token

const createReq = (url, responseHandler = ({data}) => data) => ({
  url,
  responseHandler
})

const findOriginByNameSize = (name, size) => find(
  and(
    propEq('name', name),
    propEq('size', size)
  )
)

const userLang = navigator.language || navigator.userLanguage;
export const uploadBook = (file, origin, callback) => {
  let { name, type, size } = file
  const File = findOriginByNameSize(name, size)(origin)

  if (name.indexOf('.chm') > -1) {
    type = 'application/vnd.ms-htmlhelp'
  } else if (name.indexOf('.fb2') > -1) {
    type = 'application/x-fictionbook+xml'
  }

  const config = {
    onUploadProgress: progressEvent => callback(progressEvent.loaded)
  }
  return APINew.put(`/files/${encodeURIComponent(name)}`, File, getAuthHeaders({'Content-Type': type, 'Accept-Language': userLang}, config))
}

export const checkBookStatus = (path) => {
  return API.get(`/task/${encodeURIComponent(path)}`, getAuthHeaders())
}

const getCover = ifElse(
  both(
    complement(isNil),
    pipe(length, gte(__, 1))
  ),
  pipe(head, prop('path')),
  always('')
)

const getArrayFromString = (defaultItem, data) => ifElse(
  isNil,
  () => ifElse(
    isNil,
    always([]),
    always([defaultItem])
  )(defaultItem),
  split(',')
)(data)

const mapUserBooksModel = ({id, format, isDrm, isLcp, fast_hash, purchased, read_status, bytes, collections, path, link,
                             name, created_at, position, read_percent, read_position, favorite,
                             metadata: {title, publisher, series, lang, genres, cover, authors, year, isbn, annotation},
                             hasLinks, isAudioBook }) => {
  return {
    name,
    year,
    publisher,
    title: title || 'Unknown Title',
    position: isAudioBook ? read_position : position,
    genres: getArrayFromString('Unknown', genres),
    collections: getArrayFromString(null, collections),
    lang,
    series: getArrayFromString(null, series),
    id: id.toString(),
    cover: getCover(cover),
    uri: readerFormats.indexOf(format) > -1 || (isAudioBook && !hiddenBlocks.audioBooks) ? getBookUri(encodeURIComponent(getPath(path)), fast_hash, isAudioBook, hasLinks) : null,
    format: isLcp && format !== 'acsm' ? format + ' lcp' : isDrm && format !== 'acsm' ? format + ' drm' : format || 'unknown',
    author: authors || 'Unknown Author',
    progress: isAudioBook ? read_percent : position.percent,
    size: bytes,
    isFavorite: favorite || false,
    status: read_status,
    updatedAt: position && position.updated ? position.updated : "0",
    createdAt: created_at,
    isPurchased: purchased || false,
    isAudioBook: isAudioBook,
    isDrm: isDrm,
    isLcp: isLcp,
    fast_hash: fast_hash,
    path: getPath(path),
    hasLinks: hasLinks,
    isbn: isbn,
    annotation: annotation,
    link: link
  }
}

const mapWidgetBooksModel = (data) => {
  data.books = data.books.map(({id, title, fast_hash, url, year, description, created_at, cover, authors, isAudioBook, purchased}) => ({
    id,
    title: title || 'Unknown Title',
    fast_hash,
    url,
    year,
    description,
    created_at,
    updatedAt: "0",
    cover,
    author: authors || 'Unknown Author',
    isPurchased: purchased || false,
    isAudioBook: isAudioBook || false,
  }))
  return data;
}

const getPath = (path) => {
  if (path.indexOf('/') === 0) {
    return path.slice(1)
  }
  return path
}

const getBookUri = (path, fast_hash, isAudioBook, hasLinks) => {
    if (isAudioBook) {
      return hasLinks ? `/audio/${fast_hash}` : null
    } else {
      return `${READER_URL}/?${fast_hash}`
    }
}

export const fetchBooks = ({type, options}) => {
  const getUrl = cond([
    [equals('recommended'), () => createReq('/partner-books/recommended', ({data}) =>
      mapWidgetBooksModel(data)
    )],
    [equals('bestsellers'), () => createReq('/partner-books/bestsellers', ({data}) =>
      mapWidgetBooksModel(data)
    )],
    [equals('new'), () => createReq('/partner-books/news', ({data}) =>
      mapWidgetBooksModel(data)
    )],
    [equals('audio'), () => createReq('/audio-books', ({data}) =>
      (
        map(mapUserBooksModel, data)
      )
    )],
    [equals('user'), () => createReq('/books', ({data: {items, total}}) =>
      ({
        items: map(mapUserBooksModel, items),
        total
      })
    )],
    [equals('lastRead'), () => createReq('/books/last-opened', ({data}) =>
      ifElse(
        has('path'),
        () => map(mapUserBooksModel, [data]),
        always([])
      )(data)
    )]
  ])

  const { url, responseHandler } = getUrl(type)

  return API.get(url, {
    ...getAuthHeaders(),
    params: options
  }).then(responseHandler)
}

export const downloadBooks = (books) => {
  let book = 0
  let interval = null
  const intervalTime = 2000 // it's going to ignore download calls when decreased too much

  const tempContainer = document.createElement('div')
  tempContainer.style.visibility = 'collapse'
  document.body.appendChild(tempContainer)

  const download = () => {
    if (book === books.length) {
      clearInterval(interval)
      document.body.removeChild(tempContainer)
      return false
    }
    let bookId = books[book].id
    let isPurchased = books[book].isPurchased
    let fast_hash = books[book].fast_hash
    let url = `${API_URL}files/?fast_hash=${fast_hash}&access_token=${getAccessToken()}`
    if (books[book].isAudioBook) {
      getDownloadUrl(fast_hash).then(newUrl => {
        if (isPurchased) {
          createLink(bookId, newUrl, tempContainer)
        } else {
          url = newUrl.replace(SERVER + '/api/v1.0', '')
          waitArchive(url, bookId, newUrl, tempContainer)
        }
      })
    } else {
      createLink(bookId, url, tempContainer)
    }
    book++
  }

  download()
  interval = setInterval(() => {
    download()
  }, intervalTime)
}

const createLink = (book, url, tempContainer, isBlank = false) => {
  const link = document.createElement('a')
  link.setAttribute('download', book)
  link.setAttribute('href', url)
  if (isBlank) {
    link.setAttribute('target', '_blank')
  }
  tempContainer.appendChild(link)
  link.click()
}

export const waitArchive = (url, bookId, newUrl, tempContainer) => {
  API.get(url, {
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`
    }
  }).then(res => res.data).then(res => {
    if (res.result === 'in_process') {
      setTimeout(() => {
        waitArchive(url, bookId, newUrl, tempContainer)
      }, 2000)
    } else if (res.result !== 'failed') {
      createLink(bookId, newUrl, tempContainer)
    }
  })
}

export const getDownloadUrl = (fast_hash) =>
  API.post(`/audio-books/download/?fast_hash=${fast_hash}`, null, getAuthHeaders()).then(res => res.data.url)

export const deleteBooks = (books) => {
  forEach(ifElse(
    propEq('isAudioBook', true),
    (book) => API.delete(`/audio-books/${book.path}/`, {...getAuthHeaders()}).then(res => res.data),
    (book) => APINew.post(`/fileops/delete/?fast_hash=${book.fast_hash}`, null, getAuthHeaders()).then(res => res.data)
  ))(books)
}

export const deleteCollections = (collections) => {
  forEach(
      ({name}) => API.delete(`/collections/${encodeURIComponent(name)}`, getAuthHeaders()).then(res => res.data)
  )(collections)
}

export const getAudioBookFiles = (fast_hash) =>
  API.get(`/audio-books/?fast_hash=${fast_hash}`, {...getAuthHeaders()})
    .then(res => res.data)

export const updateStatus = ({status, id}) =>
  API.post(`/books/status/${encodeURIComponent(id)}/${status}`, null, getAuthHeaders())
    .then(res => res.data)

export const updateFavorite = ({status, path, isAudioBook}) => {
  ifElse(
    () => isAudioBook,
    () => ifElse(
      () => status,
      () => API.post(`/audio-books/add-to-favorites/${encodeURIComponent(path.slice(0, -1))}/`, {
        favorite: { value: status }
      }, getAuthHeaders()).then(res => res.data),
      () => {
        API.post(`/audio-books/remove-from-favorites/${encodeURIComponent(path.slice(0, -1))}/`, {
          favorite: { value: status }
        }, getAuthHeaders()).then(res => res.data)
      }
      )(),
    () => API.post(`/fileops/info/${encodeURIComponent(path)}`, {
          favorite: { value: status }
        }, getAuthHeaders()).then(res => res.data)
  )()
}

export const updateReadPosition = ({read_position, id}) => {
  API.post(`/audio-books/read-position/?fast_hash=${id}`, read_position
      , getAuthHeaders()).then(res => res.data)
}

export const updateBookmark = ({id, mark}) =>
    API.post(`/audio-books/add-markdown/?fast_hash=${id}`, mark, getAuthHeaders())

export const addMarkDown = ({read_position, id}) => {
    return API.post(`/audio-books/add-markdown/?fast_hash=${id}`, read_position
        , getAuthHeaders()).then(res => res.data)
}

export const removeMarkDown = ({read_position, id}) => {
    return API.post(`/audio-books/remove-markdown/?fast_hash=${id}`, read_position
        , getAuthHeaders()).then(res => res.data)
}

export const getBooksInfo = () =>
  API.get(`/stats/books-info`, getAuthHeaders()).then(res => res.data)

export const getCollections = () =>
  API.get(`/collections`, getAuthHeaders()).then(res => res.data)

export const addCollection = (name) =>
  API.post(`/collections/${encodeURIComponent(name)}`, null, getAuthHeaders()).then(res => res.data)

export const editCollection = ({oldName, newName}) =>
  API.post(`/collections/${encodeURIComponent(oldName)}/rename/${encodeURIComponent(newName)}`, null, getAuthHeaders()).then(res => res.data)

export const deleteCollection = (name) =>
  API.delete(`/collections/${encodeURIComponent(name)}`, getAuthHeaders()).then(res => res.data)

export const addToCollection = ({name, bookPaths, isAudioBook}) => {
  forEach(book => {
    ifElse(
      () => isAudioBook,
      book => API
        .post(`/audio-books/add-to-collection/${encodeURIComponent(book.slice(0,-1))}/`, { "collection": `${name}` }, getAuthHeaders()),
      book => API
        .post(`/collections/${encodeURIComponent(name)}/files/${encodeURIComponent(book)}`, null, getAuthHeaders())
    )(book)
    },
    bookPaths
  )
}

export const removeFromCollection = ({name, bookPaths, isAudioBook}) => {
  forEach(book => {
    ifElse(
      () => isAudioBook,
      book => API
        .post(`/audio-books/remove-from-collection/${encodeURIComponent(book.slice(0, -1))}/`, { "collection": `${name}` }, getAuthHeaders()),
      book => API
        .delete(`/collections/${encodeURIComponent(name)}/files/${encodeURIComponent(book)}`, getAuthHeaders())
    )(book)
    },
    bookPaths
  )
}

export const getLastRead = () => {
  return API.get(`/books/last-opened`, getAuthHeaders()).then(({data}) => ifElse(
    has('path'),
    () => map(mapUserBooksModel, [data]),
    always([])
  )(data))
}

export const getGenres = () => {
  return API.get(`/books/genres`, getAuthHeaders()).then(res => res.data)
}