import React from 'react'
import { connect } from 'react-redux'

import { InnerLink } from '../../../../../../../Shared/InnerLink/InnerLink'
import { sortBooks } from '../../../../../../../../actions/books';
import { getLocaleString } from '../../../../../../../../locale';

import './Notification.css'

const mapDispatchToProps = (dispatch) => ({
  sortBooks: (type) => dispatch(sortBooks(type)),
})

const Notification = ({sortBooks, action, notification: {message, id, sortbooks, isSpaceNotification, showButton} }) => (
  <li className="Notification-item">
    <button className="ModalContent__close-btn" onClick={() => action(id)}>
      <i alt="cancel"></i>
    </button>
    {
      message.bookName !== null
      ? <p className="Notification-item__type"><span>{message.bookName}</span> {message.message}</p>
      : <p className="Notification-item__type">{message.message}</p>
    }
    {
      showButton
      ?
      <InnerLink to={ isSpaceNotification ? '/settings/plan/change' : '/books' } onClick={() => action(id) && sortbooks && sortBooks('date')}>
        { isSpaceNotification ? getLocaleString('notifications.getMoreSpace') : getLocaleString('notifications.view') }
      </InnerLink>
      :
      ''
    }
  </li>
)

export default connect(null, mapDispatchToProps)(Notification)
