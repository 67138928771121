const cookie = {
  text_before: "Wie viele Unternehmen verwendet PocketBook die Cookie-Technologie, um Ihre Benutzererfahrung zu verbessern, für Analyse- und Marketingzwecke, um Ihnen relevante Angebote zu zeigen, die während des Betriebs dieser Website und der Websites Dritter optimal auf Ihre Interessen zugeschnitten sind. PocketBook respektiert Ihre Datenschutzrechte, daher bitten wir Sie, sich einen Moment Zeit zu nehmen, um die",
  preferences: "Cookie-Einstellungen verwalten.",
  text_between: "Bitte beachten Sie, dass zwingend erforderliche Cookies immer aktiviert sind. Wenn Sie mit der Verwendung aller Cookie-Dateien zufrieden sind, klicken Sie einfach auf",
  ok: "Ok",
  text_after: "in diesem Pop-up-Fenster. Um mehr über die Cookie-Technologie, ihre Vorteile und die Verwendung durch PocketBook zu erfahren, lesen Sie bitte unseren",
  cookie_notice: "Cookie-Hinweis.",
  accept: "Alle Cookies akzeptieren",
  customise: "Cookie-Einstellungen anpassen",
  save: "Benutzerdefinierte Einstellungen speichern",
  necessary: "Zwingend erforderliche Cookies: Hierbei handelt es sich um Cookies, die für die Bereitstellung von Diensten und Funktionen benötigt werden, die Sie ausdrücklich angefordert haben. Wir können Cookies und Tracking-Technologien zur Systemadministration und/oder zur Nutzung der Warenkorbfunktionalität verwenden.",
  analytics: "Analyse- und leistungsbezogene Cookies: Wir können Cookies verwenden, um die Leistung unserer Dienste zu bewerten, auch im Rahmen unserer Analyseverfahren, um die Auswahl der Ihnen durch die Dienste empfohlenen Inhalte zu verbessern.",
  functionality: "Funktionalitätsbezogene Cookies: Wir können Cookies verwenden, um beispielsweise zu erfahren, ob Sie die Dienste schon einmal besucht haben oder ob Sie ein neuer Besucher sind, und um uns dabei zu helfen, die Funktionen zu identifizieren, an denen Sie das größte Interesse haben könnten.",
  targeting: "Zielgruppenbezogene Cookies: Wir können Cookies verwenden, um Inhalte, einschließlich Anzeigen, zu liefern, die für Ihre Interessen an unseren Diensten relevant sind."
}
export default cookie;