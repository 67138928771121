const settings = {
  delete: 'Delete',
  eMail: 'E-mail',
  vendor: 'Vendor',
  UUID: 'UUID',
  password: 'Password',
  makeDefault: 'Make default',
  defaultID: 'Default ID',
  addAdobeID: 'Add Adobe ID',
  youAreSigned: 'You are signed in to your ReadRate account',
  logOut: 'Log out',
  linkAccount: 'Link your account',
  used: 'Used',
  outOf: 'out of',
  changeAccount: 'Change your account',
  spaceInAccount: 'Space in your account',
  settings: 'Settings',
  getMoreSpace: 'Get more space',
  loginLength: 'Login must be longer than 6 symbols',
  loginNotEmpty: 'Login cannot be empty',
  passLength: 'Password must be longer than 8 symbols',
  selectVendor: 'Please select a vendor from the list',
  passNotEmpty: 'Password cannot be empty',
  chooseVendorAdobe: 'Choose vendor of Adobe ID',
  login: 'Login',
  provideLogin: 'Provide login',
  providePassword: 'Provide password',
  continue: 'Continue',
  currency: 'USD',
  month: 'month',
  moreSpace: 'More space, more books, more joy',
  perfectPlan: 'Find the perfect plan for you — 100% satisfaction guaranteed',
  cancel: 'Cancel',
  confirm: 'Confirm',
  showPassword: 'show password',
  secured: 'Secured',
  addVendorFormFailureMessage: 'Adobe authorization failed. Login or Password is invalid',
  add: 'Add',
  freshTheme: 'Fresh theme',
  classicTheme: 'Classic theme',

  emailLength: '',
  getPassword: 'Get password',
  emailNotEmpty: 'Email cannot be empty',
  resetPasswordFormText: 'Please, enter your email to receive a link to view the password.',
  passwordFormSuccessMessage: 'Email has been sent successfully',
  passwordFormFailedMessage: 'Error sending email',
  showPasswordTitle: 'Password from internal DRM account:',
  'Invalid hash': 'Invalid link',
  'Link expired': 'Link expired'
}
export default settings;