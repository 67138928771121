import {TYPES, loadUserData, onFetchUserData, loadUsersStorageData, updateUserData, removeUser} from '../actions/user'
import { setDefault } from '../actions/adobe'
import { put, takeEvery, call } from 'redux-saga/effects'
import * as API from '../API/user'
import {removeUser as storageRemoveUser} from "../utilities";

function* userData() {
  try {
    yield put(onFetchUserData(true))
    const data = yield call(() => API.getUserData())
    if (!data) {
      storageRemoveUser()
      yield put(removeUser())
    }
    yield put(loadUserData(data))
    yield put(onFetchUserData(false))
    if (data.internal_account.is_main) {
      yield put(setDefault(data.internal_account.email))
    }
  } catch (error) {
    console.log('Error loading user data', error)
  }
}

function* getUsersStorageData() {
  try {
    const data = yield call(() => API.getUserData())
    yield put(loadUsersStorageData(data))
  } catch (error) {
    console.log('Error loading user storage data', error)
  }
}

function* onUpdateUserData({ payload }) {
  try {
    const data = yield call(() => API.updateUserData(payload))
    yield put(updateUserData(data))
  } catch (error) {
    console.log('Error updating user data', error)
  }
}

export const userSaga = [
  takeEvery(TYPES.LOAD_USER_DATA, userData),
  takeEvery(TYPES.USERS_STORAGE_DATA, getUsersStorageData),
  takeEvery(TYPES.ON_UPDATE_USER_DATA, onUpdateUserData)
]
