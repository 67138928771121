import React from 'react'
import PropTypes from 'prop-types'
import { compose, setDisplayName, pure, setPropTypes, onlyUpdateForPropTypes } from 'recompose'

import './AppLink.css'

const propTypes = setPropTypes({
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired
})

export const enhance = compose(
  setDisplayName('AppLink'),
  pure,
  onlyUpdateForPropTypes,
  propTypes
)

export const View = ({icon, label, iconStyle, ...props}) => (
  <a className="AppLink" {...props}>
    <figure className="AppLink__icon" style={iconStyle}>
      <i alt={label} className={label}></i>
    </figure>
  </a>
)

export const AppLink = enhance(View)
