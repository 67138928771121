const cookie = {
  text_before: "Пожалуйста, обратите внимание, что данный сайт PocketBook использует в своей работе технологию cookie для улучшения Вашего пользовательского опыта, а также в аналитических и маркетинговых целях, для демонстрации предложений, адаптированных в соответствии с Вашими интересами. PocketBook уважает Ваше право на приватность, поэтому мы просим уделить минуту времени для того, чтобы Вы сами смогли",
  preferences: "Управлять настройками файлов cookie.",
  text_between: "Пожалуйста, обратите внимание, что крайне необходимые файлы cookie всегда включены. Если Вы согласны с использованием всех видов файлов cookie, нажмите кнопку",
  ok: "Ok",
  text_after: "в этом окне. Чтобы узнать больше о технологии cookie, ее преимущества и о том, как ее использует PocketBook, ознакомьтесь с нашим",
  cookie_notice: "Сообщением о файлах cookie.",
  accept: "Принять все файлы cookie",
  customise: "Настроить файлы cookie",
  save: "Сохранить пользовательские настройки",
  necessary: "Крайне необходимые файлы cookie: это файлы cookie, необходимые для надлежащего оказания услуг и обеспечения функционала, который Вы специально спрашивали. Мы можем использовать файлы cookie и технологии отслеживания для администрирования системы и / или чтобы Вы могли использовать функционал корзины покупок. Мы не обязаны получать Ваше согласие на использование крайне необходимых файлов cookie.",
  analytics: "Аналитические файлы и файлы cookie, связанные с эффективностью. Мы можем использовать файлы cookie для оценки эффективности наших сервисов, в том числе в рамках нашей аналитической работы, необходимой в целях улучшения выбора контента, рекомендованного Вам с помощью сервисов.",
  functionality: "Функциональные файлы cookie: мы можем использовать файлы cookie, например, для того, чтобы сообщить нам, пользовались Вы сервисами ранее или является новым клиентом, а также чтобы помочь нам определить функционал, который может Вас заинтересовать.",
  targeting: "Рекламные файлы cookie: мы можем использовать файлы cookie чтобы предложить Вам контент, в том числе рекламный, который соответствует Вашим интересам по использованию нашими сервисами."
}
export default cookie;