const sidebar = {
  eBooks: 'Електронні книги',
  audioBooks: 'Аудіокниги',
  purchased: 'Покупки',
  recentlyRead: 'Нещодавно прочитані',
  favorites: 'Обрані',
  authors: 'Автори',
  series: 'Серії',
  genres: 'Жанри',
  formats: 'Формати',
  collections: 'Колекції',
  settings: 'Налаштування',
  yourAccount: 'Ваш обліковий запис',
  adobeID: 'AdobeID',
  readRate: 'ReadRate',
  plan: 'План',
  dashboard: 'Головна',
  books: 'Книги',
  getMore: 'Отримати більше в моєму магазині',
  getApp: 'Встановити програму',

  interface: 'Interface',

  about_btn: 'Про сервіс',
  advantages_btn: 'Переваги',
  how_to_use_btn: 'Як користуватись сервісом',
  backToDashboard: 'Повернутись до головної',
  backToLogin: ' авторизації',
  aboutUs: 'Про нас'
}
export default sidebar;