import {API_URL} from "../../../../constants";
import {getAccessToken} from "../../../../utilities";

export const getPlayerType = ({pathname}) => {
  return pathname.indexOf('/audio/') > -1 ? 'onPage' : 'inFooter'
}

export const onUpdateReadPosition = (fast_hash, trackName, updateReadPosition, played ) => {
  if(played > 0) {
    const readPosition = {"page": trackName, "offs": Math.floor(played) * 1000}
    updateReadPosition(readPosition, fast_hash)
  }
}

const pad = (string) => {
  return ('0' + string).slice(-2)
}

export const formatTime = (seconds) => {
  if (!seconds) return 0

  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

export const formatPercent = (played, duration) => {
  if (!duration && !played) return 0 + '%'
  let percent = Math.ceil(((Math.floor(played))*100 / duration))
  if (percent === Infinity) return 0 + '%'
  return percent + '%'
}

export const trackPath = (chapters, trackNumber) => {
  let path = chapters[0].path
  if (chapters[trackNumber]) path = chapters[trackNumber].path
  return `${API_URL}files${path}?access_token=${getAccessToken()}`
}

export const trackName = (chapters, trackNumber) => {
  let title = chapters[0].title
  if (chapters[trackNumber]) title = chapters[trackNumber].title
  return title
}

export const decodeBookName = (encodedName) => {
  let path = decodeURIComponent(encodedName)
  if (path.indexOf('/') === 0) {
    path = path.slice(1)
  }
  if (path.lastIndexOf('/') === path.length-1) {
    path = path.slice(0, path.length-1)
  }
  return path
}

export const getTrackNumber = (chapters, chapterName) => {
  let chapterNumber = 0
  chapters.map((chapter, index) => {
    if ( chapter.name === chapterName ) {
      chapterNumber = index
    }
    return chapter
  })

  return chapterNumber
}

export const sortList = (a, b) => {
  if (a.mark && b.mark) {
    if (a.mark.created && b.mark.created) {
      if (a.mark.created < b.mark.created)
        return 1;
      if (a.mark.created > b.mark.created)
        return -1;
    } else {
      if (a.mark.updated < b.mark.updated)
        return 1;
      if (a.mark.updated > b.mark.updated)
        return -1;
    }
  }
  return 0;
}

export const getDuration = (duration) => {
  if (!duration) return 0
  duration = duration.split(':')
  let hoursInMinutes = parseInt(duration[0], 10)*60
  let minutesInSeconds = (parseInt(duration[1], 10) + hoursInMinutes)*60
  let seconds = parseInt(duration[2], 10) + minutesInSeconds
  return seconds
}