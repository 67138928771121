const login = {
  login: 'Войти',
  loginWith: 'Войдите с помощью',
  useQRCode: 'QR-код для входа через смартфон',
  useEmail: 'Либо введите e-mail',
  emailPlaseHolder: 'введите ваш e-mail или имя пользователя',
  FAQ_btn: 'Помощь',
  privacyPolicy_btn: 'Политика конфиденциальности',
  privacyPolicy: 'Политикой конфиденциальности',
  impressum_btn: 'Контакты',
  contact_us_btn: 'Связаться с нами',
  login_btn: 'Войти',
  next_btn: 'Далее',
  passwordIncorect: 'Неверный пароль',
  emailIncorect: 'Неверный e-mail',
  searchProviders: 'Поиск провайдеров',
  loggingIn: 'Вход',
  syncAllDevices: 'Синхронизация между всеми вашими устройствами и платформами',
  readAnyFormats: 'Удобный web ридер для ePub, PDF, fb2',
  allYourLibrary: 'Вся Ваша библиотека - в надёжном месте',
  chooseBookstore: 'Выберите свой книжный магазин',
  iAgreeTo: 'Я согласен с ',
  termsOfUse: 'Условиями использования',
  andWith: ' и с ',
  goBack_btn: 'Вернуться',
  enterPassword: 'Введите ваш пароль',
  savePasswordNextTime: ' Сохранить мой пароль для следующего раза',
  googleBtn: 'Войти с помощью Google',
  facebookBtn: 'Войти с помощью Facebook',

  legal: 'Юридическая информация',
  about: 'О сервисе',
  for_developers_btn: 'Для разработчиков'
}
export default login;