const account = {
  name: 'Name',
  surname: 'Nachname',
  loginEmail: 'E-Mail-Adresse für Anmeldung',
  bookOfWeek: 'Buch der Woche',
  personRecom: 'Empfehlungen',
  bestsellers: 'Bestseller',
  newThisWeek: 'Neu',
  readReateNews: 'ReadRate-News',
  readRateDisc: 'ReadRate-Diskussion',
  nameNotEmpty: 'Name darf nicht leer sein.',
  nameIncorect: 'Name ist fehlerhaft.',
  emailNotEmpty:'E-Mail-Adresse darf nicht leer sein.',
  emailIncorect: 'E-Mail-Adresse ist fehlerhaft.',
  surnameNotEmpty: 'Nachname darf nicht leer sein.',
  surnameIncorect: 'Nachname ist fehlerhaft.',
  resetGoToStore: 'Öffnen Sie zum Zurücksetzen des Kennwortes den Store:',
  resetPassword: 'Kennwort zurücksetzen',
  dashboardWidgets: 'Startseite-Widgets:',
  saveChanges: 'Änderungen speichern',
  changeLanguage: 'Sprache ändern',
  en: 'Englisch',
  ru: 'Russisch',
  de: 'Deutsch',
  zh: 'Chinesisch',
  uk: 'Ukrainisch',
  findStore: 'Sie haben noch kein Konto?'
}
export default account;