import { TYPES } from '../actions/privacyPolicy'

const initialState = {
  activePrivacy: false,
  activeImpressum: false,
  activeCookies: false,
  activeFaq: false,
  activeContactUs: false,
  activeFindInfo: true,
  activeTerms: false,
  activeLogin: false
}

const hideAll = (state, current) => {
  Object.keys(state).forEach((key) => {
    if (key !== current) state[key] = false
  })
  return state
}

export const privacyPolicy = (state = initialState, action) => {
  switch(action.type) {

    case TYPES.PRIVACY:
      state = hideAll(state, 'activePrivacy')
      return { ...state,  activePrivacy: !state.activePrivacy }

    case TYPES.IMPRESSIUM:
      state = hideAll(state, 'activeImpressum')
      return { ...state,  activeImpressum: !state.activeImpressum }

    case TYPES.COOKIES_TERMS:
      state = hideAll(state, 'activeCookies')
      return { ...state,  activeCookies: !state.activeCookies }

    case TYPES.FAQ:
      state = hideAll(state, 'activeFaq')
      return { ...state,  activeFaq: !state.activeFaq }

    case TYPES.CONTACT_US:
      state = hideAll(state, 'activeContactUs')
      return { ...state,  activeContactUs: !state.activeContactUs }

    case TYPES.FIND_INFO:
      state = hideAll(state, 'activeFindInfo')
      return { ...state,  activeFindInfo: !state.activeFindInfo }

    case TYPES.TERMS:
      state = hideAll(state, 'activeTerms')
      return { ...state, activeTerms: !state.activeTerms }

    case TYPES.LOG_IN_ACTION:
      state = hideAll(state, 'activeLogin')
      return { ...state, activeLogin: !state.activeLogin }

    default:
      return state
  }
}