export const about = () => (`
  <h1>Service description PocketBook Cloud</h1>
  <section>
    <p>Imagine a huge library that can hold thousands of your books, placed in a convenient order, with your notes in the margins. Thanks to the latest technology, this library is a reality and always on hand, no matter where you are or what device you use.</p>
    <p><span>PocketBook Cloud</span> is a cross-platform service that combines your e-reader, Android or iOS smartphones, tablets, and PC, linking all devices into one single ecosystem. It allows you to synchronise e-book files, your reading positions and personal settings. In other words, you can start a book on the e-reader, continue on your mobile phone, and then again return to PocketBook, without wasting time searching for the right page, changing the font or any other settings for that matter. <span>PocketBook Cloud online</span> service was created to provide the greatest reading experience to passionate book lovers from all over the world. </p>
    <p>Our devices use the latest generation of E Ink technology, which makes the screen of the e-reader as close as possible to ordinary paper and guarantees the safety of your eyes. Of course, even modern smartphones, tablets and laptops cannot boast of such characteristics, so it makes you question: why does anyone read from a TFT display? The fact is that the e-reader, with all its compactness and lightness, is not always on hand. For example, if you are travelling by public transport or waiting for a friend in a cafe. There are many situations when you can carve out 10 minutes and plunge into a fascinating novel, and the absence of an e-reader should not stop you.</p>
    <p>Thanks to <span>PocketBook Cloud app preinstalled on your e-reader,</span> the personal library is even more mobile and interactive. You can work with a text on any device connected with <span>PocketBook Cloud: ebook</span> you’ve read will also be available on your smartphone, tablet, or PC.  </p>
    <p>To use Cloud from your PC go to the web-page cloud.pocketbook.digital. The service is already preinstalled in PocketBook e-readers and really easy to bring in to your mobile devices. All you need to do is to upload the PocketBook Reader application from Play Market or App Store then register on a native online bookstore (BookLand, Buchmedia, KNV, Umbreit, Legimi, etc.). Make sure you use the same login data from all of your devices to <span>PocketBook Cloud: reader, iPhone, Android smartphone, and tablet</span> as they will automatically receive your library with synchronised files, reading positions and settings. </p>
  </section>
`)
export const advantages = () => (`
  <h1>Advantages PocketBook Cloud</h1>
  <section>
    <p>One of the main drivers behind PocketBook is to bring e-reading to an entirely new level by constantly progressing forward and developing new ideas. An excellent example of such innovation is open access to your books across a variety of devices. Thanks to the <span>PocketBook Cloud, reader’ library</span> is always on hand, and the file synchronisation process between different devices is fast and straightforward.</p>
    <p><span>E-book cloud storage</span> provides readers with numerous advantages: your books are always with you, regardless of whether you are holding an e-reader or smartphone in your hands, sitting at a computer or with a tablet. For example, using the <span>online cloud, reader</span> can synchronise content from your phone or tablet in just a few seconds. All you need to do is connect the device to the service (find the detailed instructions here).</p>
    <p>To benefit from PocketBook Cloud, you need to understand how it works. Its main task is to synchronise an e-reader with a phone or PC. You receive 2 GB of free storage space for documents, which are simultaneously available in all connected devices. But that’s not all, your settings and reading positions are also saved, along with your library. Just imagine how handy it will be: your book will always be open on the right page, no matter what device you’re using. </p>
    <p><span>PocketBook Cloud library</span> is an easy and convenient way to read anytime and anywhere. Even though the E Ink screen is much safer than TFT displays of conventional gadgets, there are many situations, when an e-reader is not close by. That’s when the <span>e-reader cloud</span> service comes in. It allows you to continue reading that unfinished novel on your smartphone when you have a moment.</p>
    <p>Taking care of readers, PocketBook has created an entire ecosystem, combining devices running on Android, iOS, Windows, and Linux. One of the advantages of our gadgets is the fact that it’s a cross-platform e-reader with synchronisation. You don’t have to choose – PocketBook Cloud has put all the popular gadgets together.</p>
    <p>We are confident that openness is the future. Therefore, synchronisation between different devices and platforms is a matter of need. Our product is not just an e-reader, it is a real library, which is always with you.</p>
    <h2>PocketBook Cloud is supported by:</h2>
    <p>- e-readers of PocketBook Touch Lux series starting from Touch Lux 2,</p>
    <p>- e-readers of PocketBook Touch HD series starting from Touch HD,</p>
    <p>- e-readers of PocketBook Aqua series starting from Aqua 2, </p>
    <p>- e-readers of PocketBook InkPad series starting from InkPad.</p>
  </section>
`)
export const howToUse = () => (`
  <h1>How to use PocketBook Cloud</h1>
  <section>
    <p>PocketBook Сloud service is your virtual library, which can be accessed from any electronic device. For your reading comfort, e-books, reading positions and personal settings can all be synchronised. You will always be directed to the page you stopped reading at, regardless of the device: e-reader, smartphone, tablet or PC. The service is developed to be as simple and clear for every user as possible. Find out more and learn <span>how to use PocketBook Cloud, Reader</span> app for mobile devices, and web version for PCs.</p>
    <h2>How to access PocketBook Cloud</h2>
    <p>The first step to work with Cloud is to register in the native online bookshop (BookLand, Buchmedia, KNV, Umbreit etc.). Create a personal account and don’t forget to take note of your username and password – you’ll need them to activate your <span>PocketBook Cloud account.</span></p>
    <h2><span>How to login PocketBook Cloud reading</span> platform?</h2>
    <p>The next step is pretty simple: you need to enter the native bookshop login data on your e-reader to connect it to the Cloud. The process only takes a minute, but make sure you are connected to WI-FI before logging in.</p>
    <p>To synchronise smartphones or tablets and <span>open PocketBook Cloud reader</span> you will need to download a specific application – PocketBook Reader, which is available for free on Android and iOS. Enter your login and password from the native bookshop, to connect the device to the Cloud.</p>
    <p>If you want to read on a PC, you need to open the PocketBook Cloud official website in any browser and enter the login data mentioned above.</p>
    <p>However, it’s worth mentioning that users aren’t tied to the content of the natives’ bookstores only. You can easily add to the PocketBook Cloud books purchased in other bookstores. To upload them, you need to open the website cloud.pocketbook.digital on your computer and click on the “Upload a new book” button on the main page. Select the file you want to download in the popup window. The book will appear not only in the web version of the Cloud but also in the corresponding section of your e-reader.</p>
    <p>In addition to this, each user is provided with 2 GB of free space to store their electronic library, which has never been so mobile than with our cross-platform service. To find out more information, visit our official YouTube channel and watch the video-manual on working with Cloud.</p>
  </section>
`)

const seo = {
  about: about,
  advantages: advantages,
  howToUse: howToUse
}
export default seo;