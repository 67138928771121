import React from 'react'
import { connect } from 'react-redux'
import { values, toLower, replace, ifElse, equals, uniqBy, always, not, when, pipe, both, length, prop, gt, __, filter, propEq, match,  head, isNil } from 'ramda'
import { compose, setDisplayName, withHandlers, withStateHandlers, toClass, withPropsOnChange } from 'recompose'

import './Search.css'

import { nextDropdown } from '../../../../../../actions/dropdown'

import { Input } from '../../../../../Shared/Input/Input'
import { DropdownMenu } from '../../../../../Shared/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from '../../../../../Shared/DropdownMenu/components/DropdownMenuItem/DropdownMenuItem'

import SearchResults from './components/SearchResults/SearchResults'
import { getLocaleString } from '../../../../../../locale'
import {logInAction} from "../../../../../../actions/privacyPolicy";
import {addNotification} from "../../../../../../actions/notifications";
import {setUploadErrors} from "../../../../../../actions/books";

const DROPDOWN_ID = 'SEARCH_DROPDOWN'

const isActive = equals(DROPDOWN_ID)

const ToggleButton = ({onToggleSearch, active}) => ifElse(
  equals(false),
  always(
    <button className="Search__toggle" onClick={onToggleSearch}>
      <i className="Search__toggle-icon" alt={getLocaleString('search.searchIcon')}></i>
    </button>
  ),
  always(
    <button className="Search__toggle Search__toggle--cancel" onClick={onToggleSearch}>
      <i className="Search__toggle-icon cancel"alt={getLocaleString('search.canselSearch')}></i>
    </button>
  ),
)(active)

const MaybeInput = ({onInput, searchStr, active, registerInput}) => (
  <div className="Search__input-wrapper" data-show={active}>
    <Input type="text" onInput={onInput} registerRef={registerInput} placeholder={getLocaleString('search.searchByTitleAuthor')} />
  </div>
)

const focusInput = (getInputRef) => () => {
  getInputRef().focus()
}

const onToggleSearch = (getInputRef) => ({active, resetSearch, nextDropdown}) => () => {
  getInputRef().value = ''
  resetSearch()
  toggleMenu({active, nextDropdown})()
  setTimeout(() => {
    when(equals(false), focusInput(getInputRef))(active)
  }, 700)
}

const resetSearch = () => () => ({ searchStr: '' })

const toggleMenu = ({active, nextDropdown}) => () => ifElse(
  equals(true),
  () => nextDropdown(null),
  () => nextDropdown(DROPDOWN_ID)
)(active)

const onToggleLoading = () => (status) => ({ loading: status })

const onInput = () => ({ target: { value } }) => ({ searchStr: value })

const updateResults = () => (results) => ({ results })

const isSearchMenuActive = (props) => () => both(
  pipe(prop('active'), equals(true)),
  pipe(prop('searchStr'), length, gt(__, 2))
)(props)

const state = {
  searchStr: '',
  results: [],
  loading: false,
  resultsLength: 4,
  showDetails: false
}

const stateHandlers = {
  resetSearch,
  onToggleLoading,
  onInput,
  updateResults,
  showBookDetails: ({showDetails}) => (e, id) => {
    e.stopPropagation()
    if (showDetails === id) {
      return {showDetails: false}
    }
    return {showDetails: id}
  }
}

const handlers = (props) => {
  let _inputRef = null

  const registerInput = () => (ref) => {
    _inputRef = ref
  }

  const getInputRef = () => _inputRef

  return {
    onToggleSearch: onToggleSearch(getInputRef),
    registerInput,
    isSearchMenuActive
  }
}

const mapStateToProps = (state) => ({
  activeDropdown: state.activeDropdown,
  books: state.books.byId,
  recentlyRead: state.books.lastReadId,
  collections: state.books.collections,
  language: state.userData.language,
  internal_email: state.userData.data ? (state.userData.data.internal_account ? state.userData.data.internal_account.email : '') : '',
  userId: state.userData.data.user_id
})

const mapDispathToProps = (dispatch) => ({
  nextDropdown: (id) => dispatch(nextDropdown(id)),
  toggleActiveLogin: () => dispatch(logInAction()),
  setUploadErrors: (params) => dispatch(setUploadErrors(params)),
  addNotification: (notification) => dispatch(addNotification(notification)),
})

const searchFilter = (property, searchStr) => filter(
  pipe(prop(property), formatString, match(formatString(searchStr)), head, isNil, not)
)

const formatString = pipe(toLower, replace(/[\s%&^#@$!?*()/]/gim, ''))

const searchResultsByProperty = (property, searchStr, target) => {
  if (formatString(searchStr).length === 0) return []
  return uniqBy(prop(property), values(pipe(
    searchFilter(property, searchStr)
  )(target)))
}

const searchResultsBooks = (property, searchStr, target) => {
  if (formatString(searchStr).length === 0) return []
  return values(pipe(
    searchFilter(property, searchStr)
  )(target))
}

export const enhance = compose(
  setDisplayName('Search'),
  connect(mapStateToProps, mapDispathToProps),
  withPropsOnChange(['activeDropdown'], ({activeDropdown}) => ({
    active: isActive(activeDropdown)
  })),
  withStateHandlers(state, stateHandlers),
  withHandlers(handlers),
  toClass
)

export const View = ({nextDropdown, resultsLength, resetSearch, recentlyRead, collections, books, audioBooks,
                       onToggleSearch, active, searchStr, results, onToggleLoading, onInput, registerInput, isSearchMenuActive,
                       language, toggleActiveLogin, setUploadErrors, internal_email, showBookDetails, showDetails, userId }) => (
  <div className="Search" data-active={active}>
    <MaybeInput onInput={onInput} active={active} searchStr={searchStr} registerInput={registerInput} />
    <ToggleButton onToggleSearch={onToggleSearch} active={active} />
    <DropdownMenu active={isSearchMenuActive()}>
      <DropdownMenuItem>
        {searchStr ?
          <SearchResults
            nextDropdown={nextDropdown}
            resultsLength={resultsLength}
            resetSearch={resetSearch}
            onToggleSearch={onToggleSearch}
            active={active}
            books={searchResultsBooks('title', searchStr, filter(propEq('isAudioBook', false), books))}
            audioBooks={searchResultsByProperty('title', searchStr, filter(propEq('isAudioBook', true), books))}
            authors={searchResultsByProperty('author', searchStr, books)}
            collections={searchResultsByProperty('name', searchStr, collections)}
            recentlyRead={searchResultsByProperty('title', searchStr, filter(book => pipe(prop('updatedAt'), isNil, not)(book))(books))}
            language={language}
            toggleActiveLogin={toggleActiveLogin}
            setUploadErrors={setUploadErrors}
            addNotification={addNotification}
            internal_email={internal_email}
            showBookDetails={showBookDetails}
            showDetails={showDetails}
            userId={userId}
          />
        : <div></div>}
      </DropdownMenuItem>
    </DropdownMenu>
  </div>
)

export const Search = enhance(View)
