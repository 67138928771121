import { TYPES } from '../actions/contactUs'
import * as API from "../API/contactUs";
import { takeEvery, call, put } from 'redux-saga/effects'
import { sendFormStatus } from '../actions/contactUs'

function* onSendForm ({payload}) {
  try {
    let send = yield call(() => API.sendForm(payload))
    if (send) {
      yield put(sendFormStatus(true))
    }
  } catch (error) {
    console.log('Error to send form. ' + error)
  }
}

export const contactSaga = [
  takeEvery(TYPES.SEND_FORM, onSendForm),
]