const login = {
  login: 'Anmelden',
  loginWith: 'Anmelden mit',
  useQRCode: 'QR-Code zum Einloggen via Smartphone',
  useEmail: 'Oder E-Mail-Adresse für Anmeldung verwenden',
  emailPlaseHolder: 'E-Mail-Adresse eingeben oder benutzername',
  FAQ_btn: 'Häufig gestellte Fragen',
  privacyPolicy_btn: 'Datenschutzerklärung',
  privacyPolicy: 'Datenschutzerklärung',
  impressum_btn: 'Impressum',
  contact_us_btn: 'Kontakt',
  login_btn: 'Anmelden',
  next_btn: 'Weiter',
  passwordIncorect: 'Kennwort ist falsch.',
  emailIncorect: 'E-Mail-Adresse ist falsch.',
  searchProviders: 'Suche nach Anbietern',
  loggingIn: 'Anmeldung erfolgt',
  syncAllDevices: 'Synchronisierung zwischen allen Ihren Geräten und Plattformen',
  readAnyFormats: 'Ein praktischer Web-Reader für ePub, PDF, fb2',
  allYourLibrary: 'Ihre gesamte Bibliothek ist an einem sicheren Ort',
  chooseBookstore: 'Ihren Bücherladen wählen',
  iAgreeTo: 'Ich akzeptiere die ',
  termsOfUse: 'Nutzungsbedingungen',
  andWith: ' und mit ',
  goBack_btn: 'Zurück',
  enterPassword: 'Kennwort eingeben',
  savePasswordNextTime: ' Kennwort für nächstes Mal speichern',
  googleBtn: 'Weiter mit Google',
  facebookBtn: 'Weiter mit Facebook',

  legal: 'Rechtliche Hinweise',
  about: 'Über Cloud',
  for_developers_btn: 'Für Entwickler'
}
export default login;