const notifications = {
  noNotifications: 'Новых уведомлений нет',
  spaceAlmostOver: 'Свободное место почти закончилось',
  bookAdded: 'Новая книга добавлена',
  cannotBeLoaded: 'Нельзя загрузить файл, свободное место закончилось',
  alreadyInCloud: 'уже есть в вашем облаке',
  errorHappened: 'при загрузке произошла ошибка',
  formatNotSupported: 'Этот формат не поддерживается',
  getMoreSpace: 'Получить больше места',
  view: 'view',

  licenseCancelled: 'Лицензия была отменена',
  licenseExpired: 'Срок действия лицензии истек',
  licenseRevoked: 'Лицензия отозвана',
  withoutLcpToken: 'Отсутствует Lcp-токена',
  incorrectLcpToken: 'Некорректный ключ Lcp',
  plsLogin: 'Пожалуйста, войдите с учетной записью Lcp',
  tryAgain: 'Попробуйте еще раз',
  errorLoginText: 'Lcp Drm Вендор не найден для пользователя',
  doneLoginText: 'Успешный вход в систему',

  oldCloud: 'Мы изменили дизайн! Вы можете переключиться на предыдущую версию Облака в настройках.',

  bookmarkAdded: 'Закладка добавлена',
  bookmarkError: 'Эта позиция уже имеет закладку',

  'error303': 'уже есть в вашем облаке',
  'error325': 'этот формат не поддерживается',
  'error311': 'файл не найден',

  'error312': 'File is empty',
  'error320': 'Invalid file data',
  'error321': 'Can not create directory or file',
  'error322': 'Can not save file changes',
  'error324': 'File path is empty or invalid',
  'error326': 'File mime type is not found or is not supported',
  'error334': 'User has no enough free space left to upload the file',
  'error335': 'File size doesn\'t much with header param "Content-Length" or file md5 hash doesn\'t much with header param "file_md5"',
  'error339': 'Can not parse metadata',
  'error441': 'Invalid downloading url',
  'error442': 'File downloading failed',
  'error443': 'File uploading to azure failed',
  'error450': 'Unknown file service error',
  'error1701': 'Task not found',
  'error1702': 'Task not created',
  'error1703': 'Task not completed',
  'error1704': 'Task failed',
  'error151': 'This book is fulfilled for another account',
  'error152': 'This book is fulfilled only for one (some other) device'
}
export default notifications;