const settings = {
  delete: 'Видалити',
  eMail: 'E-mail',
  vendor: 'Vendor',
  UUID: 'UUID',
  password: 'Пароль',
  makeDefault: 'Використовувати за замовчуванням',
  defaultID: 'За замовчуванням',
  addAdobeID: 'Додати Adobe ID',
  youAreSigned: 'Ви увійшли до свого облікового запису ReadRate',
  logOut: 'Вийти',
  linkAccount: 'Приєднати свій обліковий запис',
  used: 'Використано',
  outOf: 'з',
  changeAccount: 'Змінити тарифний план',
  spaceInAccount: 'Місця у вашому обліковому записі',
  settings: 'Налаштування',
  getMoreSpace: 'Отримати більше місця',
  loginLength: 'Логін повинен бути довшим за 6 символів',
  loginNotEmpty: 'Логін не може бути порожнім',
  passLength: 'Пароль повинен бути довшим за 8 символів',
  selectVendor: 'Будь ласка, виберіть Vendor зі списку',
  passNotEmpty: 'Пароль не може бути порожнім',
  chooseVendorAdobe: 'Виберіть свій vendor для Adobe ID',
  login: 'Авторизуватися',
  provideLogin: 'Введіть логін',
  providePassword: 'Введіть пароль',
  continue: 'Продовжити',
  currency: 'USD',
  month: 'місяць',
  moreSpace: 'Більше місця, більше книжок, більше радості',
  perfectPlan: 'Знайдіть ідеальний план для вас – гарантоване задоволення 100%',
  cancel: 'Скасувати',
  confirm: 'Підтвердити',
  showPassword: 'Показати пароль',
  secured: 'Secured',
  addVendorFormFailureMessage: 'Adobe authorization failed. Login or Password is invalid',
  add: 'Додати',
  freshTheme: 'Сучасна тема',
  classicTheme: 'Класична тема',

  emailLength: '',
  getPassword: 'Дізнатися пароль',
  emailNotEmpty: 'E-mail не може бути порожнім',
  resetPasswordFormText: 'Вкажіть e-mail, на який Вам буде надіслано посилання на перегляд пароля.',
  passwordFormSuccessMessage: 'Email був відправлений успішно',
  passwordFormFailedMessage: 'Помилка при надсиланні листа',
  showPasswordTitle: 'Пароль від Pocketbook Adobe DRM:',
  'Invalid hash': 'Неправильне посилання',
  'Link expired': 'Час дії посилання закінчився'
}
export default settings;