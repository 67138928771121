export const TYPES = {
  GET_SEO_META: 'GET_SEO_META',
  SET_SEO_META: 'SET_SEO_META'
}

export const getSeoMeta = () => ({
  type: TYPES.GET_SEO_META
})

export const setSeoMeta = (meta) => ({
  type: TYPES.SET_SEO_META,
  payload: meta
})