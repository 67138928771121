const dashboard = {
  dashboardTitle: 'Startseite',
  lastRead: 'Zuletzt gelesen',
  continueReading: 'Weiterlesen',
  continueListening: 'Weiter hören',
  newBookMonthZero: 'Kein neues Buch in diesem Monat',
  newBookMonthOne: 'Neues Buch in diesem Monat',
  newBookMonth: 'Neue Bücher in diesem Monat',
  booksInCloudOne: 'Buch in Ihrer Cloud',
  booksInCloud: 'Bücher in Ihrer Cloud',
  readingNowOne: 'Wird gerade gelesen',
  readingNow: 'Werden gerade gelesen',
  markedReadZero: 'Keine Bücher als gelesen markiert',
  markedReadOne: 'Buch als gelesen markiert',
  markedRead: 'Bücher als gelesen markiert',
  yourCloudStat: "Ihre Cloud-Statistik",
  myBooks: 'Meine Bücher',
  mostDiscussed: 'Am meisten diskutiert',
  news: 'News',
  shareYourReading: 'Teilen Sie Ihren Lesefortschritt mit Freunden und diskutieren Sie über Lieblingsbücher.',
  loginToReadRate: 'Am ReadRate-Konto anmelden',
  login: 'Anmelden',
  more: 'Mehr',
  justAboutToStart: 'Gerade dabei, mit dem Lesen zu beginnen',
  haveNotMarked: 'Keine Bücher als gelesen markiert'
}
export default dashboard;