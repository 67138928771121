const sidebar = {
  eBooks: 'Электронные книги',
  audioBooks: 'Аудиокниги',
  purchased: 'Покупки',
  recentlyRead: 'Недавно прочитанные',
  favorites: 'Избранные',
  authors: 'Авторы',
  series: 'Серии',
  genres: 'Жанры',
  formats: 'Форматы',
  collections: 'Коллекции',
  settings: 'Настройки',
  yourAccount: 'Ваш аккаунт',
  adobeID: 'AdobeID',
  readRate: 'ReadRate',
  plan: 'План',
  dashboard: 'Главная',
  books: 'Книги',
  getMore: 'Получить больше в моем Магазине',
  getApp: 'Установить приложение',

  interface: 'Interface',

  about_btn: 'О сервисе',
  advantages_btn: 'Преимущества',
  how_to_use_btn: 'Как пользоваться сервисом',
  backToDashboard: 'Вернуться к главной',
  backToLogin: ' авторизации',
  aboutUs: 'О нас'
}
export default sidebar;