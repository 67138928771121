export const TYPES = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  SHOW_POPUP_NOTIFICATION: 'SHOW_POPUP_NOTIFICATION',
  HIDE_POPUP_NOTIFICATION: 'HIDE_POPUP_NOTIFICATION'
}

export const addNotification = (notification) => ({
  type: TYPES.ADD_NOTIFICATION,
  payload: notification
})

export const removeNotification = (id) => ({
  type: TYPES.REMOVE_NOTIFICATION,
  payload: id
})

export const showPopUpNotification = (notification) => ({
  type: TYPES.SHOW_POPUP_NOTIFICATION,
  payload: notification
})

export const hidePopUpNotification = () => {
  return {type: TYPES.HIDE_POPUP_NOTIFICATION}
}