import React from 'react'
import { Link } from 'react-router-dom'
import { compose, setDisplayName, pure } from 'recompose'

import './InnerLink.css'

export const Arrow = () => (
  <figure className="InnerLink__arrow">
    <i alt="arrow"></i>
  </figure>
)

export const enhance = compose(
  setDisplayName('InnerLink'),
  pure
)

export const View = ({children, ...props}) => (
  <Link className="InnerLink" {...props}>
    {children}
    <Arrow />
  </Link>
)

export const InnerLink = enhance(View)
