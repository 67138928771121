const dashboard = {
  dashboardTitle: 'Головна',
  lastRead: 'Остання',
  continueReading: 'Продовжити читання',
  continueListening: 'Продовжити слухати',
  newBookMonth: 'Нових книг цього місяця',
  booksInCloud: 'Книг у Вашій хмарі',
  readingNow: 'Зараз читаю',
  markedRead: 'Прочитано',
  yourCloudStat: "Ваша статистика",
  myBooks: 'Мої книги',
  mostDiscussed: 'Найбільш обговорювані',
  news: 'Новини',
  shareYourReading: 'Поділіться своїм прогресом читання з друзями та обговоріть улюблені книги',
  loginToReadRate: 'Увійти до ReadRate',
  login: 'Увійти',
  more: 'Більше',
  justAboutToStart: 'Поки не приступили до читання',
  haveNotMarked: 'Ще немає прочитаних книг'
}
export default dashboard;