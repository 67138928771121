import { combineReducers } from 'redux'
import { TYPES } from '../actions/readRate'
import { equals, cond, T, always, toUpper } from 'ramda'

const loginUrl = (state = '#', action) => cond([
  [equals(TYPES.SET_LOGIN_URL), always(action.payload)],
  [T, always(state)]
])(action.type)

const isLogged = (state = false, action) => cond([
  [equals(TYPES.SET_LOGIN_STATUS), always(action.payload)],
  [T, always(state)]
])(action.type)

const userInfo = (state = null, action) => cond([
  [equals(TYPES.SET_USER_INFO), always(action.payload)],
  [T, always(state)]
])(action.type)

const getData = (type) => (state = [], action) => cond([
  [equals(TYPES[`SET_${toUpper(type)}`]), always(action.payload)],
  [T, always(state)]
])(action.type)

export const readRateReducer = combineReducers({
  loginUrl: loginUrl,
  isLogged: isLogged,
  user: userInfo,
  news: getData('news'),
  discussion: getData('discussion')
})
