import {always, cond, equals, prop, T} from "ramda";
import {TYPES} from "../actions/findStore";
import {combineReducers} from "redux";

export const setStores = (state = [], action) => cond([
  [equals(TYPES.SET_STORES), always(prop('payload', action))],
  [T, always(state)]
])(prop('type', action))

export const findStoreReducer = combineReducers({
  stores: setStores
})