import { TYPES, setQRCode } from "../actions/login";
import { put, takeEvery, call } from 'redux-saga/effects'
import * as API from '../API/login'
import {storeQrCode} from "../utilities";
import {getClientId} from "../socket";

function* getQRCode ({payload}) {
  try {
    let qrCode = yield call(() => API.getQRCode(payload))
    let session_id = getClientId()
    if (!qrCode.expired) {
      storeQrCode(qrCode, session_id, 10)
      yield put(setQRCode(qrCode))
    } else {
      yield put(setQRCode(qrCode.value))
    }
  } catch (error) {
    console.log('Error to get qr code', error)
  }
}

export const loginSaga = [
  takeEvery(TYPES.GET_QR_CODE, getQRCode)
]