export const infoText = () => (`
  <div data-ng-bind-html="text" class="break-word ng-binding">
    Sie haben noch kein Konto? – Mit den folgenden Schritten legen Sie Ihr PocketBook Cloud Konto an:
    <br><br> 
    1. Wählen Sie eine Buchhandlung
    <br> 
    2. Erstellen Sie ein Kundenkonto im Webshop der gewählten Buchhandlung
    <br> 
    3. Gehen Sie zurück zur Pocketbook Cloud Startseite und melden Sie sich mit diesen Kontodaten an.
  </div>
`)

const findStore = {
  infoText: infoText,
  okay: 'OK',
  findModalTitle: 'Buchhandlung in der Nähe finden',
  findModalSearch: 'Name, Adresse oder Postleitzahl',
  redirect_To_Registration: 'Jetzt registrieren'
}
export default findStore;