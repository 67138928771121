const notifications = {
  noNotifications: 'Нових повідомлень немає',
  spaceAlmostOver: 'Вільне місце майже закінчилося',
  bookAdded: 'Нова книга додана',
  cannotBeLoaded: 'Не можна завантажити файл, вільне місце закінчилось',
  alreadyInCloud: 'Вже є у вашій хмарі',
  errorHappened: 'при завантаженні сталася помилка',
  formatNotSupported: 'Цей формат не підтримується',
  getMoreSpace: 'Отримати більше місця',
  view: 'view',

  licenseCancelled: 'Ліцензію було скасовано',
  licenseExpired: 'Строк дії ліцензії закінчився',
  licenseRevoked: 'Ліцензія відкликана',
  withoutLcpToken: 'Відсутній LCP-токен',
  incorrectLcpToken: 'Некоректний ключ Lcp',
  plsLogin: 'Будь ласка, увійдіть з обліковим записом Lcp',
  tryAgain: 'Спробуйте ще раз',
  errorLoginText: 'Lcp Drm Вендор не знайдено для користувача',
  doneLoginText: 'Успішний вхід до системи',

  oldCloud: 'Ми змінили дизайн! Ви можете переключитись на попередню версію Хмари в налаштуваннях.',

  bookmarkAdded: 'Замітка додана',
  bookmarkError: 'Ця позиція вже має замітку',

  'error303': 'вже є у вашій хмарі',
  'error325': 'цей формат не підтримується',
  'error311': 'файл не знайдено',

  'error312': 'Файл порожній',
  'error320': 'Недійсні дані файлу',
  'error321': 'Неможливо створити каталог або файл',
  'error322': 'Не вдається зберегти зміни файлу',
  'error324': 'Шлях до файлу порожній або недійсний',
  'error326': 'Тип файлу mime не знайдено або не підтримується',
  'error334': 'У користувача недостатньо вільного місця для завантаження файлу',
  'error335': 'Розмір файлу не співпадає з параметром заголовка "Content-Length" або хеш файлу md5 не співпадає з параметром заголовка "file_md5"',
  'error339': 'Неможливо проаналізувати метадані',
  'error441': 'Недійсна URL-адреса завантаження',
  'error442': 'Не вдалося завантажити файл',
  'error443': 'Помилка завантаження файлу в azure',
  'error450': 'Невідома помилка файлової служби',
  'error1701': 'Завдання не знайдено',
  'error1702': 'Завдання не створено',
  'error1703': 'Завдання не виконано',
  'error1704': 'Завдання не виконано',
  'error151': 'Ця книга виконана для іншого облікового запису',
  'error152': 'Ця книга виконана для іншого пристрою'
}
export default notifications;