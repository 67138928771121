import { combineReducers } from 'redux'
import { userReducer } from './userReducer'
import { readRateReducer } from './readRateReducer'
import { booksReducer } from './booksReducer'
import { dropdownReducer } from './dropdownReducer'
import { userDataReducer } from './userDataReducer'
import { notificationsReducer } from './notificationsReducer'
import { adobeReducer } from './adobeReducer'
import { plansReducer } from './plansReducer'
import { privacyPolicy } from './privacyPolicy'
import { contactUsReducer } from "./contactUsReducer";
import { findStoreReducer } from "./findStore";
import { seoReducer } from "./seoReducer";
import { socketReducer } from "./socketReducer";
import { loginReducer } from "./loginReducer";
import { sidebarReducer } from "./sidebarReducer";

const reducer = combineReducers({
  user: userReducer,
  books: booksReducer,
  readRate: readRateReducer,
  activeDropdown: dropdownReducer,
  userData: userDataReducer,
  notifications: notificationsReducer,
  adobe: adobeReducer,
  plans: plansReducer,
  privacyPolicy: privacyPolicy,
  contactUs: contactUsReducer,
  findedStores: findStoreReducer,
  seo: seoReducer,
  socket: socketReducer,
  login: loginReducer,
  sidebar: sidebarReducer
})

export default reducer
