import React from 'react'
import PropTypes from 'prop-types'
import { compose, setDisplayName, pure, onlyUpdateForPropTypes, setPropTypes } from 'recompose'

import './DropdownMenuItem.css'

export const enhance = compose(
  setDisplayName('DropdownMenuItem'),
  pure,
  onlyUpdateForPropTypes,
  setPropTypes({
    children: PropTypes.any.isRequired,
    action: PropTypes.bool,
    onClick: PropTypes.func
  })
)

export const View = ({children, classes, action = false, onClick = () => {}}) => (
  <div className={`DropdownMenuItem${classes ? ' ' + classes : ''}`} data-action={action} onClick={(e) => e.preventDefault() || e.stopPropagation() || onClick(e)}>
    {children}
  </div>
)

export const DropdownMenuItem = enhance(View)
