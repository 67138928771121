import React, { Fragment } from 'react'
import { path, concat, toString, prop, pipe, __ } from 'ramda'

const getStyle = () => ({
  width: prop('innerWidth', window),
  height: prop('innerHeight', window)
})

export const px = pipe(toString, concat(__, 'px'))

export class WithWindowHeightStyle extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      style: getStyle()
    }
    this.onResize = this.onResize.bind(this)
  }
  componentDidMount () {
    window.addEventListener('resize', prop('onResize', this))
  }
  componentWillUnmount () {
    window.removeEventListener('resize', prop('onResize', this))
  }
  onResize () {
    const style = getStyle()
    this.setState({
      style: style
    })
  }
  render () {
    const style = path(['state', 'style'], this)
    const render = path(['props', 'render'], this)
    return (
      <Fragment>
        { render(style) }
      </Fragment>
    )
  }
}
