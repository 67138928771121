import { combineReducers } from 'redux'
import { TYPES } from '../actions/adobe'
import { equals, cond, T, always, when, propEq, map, filter } from 'ramda'

const updateVendor = (state, vendor) => map(when(
  propEq('email', vendor.email),
  always(vendor)
), state)

const removeVendor = (state, vendorEmail) => filter(when(
  propEq('email', vendorEmail),
  always(false)
), state)

const vendors = (state = [], action) => cond([
  [equals(TYPES.SET_VENDORS), () => action.payload ? action.payload : state],
  [equals(TYPES.UPDATE_VENDOR), () => updateVendor(state, action.payload)],
  [equals(TYPES.REMOVE_VENDOR), () => removeVendor(state, action.payload)],
  [T, always(state)]
])(action.type)

const defaultVendor = (state = null, action) => cond([
  [equals(TYPES.SET_DEFAULT), always(action.payload)],
  [T, always(state)]
])(action.type)

const vendorsIds = (state = [], action) => cond([
  [equals(TYPES.SET_VENDORS_IDS), () => action.payload ? action.payload : state],
  [T, always(state)]
])(action.type)

const addVendorFormStatus = (state = null, action) => cond([
  [equals(TYPES.SET_VENDOR_FORM_STATUS), always(action.payload)],
  [T, always(state)]
])(action.type)

const addPasswordFormStatus = (state = null, action) => cond([
  [equals(TYPES.SET_PASSWORD_FORM_STATUS), always(action.payload)],
  [T, always(state)]
])(action.type)

export const adobeReducer = combineReducers({
  vendors: vendors,
  defaultVendor: defaultVendor,
  vendorsIds: vendorsIds,
  addVendorFormStatus: addVendorFormStatus,
  passwordFormStatus: addPasswordFormStatus
})
