import React from 'react'
import PropTypes from 'prop-types'
import { compose, setDisplayName, pure, onlyUpdateForPropTypes, setPropTypes, withHandlers, defaultProps } from 'recompose'

import './DropdownMenu.css'

let CLOSE_TIMEOUT = null

const propTypes = setPropTypes({
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  active: PropTypes.bool.isRequired
})

const onLeave = ({onClose}) => (timer = 1000) => {
  CLOSE_TIMEOUT = setTimeout(() => {
    onClose({isInner: true})
  }, timer)
}

const onEnter = () => () => {
  clearTimeout(CLOSE_TIMEOUT)
}

const handlers = withHandlers({
  onLeave,
  onEnter
})

export const enhance = compose(
  setDisplayName('DropdownMenu'),
  pure,
  onlyUpdateForPropTypes,
  propTypes,
  defaultProps({
    onClose: () => {}
  }),
  handlers,
)

export const View = ({active, children, onLeave, onEnter, withoutLeave}) => (
  <div
    className="DropdownMenu"
    onMouseLeave={withoutLeave ? null : onLeave}
    onMouseEnter={onEnter}
    data-active={active}>
    {children}
  </div>
)

export const DropdownMenu = enhance(View)
