import React from 'react'
import { connect } from 'react-redux'
import { compose, setDisplayName } from 'recompose'
import { toggleActive } from '../../../../../../actions/books'
import { Arrow } from '../../../../../Shared/InnerLink/InnerLink'
import { getLocaleString } from '../../../../../../locale'

import './UploadBooksStatusBar.css'

const mapStateToProps = state => ({
  totalSize: state.books.uploadingBooks.totalSize,
  uploadedSize: state.books.uploadingBooks.uploadedSize,
  files: state.books.uploadingBooks.files,
})

const mapDispathToProps = (dispatch) => ({
  toggleActive: (status) => dispatch(toggleActive(status))
})

export const enhance = compose(
  setDisplayName('UploadBooksStatusBar'),
  connect(mapStateToProps, mapDispathToProps),
)

const filesUploaded = (files) => {
  const uploaded = files.filter(file => file.status === 'loading')
  return uploaded.length
}

export const View = ({files, totalSize, uploadedSize, toggleActive}) => (
  <div className="UploadBooksStatusBar">
    <h2 className="UploadBooksStatusBar__percentage">{getLocaleString('books.uploading')}{`${Math.round((uploadedSize * 100) / totalSize)}% ${getLocaleString('books.completed')}`}</h2>
    <h2>{`${filesUploaded(files)} ${getLocaleString('books.from')} ${files.length} ${getLocaleString('books.filesLeft')}`}</h2>
    <button className="UploadBooksStatusBar__button" onClick={() => toggleActive()}>{getLocaleString('books.seeDetails')}<Arrow /></button>
  </div>
)

export const UploadBooksStatusBar = enhance(View)
