const notifications = {
  noNotifications: 'No unread notifications',
  spaceAlmostOver: 'Free space is almost over',
  bookAdded: 'New book is added',
  cannotBeLoaded: 'Not enough space to upload book',
  alreadyInCloud: 'is already in your cloud',
  errorHappened: 'an error happened while uploading',
  formatNotSupported: 'This format is not supported',
  licenseCancelled: 'License has been cancelled',
  licenseExpired: 'License is expired',
  licenseRevoked: 'License is revoked',
  withoutLcpToken: 'User has no Lcp-token',
  incorrectLcpToken: 'User has an incorrect Lcp key',
  errorLoginText: 'Lcp DRM vendor not found for user',
  oldCloud: 'If you want to use the old interface , go to Settings → Interface',

  plsLogin: 'Please login with Lcp account',
  tryAgain: 'Try again',
  doneLoginText: 'Login Successful',
  getMoreSpace: 'Get more space',
  view: 'view',

  bookmarkAdded: 'Bookmark added',
  bookmarkError: 'This position already has a bookmark',

  'error303': 'is already in your cloud',
  'error325': 'this format is not supported',
  'error311': 'file not found',

  'error312': 'File is empty',
  'error320': 'Invalid file data',
  'error321': 'Can not create directory or file',
  'error322': 'Can not save file changes',
  'error324': 'File path is empty or invalid',
  'error326': 'File mime type is not found or is not supported',
  'error334': 'User has no enough free space left to upload the file',
  'error335': 'File size doesn\'t much with header param "Content-Length" or file md5 hash doesn\'t much with header param "file_md5"',
  'error339': 'Can not parse metadata',
  'error441': 'Invalid downloading url',
  'error442': 'File downloading failed',
  'error443': 'File uploading to azure failed',
  'error450': 'Unknown file service error',
  'error1701': 'Task not found',
  'error1702': 'Task not created',
  'error1703': 'Task not completed',
  'error1704': 'Task failed',
  'error151': 'This book is fulfilled for another account',
  'error152': 'This book is fulfilled only for one (some other) device'
}
export default notifications;